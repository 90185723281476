import {
    MultiSelectType,
    IFormConfig,
    FormControlType,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    IMultiselectOption,
} from 'marine-panel-common-web';

export const berthMarinaFormConfig = (
    value?: any,
    marinas?: IMultiselectOption[] | [],
    defaulMarina?: IMultiselectOption | null
): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },

        controls: [
            {
                key: 'skipper_details',
                controlType: 'group',
                class: 'column',
                controls: {
                    marina: {
                        controlType: 'control',
                        defaultValue: defaulMarina,
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: marinas,
                        validationRules: [],
                        placeholder: '',
                        label: '',
                        hostClass: 'col-xl-12 form-control',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                    },
                },
            },
        ],
    };
};
