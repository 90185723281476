import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {finishApiCall} from '../../utils/reduxHelpers';
import cloneDeep from 'lodash/cloneDeep';
import {AsyncTaskOutput, AsyncWaterApiStatus, LatLng, MarinaRouteNavigation, RouteOutput} from 'marine-panel-common-web';

export interface IRouteFindState {
    calculatedRoutes: {[marinaId: string]: MarinaRouteNavigation};
    routesQueue: {[marinaId: string]: LatLng}[];
    error: string | null;
    isLoading: boolean;
}

export type RouteInput = {
    waypoints: LatLng[];
    vesselTypeId: string;
};

const NULL_ROUTE: MarinaRouteNavigation = {
    marinaId: '',
    status: AsyncWaterApiStatus.DRAFT,
    route: null,
};

const initialState: IRouteFindState = {
    calculatedRoutes: {},
    routesQueue: [],
    error: null,
    isLoading: false,
};

export interface IFindRoute {
    routeInput: RouteInput;
    marinaId: string;
}

export interface IAddMarinasToQueue {
    queue: {[marinaId: string]: LatLng}[];
}

export interface IAddMarinasToQueueSuccess {
    queue: {[marinaId: string]: LatLng}[];
    activeTasks: {[marinaId: string]: LatLng};
}

export interface IRemoveMarinaFromQueue {
    marinaId: string;
}

export interface IFindRouteSuccess {
    route: {[marinaId: string]: MarinaRouteNavigation};
}

export interface ICancelTask {
    task: AsyncTaskOutput<{} | RouteOutput> | null;
}

export interface IFindRouteError {
    error: string;
    marinaId: string;
}

const routeFindSlice = createSlice({
    name: 'routeFind',
    initialState: initialState,
    reducers: {
        findRouteSuccess: {
            reducer: (state: IRouteFindState, action: PayloadAction<IFindRouteSuccess>) => {
                return {
                    ...state,
                    isLoading: false,
                    calculatedRoutes: {...state.calculatedRoutes, ...action.payload.route},
                };
            },
            prepare(route: {[marinaId: string]: MarinaRouteNavigation}) {
                return {
                    payload: {route},
                };
            },
        },
        findRouteError: {
            reducer: (state: IRouteFindState, action: PayloadAction<IFindRouteError>) => {
                let calculatedRoutes: {[marinaId: string]: MarinaRouteNavigation} = cloneDeep(state.calculatedRoutes);
                calculatedRoutes = {
                    ...calculatedRoutes,
                    [action.payload.marinaId]: {...calculatedRoutes[action.payload.marinaId], status: AsyncWaterApiStatus.FAILED},
                };
                return {
                    ...state,
                    calculatedRoutes,
                    isLoading: false,
                    error: action.payload.error,
                };
            },
            prepare(error: string, marinaId: string) {
                return {
                    payload: {error, marinaId},
                };
            },
        },
        findRoute: {
            reducer: (state: IRouteFindState) => {
                return {
                    ...state,
                    isLoading: false,
                    error: null,
                };
            },
            prepare(routeInput: IFindRoute) {
                return {
                    payload: routeInput,
                };
            },
        },
        resetQueue: {
            reducer: (state: IRouteFindState) => {
                return {
                    ...state,
                    calculatedRoutes: {},
                };
            },
            prepare() {
                return {payload: {}};
            },
        },
        addMarinasToQueueSuccess: {
            reducer: (state: IRouteFindState, action: PayloadAction<IAddMarinasToQueueSuccess>) => {
                const queue = [...state.routesQueue, ...action.payload.queue];

                const newRoutesInCalculation: {[marinaId: string]: MarinaRouteNavigation} = {};
                Object.keys(action.payload.activeTasks).forEach(
                    (marinaId) => (newRoutesInCalculation[marinaId] = Object.assign({}, NULL_ROUTE, {marinaId: marinaId}))
                );

                const calculatedRoutes = {...state.calculatedRoutes, ...newRoutesInCalculation};
                return {
                    ...state,
                    routesQueue: queue,
                    error: null,
                    calculatedRoutes,
                };
            },
            prepare(queue: IAddMarinasToQueueSuccess) {
                return {
                    payload: queue,
                };
            },
        },
        removeMarinaFromQueue: {
            reducer: (state: IRouteFindState, action: PayloadAction<IRemoveMarinaFromQueue>) => {
                const queue = state.routesQueue.filter((element) => Object.keys(element)[0] !== action.payload.marinaId);

                return {
                    ...state,
                    routesQueue: queue,
                    error: null,
                };
            },
            prepare(marinaId: IRemoveMarinaFromQueue) {
                return {
                    payload: marinaId,
                };
            },
        },
        addMarinasToQueue: (state: IRouteFindState, action: PayloadAction<IAddMarinasToQueue>) => finishApiCall(state, action),
        cancelTask: {
            reducer: (state: IRouteFindState) => {
                return {
                    ...state,
                };
            },
            prepare(task: AsyncTaskOutput<{} | RouteOutput> | null) {
                return {
                    payload: {
                        task: task,
                    },
                };
            },
        },
        resetToInitialFindRouteState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    findRouteSuccess,
    findRoute,
    findRouteError,
    addMarinasToQueue,
    removeMarinaFromQueue,
    addMarinasToQueueSuccess,
    resetQueue,
    cancelTask,
    resetToInitialFindRouteState,
} = routeFindSlice.actions;
export default routeFindSlice.reducer;
