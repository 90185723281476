import {LatLngTuple} from 'leaflet';
import {
    Form,
    FormControlChangeType,
    GeoPolygon,
    IMultiselectOption,
    Loader,
    Translation,
    isNotNullOrUndefined,
} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../store/reducers';
import {IChangeBerthLocationPayload, fetchMarinaList} from '../../../../../store/reducers/berthEditWizardSlice';
import {InitWizardStep, changeCurrentStep, createFirstBerth} from '../../../../../store/reducers/berthInitWizardSlice';
import {SelectedItemType} from '../../../../../store/reducers/mapHostSlice';
import {marinaListSelector, sectorListSelector} from '../../../../../store/selectors/berthEditWizardSelectors';
import {isBerthInitWizardLoadingSelector} from '../../../../../store/selectors/berthInitWizardSelectors';
import LocationInput from '../../../WizardEdit/WizardContent/Common/SetLocationModal/LocationInput';
import WizardFormNavigationButtons from '../../Common/WizardFormNavigationButtons';
import WizardFormWrapper from '../../Common/WizardFormWrapper';
import {berthMarinaFormConfig} from './berthMarinaFormConfig';
import {berthNameFormConfig} from './berthNameFormConfig';

interface IBaseInformationProps {
    readonly marinasList: IMultiselectOption[] | [];
    readonly sectorList: any[] | [];
    readonly createFirstBerth: typeof createFirstBerth;
    readonly fetchMarinaList: typeof fetchMarinaList;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly isLoading: boolean;
}

interface IBaseInformationValues {
    name: string | null;
    marina: string | null;
    location: any[] | null;
    mapCenter: LatLngTuple | null;
}

const BaseInformation: FC<IBaseInformationProps> = ({sectorList, changeCurrentStep, isLoading}) => {
    const dispatch = useDispatch(),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<IBaseInformationValues>({
            name: null,
            marina: null,
            location: null,
            mapCenter: null,
        });
    useEffect(() => {
        dispatch(fetchMarinaList());
    }, [dispatch]);

    // left when data from state will be available TODO - PT
    // useEffect(() => {
    //     if (berth !== null) {
    //         setValue({
    //             name: berth ? berth.name : null,
    //             description: berth ? berth.description : null,
    //             marina: berth?.sector ? berth?.sector?.marina?.name : undefined,
    //         });
    //     }
    // }, [berth]);

    useEffect(() => {
        const subscriptions: Subscription[] = [];

        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState) => {
                            const formValues = {
                                name: data.value && isNotNullOrUndefined(data.value.name) ? data.value.name : prevState?.name,
                                marina: data.value && data.value.marina ? data.value.marina : prevState?.marina,
                                location: prevState?.location ? prevState?.location : null,
                                mapCenter: prevState?.mapCenter ? prevState?.mapCenter : null,
                            };
                            if (data.controlName === 'berthMarinaForm') {
                                if (data.value?.marina && sectorList) {
                                    const marina = sectorList.find((item) => item.value === data.value?.marina);
                                    formValues.mapCenter = marina?.location;
                                }
                            }
                            return formValues;
                        });
                    }
                })
        );
    }, [onValueStateChange$$, sectorList]);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    // const defaultMarina = {
    //     value: berth?.sector?.marina ? berth?.sector?.marina?.id : '',
    //     label: berth?.sector?.marina ? berth?.sector?.marina?.name : '',
    // };
    const locationPayload: GeoPolygon | null = values.location ? {vertexes: values.location} : null;

    const isSubmitAllowed = isNotNullOrUndefined(values?.name) && values?.name !== '' && isNotNullOrUndefined(locationPayload);
    const onConfirmAction = () => {
        if (isSubmitAllowed) {
            const payload = {
                name: values?.name ? values.name : '',
                location: locationPayload,
                sectorId: values.marina,
            };
            dispatch(createFirstBerth(payload.sectorId, payload.name, payload.location));
        }
    };
    const goToCreateMarinaStep = () => {
        changeCurrentStep(InitWizardStep.CREATE_FIRST_MARINA);
    };

    return (
        <div id="base_information" className="wizard-init-form-wrapper" data-section="berth_basics">
            <h3 className="form-title">
                <Translation text="editMenuItems.sections.berth_basics.title" />
            </h3>

            <WizardFormWrapper
                currentItemId={'berth_name'}
                titleTranslationKey="editMenuItems.sections.berth_basics.formControls.berth_name"
                subTitleTranslationKey="editMenuItems.sections.berth_basics.formControls.berth_name_description"
                children={
                    <Form
                        controlName={'berthNameForm'}
                        config={berthNameFormConfig()}
                        value={values}
                        onValueStateChange={onValueStateChange}
                    />
                }
            />

            <WizardFormWrapper
                currentItemId={'berth_marina'}
                titleTranslationKey="editMenuItems.sections.berth_basics.formControls.berth_marina"
                children={
                    <>
                        <Form
                            controlName="berthMarinaForm"
                            value={values}
                            config={berthMarinaFormConfig(sectorList)}
                            onValueStateChange={onValueStateChange}
                        />
                        <div className="create-marina-wrapper">
                            <p className="d-flex align-items-center">
                                <Translation text="editMenuItems.sections.berth_basics.cantFindMarina" />
                            </p>
                            <button className="sm-btn smaller btn-black-white" onClick={goToCreateMarinaStep}>
                                <Translation text="buttons.createMarine" />
                            </button>
                        </div>
                    </>
                }
            />
            <WizardFormWrapper
                currentItemId={'berth_location'}
                titleTranslationKey="editMenuItems.sections.berth_basics.formControls.berth_location"
                subTitleTranslationKey="editMenuItems.sections.berth_basics.formControls.berth_location_description"
                children={
                    <div className="location-input-wrapper">
                        <LocationInput
                            updatedCoords={(coords: IChangeBerthLocationPayload) =>
                                setValue((prevState: any) => ({...prevState, location: coords.location}))
                            }
                            createdCoords={(coords: any) => setValue((prevState: any) => ({...prevState, location: coords}))}
                            locationItem={SelectedItemType.BERTH}
                            predefinedLocation={values.mapCenter ? values.mapCenter : null}
                        />
                    </div>
                }
            />
            <WizardFormNavigationButtons
                submitAllowed={isSubmitAllowed}
                currentStepId={InitWizardStep.BASE_INFORMATION}
                handleBack={() => null}
                handleNext={() => onConfirmAction()}
            />
            <Loader showLoader={isLoading} />
        </div>
    );
};
export default connect(
    (state: RootState) => ({
        marinasList: marinaListSelector(state),
        sectorList: sectorListSelector(state),
        isLoading: isBerthInitWizardLoadingSelector(state),
    }),
    {
        fetchMarinaList,
        createFirstBerth,
        changeCurrentStep,
    }
)(BaseInformation);
