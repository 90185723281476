import {marineHubAPI, RestQueryParams, PricingStrategyDefinitionInput} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function createPricingStrategyDefinitionAPI(
    authToken: string | null,
    actionPayload?: PricingStrategyDefinitionInput
): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return marineHubAPI.post(`api/pricing_strategy_definitions`, actionPayload, new RestQueryParams(), headers);
}
