import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ITransactionHistoryState} from '../reducers/transactionHistory';

export const selectTransactionHistoryPage = (state: RootState) => {
    return state.transactionHistory;
};

export const transactionHistorySelector = createSelector(
    [selectTransactionHistoryPage],
    (state: ITransactionHistoryState) => state.transactionHistory
);

export const transactionHistoryFiltersSelector = createSelector(
    [selectTransactionHistoryPage],
    (state: ITransactionHistoryState) => state.transactionHistoryFilters
);

export const isTransactionHistoryPageLoadingSelector = createSelector(
    [selectTransactionHistoryPage],
    (state: ITransactionHistoryState) => state.isTransactionHistoryLoading
);

export const isTransactionHistoryPageInitializedSelector = createSelector(
    [selectTransactionHistoryPage],
    (state: ITransactionHistoryState) => state.isTransactionHistoryInitialized
);

export const transactionHistoryErrorSelector = createSelector(
    [selectTransactionHistoryPage],
    (state: ITransactionHistoryState) => state.transactionHistoryError
);
