import {RestQueryParams, marineHubAPI, IRawRestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IStripeLoginPayload {
    redirectUrl: string;
}

export function generateStripeLoginLink(
    authToken: string | null,
    accountId: string,
    payload: IStripeLoginPayload,
    params?: IRawRestQueryParams
): Observable<any> {
    const queryParams: RestQueryParams = params ? new RestQueryParams(params) : new RestQueryParams(),
        headers = {
            Authorization: `Bearer ${authToken}`,
        };

    return marineHubAPI.put(`api/vendor_payment_accounts/stripe/${accountId}/generate_stripe_login_link`, payload, queryParams, headers);
}
