import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import {
    Form,
    FormControlChangeType,
    IFormConfig,
    accountIsLoadingSelector,
    Translation,
    Loader,
    LoaderType,
    basicAccountDataSelector,
    updateOperatorFullAccount,
    IUpdateOperatorFullAccountPayload,
} from 'marine-panel-common-web';
import {share, filter} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {changeAccountInformationFormConfig} from './changeAccountInformationFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';

interface IAccountInformationProps {
    readonly account: any;
    readonly updateOperatorFullAccount: typeof updateOperatorFullAccount;
    readonly accountLoading: boolean;
}

const AccountInformation: React.FC<IAccountInformationProps> = ({account, updateOperatorFullAccount, accountLoading}) => {
    const [value, setValue] = useState<{[key: string]: any} | null>(null),
        [isFormValid, setIsFormValid] = useState<boolean>(false),
        [formConfig, setFormConfig] = useState<IFormConfig | null>(changeAccountInformationFormConfig()),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        setAccountInformationFormConfig();
    }, [account]);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    function changeAccountInformation() {
        if (value === null) {
            return;
        }

        const payload: IUpdateOperatorFullAccountPayload = {
            operator: {
                operator: {
                    name: account?.operator?.name ? account.operator.name : `${value.firstName} ${value.lastName}`,
                    address: account?.operator?.address,
                    city: account?.operator?.city,
                    countryId: account?.operator?.country?.id,
                },
            },
            firstName: value.firstName,
            lastName: value.lastName,
            mobile: value.phone,
        };

        updateOperatorFullAccount({accountPayload: payload});
    }

    function onFormValidityChange(controlName: string, isValid: boolean) {
        setIsFormValid(isValid);
    }

    function discardFormValue() {
        setValue(null);
    }

    function setAccountInformationFormConfig() {
        if (account) {
            const formValues = {
                    firstName: account.user?.firstName,
                    lastName: account.user?.lastName,
                    phone: account.user?.mobile,
                },
                formConfig = changeAccountInformationFormConfig();

            setFormConfig(formConfig);
            setValue(formValues);
        }
    }

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        setValue(data.value);
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    return (
        <div>
            {formConfig && (
                <Form
                    config={formConfig}
                    onValueStateChange={onValueStateChange}
                    value={value}
                    controlName={'profileForm'}
                    onValidationStateChange={onFormValidityChange}
                />
            )}

            <div className={styles.btnWrapper}>
                <button
                    className="btn btn-underline btn-underline-primary discard-changes-button"
                    onClick={() => discardFormValue()}
                    disabled={!value}>
                    <Translation text="buttons.discard" />
                </button>
                <button
                    className="btn btn-create change-password-button"
                    onClick={() => changeAccountInformation()}
                    disabled={!isFormValid}>
                    <Translation text="buttons.saveChanges" />
                </button>
            </div>

            <Loader type={LoaderType.Local} showLoader={accountLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        accountLoading: accountIsLoadingSelector(state),
        account: basicAccountDataSelector(state),
    }),
    {updateOperatorFullAccount}
)(AccountInformation);
