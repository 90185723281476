import {Form, FormControlChangeType, IconSize, Translation} from 'marine-panel-common-web';
import * as React from 'react';
import {CheckCircle, MinusCircle, X} from 'react-feather';
import {icon} from '..';
import {addTag, removeTag} from '../../../../../../../store/reducers/berthsTagsSlice';
import styles from '../style.module.scss';
import {tagDescriptionFormConfig} from '../tagDescriptionFormConfig';

interface ITagEditItemProps {
    currentItem: any;
    editedItemId: string | null | undefined;
    value: any;
    isProperUserRole: boolean;
    switchEditing: (item: any, description?: string) => void;
    onValueStateChange: (controlName: string, value: any, changeType: FormControlChangeType) => void;
    changeDescription: (id: string, value: string) => void;
    changeTag: (value: string, method: any) => void;
}

const TagEditItem: React.FunctionComponent<ITagEditItemProps> = (props) => {
    const isEdited: boolean = props.editedItemId === props.currentItem?.id;
    const renderEditMode = (item: any) => {
        return (
            <section key={item?.id} id={item?.id} data-section={item?.id} className="details-edit-form-control edit-mode">
                <div className="edit-form-control-body-wrapper">
                    <div className="edit-form-control-header">
                        <h3 className="title">{item.tag.name ? item.tag.name : item?.name}</h3>
                        <button id={item?.id} className="btn btn-underline" onClick={() => props.switchEditing(null)}>
                            <X size={IconSize.BerthDeleteButton} />
                        </button>
                    </div>
                    <p className="edit-form-control-subtitle">
                        <Translation text="berths.berthDetails.berthTags.tagSubtitle" />
                    </p>
                    <Form
                        controlName={'tagDescriptionForm'}
                        config={tagDescriptionFormConfig(props.value.description)}
                        value={props.value?.description}
                        onValueStateChange={props.onValueStateChange}
                    />
                </div>
                <div className="edit-form-control-footer">
                    <button
                        className="sm-btn btn btn-underline btn-underline-primary close-tag-update-button"
                        id={item?.id}
                        onClick={() => props.switchEditing(null)}>
                        <Translation text="buttons.cancel" />
                    </button>
                    <button
                        className="btn sm-btn btn-create update-berth-tag-button"
                        onClick={() => {
                            props.changeDescription(item?.tag.id, props.value.description);
                        }}>
                        <Translation text="buttons.update" />
                    </button>
                </div>
            </section>
        );
    };

    const renderDisplayMode = (item: any) => {
        return (
            <div className={styles.tagItem} key={item.name + item.id}>
                <div className={styles.tagInfo}>
                    <div className={styles.tagIcon}>{icon(item?.tag?.code ? item?.tag?.code : item?.code)}</div>
                    <div>
                        <p className={styles.tagName}>{item?.tag?.name ? item.tag.name : item?.name}</p>
                        {item?.description !== undefined ? <p className={styles.tagDescription}>{item?.description}</p> : null}
                    </div>
                </div>
                <div className={styles.btns}>
                    {props.isProperUserRole && (
                        <button
                            disabled={item?.description === undefined}
                            className="btn btn-underline"
                            id={item?.id}
                            onClick={() => props.switchEditing(item?.id, item?.description)}>
                            <Translation text="buttons.changeDescription" />
                        </button>
                    )}
                    <button
                        className="btn btn-icon"
                        disabled={item?.description !== undefined}
                        onClick={() => (props.isProperUserRole ? props.changeTag(item.id, addTag) : null)}>
                        <CheckCircle size={IconSize.XLarge} />
                    </button>
                    <button
                        className="btn btn-icon"
                        disabled={item?.description === undefined}
                        onClick={() => (props.isProperUserRole ? props.changeTag(item?.tag.id, removeTag) : null)}>
                        <MinusCircle size={IconSize.XLarge} />
                    </button>
                </div>
            </div>
        );
    };
    const currentlyEditedTag = props.currentItem;
    return currentlyEditedTag ? (
        <div className={isEdited ? 'details-edit-section' : ''}>
            {isEdited ? renderEditMode(currentlyEditedTag) : renderDisplayMode(currentlyEditedTag)}
        </div>
    ) : null;
};

export default TagEditItem;
