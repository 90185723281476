import {InitWizardStep} from '../../../../store/reducers/berthInitWizardSlice';
import Tags from '../../WizardEdit/WizardContent/Berth/Tags';
import BaseInformation from './BaseInformation';
import WizardInitPricingStrategy from './PricingStrategy';
import WizardInitCreateFirstMarina from './CreateFirstMarina';
import PoliciesAndRules from '../../WizardEdit/WizardContent/Berth/PoliciesAndRules';
import Gallery from '../../WizardEdit/WizardContent/Berth/Gallery';

export type WizardStep = 'base_information' | 'photos' | 'tags' | 'pricing_strategy' | 'policies_and_rules' | 'well_done';

export type IInitWizardStep = {
    step: InitWizardStep;
    stepImage: string;
    closeButtonMessage: string;
    stepComponent: JSX.Element;
    mainTitle: string;
    formName: string;
    description?: string;
    secondaryTitle?: string;
    secondaryDescription?: string;
    additionalTitle?: string;
    additionalDescription?: string;
};

const Step1Jpg = require('../../../../assets/images/berthInit/step_1.jpg');
const Step2Jpg = require('../../../../assets/images/berthInit/step_2.jpg');
const Step3Jpg = require('../../../../assets/images/berthInit/step_3.jpg');
const Step4Jpg = require('../../../../assets/images/berthInit/step_4.jpg');
const Step5Jpg = require('../../../../assets/images/berthInit/step_5.jpg');
const Step6Jpg = require('../../../../assets/images/berthInit/step_6.jpg');

export const additionalWizardStep: IInitWizardStep = {
    step: InitWizardStep.CREATE_FIRST_MARINA,
    stepImage: Step1Jpg,
    closeButtonMessage: 'initWizard.create_first_marina.closeButtonMessage',
    stepComponent: <WizardInitCreateFirstMarina />,
    mainTitle: 'initWizard.create_first_marina.title',
    formName: 'initWizard.create_first_marina.formName',
};

export const initWizardSteps: IInitWizardStep[] = [
    {
        step: InitWizardStep.BASE_INFORMATION,
        stepImage: Step1Jpg,
        closeButtonMessage: 'initWizard.base_information.closeButtonMessage',
        stepComponent: <BaseInformation />,
        mainTitle: 'initWizard.base_information.title',
        formName: 'initWizard.base_information.formName',
        description: 'initWizard.base_information.description',
        secondaryTitle: 'initWizard.base_information.secondary_title',
        secondaryDescription: 'initWizard.base_information.secondary_description',
        additionalTitle: 'initWizard.base_information.additional_title',
        additionalDescription: 'initWizard.base_information.additional_description',
    },
    {
        step: InitWizardStep.PHOTOS,
        stepImage: Step2Jpg,
        closeButtonMessage: 'initWizard.photos.closeButtonMessage',
        stepComponent: <Gallery isInitWizard={true} />,
        mainTitle: 'initWizard.photos.title',
        formName: 'initWizard.photos.formName',
        description: 'initWizard.photos.description',
        secondaryTitle: 'initWizard.photos.secondary_title',
        secondaryDescription: 'initWizard.photos.secondary_description',
        additionalTitle: 'initWizard.photos.additional_title',
        additionalDescription: 'initWizard.photos.additional_description',
    },
    {
        step: InitWizardStep.TAGS,
        stepImage: Step3Jpg,
        closeButtonMessage: 'initWizard.tags.closeButtonMessage',
        stepComponent: <Tags />,
        mainTitle: 'initWizard.tags.title',
        formName: 'initWizard.tags.formName',
    },
    {
        step: InitWizardStep.PRICING_STRATEGY,
        stepImage: Step4Jpg,
        closeButtonMessage: 'initWizard.pricing_strategy.closeButtonMessage',
        stepComponent: <WizardInitPricingStrategy />,
        mainTitle: 'initWizard.pricing_strategy.title',
        formName: 'initWizard.pricing_strategy.formName',
        description: 'initWizard.pricing_strategy.description',
        secondaryTitle: 'initWizard.pricing_strategy.secondary_title',
        secondaryDescription: 'initWizard.pricing_strategy.secondary_description',
        additionalTitle: 'initWizard.pricing_strategy.additional_title',
        additionalDescription: 'initWizard.pricing_strategy.additional_description',
    },
    {
        step: InitWizardStep.POLICIES_AND_RULES,
        stepImage: Step5Jpg,
        closeButtonMessage: 'initWizard.policies_and_rules.closeButtonMessage',
        stepComponent: <PoliciesAndRules isInitWizard={true} />,
        mainTitle: 'initWizard.policies_and_rules.title',
        formName: 'initWizard.policies_and_rules.formName',
    },
    {
        step: InitWizardStep.WELL_DONE,
        stepImage: Step6Jpg,
        closeButtonMessage: 'initWizard.well_done.closeButtonMessage',
        stepComponent: <></>,
        mainTitle: 'initWizard.well_done.title',
        formName: 'initWizard.well_done.formName',
    },
];
