import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IChangeBerthName {
    name: string | null;
}

export function changeBerthNameAPI(
    id: string | null | undefined,
    payload: IChangeBerthName | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return marineHubAPI.put(`api/berths/${id}/change_name`, payload, new RestQueryParams(), headers);
}
