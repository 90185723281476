import {deepCloneObject} from 'marine-panel-common-web';
import {Translation} from 'marine-panel-common-web';

interface ReservationDateRangeProps {
    readonly dates: string[];
}
function ReservationDateRange(props: ReservationDateRangeProps) {
    if (!props.dates || props.dates.length === 0) return null;
    const reservationDateRange = deepCloneObject(props.dates)
        .map((date: string) => new Date(date))
        .sort(sortByDate);
    return (
        <div className="information-details-row">
            <dt className="information-details-label">
                <Translation text={'mainMap.reservation.view.reservationDateRange'} />
            </dt>
            <dd className="information-details-value">
                <span>
                    {reservationDateRange[0].toLocaleDateString()}-
                    {reservationDateRange[reservationDateRange.length - 1].toLocaleDateString()}
                </span>
            </dd>
        </div>
    );
}
function sortByDate(a: Date, b: Date) {
    return a.valueOf() - b.valueOf();
}

export default ReservationDateRange;
