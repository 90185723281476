import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {RootState} from '../reducers';
import {changeIsLoading, getChatContacts, getChatContactsSuccess, setChatError} from '../reducers/chatSlice';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {getChatContactsApi} from '../../api/chat/getChatContactsApi';
import {of} from 'rxjs';
import {accountIdSelector, accountUserIdSelector, addAlert, ApiError, authTokenSelector, handleApiError} from 'marine-panel-common-web';

const errorActions = (error: ApiError) => {
    return [addAlert(handleApiError(error)), changeIsLoading(false), setChatError(error.message)];
};

const getChatContactsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(getChatContacts.type),
        switchMap(() => {
            //TODO account id === userId in here. Naming issue.
            const authToken = authTokenSelector(state$.value),
                accountId = accountUserIdSelector(state$.value);
            if (!accountId) {
                return of(errorActions);
            }
            return getChatContactsApi(accountId, authToken).pipe(
                mergeMap((res) => {
                    return of(getChatContactsSuccess(res), changeIsLoading(false));
                }),
                catchError(errorActions)
            );
        }),
        catchError(errorActions)
    );

const chatEpic = combineEpics(getChatContactsEpic);

export default chatEpic;
