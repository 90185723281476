import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'marine-panel-common-web';

export const contactFormConfig = (): IFormConfig => ({
    controlType: 'form',
    class: 'default-form contact-form container',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'subject',
            class: 'row',
            controls: {
                subject: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'support.form.placeholders.subject',
                    label: 'support.form.labels.subject',
                    isLabelHidden: false,
                    hostClass: 'col-xl-6 form-control',
                    type: InputType.TEXT,
                },
            },
        },
        {
            controlType: 'group',
            key: 'message',
            class: 'row',
            controls: {
                message: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MAX_LENGTH, params: {length: 2000}},
                    ],
                    placeholder: 'support.form.placeholders.message',
                    isLabelHidden: false,
                    label: 'support.form.labels.message',
                    hostClass: 'col-xl-12 form-control', // 'col-xl-12 form-control',
                    type: InputType.TEXT,
                    rows: 5,
                    maxLength: 2000,
                },
            },
        },
        {
            controlType: 'group',
            key: 'submit',
            class: 'row justify-content-end',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn btn-create',
                    buttonName: 'submit',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.send',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
