import {
    MultiSelectType,
    FormControlType,
    IFormConfig,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
} from 'marine-panel-common-web';
import moment from 'moment';

const statusSelectOptions = [
    {label: 'Draft', value: 'draft'},
    {label: 'Active', value: 'active'},
    {label: 'Cancelled', value: 'cancel'},
    {label: 'Finished', value: 'finished'},
];

export const transactionHistoryFiltersFormConfig = (): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'transaction_history_details',
                controlType: 'group',
                class: 'row',
                controls: {
                    date: {
                        controlType: 'control',
                        // defaultValue: '',
                        formControlType: FormControlType.DATE_RANGE_PICKER,
                        validationRules: [],
                        placeholder: 'reservations.transactionHistoryForm.placeholders.date',
                        label: 'reservations.transactionHistoryForm.labels.date',
                        hostClass: 'col-xl-6 form-control',
                        defaultStartValue: new Date(moment().startOf('month').format('YYYY-MM-DD')),
                        defaultEndValue: new Date(moment().endOf('month').format('YYYY-MM-DD')),
                    },
                    status: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        multiselectOptions: statusSelectOptions,
                        validationRules: [],
                        placeholder: 'reservations.transactionHistoryForm.placeholders.status',
                        label: 'reservations.transactionHistoryForm.labels.status',
                        hostClass: 'col-xl-6 form-control',
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                    },
                },
            },
        ],
    };
};
