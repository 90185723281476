import {
    Berth,
    FormControlChangeType,
    IconSize,
    Loader,
    Translation,
    UserRole,
    isNotNullOrUndefined,
    userRolesSelector,
} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {Anchor, Disc, Droplet, Wifi, Zap} from 'react-feather';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../store/reducers';
import LayoutWrapper from '../../../../../LayoutWrapper';
import {fetchBerthDetails} from '../../../../../../store/reducers/berthEditWizardSlice';
import {addTag, changeTagDescription, fetchBerthTags, removeTag} from '../../../../../../store/reducers/berthsTagsSlice';
import {currentlyEditedBerth} from '../../../../../../store/selectors/berthEditWizardSelectors';
import {berthTagsSelector, isBerthTagsLoadingSelector} from '../../../../../../store/selectors/berthTagsSelectors';
import TagEditItem from './TagItem';
import {Card, CardBody, CardTitle} from 'reactstrap';

interface IBerthTagsProps {
    readonly berth: Berth | null;
    readonly tags: any;
    readonly isLoading: boolean;
    readonly fetchBerthDetails: typeof fetchBerthDetails;
    readonly fetchBerthTags: typeof fetchBerthTags;
    readonly addTag: typeof addTag;
    readonly removeTag: typeof removeTag;
    readonly changeTagDescription: typeof changeTagDescription;
    readonly userRole: UserRole[] | null;
    readonly isRouteComponent?: boolean;
}
export enum TagsNames {
    ELECTRICITY = 'electricity',
    WIFI = 'wifi',
    WATERTANK = 'water_tank_refill',
    BUOY = 'buoy',
    ANCHOR = 'anchor',
}
export const icon = (name: string) => {
    let icon = null;
    switch (name) {
        case (name = TagsNames.ELECTRICITY):
            icon = <Zap size={IconSize.XLarge} />;
            break;
        case (name = TagsNames.WATERTANK):
            icon = <Droplet size={IconSize.XLarge} />;
            break;
        case (name = TagsNames.WIFI):
            icon = <Wifi size={IconSize.XLarge} />;
            break;
        case (name = TagsNames.BUOY):
            icon = <Disc size={IconSize.XLarge} />;
            break;
        case (name = TagsNames.ANCHOR):
            icon = <Anchor size={IconSize.XLarge} />;
            break;
    }
    return icon;
};
const BerthTags: FC<IBerthTagsProps> = ({
    berth,
    fetchBerthDetails,
    fetchBerthTags,
    tags,
    isLoading,
    changeTagDescription,
    userRole,
    isRouteComponent,
}) => {
    const params = useParams(),
        berthId = params.id ? params.id : berth?.id,
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [],
        dispatch = useDispatch();
    const [currentlyEditedItem, setCurrentlyEditedItem] = useState<any>(null);
    useEffect(() => {
        dispatch(fetchBerthDetails(berthId ? berthId : ''));
        dispatch(fetchBerthTags());

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);
    const tagsArray = berth?.tags.concat(tags);
    const unique = tagsArray?.reduce((acc: {[key: string]: any}, current: {[key: string]: any}) => {
        const x = acc.find((item: any) => item?.tag?.code === current?.code);
        return !x ? acc.concat([current]) : acc;
    }, []);

    const [value, setValue] = useState<any>({
        description: '',
    });
    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue({
                            description: data?.value?.description,
                        });
                    }
                })
        );
    }, []);
    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }
    function switchEditing(item: any, description?: string) {
        setCurrentlyEditedItem(item);
        setValue({
            description: description ? description : '',
        });
    }
    function changeTag(value: string, method: any) {
        dispatch(method(berthId ? berthId : null, value));
        dispatch(fetchBerthDetails(berthId));
        dispatch(fetchBerthTags());
    }
    function changeDescription(id: string, value: string) {
        dispatch(changeTagDescription(berthId ? berthId : null, id, value));
        dispatch(fetchBerthDetails(berthId));
        dispatch(fetchBerthTags());
        setCurrentlyEditedItem(null);
    }

    const properUserRole =
        userRole?.includes(UserRole.ADMIN) || userRole?.includes(UserRole.OWNER) || userRole?.includes(UserRole.OPERATOR_ADMIN)
            ? true
            : false;
    const renderTagList = (tagsArray: any) => {
        if (!tagsArray || tagsArray?.length === 0 || tagsArray === null) {
            return (
                <p>
                    <Translation text="berths.berthDetails.berthTags.noTags" />
                </p>
            );
        } else {
            const sortedTags = tagsArray
                .filter((item: any) => isNotNullOrUndefined(item))
                .sort((a: {[key: string]: any}, b: {[key: string]: any}) =>
                    (a.tag ? a.tag.code : a.code).localeCompare(b.tag ? b.tag.code : b.code)
                );
            return (
                <div className="details-edit-section-wrapper">
                    {sortedTags?.map((item: {[key: string]: any}) => (
                        <TagEditItem
                            key={item.id}
                            switchEditing={switchEditing}
                            changeTag={changeTag}
                            changeDescription={changeDescription}
                            currentItem={item}
                            editedItemId={currentlyEditedItem}
                            value={value}
                            onValueStateChange={onValueStateChange}
                            isProperUserRole={properUserRole}
                        />
                    ))}
                </div>
            );
        }
    };
    return isRouteComponent ? (
        <LayoutWrapper>
            <Card className="tags-card">
                <CardTitle>
                    <div className="card-header">
                        <h2 className="title main">
                            <Translation text="berths.berthDetails.berthTags.title" />
                        </h2>
                    </div>
                </CardTitle>

                <CardBody>{renderTagList(unique)}</CardBody>

                <Loader showLoader={isLoading} />
            </Card>
        </LayoutWrapper>
    ) : (
        <div>
            <h2 className="title main">
                <Translation text="berths.berthDetails.berthTags.title" />
            </h2>
            {renderTagList(unique)}
            <Loader showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        berth: currentlyEditedBerth(state),
        tags: berthTagsSelector(state),
        isLoading: isBerthTagsLoadingSelector(state),
        userRole: userRolesSelector(state),
    }),
    {fetchBerthDetails, fetchBerthTags, addTag, removeTag, changeTagDescription}
)(BerthTags);
