import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function archiveBerthAPI(authToken: string | null, berthId: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${berthId}/archive`, {}, new RestQueryParams(), headers);
}
