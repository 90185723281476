import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IChangeMaxLength {
    maxLength: string;
}

export function changeMaxLengthAPI(
    id: string | null | undefined,
    payload: IChangeMaxLength | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/change_max_length`, payload, new RestQueryParams(), headers);
}
