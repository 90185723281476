import {PayloadAction} from '@reduxjs/toolkit';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    isNullOrUndefined,
    getMetadataDetails,
    flattenObj,
    deepCloneObject,
} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, concatMap, filter, map, switchMap, tap} from 'rxjs/operators';
import {getBerthAPI} from '../../api/berth/getBerth';
import {getBerthsAPI} from '../../api/berth/getBerths';
import {getSelectedMarinaBerthsAPI} from '../../api/berth/getSelectedMarinaBerths';
import {RootState} from '../reducers';
import {
    applyBerthFilters,
    changeBerths,
    changeIsBerthInitialized,
    changeIsBerthLoading,
    changePagination,
    fetchAllBerths,
    fetchBerthDetails,
    fetchBerths,
    fetchSpecificMarinaBerths,
    IFetchBerthDetails,
    setActiveBerth,
    setBerthError,
    setMetadata,
} from '../reducers/berthsSlice';
import {applyReservationsFilters, changeReservationCountFilters, changeReservationsFilters} from '../reducers/reservationsSlice';
import {berthPaginationSelector, berthFiltersSelector} from '../selectors/berthSelectors';
import {reservationCountFiltersSelector} from '../selectors/reservationSelectors';

const fetchBerthsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, fetchBerths, 'berths', changeBerths, changeIsBerthLoading, changeIsBerthInitialized, setBerthError);

const applyBerthFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getAction(
        action$,
        state$,
        applyBerthFilters,
        'berths',
        changeBerths,
        changeIsBerthLoading,
        changeIsBerthInitialized,
        setBerthError
    );
};

const changePaginationEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, changePagination, 'berths', changeBerths, changeIsBerthLoading, changeIsBerthInitialized, setBerthError);

const fetchBerthDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(fetchBerthDetails.type),

        concatMap((action: PayloadAction<IFetchBerthDetails>) => {
            const authToken = authTokenSelector(state$.value);
            return getBerthAPI(action.payload.berthId, authToken).pipe(
                switchMap((resp: any) => {
                    const reservationFilters = {
                        'berth.id': resp.id,
                    };
                    const updatedReservationCountFilters = deepCloneObject(reservationCountFiltersSelector(state$.value));
                    if (updatedReservationCountFilters) {
                        updatedReservationCountFilters['berth.id'] = resp.id;
                        const actions = [
                            setActiveBerth(resp),
                            changeIsBerthLoading(false),
                            changeReservationsFilters(reservationFilters),
                            applyReservationsFilters(),
                            changeReservationCountFilters(updatedReservationCountFilters),
                        ];
                        return of(...actions);
                    }
                    const actions = [
                        setActiveBerth(resp),
                        changeIsBerthLoading(false),
                        changeReservationsFilters(reservationFilters),
                        applyReservationsFilters(),
                    ];
                    return of(...actions);
                }),
                catchError((error) => of(...getErrorMessage(error)))
            );
        }),
        catchError(() => {
            return of();
        })
    );

const fetchSpecificMarinaBerthsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchSpecificMarinaBerths.type),
        switchMap((action: PayloadAction<any>) => {
            const authToken = authTokenSelector(state$.value);
            return getSelectedMarinaBerthsAPI(authToken, action.payload.marinaId).pipe(
                switchMap((resp): any => {
                    const actions = [changeBerths(resp), changeIsBerthLoading(false)];

                    return of(...actions);
                }),
                catchError((error) => of(...getErrorMessage(error)))
            );
        }),
        catchError((error) => of(...getErrorMessage(error)))
    );
};

const fetchAllBerthsEpic: Epic = (action$) =>
    action$.pipe(
        ofType(fetchAllBerths.type),
        concatMap(() => {
            return of(fetchBerths());
        }),
        catchError(() => {
            return of();
        })
    );

export type FetchAction = {
    token: string | null;
    dictionaryName: string | null;
    changeSliceList: any;
    changeIsSliceLoading: any;
    changeIsSliceInitialized: any;
    setSliceError: any;
    pagination: any;
    filters: any;
};

const fetchSubject = new BehaviorSubject<FetchAction>({
    token: null,
    dictionaryName: null,
    changeSliceList: null,
    changeIsSliceLoading: null,
    changeIsSliceInitialized: null,
    setSliceError: null,
    pagination: null,
    filters: null,
});

const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject
    .asObservable()
    .pipe(
        concatMap((fetch) => {
            if (isNullOrUndefined(fetch.token) || isNullOrUndefined(fetch.dictionaryName)) {
                return of(null);
            }

            return getBerthList(
                fetch.changeSliceList,
                fetch.changeIsSliceLoading,
                fetch.changeIsSliceInitialized,
                fetch.setSliceError,
                fetch.token ? fetch.token : '',
                fetch.pagination,
                fetch.filters
            );
        }),
        tap((action) => resultsSubject.next(action))
    )
    .subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (
    state: RootState,
    dictionaryName: string,
    changeSliceList: null,
    changeIsSliceLoading: null,
    changeIsSliceInitialized: null,
    setSliceError: null
) => {
    const authToken = authTokenSelector(state),
        paginationParams = berthPaginationSelector(state),
        filters = berthFiltersSelector(state);
    fetchSubject.next({
        token: authToken,
        dictionaryName: dictionaryName,
        changeSliceList: changeSliceList,
        changeIsSliceLoading: changeIsSliceLoading,
        changeIsSliceInitialized: changeIsSliceInitialized,
        setSliceError: setSliceError,
        pagination: paginationParams,
        filters: filters,
    });

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap((action) => of(action))
    );
};

const getBerthList = (
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any,
    setSliceError: any,
    authToken: string,
    pagination: {[key: string]: any},
    filters: {[key: string]: any}
) => {
    const filtersAndParams = {
            ...filters,
            ...pagination,
        },
        flattened: any = flattenObj(filtersAndParams);
    return getList(
        getBerthsAPI(authToken, flattened),
        (resp: any) => fetchListSuccessActions(resp, changeSliceList, changeIsSliceLoading, changeIsSliceInitialized),
        (error: any) => fetchListErrorActions(error, setSliceError, changeIsSliceLoading)
    );
};

export const getList = (api: Observable<any>, successActions: (resp: any) => any[], errorActions: (error: any) => any[]) => {
    return api.pipe(
        switchMap((resp: any) => successActions(resp)),
        catchError((error: any) => of(...errorActions(error)))
    );
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    dictionaryName: string,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any,
    setSliceError: any
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap((state: RootState) => {
            return doFetch(state, dictionaryName, changeSliceList, changeIsSliceLoading, changeIsSliceInitialized, setSliceError);
        })
    );
};

export const fetchListSuccessActions = (
    resp: any,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any
): any[] => {
    const metadata = getMetadataDetails(resp['hydra:view']);
    return [changeSliceList(resp['hydra:member']), changeIsSliceLoading(false), changeIsSliceInitialized(true), setMetadata(metadata)];
};

export const fetchListErrorActions = (error: any, setSliceError: any, setSliceIsLoading: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        setSliceError(getErrorMessage(error)),
        setSliceIsLoading(false),
    ];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const berthsEpic = combineEpics(
    fetchBerthDetailsEpic,
    fetchBerthsEpic,
    fetchSpecificMarinaBerthsEpic,
    fetchAllBerthsEpic,
    changePaginationEpic,
    applyBerthFiltersEpic
);

export default berthsEpic;
