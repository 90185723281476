import {FormControlType, IFormConfig, InputType, CurrencyType} from 'marine-panel-common-web';

export const setStrategyInputPriceFormConfig = (): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form create-pricing-strategy',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'strategy_price',
                controlType: 'group',
                class: 'row',
                controls: {
                    basePriceGross: {
                        controlType: 'control',
                        formControlType: FormControlType.CURRENCY,
                        validationRules: [],
                        currency: CurrencyType.EURO,
                        placeholder: '',
                        isLabelHidden: true,
                        label: 'modal.createPricingStrategy.inputs.price',
                        isCurrencyInput: true,
                        type: InputType.NUMBER,
                        hostClass: 'form-control currency-input',
                    },
                },
            },
        ],
    };
};
