import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BerthPricingStrategy, BerthPricingStrategyInput, PricingStrategyDefinitionInput} from 'marine-panel-common-web';

export interface IPricingStrategiesState {
    pricingStrategies: any;
    pricingDefinitions: any;
    editedPricingStrategy: BerthPricingStrategy | null;
    isPricingStrategiesLoading: boolean;
    isPricingStrategiesInitialized: boolean;
    isActionSuccessful: boolean;
    pricingStrategyError: string | null;
}

export interface ISetPricingStrategies {
    pricingStrategies: any;
}
export interface ISetEditedPricingStrategy {
    editedPricingStrategy: BerthPricingStrategy | null;
}
export interface IChangePricingStrategyActiveStatus {
    payload: {active: boolean};
    berthPricingStrategyId: string;
}
export interface IFetchPricingStrategyDetails {
    pricingStrategyId: string;
}
export interface ISetPricingDefinitions {
    pricingDefinitions: any;
}
export interface IChangeIsPricingStrategiesLoading {
    isPricingStrategiesLoading: boolean;
}

export interface IChangeIsPricingStrategiesInitialized {
    isPricingStrategiesInitialized: boolean;
}

export interface IChangePricingStrategiesError {
    pricingStrategyError: string | null;
}

export interface ICreatePricingStrategyPayload {
    pricingStrategy: BerthPricingStrategyInput | null;
}

export interface IUpdatePricingStrategyPayload {
    pricingStrategy: BerthPricingStrategyInput;
}

export interface IChangeIsActionSuccessful {
    isActionSuccessful: boolean;
}

export interface IChangePricingStrategy {
    berthId: string | null;
    tagPayload: {
        berthPricingStrategyId: string | null;
        description: string | null;
    };
}

const initialState: IPricingStrategiesState = {
    pricingStrategies: null,
    pricingDefinitions: null,
    editedPricingStrategy: null,
    isPricingStrategiesInitialized: false,
    isActionSuccessful: false,
    isPricingStrategiesLoading: false,
    pricingStrategyError: null,
};

const pricingStrategySlice = createSlice({
    name: 'pricingStrategy',
    initialState: initialState,
    reducers: {
        changePricingStrategy: {
            reducer: (state: IPricingStrategiesState) => {
                return {
                    ...state,
                    isPricingStrategiesLoading: true,
                };
            },
            prepare: (berthId: string | null, berthPricingStrategyId: string | null, description: string | null) => {
                return {
                    payload: {
                        berthId: berthId,
                        tagPayload: {
                            berthPricingStrategyId: berthPricingStrategyId,
                            description: description,
                        },
                    },
                };
            },
        },
        changeIsActionSuccessful: {
            reducer: (state: IPricingStrategiesState, action: PayloadAction<IChangeIsActionSuccessful>) => {
                return {
                    ...state,
                    isActionSuccessful: action.payload.isActionSuccessful,
                };
            },
            prepare: (isActionSuccessful: boolean) => {
                return {
                    payload: {
                        isActionSuccessful: isActionSuccessful,
                    },
                };
            },
        },
        fetchPricingStrategies: (state: IPricingStrategiesState) => {
            return {
                ...state,
                isPricingStrategiesLoading: true,
            };
        },
        fetchPricingDefinitions: (state: IPricingStrategiesState) => {
            return {
                ...state,
                isPricingStrategiesLoading: true,
            };
        },

        setPricingStrategies: {
            reducer: (state: IPricingStrategiesState, action: PayloadAction<ISetPricingStrategies>) => {
                return {
                    ...state,
                    pricingStrategies: action.payload.pricingStrategies,
                };
            },
            prepare: (pricingStrategies: any) => {
                return {
                    payload: {
                        pricingStrategies: pricingStrategies,
                    },
                };
            },
        },
        setEditedPricingStrategy: {
            reducer: (state: IPricingStrategiesState, action: PayloadAction<ISetEditedPricingStrategy>) => {
                return {
                    ...state,
                    editedPricingStrategy: action.payload.editedPricingStrategy,
                };
            },
            prepare: (editedPricingStrategy: BerthPricingStrategy | null) => {
                return {
                    payload: {
                        editedPricingStrategy: editedPricingStrategy,
                    },
                };
            },
        },
        changePricingStrategyActiveStatus: {
            reducer: (state: IPricingStrategiesState) => {
                return {
                    ...state,
                    isPricingStrategiesLoading: true,
                };
            },
            prepare: (payload: IChangePricingStrategyActiveStatus) => {
                return {
                    payload: {
                        payload: payload.payload,
                        berthPricingStrategyId: payload.berthPricingStrategyId,
                    },
                };
            },
        },
        setPricingDefinitions: {
            reducer: (state: IPricingStrategiesState, action: PayloadAction<ISetPricingDefinitions>) => {
                return {
                    ...state,
                    pricingDefinitions: action.payload.pricingDefinitions,
                };
            },
            prepare: (pricingDefinitions: any) => {
                return {
                    payload: {
                        pricingDefinitions: pricingDefinitions,
                    },
                };
            },
        },
        removePricingStrategy: {
            reducer: (state: IPricingStrategiesState) => {
                return {
                    ...state,
                    isPricingStrategiesLoading: true,
                };
            },
            prepare: (berthPricingStrategyId: string) => {
                return {
                    payload: {
                        berthPricingStrategyId: berthPricingStrategyId,
                    },
                };
            },
        },
        updatePricingStrategy: {
            reducer: (state: IPricingStrategiesState) => {
                return {
                    ...state,
                    isPricingStrategiesLoading: true,
                };
            },
            prepare: (payload: BerthPricingStrategyInput) => {
                return {
                    payload: payload,
                };
            },
        },
        createPricingStrategy: {
            reducer: (state: IPricingStrategiesState) => {
                return {
                    ...state,
                    isPricingStrategiesLoading: true,
                };
            },
            prepare: (payload: BerthPricingStrategyInput) => {
                return {
                    payload: payload,
                };
            },
        },
        createPricingStrategyDefinition: {
            reducer: (state: IPricingStrategiesState) => {
                return {
                    ...state,
                    isPricingStrategiesLoading: true,
                };
            },
            prepare: (payload: PricingStrategyDefinitionInput) => {
                return {
                    payload: payload,
                };
            },
        },
        fetchPricingStrategyDetails: {
            reducer: (state: IPricingStrategiesState) => {
                return {
                    ...state,
                    isPricingStrategiesLoading: true,
                };
            },
            prepare: (id: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        changeIsPricingStrategiesLoading: {
            reducer: (state: IPricingStrategiesState, action: PayloadAction<IChangeIsPricingStrategiesLoading>) => {
                return {
                    ...state,
                    isPricingStrategiesLoading: action.payload.isPricingStrategiesLoading,
                };
            },
            prepare: (isPricingStrategiesLoading: boolean) => {
                return {
                    payload: {
                        isPricingStrategiesLoading: isPricingStrategiesLoading,
                    },
                };
            },
        },
        changeIsPricingStrategiesInitialized: {
            reducer: (state: IPricingStrategiesState, action: PayloadAction<IChangeIsPricingStrategiesInitialized>) => {
                return {
                    ...state,
                    isPricingStrategiesInitialized: action.payload.isPricingStrategiesInitialized,
                };
            },
            prepare: (isPricingStrategiesInitialized: boolean) => {
                return {
                    payload: {
                        isPricingStrategiesInitialized: isPricingStrategiesInitialized,
                    },
                };
            },
        },
        changePricingStrategiesError: {
            reducer: (state: IPricingStrategiesState, action: PayloadAction<IChangePricingStrategiesError>) => {
                return {
                    ...state,
                    pricingStrategyError: action.payload.pricingStrategyError,
                };
            },
            prepare: (pricingStrategyError: string | null) => {
                return {
                    payload: {
                        pricingStrategyError: pricingStrategyError,
                    },
                };
            },
        },
        returnToInitialPricingStrategiesState: (state: IPricingStrategiesState) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    returnToInitialPricingStrategiesState,
    changeIsActionSuccessful,
    changePricingStrategiesError,
    changeIsPricingStrategiesInitialized,
    changeIsPricingStrategiesLoading,
    fetchPricingStrategyDetails,
    setEditedPricingStrategy,
    createPricingStrategy,
    removePricingStrategy,
    setPricingStrategies,
    changePricingStrategy,
    fetchPricingStrategies,
    fetchPricingDefinitions,
    setPricingDefinitions,
    createPricingStrategyDefinition,
    changePricingStrategyActiveStatus,
    updatePricingStrategy,
} = pricingStrategySlice.actions;

export default pricingStrategySlice.reducer;
