import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './berthsSlice';

export interface ISupportPageState extends IBaseDictionaryState {}

export interface ISendSupportRequest {
    readonly subject: string;
    readonly description: string;
}

const initialState: ISupportPageState = {
    isLoading: false,
    isInitialized: false,
    error: null,
};

const supportPageSlice = createSlice({
    name: 'supportPage',
    initialState: initialState,
    reducers: {
        changeIsSupportPageLoading: {
            reducer: (state: ISupportPageState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsSupportPageInitialized: {
            reducer: (state: ISupportPageState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setSupportPageError: {
            reducer: (state: ISupportPageState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        sendContactForm: {
            reducer: (state: ISupportPageState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(subject: string, description: string) {
                return {
                    payload: {subject: subject, description: description},
                };
            },
        },
    },
});

export const {changeIsSupportPageLoading, changeIsSupportPageInitialized, setSupportPageError, sendContactForm} = supportPageSlice.actions;

export default supportPageSlice.reducer;
