import {Reservation} from 'marine-panel-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {ISelectedSidePanelItem, MapActionType, SelectedItemType} from '../../../store/reducers/mapHostSlice';
import {selectedMapItemSelector} from '../../../store/selectors/mapHostSelectors';
import {activeReservationsSelector} from '../../../store/selectors/reservationSelectors';
import MarinaSideCard from './MarinaSideCard';
import ReservationCreate from './ReservationCreate';
import ReservationEdit from './ReservationEdit';
import ReservationView from './ReservationView';

interface SideInfoCardProps {
    readonly selectedItem: ISelectedSidePanelItem | null;
    readonly activeReservation: Reservation | null;
}

function SideInfoCard(props: SideInfoCardProps) {
    return <section className="main-map-sidebar">{renderCurrentCard(props.selectedItem, props.activeReservation)}</section>;
}

function renderCurrentCard(selectedItem: ISelectedSidePanelItem | null, activeReservation: Reservation | null) {
    if (!selectedItem) return null;
    switch (selectedItem.selectedItemType) {
        case SelectedItemType.GENERAL:
            return <MarinaSideCard />;
        case SelectedItemType.BERTH:
            return <MarinaSideCard />;
        // TODO: when we have a berth side card, uncomment this
        // return <BerthSideCard />;
        case SelectedItemType.RESERVATION:
            return renderReservationAction(selectedItem, activeReservation);
        default:
            return <MarinaSideCard />;
    }
}

function renderReservationAction(selectedItem: ISelectedSidePanelItem, activeReservation: Reservation | null) {
    switch (selectedItem.selectedItemActionType) {
        case MapActionType.CREATE:
            return <ReservationCreate />;
        case MapActionType.EDIT:
            return <ReservationEdit activeReservation={activeReservation} />;
        default:
            return <ReservationView activeReservation={activeReservation} />;
    }
}

export default connect(
    (state: RootState) => ({
        selectedItem: selectedMapItemSelector(state),
        activeReservation: activeReservationsSelector(state),
    }),
    {}
)(SideInfoCard);
