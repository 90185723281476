import {InputType, FormControlType, IFormConfig} from 'marine-panel-common-web';

export const policiesAndRulesDefaultFormConfig = (policyKey: string, value?: any): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: policyKey,
                controlType: 'group',
                class: 'row',
                controls: {
                    [`${policyKey}`]: {
                        controlType: 'control',
                        defaultValue: value ? value : '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [],
                        placeholder: '',
                        isLabelHidden: false,
                        label: '',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
        ],
    };
};
