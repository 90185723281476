import {LifeBuoy, LogOut, Settings} from 'react-feather';
import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem, IconSize} from 'marine-panel-common-web';

const subMenu = (onLogout?: () => void): (ISideMenuGroup | ISideMenuItem | ISideMenuHeader)[] => [
    {
        id: 'support',
        title: 'menuItems.support',
        icon: <LifeBuoy size={IconSize.SubMenuItems} />,
        navLink: '/panel/support',
        navLinkClassname: 'support-link',
    },
    {
        id: 'settings',
        title: 'menuItems.settings',
        icon: <Settings size={IconSize.SubMenuItems} />,
        navLink: '/panel/settings',
        navLinkClassname: 'settings-link',
    },
    {
        id: 'logout',
        title: 'menuItems.logout',
        icon: <LogOut size={IconSize.SubMenuItems} />,
        navLinkClassname: 'logout-button',
        onClick: () => {
            if (onLogout) {
                return onLogout();
            }
        },
    },
];
export default subMenu;
