import {
    Berth,
    BerthPricingStrategy,
    BerthPricingStrategyInput,
    fetchVesselTypes,
    Form,
    FormControlChangeType,
    IModelDictionaryDatum,
    IMultiselectOption,
    Loader,
    PricingStrategyCalendarRule,
    PricingStrategyDefinitionInput,
    Switch,
    Translation,
    vesselTypesSelector,
} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {
    convertPriceToInputValue,
    formatHighPrecisionPrice,
    transformCalendarRulesToPayloadInputs,
    transformValueToMoneyInput,
} from '../../../../../../../service/helperFunctions';
import {RootState} from '../../../../../../../store/reducers';
import {
    changeIsPricingStrategiesLoading,
    changePricingStrategyActiveStatus,
    createPricingStrategyDefinition,
    fetchPricingDefinitions,
    fetchPricingStrategyDetails,
    IChangePricingStrategyActiveStatus,
    updatePricingStrategy,
} from '../../../../../../../store/reducers/pricingStrategiesSlice';
import {currentlyEditedBerth} from '../../../../../../../store/selectors/berthEditWizardSelectors';
import {currentlyEditedItemIdSelector} from '../../../../../../../store/selectors/modalSelectors';
import {
    editedPricingStrategySelector,
    isPricingStrategiesLoadingSelector,
    pricingDefinitionsSelector,
} from '../../../../../../../store/selectors/pricingStrategiesSelectors';
import BasePriceDetails from '../SharedComponents/BasePriceDetails';
import {pricingStrategyNameFormConfig} from '../SharedComponents/FormConfigs/pricingStrategyNameFormConfig';
import {yachtTypeFormConfig} from '../SharedComponents/FormConfigs/yachtTypeFormConfig';
import PricingStrategyDatepicker from '../SharedComponents/PricingStrategyDatepicker';
import PricingStrategyRemoveButton from './PricingStrategyRemoveButton';

interface IEditPricingStrategyModalProps {
    readonly yachtTypes: IModelDictionaryDatum[] | [];
    readonly berth: Berth | null;
    readonly editedPricingStrategy: BerthPricingStrategy | null;
    readonly currentlyEditedModalItemId: string | null;
    readonly updatePricingStrategy: typeof updatePricingStrategy;
    readonly createPricingStrategyDefinition: typeof createPricingStrategyDefinition;
    readonly fetchPricingStrategyDetails: typeof fetchPricingStrategyDetails;
    readonly changeIsPricingStrategiesLoading: typeof changeIsPricingStrategiesLoading;
    readonly isLoading: boolean;

    toggleModalOpen: () => void;
}

interface IEditPricingStrategyModalValues {
    berthId: string;
    name: string | null;
    basePrice: string | null;
    basePriceGross: string | null;
    commission: string | null;
    calendarRules: PricingStrategyCalendarRule[];
}
const EditPricingStrategyModal: FC<IEditPricingStrategyModalProps> = ({
    yachtTypes,
    editedPricingStrategy,
    berth,
    currentlyEditedModalItemId,
    updatePricingStrategy,
    fetchPricingStrategyDetails,
    isLoading,
    toggleModalOpen,
}) => {
    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }
    const [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<IEditPricingStrategyModalValues>({
            berthId: berth?.id ? berth.id : '',
            name: null,
            basePrice: null,
            basePriceGross: null,
            commission: '8',
            calendarRules: [],
        }),
        [yachtTypeValues, setYachtTypeValues] = useState<{yachtTypes: IMultiselectOption[]}>({yachtTypes: []}),
        subscriptions: Subscription[] = [],
        dispatch = useDispatch();

    useEffect(() => {
        if (currentlyEditedModalItemId) {
            dispatch(fetchPricingStrategyDetails(currentlyEditedModalItemId));
        }

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [currentlyEditedModalItemId]);
    useEffect(() => {
        if (editedPricingStrategy) {
            setValue(() => {
                const yachtTypesFromTemplate: IMultiselectOption[] = yachtTypes
                    .filter((item: IModelDictionaryDatum) => editedPricingStrategy.yachtTypes.includes(item.id))
                    .map((item: IModelDictionaryDatum) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                setYachtTypeValues({yachtTypes: yachtTypesFromTemplate});
                const calendarRules: PricingStrategyCalendarRule[] = editedPricingStrategy.calendarRules.map(
                    (calendarRule: PricingStrategyCalendarRule) => {
                        return {
                            startsAt: calendarRule.startsAt,
                            endsAt: calendarRule.endsAt,
                            price: calendarRule.price,
                            active: calendarRule.active,
                        };
                    }
                );

                return {
                    berthId: berth?.id ? berth.id : '',
                    pricingStrategyTemplate: editedPricingStrategy.id,
                    name: editedPricingStrategy.name,
                    basePrice: convertPriceToInputValue(editedPricingStrategy.basePrice.amount),
                    basePriceGross: convertPriceToInputValue(editedPricingStrategy.basePriceGross.amount),
                    commission: '8',
                    calendarRules: calendarRules,
                };
            });
        }
    }, [editedPricingStrategy]);

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName === 'yachtTypes' && data.value?.yachtTypes) {
                        setYachtTypeValues({yachtTypes: data.value.yachtTypes});
                    } else if (data.controlName) {
                        setValue((prevState: IEditPricingStrategyModalValues) => {
                            const grossPrice =
                                    data.value && data.value.basePriceGross ? data.value.basePriceGross : prevState?.basePriceGross,
                                commission = 8;
                            const calculatedBasePrice = formatHighPrecisionPrice((grossPrice * 100) / (1 + commission / 100));

                            return {
                                berthId: berth?.id ? berth.id : '',
                                name: data.value && data.value.name ? data.value.name : prevState?.name,
                                basePrice: calculatedBasePrice,
                                basePriceGross: grossPrice,
                                commission: '8',
                                calendarRules: data.value && data.value.calendarRules ? data.value.calendarRules : prevState?.calendarRules,
                            };
                        });
                    }
                })
        );
    }, [onValueStateChange$$]);

    const isDisabled = !values.name;

    const multiselectOptionVesselTypes = yachtTypes.map((vesselType: IModelDictionaryDatum) => {
        const multiselect: IMultiselectOption = {
            value: vesselType.id,
            label: vesselType.name,
        };
        return multiselect;
    });

    const changeCalendarRules = (calendarRules: PricingStrategyCalendarRule[]) => {
        setValue((prevState: IEditPricingStrategyModalValues) => {
            return {
                berthId: prevState.berthId,
                name: prevState.name,
                basePrice: prevState.basePrice,
                basePriceGross: prevState.basePriceGross,
                commission: '8',
                calendarRules: calendarRules,
            };
        });
    };

    const editPricingStrategyAction = () => {
        let payload: BerthPricingStrategyInput | null = null;
        if (values.berthId && values.name && values.basePrice && values.basePriceGross && values.commission) {
            const yachtTypes =
                yachtTypeValues.yachtTypes && yachtTypeValues.yachtTypes.length > 0
                    ? yachtTypeValues.yachtTypes.map((item: IMultiselectOption) => item.value)
                    : [];
            payload = {
                berthId: values.berthId,
                name: values.name,
                basePrice: transformValueToMoneyInput(values.basePrice),
                basePriceGross: transformValueToMoneyInput(values.basePriceGross),
                commission: '8',
                calendarRules: transformCalendarRulesToPayloadInputs(values.calendarRules),
                yachtTypes: yachtTypes,
            };
        }
        if (payload) {
            updatePricingStrategy(payload);
        }
    };

    const createPricingStrategyDefinitionAction = () => {
        let payload: PricingStrategyDefinitionInput | null = null;
        if (values.name && values.basePrice && values.basePriceGross && values.commission) {
            const yachtTypes =
                yachtTypeValues.yachtTypes && yachtTypeValues.yachtTypes.length > 0
                    ? yachtTypeValues.yachtTypes.map((item: IMultiselectOption) => item.value)
                    : [];
            payload = {
                name: values.name + ' definition',
                basePrice: transformValueToMoneyInput(values.basePrice),
                basePriceGross: transformValueToMoneyInput(values.basePriceGross),
                commission: '8',
                calendarRules: transformCalendarRulesToPayloadInputs(values.calendarRules),
                yachtTypes: yachtTypes,
            };
            if (payload) createPricingStrategyDefinition(payload);
        }
    };

    const changeActiveAction = () => {
        if (editedPricingStrategy) {
            const activePayload = {active: !editedPricingStrategy.active};
            const payload: IChangePricingStrategyActiveStatus = {
                payload: activePayload,
                berthPricingStrategyId: editedPricingStrategy.id,
            };
            dispatch(changePricingStrategyActiveStatus(payload));
        }
    };
    const isPricingStrategyActive = editedPricingStrategy?.active;

    return (
        <div className="modal-wrapper create-pricing-strategy">
            <Loader showLoader={isLoading} />
            <ModalHeader tag={'h3'}>
                <Translation text="modal.editPricingStrategy.title" />
                <button className="btn-close" onClick={() => toggleModalOpen()}></button>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="form-control offers-form-control modal-form">
                        <div className="activity-wrapper mb-2">
                            {editedPricingStrategy && <PricingStrategyRemoveButton id={editedPricingStrategy.id} />}
                        </div>
                        <div className="activity-wrapper">
                            <span className="secondary-title description">
                                <Translation text={`modal.editPricingStrategy.inputs.${isPricingStrategyActive ? 'active' : 'inactive'}`} />
                            </span>
                            <Switch name="active" checked={isPricingStrategyActive} handleChange={changeActiveAction} />
                        </div>
                    </div>
                    <div className="form-control offers-form-control modal-form">
                        <h4 className="main title">
                            <Translation text="modal.editPricingStrategy.inputs.name" />
                        </h4>
                        <p className="secondary-title description">
                            <Translation text="modal.editPricingStrategy.inputs.nameDescription" />
                        </p>
                        <Form
                            controlName={'pricingStrategy'}
                            config={pricingStrategyNameFormConfig()}
                            value={values}
                            onValueStateChange={onValueStateChange}
                        />
                    </div>
                    <div className="form-control offers-form-control modal-form">
                        <h4 className="main title">
                            <Translation text="modal.editPricingStrategy.inputs.yachtTypes" />
                        </h4>
                        <p className="secondary-title description">
                            <Translation text="modal.editPricingStrategy.inputs.yachtTypesDescription" />
                        </p>
                        <Form
                            controlName={'yachtTypes'}
                            config={yachtTypeFormConfig(multiselectOptionVesselTypes)}
                            value={yachtTypeValues}
                            onValueStateChange={onValueStateChange}
                        />
                    </div>
                    <BasePriceDetails values={values} onValueStateChange={onValueStateChange} />
                    <div className="form-control offers-form-control modal-form">
                        <PricingStrategyDatepicker
                            startDate={new Date()}
                            endDate={new Date()}
                            values={values}
                            changeCalendarRules={changeCalendarRules}
                        />
                    </div>
                    <div className="form-control offers-form-control modal-form">
                        <h4 className="main title">
                            <Translation text="modal.editPricingStrategy.inputs.saveAsTemplate" />
                        </h4>
                        <p className="secondary-title description">
                            <Translation text="modal.editPricingStrategy.inputs.saveAsTemplateDescription" />
                        </p>
                        <button className="sm-btn btn btn-create mt-2 save-template-button" onClick={createPricingStrategyDefinitionAction}>
                            <Translation text="modal.editPricingStrategy.buttons.saveAsTemplate" />
                        </button>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="sm-btn btn btn-underline btn-underline-primary close-modal-button" onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button
                    className="sm-btn btn btn-create update-pricing-strategy-button"
                    disabled={isDisabled}
                    onClick={() => editPricingStrategyAction()}>
                    <Translation text="buttons.update" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        yachtTypes: vesselTypesSelector(state),
        pricingStrategyDefinitions: pricingDefinitionsSelector(state),
        editedPricingStrategy: editedPricingStrategySelector(state),
        currentlyEditedModalItemId: currentlyEditedItemIdSelector(state),
        berth: currentlyEditedBerth(state),
        isLoading: isPricingStrategiesLoadingSelector(state),
    }),
    {
        updatePricingStrategy,
        createPricingStrategyDefinition,
        fetchVesselTypes,
        fetchPricingStrategyDetails,
        fetchPricingDefinitions,
        changeIsPricingStrategiesLoading,
    }
)(EditPricingStrategyModal);
