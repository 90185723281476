import {
    isObjectEmpty,
    LoaderType,
    Loader,
    MarinaRouteNavigation,
    Reservation,
    Translation,
    VesselPosition,
    DateComponent,
    AsyncTaskOutput,
    RouteOutput,
} from 'marine-panel-common-web';
import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';
import {fetchVesselLatestPosition, setLatestVesselPosition} from '../../../../../store/reducers/reservationsSlice';
import {
    reservationsErrorSelector,
    routeCalculationTaskSelector,
    vesselLatestPositionSelector,
} from '../../../../../store/selectors/reservationSelectors';
import VesselPositionMap from './VesselPositionMap';
import {cancelTask, findRoute, resetToInitialFindRouteState} from '../../../../../store/reducers/routeFindSlice';
import {calculatedRoutesSelector, findRouteErrorSelector} from '../../../../../store/selectors/routeFindSelectors';

interface IExternalVesselLatestPositionModalProps {
    readonly reservation: Reservation | null;
    readonly isModalOpen: boolean;
    readonly toggleModal: () => void;
}

interface IConnectedVesselLatestPositionModalProps {
    readonly calculatedRoutes: {[marinaId: string]: MarinaRouteNavigation};
    readonly vesselLatestPosition: VesselPosition | null;
    readonly routeCalculationTask: AsyncTaskOutput<{} | RouteOutput> | null;
    readonly findRouteError: string | null;
    readonly reservationsError: string | null;
    readonly fetchVesselLatestPosition: typeof fetchVesselLatestPosition;
    readonly findRoute: typeof findRoute;
    readonly cancelTask: typeof cancelTask;
    readonly resetToInitialFindRouteState: typeof resetToInitialFindRouteState;
    readonly setLatestVesselPosition: typeof setLatestVesselPosition;
}

interface IVesselLatestPositionModalProps extends IConnectedVesselLatestPositionModalProps, IExternalVesselLatestPositionModalProps {}

const VesselLatestPositionModal: FC<IVesselLatestPositionModalProps> = ({
    reservation,
    vesselLatestPosition,
    isModalOpen,
    toggleModal,
    fetchVesselLatestPosition,
    routeCalculationTask,
    calculatedRoutes,
    findRouteError,
    reservationsError,
    findRoute,
    cancelTask,
    resetToInitialFindRouteState,
    setLatestVesselPosition,
}) => {
    const [estimatedArrival, setEstimatedArrival] = useState<number | null>(null),
        [marinaId, setMarinaId] = useState<string | null>(null),
        [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const vesselId = reservation && reservation.vessel ? reservation.vessel.id : null;
        if (vesselId) {
            fetchVesselLatestPosition(vesselId);
        } else {
            setIsLoading(false);
        }

        return () => {
            if (null !== routeCalculationTask) {
                cancelTask(routeCalculationTask);
            }
            resetToInitialFindRouteState();
            setLatestVesselPosition(null);
        };
    }, []);

    useEffect(() => {
        if (vesselLatestPosition !== null) {
            getNavigationRoute();
        }
    }, [vesselLatestPosition]);

    useEffect(() => {
        if (!isObjectEmpty(calculatedRoutes) && marinaId) {
            const routeDetails = calculatedRoutes[marinaId].route;
            setEstimatedArrival(routeDetails !== null && routeDetails !== undefined ? routeDetails.time : null);
            setIsLoading(false);
        }
    }, [calculatedRoutes]);

    useEffect(() => {
        if (findRouteError !== null || reservationsError !== null) {
            setIsLoading(false);
        }
    }, [findRouteError, reservationsError]);

    function getNavigationRoute() {
        const vesselTypeId = reservation?.vesselType?.id,
            marinaId = reservation?.marina?.id;

        if (marinaId && vesselTypeId && reservation && reservation.marina && vesselLatestPosition) {
            setMarinaId(marinaId);

            const marinaLocation = {
                    latitude: reservation.marina.location.latitude,
                    longitude: reservation.marina.location.longitude,
                },
                vesselLocation = {
                    latitude: vesselLatestPosition.position.latitude,
                    longitude: vesselLatestPosition.position.longitude,
                };

            const waypoints = [marinaLocation, vesselLocation];

            const input = {
                routeInput: {
                    waypoints: waypoints,
                    vesselTypeId: vesselTypeId,
                },
                marinaId: marinaId,
            };
            return findRoute(input);
        }
    }

    function getEstimatedArrivalTime() {
        if (estimatedArrival) {
            const currentDate = new Date(),
                arrivalTime = currentDate.setMilliseconds(currentDate.getMilliseconds() + estimatedArrival);
            return new Date(arrivalTime);
        } else {
            return '';
        }
    }

    const isVesselProvided = reservation && reservation.vessel;

    return (
        <Modal isOpen={isModalOpen} toggle={() => toggleModal()} className="vessel-position-modal">
            <ModalBody>
                <div className="content-header vessel-position-modal">
                    <p className="main title">
                        <Translation text="mainMap.reservation.vesselPosition.title" />
                    </p>

                    <button className="btn btn-close" onClick={() => toggleModal()}></button>
                </div>

                <div className={`value-container ${estimatedArrival ? 'estimated-arrival-container' : 'no-data-container'}`}>
                    <p className="main title">
                        <Translation text="mainMap.reservation.vesselPosition.estimatedArrival" />
                    </p>
                    {estimatedArrival ? (
                        <p className="main title arrival-time">
                            <DateComponent date={getEstimatedArrivalTime()} format="HH:mm" />
                        </p>
                    ) : (
                        <p className="no-data">
                            <Translation text="mainMap.reservation.vesselPosition.noEstimatedArrivalData" />
                        </p>
                    )}
                </div>
                <div className="value-container">
                    <p className="main title position-label">
                        <Translation text="mainMap.reservation.vesselPosition.currentPosition" />
                    </p>
                    {isVesselProvided ? (
                        <div className="location-input-wrapper">
                            <div className="location-map-wrapper">
                                <VesselPositionMap vesselLatestPosition={vesselLatestPosition} />
                            </div>
                        </div>
                    ) : (
                        <p className="no-data">
                            <Translation text="mainMap.reservation.vesselPosition.noEstimatedPositionData" />
                        </p>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="sm-btn btn btn-underline" onClick={() => toggleModal()}>
                    <Translation text="buttons.cancel" />
                </button>
            </ModalFooter>

            <Loader type={LoaderType.Local} showLoader={isLoading} />
        </Modal>
    );
};

export default connect(
    (state: RootState) => ({
        vesselLatestPosition: vesselLatestPositionSelector(state),
        calculatedRoutes: calculatedRoutesSelector(state),
        routeCalculationTask: routeCalculationTaskSelector(state),
        findRouteError: findRouteErrorSelector(state),
        reservationsError: reservationsErrorSelector(state),
    }),
    {
        fetchVesselLatestPosition,
        findRoute,
        cancelTask,
        resetToInitialFindRouteState,
        setLatestVesselPosition,
    }
)(VesselLatestPositionModal);
