import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {isSameValue, IReservationsReportOutput} from 'marine-panel-common-web';
import moment from 'moment/moment';

export interface ITransactionHistoryFilters {
    start_date: string;
    end_date: string;
    status: string[] | null;
}

export interface ISetTransactionHistory {
    transactionHistory: IReservationsReportOutput | null;
}

export interface IChangeTransactionHistoryLoading {
    isTransactionHistoryLoading: boolean;
}

export interface IChangeTransactionHistoryInitialized {
    isTransactionHistoryInitialized: boolean;
}

export interface IChangeTransactionHistoryError {
    transactionHistoryError: string | null;
}

export interface IChangeTransactionHistoryFilters {
    transactionHistoryFilters: ITransactionHistoryFilters;
}

export interface ITransactionHistoryState {
    transactionHistory: IReservationsReportOutput | null;
    transactionHistoryFilters: ITransactionHistoryFilters | null;
    isTransactionHistoryLoading: boolean;
    isTransactionHistoryInitialized: boolean;
    transactionHistoryError: string | null;
}

const initialState: ITransactionHistoryState = {
    transactionHistory: null,
    transactionHistoryFilters: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        status: null,
    },
    isTransactionHistoryLoading: false,
    isTransactionHistoryInitialized: false,
    transactionHistoryError: null,
};

const transactionHistorySlice = createSlice({
    name: 'transactionHistoryPage',
    initialState: initialState,
    reducers: {
        setTransactionHistory: {
            reducer: (state: ITransactionHistoryState, action: PayloadAction<ISetTransactionHistory>) => {
                return {
                    ...state,
                    transactionHistory: action.payload.transactionHistory,
                };
            },
            prepare: (transactionHistory: IReservationsReportOutput | null) => {
                return {
                    payload: {
                        transactionHistory: transactionHistory,
                    },
                };
            },
        },
        changeTransactionHistoryPageLoading: {
            reducer: (state: ITransactionHistoryState, action: PayloadAction<IChangeTransactionHistoryLoading>) => {
                return {
                    ...state,
                    isTransactionHistoryLoading: action.payload.isTransactionHistoryLoading,
                };
            },
            prepare: (isTransactionHistoryLoading: boolean) => {
                return {
                    payload: {
                        isTransactionHistoryLoading: isTransactionHistoryLoading,
                    },
                };
            },
        },
        changeTransactionHistoryPageInitialized: {
            reducer: (state: ITransactionHistoryState, action: PayloadAction<IChangeTransactionHistoryInitialized>) => {
                return {
                    ...state,
                    isTransactionHistoryInitialized: action.payload.isTransactionHistoryInitialized,
                };
            },
            prepare: (isTransactionHistoryInitialized: boolean) => {
                return {
                    payload: {
                        isTransactionHistoryInitialized: isTransactionHistoryInitialized,
                    },
                };
            },
        },
        changeTransactionHistoryPageError: {
            reducer: (state: ITransactionHistoryState, action: PayloadAction<IChangeTransactionHistoryError>) => {
                return {
                    ...state,
                    transactionHistoryError: action.payload.transactionHistoryError,
                };
            },
            prepare: (transactionHistoryError: string | null) => {
                return {
                    payload: {
                        transactionHistoryError: transactionHistoryError,
                    },
                };
            },
        },
        fetchTransactionHistory: (state: ITransactionHistoryState) => {
            return {
                ...state,
                isTransactionHistoryLoading: true,
            };
        },
        changeTransactionHistoryFilters: {
            reducer: (state: ITransactionHistoryState, action: PayloadAction<IChangeTransactionHistoryFilters>) => {
                if (isSameValue(action.payload.transactionHistoryFilters, state.transactionHistoryFilters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isTransactionHistoryLoading: true,
                    transactionHistoryFilters: action.payload.transactionHistoryFilters,
                };
            },
            prepare(transactionHistoryFilters: ITransactionHistoryFilters) {
                return {
                    payload: {
                        transactionHistoryFilters: transactionHistoryFilters,
                    },
                };
            },
        },
        applyTransactionHistoryFilters: (state: ITransactionHistoryState) => {
            return {
                ...state,
                isTransactionHistoryLoading: true,
            };
        },
        resetToInitialTransactionHistoryState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setTransactionHistory,
    fetchTransactionHistory,
    resetToInitialTransactionHistoryState,
    changeTransactionHistoryPageError,
    changeTransactionHistoryPageInitialized,
    changeTransactionHistoryPageLoading,
    changeTransactionHistoryFilters,
    applyTransactionHistoryFilters,
} = transactionHistorySlice.actions;

export default transactionHistorySlice.reducer;
