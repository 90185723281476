import React, {useEffect, useState} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Card, CardBody} from 'reactstrap';
import {FormControlChangeType, IFormConfig, Form, Loader, LoaderType} from 'marine-panel-common-web';
import {filter, share} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {contactFormConfig} from './contactFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {isSupportPageLoadingSelector} from '../../../store/selectors/supportPageSelectors';
import {sendContactForm} from '../../../store/reducers/supportPageSlice';

interface IConnectedContactFormProps {
    readonly isLoading: boolean;
    readonly sendContactForm: typeof sendContactForm;
}

interface IContactFormProps extends IConnectedContactFormProps, WithTranslation {}

const ContactForm: React.FC<IContactFormProps> = ({isLoading, sendContactForm}) => {
    const [value, setValue] = useState<{[key: string]: any} | null>(null),
        [formConfig] = useState<IFormConfig | null>(contactFormConfig()),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        setValue(data.value);
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    function submitContactForm() {
        if (value === null) {
            return;
        }
        sendContactForm(value.subject, value.message);
    }

    return (
        <Card className="payment-details-card">
            <CardBody>
                <div className="col-12">
                    {formConfig && (
                        <Form
                            config={formConfig}
                            onValueStateChange={onValueStateChange}
                            value={value}
                            submitForm={() => submitContactForm()}
                            controlName={'changeBillingDetailsForm'}
                        />
                    )}
                </div>

                <Loader type={LoaderType.Local} showLoader={isLoading} />
            </CardBody>
        </Card>
    );
};

export default connect(
    (state: RootState) => ({
        isLoading: isSupportPageLoadingSelector(state),
    }),
    {
        sendContactForm,
    }
)(withTranslation()(ContactForm));
