import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import LayoutWrapper from '../LayoutWrapper';
import {Translation} from 'marine-panel-common-web';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import ContactForm from './ContactForm';
import PrivacyPolicyCard from './PrivacyPolicyCard';

interface IConnectedSupportProps {}

interface ISupportProps extends IConnectedSupportProps, WithTranslation {}

const Support: React.FC<ISupportProps> = ({}) => {
    const pageId = process.env.REACT_APP_FACEBOOK_PAGE_ID,
        appId = process.env.REACT_APP_FACEBOOK_APP_ID;

    return (
        <LayoutWrapper>
            <h2 className="section-title">
                <Translation text="support.title" />
            </h2>
            <ContactForm />
            <MessengerCustomerChat pageId={pageId} appId={appId} htmlRef={window.location.pathname} />
            <h2 className="section-title">
                <Translation text="support.privacyPolicyTitle" />
            </h2>
            <PrivacyPolicyCard />
        </LayoutWrapper>
    );
};

export default withTranslation()(Support);
