import {LatLngExpression, LatLngTuple} from 'leaflet';
import {Berth, GeoPoint, GeoPointOutput, GeoPolygon, Loader, MarinaBroad} from 'marine-panel-common-web';
import {useEffect, useState} from 'react';
import {MapContainer, TileLayer, useMap, useMapEvent} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import {connect, useDispatch} from 'react-redux';
import {RootState} from '../../store/reducers';
import {changeBerths, fetchAllBerths, fetchBerthDetails, setActiveBerth} from '../../store/reducers/berthsSlice';
import {changeMapZoom} from '../../store/reducers/mapHostSlice';
import {fetchAllMarinas} from '../../store/reducers/marinasSlice';
import {fetchReservations} from '../../store/reducers/reservationsSlice';
import {activeBerthSelector, berthsLoading, berthsSelector} from '../../store/selectors/berthSelectors';
import {mapCenterSelector, mapZoomSelector} from '../../store/selectors/mapHostSelectors';
import {marinasSelector} from '../../store/selectors/marinaSelectors';
import BerthItem from './BerthItem';
import SideInfoCard from './SideInfoCard';

interface IMainMapProps {
    readonly berths: Berth[] | null;
    readonly marinas: MarinaBroad[] | null;
    readonly mapCenter: GeoPointOutput | null;
    readonly mapZoom: number | null;
    readonly isLoading: boolean;
    readonly activeBerth: Berth | null;
    readonly fetchBerthDetails: typeof fetchBerthDetails;
    readonly fetchAllBerths: typeof fetchAllBerths;
    readonly fetchAllMarinas: typeof fetchAllMarinas;
    readonly fetchReservations: typeof fetchReservations;
    readonly changeBerths: typeof changeBerths;
    readonly setActiveBerth: typeof setActiveBerth;
}

function MainMap(props: IMainMapProps) {
    const [mapCenter, setMapCenter] = useState<LatLngTuple>([0, 0]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllBerths());
        dispatch(fetchAllMarinas());
        // props.fetchReservations();
    }, [dispatch]);

    useEffect(() => {
        if (props.mapCenter) {
            setMapCenter([props.mapCenter?.latitude || 0, props.mapCenter?.longitude || 0]);
        }
    }, [props.mapCenter]);

    const fetchBerth = (berth: Berth) => {
        props.fetchBerthDetails(berth.id);
    };
    const resetEditAndSelect = () => {
        dispatch(setActiveBerth(null));
    };

    const calculatePolygonCenter = (polygon: GeoPolygon): GeoPoint => {
        const totalVertices = polygon.vertexes.length;

        if (totalVertices === 0) {
            return {longitude: 0, latitude: 0};
        }

        let totalLongitude = 0;
        let totalLatitude = 0;

        for (const vertex of polygon.vertexes) {
            totalLongitude += vertex.longitude;
            totalLatitude += vertex.latitude;
        }
        const centerLongitude = totalLongitude / totalVertices;
        const centerLatitude = totalLatitude / totalVertices;

        return {longitude: centerLongitude, latitude: centerLatitude};
    };

    useEffect(() => {
        if (props.berths && props.berths.length === 1) {
            const centeredBerth = props.berths[0];
            const centerPoint = calculatePolygonCenter(centeredBerth.location);
            setMapCenter([centerPoint.latitude || 0, centerPoint.longitude || 0]);
            dispatch(changeMapZoom(19));
        }
    }, [props.berths, props.mapZoom, props.mapCenter, dispatch]);

    const ClickOutsideComponent = () => {
        useMapEvent('click', () => resetEditAndSelect());
        return null;
    };

    function SetViewOnClick({coords}: {coords: LatLngExpression}) {
        const map = useMap();
        if (map) {
            map.setView(coords, props.mapZoom ? props.mapZoom : 3);
        }

        return null;
    }

    return (
        <div className={`map-container map-main`}>
            <Loader showLoader={props.isLoading} />
            <SideInfoCard />
            <MapContainer center={mapCenter} zoom={props.mapZoom ? props.mapZoom : 3} maxZoom={22}>
                <SetViewOnClick coords={mapCenter} />
                <ClickOutsideComponent />
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxNativeZoom={22} maxZoom={22} />
                <MarkerClusterGroup
                    zoomToBoundsOnClick={true}
                    animate={true}
                    singleMarkerMode={true}
                    spiderfyOnMaxZoom={false}
                    disableClusteringAtZoom={15}>
                    {props.berths?.map((berth: Berth) => (
                        <BerthItem
                            berth={berth}
                            key={berth.id}
                            isSelected={props.activeBerth?.id === berth.id}
                            selectItem={() => fetchBerth(berth)}
                        />
                    ))}
                </MarkerClusterGroup>
            </MapContainer>
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        berths: berthsSelector(state),
        marinas: marinasSelector(state),
        mapCenter: mapCenterSelector(state),
        mapZoom: mapZoomSelector(state),
        isLoading: berthsLoading(state),
        activeBerth: activeBerthSelector(state),
    }),
    {
        fetchAllBerths,
        fetchBerthDetails,
        fetchAllMarinas,
        fetchReservations,
        changeBerths,
        setActiveBerth,
    }
)(MainMap);
