import {Observable} from 'rxjs';
import {ApiSingleResponseBase, AsyncTaskOutput, marineHubAPI, RestQueryParams} from 'marine-panel-common-web';

export function checkStatusApi<T>(authToken: string | null, id: string): Observable<ApiSingleResponseBase<AsyncTaskOutput<T>>> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return marineHubAPI.get(`api/async_tasks/${id}`, new RestQueryParams(), headers);
}
