import {Table} from 'reactstrap';
import {Marina, Loader, Translation, MoneyInput} from 'marine-panel-common-web';
import {
    changeExtraOrder,
    changeIsMarinaEditWizardLoading,
    fetchMarinaDetails,
    IChangeExtraOrder,
    IChangeExtraOrderItem,
    IRemoveExtra,
    removeExtra,
    resetToInitialMarinaEditWizardState,
} from '../../../../../../store/reducers/marinaEditWizardSlice';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RootState} from '../../../../../../store/reducers';
import {useEffect} from 'react';
import {
    currentlyEditedMarina,
    isActionSuccessfulSelector,
    isMarinaEditWizardLoadingSelector,
    isMarinaEditWizardInitializedSelector,
} from '../../../../../../store/selectors/marinaEditWizardSelectors';
import {changeIsModalOpen, ModalNames, setCurrentlyEditedItemId, setModalName} from '../../../../../../store/reducers/modalSlice';
import DragDropTable from './DragDropTable';

export interface IExtra {
    id: string;
    name: string;
    description: string | null;
    price: MoneyInput | null;
}

interface IConnectedMarinaExtrasProps {
    readonly marina: Marina | null;
    readonly isLoading: boolean;
    readonly fetchMarinaDetails: typeof fetchMarinaDetails;
    readonly resetToInitialMarinaEditWizardState: typeof resetToInitialMarinaEditWizardState;
    readonly removeExtra: typeof removeExtra;
    readonly changeExtraOrder: typeof changeExtraOrder;
    readonly changeIsMarinaEditWizardLoading: typeof changeIsMarinaEditWizardLoading;
    readonly setCurrentlyEditedItemId: typeof setCurrentlyEditedItemId;

    onCreate: () => void;
}

const MarinaExtras: React.FC<IConnectedMarinaExtrasProps> = ({
    marina,
    isLoading,
    fetchMarinaDetails,
    resetToInitialMarinaEditWizardState,
    onCreate,
    changeExtraOrder,
    removeExtra,
}) => {
    const params = useParams(),
        marinaId = params.id,
        dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchMarinaDetails(marinaId ? marinaId : null));
        return () => {
            dispatch(resetToInitialMarinaEditWizardState());
        };
    }, [marinaId, dispatch, fetchMarinaDetails, resetToInitialMarinaEditWizardState]);
    function openCreateExtraModal() {
        dispatch(setModalName(ModalNames.CREATE_EXTRA));
        dispatch(changeIsModalOpen(true));
    }

    const removeExtraAction = (extraId: string) => {
        if (!marinaId) return null;
        const payload: IRemoveExtra = {
            marinaId: marinaId ? marinaId : '',
            payload: {
                extraId: extraId,
            },
        };
        dispatch(changeIsMarinaEditWizardLoading(true));
        dispatch(removeExtra(payload));
    };

    const changeExtraServicesOrder = (extrasList: IChangeExtraOrderItem[]) => {
        const payload: IChangeExtraOrder = {
            marinaId: marinaId ? marinaId : '',
            payload: extrasList,
        };
        changeExtraOrder(payload);
    };

    const openEditExtraModal = (extraId: string) => {
        dispatch(setModalName(ModalNames.EDIT_EXTRA));
        dispatch(changeIsModalOpen(true));
        dispatch(setCurrentlyEditedItemId(extraId));
    };

    return (
        <div>
            <h1 className="title main">
                <Translation text="administration.extras.title" />
            </h1>
            <h2 className="title secondary">
                <Translation text="administration.extras.description" />
            </h2>
            <div className="d-flex justify-content-end mb-2">
                <button className="btn btn-create" onClick={() => openCreateExtraModal()}>
                    <Translation text="administration.extras.buttons.create" />
                </button>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>
                            <Translation text="administration.extras.table.headers.name" />
                        </th>
                        <th>
                            <Translation text="administration.extras.table.headers.currentPrice" />
                        </th>
                        <th>
                            <Translation text="administration.extras.table.headers.actions" />
                        </th>
                    </tr>
                </thead>

                <DragDropTable
                    extras={marina?.extras}
                    openEditExtraModal={openEditExtraModal}
                    removeExtraAction={removeExtraAction}
                    changeExtraServiceOrder={changeExtraServicesOrder}
                />
            </Table>
            <Loader showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        marina: currentlyEditedMarina(state),
        isLoading: isMarinaEditWizardLoadingSelector(state),
        isActionSuccessful: isActionSuccessfulSelector(state),
        isGalleryMarinaComponentInitialized: isMarinaEditWizardInitializedSelector(state),
    }),
    {
        fetchMarinaDetails,
        removeExtra,
        resetToInitialMarinaEditWizardState,
        changeIsMarinaEditWizardLoading,
        setCurrentlyEditedItemId,
        changeExtraOrder,
    }
)(MarinaExtras);
