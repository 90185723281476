import {Reservation, Translation} from 'marine-panel-common-web';
import MarinaReservationItem from './MarinaReservationItem';

const MarinaReservationList = ({reservations}: {reservations: Reservation[] | null}) => {
    if (!reservations || reservations.length === 0)
        return (
            <p className="empty-list-info">
                <Translation text={'mainMap.reservation.view.noReservations'} />
            </p>
        );
    return (
        <ul className="marina-reservation-list">
            {reservations.map((reservation: Reservation) => (
                <MarinaReservationItem key={reservation.id} reservation={reservation} />
            ))}
        </ul>
    );
};

export default MarinaReservationList;
