import {createSelector} from '@reduxjs/toolkit';
import {IMarinasState} from '../reducers/marinasSlice';

export const selectMarinasSlice = (state: any): IMarinasState => {
    return state.marinas;
};

export const marinasSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.marinas);

export const activeMarinaSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.activeMarina);
export const marinasLoading = createSelector([selectMarinasSlice], (state: IMarinasState) => state.isLoading);
