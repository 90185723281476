import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {RootState} from '../reducers';
import {
    accountUserIdSelector,
    addAlert,
    AlertType,
    ApiError,
    authTokenSelector,
    handleApiError,
    IBillingInfo,
} from 'marine-panel-common-web';
import {
    changeBillingInformationPageError,
    changeBillingInformationPageLoading,
    fetchBillingInformation,
    IUpdateBillingInformation,
    setBillingInformation,
    updateBillingInformation,
} from '../reducers/settingsPageSlice';
import {getBillingInfoAPI} from '../../api/getBillingInfo';
import {PayloadAction} from '@reduxjs/toolkit';
import {updateBillingDataAPI} from '../../api/updateBillingData';

const errorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), changeBillingInformationPageLoading(false), changeBillingInformationPageError(errorObj.message)];
};

const fetchBillingInformationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchBillingInformation.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                accountId = accountUserIdSelector(state$.value);
            return getBillingInfoAPI(authToken, accountId).pipe(
                switchMap((resp: any) => {
                    const billingInformation: IBillingInfo = resp,
                        actions = successActions(setBillingInformation(billingInformation));
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const updateBillingInformationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(updateBillingInformation.type),
        switchMap((action: PayloadAction<IUpdateBillingInformation>): any => {
            const authToken = authTokenSelector(state$.value),
                accountId = accountUserIdSelector(state$.value);
            return updateBillingDataAPI(authToken, action.payload.billingInformation, accountId).pipe(
                switchMap(() => {
                    const successMessage = 'settings.billingData.alerts.updated',
                        actions = successActions(addAlert({message: successMessage}));
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (changeSliceList: any): any[] => {
    return [changeSliceList, changeBillingInformationPageLoading(false)];
};

const billingInfoEpic = combineEpics(fetchBillingInformationEpic, updateBillingInformationEpic);

export default billingInfoEpic;
