import React from 'react';
import styles from './styles.module.scss';
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Smile} from 'react-feather';
import {IBaseChatProps, Translation} from 'marine-panel-common-web';
import {ChatChannel, ChatMessage} from '../../../../WithSoccetChatConnection/chat.types';
import OutsideClickHandler from 'react-outside-click-handler';

interface IChatActionsProps extends WithTranslation, IBaseChatProps {
    error: string | null;
    peerConnection: ChatChannel | null; // PeerConnection | null;
    errorModuleHandle: (errorMessage: string) => void;
    canSendMessagesToOffline: boolean;
    addMessage: (message: ChatMessage | string) => void;
}

interface IChatActionsState {
    isEmojiShown: boolean;
    messageInput: string;
    imgUrl: string | null;
    isDeleteModalOpen: boolean;
}

class ChatActions extends React.Component<IChatActionsProps, IChatActionsState> {
    private emojiPicker: any;
    private divElement: any;

    constructor(props: IChatActionsProps) {
        super(props);

        this.state = {
            isEmojiShown: false,
            messageInput: '',
            imgUrl: null,
            isDeleteModalOpen: false,
        };

        this.emojiPicker = React.createRef();
        this.divElement = React.createRef();
    }

    componentDidMount(): void {
        this.divElement.current.addEventListener('input', this.onChange, false);
    }

    componentWillUnmount() {
        document.removeEventListener('input', this.onChange, false);
    }

    render() {
        const canSendMessage =
            (this.props.canSendMessagesToOffline && !this.props.peerConnection?.peerIsOnline) || this.props.peerConnection?.peerIsOnline;
        return (
            <React.Fragment>
                <div className={`${styles.interactionContainer} "interaction-container chat-app-form"`}>
                    <button
                        disabled={!canSendMessage}
                        type="button"
                        className={`${styles.toggleEmoji} ${canSendMessage ? '' : 'disabled'}`}
                        onClick={() => (this.state.isEmojiShown ? this.closeEmojiMenu() : this.showEmojis())}>
                        <Smile />
                    </button>
                    {this.state.isEmojiShown ? (
                        <OutsideClickHandler onOutsideClick={() => this.closeEmojiMenu()}>
                            <span className={styles.emojiPicker} ref={(el) => (this.emojiPicker = el)}>
                                <Picker onSelect={this.addEmoji} style={{zIndex: 10000000, width: '100%'}} />
                            </span>
                        </OutsideClickHandler>
                    ) : null}
                    <div
                        contentEditable={canSendMessage}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                ev.preventDefault();
                                return this.messageAction();
                            }
                            return;
                        }}
                        className={styles.editableDiv}
                        ref={this.divElement}
                        data-text={this.props.t('chat.placeholder')}
                    />

                    <div className={styles.actionBtnContainer}>
                        <button
                            disabled={!canSendMessage}
                            className={`${styles.btnSend} ${canSendMessage ? '' : 'disabled'} btn btn-create`}
                            onClick={() => this.messageAction()}>
                            <Translation text="buttons.send" />
                        </button>
                        {this.renderError()}
                        {/*{this.renderConnectionError()}*/}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderError() {
        if (!this.props.error) {
            return;
        }
        setTimeout(this.props.errorModuleHandle, 5000);
        return <div className={styles.errorMessage}>{this.props.error}</div>;
    }

    // private renderConnectionError() {
    // ToDo type of this.props.peerConnection does not have a connection property
    // if (this.props.peerConnection?.connection?.connectionState !== 'failed' || this.props.peerConnection?.connectionAttemptNumber < 1) {
    //     return null;
    // }
    // return (
    //     <div className={styles.errorConnectionMessage}>
    //         <Translation text={'chat.connectionFailed'} />
    //     </div>
    // );
    // }

    private showEmojis = () => {
        this.setState({
            isEmojiShown: true,
        });
    };

    private closeEmojiMenu = () => {
        this.setState({
            isEmojiShown: false,
        });
    };

    private addEmoji = (e: any) => {
        const emoji = e.native;
        this.setState({
            messageInput: this.state.messageInput + emoji,
        });

        this.divElement.current.innerText = this.state.messageInput + emoji;
    };

    private messageAction = () => {
        this.props.addMessage(this.state.messageInput);
        this.setState({
            messageInput: '',
        });
        this.divElement.current.dataset.text = this.props.t('chat.placeholder');
        this.divElement.current.innerText = '';
    };

    private onChange = () => {
        const {t} = this.props;

        this.setState({
            isDeleteModalOpen: true,
            messageInput: this.divElement.current.innerText,
        });

        this.divElement.current.dataset.text = t('chat.placeholder');

        if (this.divElement.current.innerText.length > 0) {
            this.setState({
                isDeleteModalOpen: false,
            });
        }

        this.setState({
            messageInput: this.divElement.current.innerText,
        });
    };
}

export default withTranslation()(ChatActions);
