import {deepCloneObject, Translation} from 'marine-panel-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {changeSelectedItem, MapActionType, resetMapToInitialState, SelectedItemType} from '../../../../../store/reducers/mapHostSlice';
import {selectedMapItemSelector} from '../../../../../store/selectors/mapHostSelectors';

function CreateReservationButton(props: any) {
    return (
        <div className="create-button-wrapper">
            <button className="btn btn-create create-reservation-button" onClick={() => switchCardToCreateReservation()}>
                <Translation text="buttons.createReservation" />
            </button>
        </div>
    );
    function switchCardToCreateReservation() {
        const updatedSelectedItem = deepCloneObject(props.currentlySelectedItem);
        updatedSelectedItem.selectedItemType = SelectedItemType.RESERVATION;
        updatedSelectedItem.selectedItemActionType = MapActionType.CREATE;
        props.changeSelectedItem(updatedSelectedItem);
    }
}

export default connect(
    (state: RootState) => ({
        currentlySelectedItem: selectedMapItemSelector(state),
    }),
    {
        resetMapToInitialState: resetMapToInitialState,
        changeSelectedItem,
    }
)(CreateReservationButton);
