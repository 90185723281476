import {PayloadAction} from '@reduxjs/toolkit';
import {AlertType, BerthPricingStrategyInput, IMultiselectOption, addAlert, authTokenSelector} from 'marine-panel-common-web';
import {Epic, StateObservable, combineEpics, ofType} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {assignToMarinaAPI} from '../../api/berth/assignToMarina';
import {createBerthAPI} from '../../api/berth/createBerth';
import {createMarinaAPI} from '../../api/marina/createMarina';
import {getMarinasAPI} from '../../api/marina/getMarinas';
import {createPricingStrategyAPI} from '../../api/pricingStrategy/createPricingStrategy';
import {RootState} from '../reducers';
import {fetchBerthDetails, setBerthDetails} from '../reducers/berthEditWizardSlice';
import {
    IChangeBerthsMarine,
    ICreateFirstBerth,
    InitWizardStep,
    changeBerthInitWizardError,
    changeCurrentStep,
    changeIsBerthInitWizardInitialized,
    changeIsBerthInitWizardLoading,
    changeMarinaList,
    createFirstBerth,
    createFirstMarina,
    createFirstPricingStrategy,
    fetchMarinaList,
    setIsActionSuccessful,
    setMarinaList,
    setSectorList,
} from '../reducers/berthInitWizardSlice';
import {ICreateMarina} from '../reducers/marinaEditWizardSlice';
import {changePricingStrategyActiveStatusAPI} from '../../api/pricingStrategy/changePricingStrategyActiveStatusAPI';

const createFirstBerthEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(createFirstBerth.type),
        switchMap((action: PayloadAction<ICreateFirstBerth>) => {
            const authToken = authTokenSelector(state$.value);
            return createBerthAPI(authToken, action.payload).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.alerts.berthCreateSuccess', type: AlertType.SUCCESS}),
                        setBerthDetails(resp),
                        changeCurrentStep(InitWizardStep.PHOTOS),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
        })
    );
};

const createFirstPricingStrategyEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(createFirstPricingStrategy.type),
        switchMap((action: PayloadAction<BerthPricingStrategyInput>) => {
            const authToken = authTokenSelector(state$.value);
            return createPricingStrategyAPI(authToken, action.payload).pipe(
                switchMap((resp: any) => {
                    return changePricingStrategyActiveStatusAPI(resp.id, {active: true}, authToken).pipe(
                        switchMap(() => {
                            const actions = successActions([
                                addAlert({message: 'editMenuItems.alerts.pricingStrategyCreateSuccess', type: AlertType.SUCCESS}),
                                changeCurrentStep(InitWizardStep.POLICIES_AND_RULES),
                                fetchBerthDetails(action.payload.berthId),
                            ]);
                            return of(...actions);
                        })
                    );
                }),

                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
        })
    );
};

const changeBerthsMarineEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeMarinaList.type),
        switchMap((action: PayloadAction<IChangeBerthsMarine>) => {
            const authToken = authTokenSelector(state$.value);
            return assignToMarinaAPI(action.payload.berthId, action.payload.marinaId, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: '', type: AlertType.SUCCESS}),
                        setBerthDetails(resp),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
        })
    );
};

const fetchMarinasOptionsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchMarinaList.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);
            return getMarinasAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const uniqueSectorList: any[] = [];
                    const uniqueMarinaList: IMultiselectOption[] = resp['hydra:member'].reduce((acc: any, item: any) => {
                        const foundItem = acc.find((i: any) => i.value === item.id);
                        if (!foundItem) {
                            acc.push({
                                value: item.id,
                                label: item.name,
                            });
                            const sectorId = item.sectors[0].id,
                                location = item.location ? [item.location.latitude, item.location.longitude] : null;
                            uniqueSectorList.push({
                                value: sectorId,
                                label: item.name,
                                location: location,
                            });
                        }
                        return acc;
                    }, []);
                    const actions = successActions([setMarinaList(uniqueMarinaList), setSectorList(uniqueSectorList)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
        })
    );
};

const createFirstMarinaEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(createFirstMarina.type),
        switchMap((action: PayloadAction<ICreateMarina>) => {
            const authToken = authTokenSelector(state$.value);
            return createMarinaAPI(action.payload, authToken).pipe(
                switchMap(() => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.alerts.marinaCreateSuccess', type: AlertType.SUCCESS}),
                        fetchMarinaList(),
                        changeCurrentStep(InitWizardStep.BASE_INFORMATION),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeBerthInitWizardError(error)));
        })
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsBerthInitWizardLoading(false), changeIsBerthInitWizardInitialized(true)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

export const fetchListErrorActions = (error: any, setSliceError: any): any[] => {
    return [addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsBerthInitWizardLoading(false), setSliceError];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const berthInitWizardEpic = combineEpics(
    fetchMarinasOptionsEpic,
    changeBerthsMarineEpic,
    createFirstBerthEpic,
    createFirstPricingStrategyEpic,
    createFirstMarinaEpic
);
export default berthInitWizardEpic;
