import {Reservation, Translation, IconSize} from 'marine-panel-common-web';
import {Component} from 'react';
import {AlertTriangle} from 'react-feather';
import {connect} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {deleteReservation} from '../../../../store/reducers/reservationsSlice';

interface IDeleteReservationModalProps {
    isModalOpen: boolean;
    toggleModal: (item: Reservation | null) => void;
    reservation: Reservation | null;
    deleteReservation: typeof deleteReservation;
}

interface IDeleteReservationModalState {
    isLoading: boolean;
}

class DeleteReservationModal extends Component<IDeleteReservationModalProps, IDeleteReservationModalState> {
    constructor(props: IDeleteReservationModalProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    render() {
        const item = this.props.reservation;
        if (!item) return null;
        return (
            <div className={`delete-reservation-modal-wrapper ${this.props.isModalOpen ? '' : 'closed'}`}>
                <div className="delete-reservation-modal">
                    <ModalHeader toggle={() => this.props.toggleModal(null)}>
                        <span>
                            <Translation text="mainMap.reservation.title" />
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <p className="warning-wrapper">
                            <AlertTriangle size={IconSize.DeleteReservationModal} color={'#EA5455'} />
                            <Translation text="mainMap.reservation.deleteModal.warning" />
                        </p>
                        <p className="confirmation-info">
                            <Translation text="mainMap.reservation.deleteModal.confirmMessage" />
                            <span className="fw-bold">{item.number}</span>
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-create no-border close-modal-button" onClick={() => this.props.toggleModal(null)}>
                            <Translation text="buttons.cancel" />
                        </button>
                        <button className="btn btn-create delete-reservation-button" onClick={() => this.deleteReservation(item)}>
                            <Translation text="buttons.confirm" />
                        </button>
                    </ModalFooter>
                </div>
            </div>
        );
    }
    private deleteReservation(reservation: Reservation) {
        this.props.deleteReservation(reservation.id);
        this.props.toggleModal(null);
    }
}

export default connect(() => ({}), {
    deleteReservation,
})(DeleteReservationModal);
