import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface ISetOrderPayload {
    photos: [
        {
            id: string;
            position: number;
        }
    ];
}

export function changeGalleryOrderAPI(
    id: string | null | undefined,
    payload: ISetOrderPayload | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/change_photo_positions`, payload, new RestQueryParams(), headers);
}
