import {withRouterWrapper, WithRouterWrapperProps} from 'marine-panel-common-web';
import {useEffect, useRef} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {IWizardEditSideMenuItem} from '../edit-menu-items';
interface IHostRoutingProps extends WithRouterWrapperProps {
    sideMenuConfig: IWizardEditSideMenuItem[];
    activeSublink: string;
    onSubmenuChanged: (submenuId: string) => void;
}

const HostRouting = (props: IHostRoutingProps) => {
    const header = document.querySelector('[data-header]'),
        navigation = useNavigate(),
        sections = Array.from(document.querySelectorAll('[data-section]')),
        scrollRoot = document.querySelector('[data-scroller]'),
        isRunned = useRef(false);

    // useRef used for useEffect to run only once
    useEffect(() => {
        if (isRunned.current) {
            return;
        }
        if (sections && sections.length > 0) {
            isRunned.current = true;
            const firstSection = sections[0];
            if (firstSection.id !== props.activeSublink) {
                navigation(`#${firstSection.id}`);
            }
        }
    }, [navigation, props, sections]);

    // useEffect(() => {
    //     const options = {
    //         root: scrollRoot,
    //         threshold: 1,
    //     };

    //     const onIntersect = (entries: any) => {
    //         entries.forEach((entry: any) => {
    //             if (entry.isIntersecting && entry.intersectionRatio > 0.3 && entry.target.id !== props.activeSublink) {
    //                 navigation(`#${entry.target.id}`);
    //             }
    //         });
    //     };
    //     const observer = new IntersectionObserver(onIntersect, options);

    //     sections.forEach((section) => {
    //         observer.observe(section);
    //     });

    //     return () => {
    //         observer.disconnect();
    //     };
    // }, [scrollRoot, sections, header, navigation, props.activeSublink, props]);

    if (props.sideMenuConfig.length === 0) {
        return null;
    }

    return (
        <div className="wizard-edit-host-content-wrapper mt-2 mt-xl-0 offset-xl-1 col-xl-8">{renderEditRoutes(props.sideMenuConfig)}</div>
    );
};

function renderEditRoutes(sideMenuConfig: IWizardEditSideMenuItem[]) {
    return (
        <Routes>
            {sideMenuConfig.map((item) => (
                <Route path={`${item.navLink}`} element={item.component} key={item.id} />
            ))}
        </Routes>
    );
}

export default withRouterWrapper(HostRouting);
