import {RestQueryParams, marineHubAPI, VendorPaymentAccount} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export type AttachStripePaymentMethodInput = {
    vendorPaymentMethodId: string;
};

export type PaymentMethodInput = {
    cardNumber: string;
    cardHolderName: string;
    expiryDate: string;
    cv: string;
};

export type PaymentAccountOutput = {
    id: string;
    vendorPaymentAccounts: VendorPaymentAccount[];
};

export enum PaymentServiceName {
    STRIPE = 'stripe',
}

export function attachPaymentMethodAPI(
    authToken: string,
    paymentAccountId: string,
    method: AttachStripePaymentMethodInput
): Observable<any> {
    return marineHubAPI.put(`api/payment_accounts/${paymentAccountId}/stripe/attach_payment_method`, method, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
