import {Berth, FormControlChangeType, Loader, Translation, Form} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../store/reducers';
import {changeBerthPolicyAndRule, fetchBerthDetails} from '../../../../../../store/reducers/berthEditWizardSlice';
import {currentlyEditedBerth, isBerthEditWizardLoadingSelector} from '../../../../../../store/selectors/berthEditWizardSelectors';
import EditFormControl from '../../Common/EditFormControl';
import {policiesAndRulesDefaultFormConfig} from './policiesAndRulesDefaultFormConfig';
import {IPolicyAndRulePayloadBerth} from '../../../../../../api/berth/changePoliciesAndRules';

interface IPoliciesAndRulesProps {
    readonly berth: Berth | null;
    readonly isLoading: boolean;
    readonly fetchBerthDetails: typeof fetchBerthDetails;
    readonly changeBerthPolicyAndRule: typeof changeBerthPolicyAndRule;
    readonly isInitWizard?: boolean;
}

export enum BerthPolicyAndRules {
    GATE_OPENS_AT = 'checkInTime',
    GATE_CLOSES_AT = 'checkOutTime',
    ADDITIONAL_RULES = 'additionalRules',
}

const PoliciesAndRules: FC<IPoliciesAndRulesProps> = ({berth, fetchBerthDetails, isLoading, changeBerthPolicyAndRule, isInitWizard}) => {
    const params = useParams(),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share()));

    const berthId = params.id ? params.id : berth?.id;

    useEffect(() => {
        const subscriptions: Subscription[] = [];

        if (berthId) {
            fetchBerthDetails(berthId);
        }
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [berthId, fetchBerthDetails]);

    const [editedItemId, setEditedItemId] = useState<string | null>(null);
    const berthPolicyAndRulesArray = Object.values(BerthPolicyAndRules);
    const arrayOfObjects = berthPolicyAndRulesArray.map((key) => ({[key]: null}));
    const tupleArray = arrayOfObjects.map((obj) => Object.entries(obj)[0] as [string, null]);
    const result = Object.fromEntries(tupleArray),
        [value, setValue] = useState<any>(result);

    useEffect(() => {
        const subscriptions: Subscription[] = [];

        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState: {[key: string]: any}) => {
                            return {
                                checkInTime: data.value && data.value.checkInTime ? data.value.checkInTime : prevState?.checkInTime,
                                checkOutTime: data.value && data.value.checkOutTime ? data.value.checkOutTime : prevState?.checkOutTime,
                                additionalRules:
                                    data.value && data.value.additionalRules ? data.value.additionalRules : prevState?.additionalRules,
                            };
                        });
                    }
                })
        );
    }, []);

    useEffect(() => {
        setPoliciesAndRulesValues();
    }, [berth]);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    if (!berthId) return null;

    const onConfirmAction = (berthId: string, policyKey: string, value: string | null) => {
        const payload: IPolicyAndRulePayloadBerth = {
            field: policyKey,
            value: value,
        };
        changeBerthPolicyAndRule(berthId, payload);

        setEditedItemId(null);
    };

    function setPoliciesAndRulesValues() {
        if (berth && berth.policyAndRules) {
            const keysToKeep = ['checkInTime', 'checkOutTime', 'additionalRules'];

            const defaultValues = Object.entries(berth.policyAndRules).reduce((obj: {[key: string]: any}, [key, value]) => {
                if (keysToKeep.includes(key)) {
                    obj[key] = value;
                }
                return obj;
            }, {});
            setValue(defaultValues);
        }
    }

    const renderPoliciesList = (policyKeys: string[]) => {
        if (policyKeys.length === 0) return null;
        return Object.entries(policyKeys).map(([policyKey, displayValue]) => {
            const policyValue = displayValue ? displayValue : <Translation text="editMenuItems.sections.berth_policies.noPolicySet" />;
            return (
                <EditFormControl
                    key={policyKey}
                    editedItemId={editedItemId}
                    setEditedItemId={(editedItemId: string | null) => setEditedItemId(editedItemId)}
                    currentItemId={policyKey}
                    titleTranslationKey={`editMenuItems.sections.berth_policies.formControls.${policyKey}`}
                    onConfirm={() => onConfirmAction(berthId ? berthId : '', policyKey, value?.[`${policyKey}`])}
                    editedChildren={
                        <Form
                            controlName={'berthNameForm'}
                            config={policiesAndRulesDefaultFormConfig(policyKey, displayValue)}
                            value={value}
                            onValueStateChange={onValueStateChange}
                        />
                    }
                    displayChildren={<p className="secondary-title description">{policyValue}</p>}
                />
            );
        });
    };
    return (
        <div className={`details-edit-section-wrapper ${isInitWizard ? 'init-wizard' : ''}`}>
            <div className={`details-edit-section ${isInitWizard ? 'col-xl-12' : 'col-xl-6'}`}>
                <h2 className={`title main ${isInitWizard ? 'd-none' : ''}`}>
                    <Translation text="editMenuItems.sections.berth_policies.title" />
                </h2>
                <div className="policies-list-wrapper">{renderPoliciesList(value)}</div>
                <Loader showLoader={isLoading} />
            </div>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        berth: currentlyEditedBerth(state),
        isLoading: isBerthEditWizardLoadingSelector(state),
    }),
    {fetchBerthDetails, changeBerthPolicyAndRule}
)(PoliciesAndRules);
