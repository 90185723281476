import {Marina, Form, FormControlChangeType, IMultiselectOption, Translation} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../../store/reducers';
import {
    changeMarinaAddress,
    changeMarinaPhone,
    changeIsMarinaEditWizardLoading,
    resetToInitialMarinaEditWizardState,
    changeCurrentlyEditedControlId,
    changeMarinaEmail,
} from '../../../../../../../store/reducers/marinaEditWizardSlice';
import {
    currentlyEditedControlId,
    isActionSuccessfulSelector,
    isMarinaEditWizardInitializedSelector,
} from '../../../../../../../store/selectors/marinaEditWizardSelectors';
import {changeIsModalOpen} from '../../../../../../../store/reducers/modalSlice';
import EditFormControl from '../../../Common/EditFormControl';
import {marinaAddressFormConfig} from './marinaAddressFormConfig';
import {marinaPhoneFormConfig} from './marinaPhoneFormConfig';
import {marinaEmailFormConfig} from './marinaEmailFormConfig';

interface IMarinaContactProps {
    readonly marina: Marina | null;
    readonly isMarinaEditWizardInitialized: boolean;
    readonly resetToInitialMarinaEditWizardState: typeof resetToInitialMarinaEditWizardState;
    readonly isActionSuccessful: boolean;
    readonly currentlyEditedControlId: string | null;
    readonly changeMarinaPhone: typeof changeMarinaPhone;
    readonly changeMarinaAddress: typeof changeMarinaAddress;
    readonly changeMarinaEmail: typeof changeMarinaEmail;
    readonly changeCurrentlyEditedControlId: typeof changeCurrentlyEditedControlId;
}

interface IMarinaContactValues {
    value: {
        phone?: string | null;
        address?: string | null;
        email?: string | null;
    };
}

const MarinaContact: FC<IMarinaContactProps> = ({marina, currentlyEditedControlId}) => {
    const params = useParams(),
        marinaId = params.id,
        dispatch = useDispatch(),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<IMarinaContactValues>();

    const subscriptions: Subscription[] = [];
    const editedItemId = currentlyEditedControlId;

    useEffect(() => {
        if (marina !== null) {
            setValue({
                value: {
                    phone: marina ? marina.phone : null,
                    address: marina ? marina.address : null,
                    email: marina ? marina.email : null,
                },
            });
        }
    }, [marina]);

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState) => {
                            const formValues = {
                                value: {
                                    phone: data.value && data.value.phone ? data.value.phone : prevState?.value.phone,
                                    address: data.value && data.value.address ? data.value.address : prevState?.value.address,
                                    email: data.value && data.value.email ? data.value.email : prevState?.value.email,
                                },
                            };
                            return formValues;
                        });
                    }
                })
        );
    }, []);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    const onConfirmAction = (action: any) => {
        dispatch(action);
        dispatch(changeCurrentlyEditedControlId(null));
    };
    return (
        <div id="marina_contact" className="details-edit-section col-xl-6" data-section="marina_contact">
            <h3 className="title main">
                <Translation text="editMenuItems.sections.marina_contact.title" />
            </h3>

            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'marina_phone'}
                titleTranslationKey="editMenuItems.sections.marina_contact.formControls.marina_phone"
                onConfirm={() => onConfirmAction(changeMarinaPhone(marinaId, values?.value?.phone))}
                confirmButtonsDisabled={true}
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.marina_contact.formControls.marina_phone_description" />
                        </p>
                        <Form
                            controlName={'marinaPhoneForm'}
                            config={marinaPhoneFormConfig(values?.value.phone, () => dispatch(changeCurrentlyEditedControlId(null)))}
                            value={values?.value.phone}
                            onValueStateChange={onValueStateChange}
                            submitForm={() => onConfirmAction(changeMarinaPhone(marinaId, values?.value?.phone))}
                        />
                    </>
                }
                displayChildren={<p className="secondary-title description">{marina?.phone}</p>}
            />

            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'marina_email'}
                onConfirm={() => onConfirmAction(changeMarinaEmail(marinaId, values?.value.email))}
                confirmButtonsDisabled={true}
                titleTranslationKey="editMenuItems.sections.marina_contact.formControls.marina_email"
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.marina_contact.formControls.marina_email" />
                        </p>
                        <Form
                            controlName={'marinaEmailForm'}
                            config={marinaEmailFormConfig(values?.value.email, () => dispatch(changeCurrentlyEditedControlId(null)))}
                            value={values?.value.email}
                            onValueStateChange={onValueStateChange}
                            submitForm={() => onConfirmAction(changeMarinaEmail(marinaId, values?.value.email))}
                        />
                    </>
                }
                displayChildren={<p className="secondary-title description">{marina?.email}</p>}
            />

            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'marina_address'}
                onConfirm={() => onConfirmAction(changeMarinaAddress(marinaId, values?.value.address))}
                confirmButtonsDisabled={true}
                titleTranslationKey="editMenuItems.sections.marina_contact.formControls.marina_address"
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.marina_contact.formControls.marina_address_description" />
                        </p>
                        <Form
                            controlName={'marinaAddressForm'}
                            config={marinaAddressFormConfig(values?.value.address, () => dispatch(changeCurrentlyEditedControlId(null)))}
                            value={values?.value.address}
                            onValueStateChange={onValueStateChange}
                            submitForm={() => onConfirmAction(changeMarinaAddress(marinaId, values?.value.address))}
                        />
                    </>
                }
                displayChildren={<p className="secondary-title description">{marina?.address}</p>}
            />
        </div>
    );
};
export default connect(
    (state: RootState) => ({
        isActionSuccessful: isActionSuccessfulSelector(state),
        isMarinaEditWizardInitialized: isMarinaEditWizardInitializedSelector(state),
        currentlyEditedControlId: currentlyEditedControlId(state),
    }),
    {
        resetToInitialMarinaEditWizardState,
        changeIsModalOpen,
        changeIsMarinaEditWizardLoading,
        changeMarinaAddress,
        changeMarinaEmail,
        changeMarinaPhone,
        changeCurrentlyEditedControlId,
    }
)(MarinaContact);
