import {authTokenSelector, Berth} from 'marine-panel-common-web';
import {FC, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Modal} from 'reactstrap';
import {Subscription} from 'rxjs';
import {RootState} from '../../../store/reducers';
import {deletePhoto, setBerthDetails, setIsActionSuccessful, setPhotoAsCover} from '../../../store/reducers/berthEditWizardSlice';
import {currentlyEditedBerth} from '../../../store/selectors/berthEditWizardSelectors';
import {isModalOpenSelector, nameSelector, photoIdSelector} from '../../../store/selectors/modalSelectors';
import {SelectedItemType} from '../../../store/reducers/mapHostSlice';
import {changeIsModalOpen, ModalNames, resetToInitialModalState} from '../../../store/reducers/modalSlice';
import {isActionSuccessfulSelector} from '../../../store/selectors/berthEditWizardSelectors';
import CreateMarinaModal from '../../Administration/Marinas/CreateMarinaModal';
import CreateBerthModal from '../../Berths/CreateBerthModal';
import CreatePricingStrategyModal from '../../Shared/WizardEdit/WizardContent/Berth/PricingAndAvailability/CreatePricingStrategyModal';
import EditPricingStrategyModal from '../WizardEdit/WizardContent/Berth/PricingAndAvailability/EditPricingStrategyModal';
import SetLocationModal from '../WizardEdit/WizardContent/Common/SetLocationModal';
import ChangeExtraModal from '../WizardEdit/WizardContent/Marina/Extras/ChangeExtraModal';
import CreateExtraModal from '../WizardEdit/WizardContent/Marina/Extras/CreateExtraModal';
import AddPhotoModal from './AddPhotoModal';
import ChangePhotoModal from './ChangePhotoModal';
import DeletePhotoModal from './DeletePhotoModal';

interface IModalComponentProps {
    toggleModalOpen: () => void;
    modalName: ModalNames | null | undefined;
    isOpen: boolean;
    berth?: Berth | null;
    readonly resetToInitialModalState: typeof resetToInitialModalState;
    readonly setPhotoAsCover: typeof setPhotoAsCover;
    readonly deletePhoto: typeof deletePhoto;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly setIsActionSuccessful: typeof setIsActionSuccessful;
    readonly setBerthDetails: typeof setBerthDetails;
    readonly authToken: string;
    photoId?: string | null;
}

const ModalComponent: FC<IModalComponentProps> = ({toggleModalOpen, modalName, isOpen, resetToInitialModalState}) => {
    const subscriptions: Subscription[] = [],
        dispatch = useDispatch();
    useEffect(() => {
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
            dispatch(resetToInitialModalState());
        };
    }, []);

    const renderModal = (modalName: ModalNames | null | undefined) => {
        let content;

        switch (modalName) {
            case ModalNames.SET_BERTH_LOCATION:
                content = <SetLocationModal locationType={SelectedItemType.BERTH} toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.SET_MARINA_LOCATION:
                content = <SetLocationModal locationType={SelectedItemType.MARINA} toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.CREATE_BERTH:
                content = <CreateBerthModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.CREATE_MARINA:
                content = <CreateMarinaModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.CREATE_EXTRA:
                content = <CreateExtraModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.EDIT_EXTRA:
                content = <ChangeExtraModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.CREATE_PRICING_STRATEGY:
                content = <CreatePricingStrategyModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.EDIT_PRICING_STRATEGY:
                content = <EditPricingStrategyModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.DELETE_PHOTO:
                content = <DeletePhotoModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.CHANGE_PHOTO:
                content = <ChangePhotoModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.ADD_PHOTO:
                content = <AddPhotoModal toggleModalOpen={toggleModalOpen} />;
                break;
            default:
                content = null;
        }
        return content;
    };

    return (
        <Modal isOpen={isOpen} toggle={() => toggleModalOpen()}>
            {renderModal(modalName)}
        </Modal>
    );
};

export default connect(
    (state: RootState) => ({
        modalName: nameSelector(state),
        isOpen: isModalOpenSelector(state),
        berth: currentlyEditedBerth(state),
        photoId: photoIdSelector(state),
        authToken: authTokenSelector(state),
        isActionSuccessful: isActionSuccessfulSelector(state),
    }),
    {
        resetToInitialModalState,
        setPhotoAsCover,
        deletePhoto,
        changeIsModalOpen,
        setIsActionSuccessful,
        setBerthDetails,
    }
)(ModalComponent);
