import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeBerthLocationPayload} from '../../store/reducers/berthEditWizardSlice';

export function changeBerthLocationAPI(
    id: string | null | undefined,
    payload: IChangeBerthLocationPayload | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    if (payload === null) return new Observable();
    const payloadItem = {location: {vertexes: payload.location}};

    return marineHubAPI.put(`api/berths/${id}/change_location`, payloadItem, new RestQueryParams(), headers);
}
