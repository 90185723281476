import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {RootState} from '../reducers';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    attachPaymentMethod,
    changeStripeChargesEnabled,
    generateOnboardingUrl,
    generateStripeCustomerPortalUrl,
    generateStripeLoginUrl,
    getPaymentInitialData,
    IAttachPaymentMethod,
    setIsPaymentLoading,
    setPaymentAccountData,
} from '../reducers/paymentSlice';
import {
    ApiError,
    authTokenSelector,
    accountUserIdSelector,
    getAccountDataAPI,
    handleApiError,
    isNotNullOrUndefined,
    AlertType,
    addAlert,
} from 'marine-panel-common-web';
import {generateOnboardingLink, IStripeOnboardingPayload, OnboardingType} from '../../api/payments/generateOnboardingLink';
import {paymentVendorAccountIdSelector} from '../selectors/paymentSelecotrs';
import {generateStripeLoginLink, IStripeLoginPayload} from '../../api/payments/generateStripeLoginLink';
import {attachPaymentMethodAPI} from '../../api/payments/attachPaymentMethod';
import {PayloadAction} from '@reduxjs/toolkit';
import {generateStripeCustomerPortalLink, IStripeCustomerPortalLinkPayload} from '../../api/payments/generateStripeCustomerPortalLink';

const errorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), setIsPaymentLoading(false)];
};

const initPaymentEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(getPaymentInitialData.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value),
                accountId = accountUserIdSelector(state$.value);

            if (!accountId) {
                return of(errorActions);
            }

            return getAccountDataAPI(authToken, accountId).pipe(
                switchMap((res: any) => {
                    const paymentAccount = res?.operator?.paymentAccount ? res.operator.paymentAccount : null,
                        stripeChargesEnabled = isNotNullOrUndefined(res?.operator?.stripeChargesEnabled)
                            ? res.operator.stripeChargesEnabled
                            : false;

                    return of(
                        setPaymentAccountData(paymentAccount),
                        changeStripeChargesEnabled(stripeChargesEnabled),
                        setIsPaymentLoading(false)
                    );
                }),
                catchError(errorActions)
            );
        }),
        catchError(errorActions)
    );

const generateOnboardingUrlEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(generateOnboardingUrl.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value),
                paymentAccountId = paymentVendorAccountIdSelector(state$.value);

            if (!paymentAccountId) {
                return of(errorActions);
            }

            const payload: IStripeOnboardingPayload = {
                refreshUrl: `${process.env.REACT_APP_URL}/panel/settings`,
                returnUrl: `${process.env.REACT_APP_URL}/panel/settings`,
                onboardingMode: OnboardingType.Onboarding,
            };

            return generateOnboardingLink(authToken, paymentAccountId, payload).pipe(
                switchMap((res: any) => {
                    const stripeUrl = res.accountLink.url;
                    window.open(stripeUrl, '_blank', 'noreferrer');
                    return of(setIsPaymentLoading(false));
                }),
                catchError(errorActions)
            );
        }),
        catchError(errorActions)
    );

const generateStripeLoginUrlEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(generateStripeLoginUrl.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value),
                paymentAccountId = paymentVendorAccountIdSelector(state$.value);

            if (!paymentAccountId) {
                return of(errorActions);
            }

            const payload: IStripeLoginPayload = {
                redirectUrl: `${process.env.REACT_APP_URL}/panel/settings`,
            };

            return generateStripeLoginLink(authToken, paymentAccountId, payload).pipe(
                switchMap((res: any) => {
                    const stripeUrl = res.loginLink.url;
                    window.open(stripeUrl, '_blank', 'noreferrer');
                    return of(setIsPaymentLoading(false));
                }),
                catchError(errorActions)
            );
        }),
        catchError(errorActions)
    );

const generateStripeCustomerPortalLinkEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(generateStripeCustomerPortalUrl.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value),
                paymentAccountId = paymentVendorAccountIdSelector(state$.value);

            if (!paymentAccountId) {
                return of(errorActions);
            }

            const payload: IStripeCustomerPortalLinkPayload = {
                returnUrl: `${process.env.REACT_APP_URL}/panel/settings`,
            };
            type CustomerPortalLinkOutput = {
                customerPortalLink: string;
            };
            return generateStripeCustomerPortalLink(authToken, paymentAccountId, payload).pipe(
                switchMap((res: CustomerPortalLinkOutput) => {
                    const stripeUrl = res.customerPortalLink;
                    window.open(stripeUrl, '_blank', 'noreferrer');
                    return of(setIsPaymentLoading(false));
                }),
                catchError(errorActions)
            );
        }),
        catchError(errorActions)
    );

const attachPaymentMethodEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(attachPaymentMethod.type),
        switchMap((action: PayloadAction<IAttachPaymentMethod>) => {
            const authToken = authTokenSelector(state$.value),
                paymentAccountId = paymentVendorAccountIdSelector(state$.value);

            if (!paymentAccountId) {
                return of(errorActions);
            }

            return attachPaymentMethodAPI(authToken, paymentAccountId, action.payload.method).pipe(
                switchMap((resp: any) => {
                    // const vendorData = paymentData.paymentAccountVendorData,
                    //     paymentAccount: PaymentAccount = {
                    //         id: paymentData.id,
                    //         paymentAccountVendorData: {
                    //             accountBankTransferBankName: vendorData.accountBankTransferBankName,
                    //             accountBankTransferCountry: vendorData.accountBankTransferCountry,
                    //             accountBankTransferCurrency: vendorData.accountBankTransferCurrency,
                    //             accountBankTransferLastFour: vendorData.accountBankTransferLastFour,
                    //             accountEmail: vendorData.accountEmail,
                    //             chargesEnabled: vendorData.chargesEnabled,
                    //             customerId: vendorData.customerId,
                    //             customerPaymentMethodBrand: vendorData.customerPaymentMethodBrand,
                    //             customerPaymentMethodExpMonth: vendorData.customerPaymentMethodExpMonth,
                    //             customerPaymentMethodExpYear: vendorData.customerPaymentMethodExpYear,
                    //             customerPaymentMethodId: vendorData.customerPaymentMethodId,
                    //             customerPaymentMethodLastFour: vendorData.customerPaymentMethodLastFour,
                    //             customerPaymentMethodName: vendorData.customerPaymentMethodName,
                    //             paymentAccountId: vendorData.paymentAccountId,
                    //         },
                    //         paymentAccountVendorType: paymentData.paymentAccountVendorType,
                    //     };
                    // this.props.changePaymentAccountData(paymentAccount);
                    return of(setIsPaymentLoading(false));
                }),
                catchError(errorActions)
            );
        }),
        catchError(errorActions)
    );

const detachPaymentMethodEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(attachPaymentMethod.type),
        switchMap((action: PayloadAction<IAttachPaymentMethod>) => {
            const authToken = authTokenSelector(state$.value),
                paymentAccountId = paymentVendorAccountIdSelector(state$.value);

            if (!paymentAccountId) {
                return of(errorActions);
            }

            return attachPaymentMethodAPI(authToken, paymentAccountId, action.payload.method).pipe(
                switchMap((resp: any) => {
                    return of(setIsPaymentLoading(false));
                }),
                catchError(errorActions)
            );
        }),
        catchError(errorActions)
    );

const paymentEpic = combineEpics(
    initPaymentEpic,
    generateOnboardingUrlEpic,
    generateStripeLoginUrlEpic,
    attachPaymentMethodEpic,
    detachPaymentMethodEpic,
    generateStripeCustomerPortalLinkEpic
);

export default paymentEpic;
