import {Form, FormControlChangeType, GeoPolygon, Translation} from 'marine-panel-common-web';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {BehaviorSubject} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../store/reducers';
import {IChangeBerthLocationPayload, resetToInitialBerthEditWizardState} from '../../../store/reducers/berthEditWizardSlice';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {fetchMarinaList} from '../../../store/reducers/berthEditWizardSlice';
import {createBerth} from '../../../store/reducers/berthsSlice';
import {SelectedItemType} from '../../../store/reducers/mapHostSlice';
import {sectorListSelector} from '../../../store/selectors/berthEditWizardSelectors';
import LocationInput from '../../Shared/WizardEdit/WizardContent/Common/SetLocationModal/LocationInput';
import {createBerthMarinaFormConfig} from './createBerthMarinaFormConfig';
import {createBerthNameFormConfig} from './createBerthNameFormConfig';
import {LatLngTuple} from 'leaflet';

interface ICreateBerthModalProps {
    readonly sectorList: any[] | [];
    readonly createBerth: typeof createBerth;
    toggleModalOpen: () => void;
}

interface ICreateBerthModalValues {
    name: string | null;
    marina: string | null;
    location: any[] | null;
    mapCenter: LatLngTuple | null;
}
const CreateBerthModal: FC<ICreateBerthModalProps> = ({sectorList, createBerth, toggleModalOpen}) => {
    const [sectorListState, setSectorListState] = useState<any[] | []>([]);
    const [values, setValue] = useState<ICreateBerthModalValues>({
        name: '',
        marina: '',
        location: null,
        mapCenter: null,
    });
    const dispatch = useDispatch();
    const onValueStateChange$ = useMemo(() => new BehaviorSubject<any>(null), []);
    const onValueStateChange$$ = useMemo(() => onValueStateChange$.pipe(share()), [onValueStateChange$]);

    useEffect(() => {
        if (sectorList) {
            setSectorListState(sectorList);
        }
        dispatch(fetchMarinaList());

        const subscription = onValueStateChange$$
            .pipe(
                filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                    return data && data?.changeType === FormControlChangeType.User;
                })
            )
            .subscribe((data: any) => {
                if (data.controlName) {
                    setValue((prevState: ICreateBerthModalValues) => {
                        const formValues = {
                            ...prevState,
                            name: data.value?.name || prevState.name,
                            marina: data.value?.marina || prevState.marina,
                        };
                        if (data.controlName === 'berthMarinaForm' && data.value?.marina && sectorListState?.length > 0) {
                            const marina = sectorListState.find((item) => item.value === data.value.marina);
                            formValues.mapCenter = marina?.location || prevState.mapCenter;
                        }
                        return formValues;
                    });
                }
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [sectorList, dispatch, onValueStateChange$$, sectorListState]);

    const onValueStateChange = useCallback(
        (controlName: string, value: any, changeType: FormControlChangeType) => {
            onValueStateChange$.next({controlName, value, changeType});
        },
        [onValueStateChange$]
    );

    const isDisabled = !values.name || !values.location;
    const locationPayload: GeoPolygon | null = values.location ? {vertexes: values.location} : null;

    return (
        <div className="modal-wrapper">
            <ModalHeader>
                <p className="main title">
                    <Translation text="modal.createBerth.title" />
                </p>
                <button className="btn-close" onClick={() => toggleModalOpen()}></button>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="form-control offers-form-control modal-form">
                        <p className="main title">
                            <Translation text="modal.createBerth.inputs.nameBerth" />
                        </p>
                        <p className="secondary-title description small">
                            <Translation text="editMenuItems.sections.berth_basics.formControls.berth_name_description" />
                        </p>
                        <Form
                            controlName={'berthNameForm'}
                            config={createBerthNameFormConfig()}
                            value={values?.name}
                            onValueStateChange={onValueStateChange}
                        />
                    </div>
                    <div className="form-control offers-form-control modal-form">
                        <p className="main title">
                            <Translation text="editMenuItems.sections.berth_basics.formControls.berth_marina" />
                        </p>
                        <p className="secondary-title description small">
                            <Translation text="editMenuItems.sections.berth_basics.formControls.berth_marina_description" />
                        </p>
                        <Form
                            controlName="berthMarinaForm"
                            value={values}
                            config={createBerthMarinaFormConfig(sectorListState)}
                            onValueStateChange={onValueStateChange}
                        />
                    </div>
                    <div className="form-control offers-form-control modal-form">
                        <p className="main title">
                            <Translation text={`modal.createBerth.locationInput.title`} />
                        </p>
                        <p className="secondary-title description small">
                            <Translation text={`modal.createBerth.locationInput.subtitle`} />
                        </p>
                        <p className="secondary-title description small">
                            <Translation text={`modal.createBerth.locationInput.description`} />
                        </p>
                        <div className="location-input-wrapper">
                            <LocationInput
                                updatedCoords={(coords: IChangeBerthLocationPayload) =>
                                    setValue((prevState: any) => ({...prevState, location: coords.location}))
                                }
                                createdCoords={(coords: any) => setValue((prevState: any) => ({...prevState, location: coords}))}
                                locationItem={SelectedItemType.BERTH}
                                predefinedLocation={values.mapCenter ? values.mapCenter : null}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="sm-btn btn btn-underline btn-underline-primary" onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button
                    className="sm-btn btn btn-create"
                    disabled={isDisabled}
                    onClick={() => (values.name && locationPayload ? createBerth(values.marina, values.name, locationPayload) : null)}>
                    <Translation text="buttons.create" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        sectorList: sectorListSelector(state),
    }),
    {
        resetToInitialBerthEditWizardState,
        createBerth,
    }
)(CreateBerthModal);
