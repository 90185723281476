import {FormControlType, IFormConfig} from 'marine-panel-common-web';

export const reservationStatusFromConfig = (value?: any): IFormConfig => {
    return {
        controlType: 'form',
        class: '',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'reservation_status',
                controlType: 'group',
                class: 'row',
                controls: {
                    isPaid: {
                        controlType: 'control',
                        defaultValue: value?.isPaid ? value.isPaid : false,
                        formControlType: FormControlType.SWITCH,
                        validationRules: [],
                        placeholder: '',
                        disabled: true,
                        isLabelHidden: false,
                        label: 'reservations.form.paid',
                        hostClass: 'col-xl-2 form-control switch-label-container',
                    },
                    isArrived: {
                        controlType: 'control',
                        defaultValue: value?.isArrived ? value.isArrived : false,
                        formControlType: FormControlType.SWITCH,
                        validationRules: [],
                        placeholder: '',
                        disabled: true,
                        isLabelHidden: false,
                        label: 'reservations.form.arrived',
                        hostClass: 'col-xl-2 form-control switch-label-container',
                    },
                },
            },
        ],
    };
};
