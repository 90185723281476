import {
    countryList,
    FormControlType,
    IFormConfig,
    InputType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
} from 'marine-panel-common-web';

export const preferencesFormConfig = (): IFormConfig => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'technology',
            controlType: 'group',
            class: 'row',
            controls: {
                technology: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: countryList ? countryList : [],
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.technology',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'location',
            controlType: 'group',
            class: 'row',
            controls: {
                location: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: countryList ? countryList : [],
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.location',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'companyType',
            controlType: 'group',
            class: 'row',
            controls: {
                companyType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: countryList ? countryList : [],
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.companyType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'industry',
            controlType: 'group',
            class: 'row',
            controls: {
                language: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: countryList ? countryList : [],
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.industry',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'contractType',
            controlType: 'group',
            class: 'row',
            controls: {
                contractType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: countryList ? countryList : [],
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.contractType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'location_options',
            controlType: 'group',
            class: 'row',
            controls: {
                onlyRemote: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'profile.preferences.addPreferences.form.labels.onlyRemote',
                    hostClass: 'col-xl-4 form-control switch-inline-container',
                },
                partTimeRemote: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'profile.preferences.addPreferences.form.labels.partTimeRemote',
                    hostClass: 'col-xl-4 form-control switch-inline-container',
                },
                onlyStationary: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'profile.preferences.addPreferences.form.labels.onlyStationary',
                    hostClass: 'col-xl-4 form-control switch-inline-container',
                },
            },
        },
        {
            key: 'work_options',
            controlType: 'group',
            class: 'row',
            controls: {
                fullTime: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'profile.preferences.addPreferences.form.labels.fullTime',
                    hostClass: 'col-xl-4 form-control switch-inline-container',
                },
                partTime: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'profile.preferences.addPreferences.form.labels.partTime',
                    hostClass: 'col-xl-4 form-control switch-inline-container',
                },
                onlyRelocation: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'profile.preferences.addPreferences.form.labels.onlyRelocation',
                    hostClass: 'col-xl-4 form-control switch-inline-container',
                },
            },
        },
        {
            key: 'salary',
            controlType: 'group',
            class: 'row',
            controls: {
                salary: {
                    controlType: 'control',
                    formControlType: FormControlType.QUANTITY_INPUT,
                    hostClass: 'col-xl-6 form-control',
                    label: 'profile.preferences.addPreferences.form.labels.salary',
                    minValue: 0,
                    stepValue: 100,
                    type: InputType.NUMBER,
                },
            },
        },
    ],
});
