import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function deleteReservationAPI(authToken: string | null, reservationId?: string, payload?: any): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return marineHubAPI.delete(`api/reservations/${reservationId}`, new RestQueryParams(), headers);
}
