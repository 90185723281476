import {PayloadAction} from '@reduxjs/toolkit';
import {addAlert, AlertType, authTokenSelector, BerthPricingStrategyInput, PricingStrategyDefinitionInput} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {changePricingStrategyActiveStatusAPI} from '../../api/pricingStrategy/changePricingStrategyActiveStatusAPI';
import {createPricingStrategyAPI} from '../../api/pricingStrategy/createPricingStrategy';
import {createPricingStrategyDefinitionAPI} from '../../api/pricingStrategy/createPricingStrategyDefinition';
import {deletePricingStrategyAPI} from '../../api/pricingStrategy/deletePricingStrategy';
import {getPricingStrategiesAPI} from '../../api/pricingStrategy/getPricingStrategies';
import {getPricingStrategyAPI} from '../../api/pricingStrategy/getPricingStrategy';
import {getPricingStrategyDefinitionsAPI} from '../../api/pricingStrategy/getPricingStrategyDefinitions';
import {updatePricingStrategyAPI} from '../../api/pricingStrategy/updatePricingStrategy';
import {RootState} from '../reducers';
import {fetchBerthDetails} from '../reducers/berthEditWizardSlice';
import {changeIsModalOpen, setCurrentlyEditedItemId} from '../reducers/modalSlice';
import {
    changeIsPricingStrategiesLoading,
    changePricingStrategiesError,
    changePricingStrategyActiveStatus,
    createPricingStrategy,
    createPricingStrategyDefinition,
    fetchPricingDefinitions,
    fetchPricingStrategies,
    fetchPricingStrategyDetails,
    IChangePricingStrategyActiveStatus,
    removePricingStrategy,
    setEditedPricingStrategy,
    setPricingDefinitions,
    setPricingStrategies,
    updatePricingStrategy,
} from '../reducers/pricingStrategiesSlice';
import {currentlyEditedBerth} from '../selectors/berthEditWizardSelectors';
import {editedPricingStrategySelector} from '../selectors/pricingStrategiesSelectors';

const fetchPricingStrategyDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(fetchPricingStrategyDetails.type),

        switchMap((action: PayloadAction<any>) => {
            const authToken = authTokenSelector(state$.value);
            return getPricingStrategyAPI(authToken, action.payload.id).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setEditedPricingStrategy(resp), changeIsPricingStrategiesLoading(false)]);
                    return of(...actions);
                }),
                catchError((error) => of(...getErrorMessage(error)))
            );
        }),
        catchError(() => {
            return of();
        })
    );

const changePricingStrategyActiveStatusEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changePricingStrategyActiveStatus.type),
        switchMap((action: PayloadAction<IChangePricingStrategyActiveStatus>) => {
            const authToken = authTokenSelector(state$.value);
            return changePricingStrategyActiveStatusAPI(action.payload.berthPricingStrategyId, action.payload.payload, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setEditedPricingStrategy(resp),
                        changeIsPricingStrategiesLoading(false),
                        addAlert({message: 'editMenuItems.alerts.pricingStrategyActiveStatusChangeSuccess', type: AlertType.SUCCESS}),
                    ]);

                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
        })
    );
};

const deletePricingStrategyEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(removePricingStrategy.type),
        switchMap((action: PayloadAction<{berthPricingStrategyId: string}>) => {
            const authToken = authTokenSelector(state$.value);
            const berth = currentlyEditedBerth(state$.value);
            return deletePricingStrategyAPI(authToken, action.payload.berthPricingStrategyId).pipe(
                switchMap(() => {
                    const actions = successActions([
                        changeIsPricingStrategiesLoading(false),
                        setEditedPricingStrategy(null),
                        fetchBerthDetails(berth?.id),
                        setCurrentlyEditedItemId(null),
                        changeIsModalOpen(false),
                        addAlert({message: 'editMenuItems.alerts.pricingStrategyDeleteSuccess', type: AlertType.SUCCESS}),
                    ]);

                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
        })
    );
};

const updatePricingStrategyEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(updatePricingStrategy.type),
        switchMap((action: PayloadAction<BerthPricingStrategyInput>) => {
            const authToken = authTokenSelector(state$.value);
            const editedPricingStrategy = editedPricingStrategySelector(state$.value);
            return updatePricingStrategyAPI(authToken, action.payload, editedPricingStrategy ? editedPricingStrategy.id : '').pipe(
                switchMap(() => {
                    const actions = successActions([
                        addAlert({message: 'editMenuItems.alerts.pricingStrategyUpdateSuccess', type: AlertType.SUCCESS}),
                        changeIsModalOpen(false),
                        fetchBerthDetails(action.payload.berthId),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
        })
    );
};

const createPricingStrategyEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(createPricingStrategy.type),
        switchMap((action: PayloadAction<BerthPricingStrategyInput>) => {
            const authToken = authTokenSelector(state$.value);
            return createPricingStrategyAPI(authToken, action.payload).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        addAlert({message: 'editMenuItems.alerts.pricingStrategyCreateSuccess', type: AlertType.SUCCESS}),
                        changeIsModalOpen(false),
                        fetchBerthDetails(action.payload.berthId),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
        })
    );
};

const createPricingStrategyDefinitionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(createPricingStrategyDefinition.type),
        switchMap((action: PayloadAction<PricingStrategyDefinitionInput>) => {
            const authToken = authTokenSelector(state$.value);
            return createPricingStrategyDefinitionAPI(authToken, action.payload).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        addAlert({message: 'editMenuItems.alerts.pricingStrategyDefinitionCreateSuccess', type: AlertType.SUCCESS}),
                        changeIsModalOpen(false),
                        fetchPricingDefinitions(),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
        })
    );
};

const fetchPricingStrategiesEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchPricingStrategies.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);
            return getPricingStrategiesAPI(authToken).pipe(
                switchMap((resp): any => {
                    const actions = successActions([setPricingStrategies(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
        })
    );
};

const fetchPricingDefinitionsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchPricingDefinitions.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);
            return getPricingStrategyDefinitionsAPI(authToken).pipe(
                switchMap((resp): any => {
                    const actions = successActions([setPricingDefinitions(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changePricingStrategiesError(error)));
        })
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsPricingStrategiesLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

export const fetchListErrorActions = (error: any, setSliceError: any): any[] => {
    return [addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsPricingStrategiesLoading(false)];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const pricingStrategiesEpic = combineEpics(
    fetchPricingStrategiesEpic,
    fetchPricingDefinitionsEpic,
    createPricingStrategyEpic,
    createPricingStrategyDefinitionEpic,
    fetchPricingStrategyDetailsEpic,
    changePricingStrategyActiveStatusEpic,
    deletePricingStrategyEpic,
    updatePricingStrategyEpic
);

export default pricingStrategiesEpic;
