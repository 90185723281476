import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function cancelAsyncTaskAPI(authToken: string | null, taskId: string, params?: any): Observable<any> {
    const queryParams = params ? params : new RestQueryParams(),
        headers = {
            Authorization: `Bearer ${authToken}`,
        };

    return marineHubAPI.get(`api/async_tasks/${taskId}/cancel`, queryParams, headers);
}
