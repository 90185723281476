import {RestQueryParams, marineHubAPI, IRawRestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function getBerthTagsAPI(authToken: string | null, params?: IRawRestQueryParams): Observable<any> {
    const queryParams: RestQueryParams = params ? new RestQueryParams(params) : new RestQueryParams(),
        headers = {
            Authorization: `Bearer ${authToken}`,
        };

    return marineHubAPI.get('api/berth_public_tags', queryParams, headers);
}
