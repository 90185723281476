import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Berth, centimeterNumberOrNull, IMultiselectOption, isSameValue} from 'marine-panel-common-web';
import {IChangeMarina} from '../../api/berth/assignToMarina';
import {IChangeBerthDescription} from '../../api/berth/changeBerthDescription';
import {IChangeBerthName} from '../../api/berth/changeBerthName';
import {IChangeBerthNote} from '../../api/berth/changeBerthNote';
import {IChangeMaxDraft} from '../../api/berth/changeMaxDraft';
import {IChangeMaxHeight} from '../../api/berth/changeMaxHeight';
import {IChangeMaxLength} from '../../api/berth/changeMaxLength';
import {IChangeMaxWidth} from '../../api/berth/changeMaxWidth';
import {IPolicyAndRulePayloadBerth} from '../../api/berth/changePoliciesAndRules';
export interface IBerthEditWizardState {
    berth: Berth | null;
    marinaList: IMultiselectOption[] | [];
    sectorList: any[] | [];
    currentlyEditedControlId: string | null;
    isBerthEditWizardLoading: boolean;
    isBerthEditWizardInitialized: boolean;
    berthEditWizardError: string | null;
    isActionSuccessful: boolean;
    isPhotosSectionLoading: boolean;
    isNameSectionLoading: boolean;
}

export interface ISetBerthDetails {
    berth: Berth;
}

export interface IChangeIsBerthEditWizardLoading {
    isBerthEditWizardLoading: boolean;
}

export interface IChangeIsBerthEditWizardInitialized {
    isBerthEditWizardInitialized: boolean;
}

export interface IChangeIsPhotosSectionLoading {
    isPhotosSectionLoading: boolean;
}

export interface IChangeCurrentlyEditedControlId {
    currentlyEditedControlId: string | null;
}

export interface ISetGalleryOrderPayload {
    berthId: string | null | undefined;
    order: {
        photos: [
            {
                id: string;
                position: number;
            }
        ];
    };
}

export interface IChangeBerthLocationPayload {
    berthId: string | null | undefined;
    location: any;
}
export interface IFetchId {
    id: string | null;
    berthId: string | null;
}
export interface IBerthsSizePayload {
    berthId: string | null | undefined;
    maxWidth: IChangeMaxWidth | null;
    maxHeight: IChangeMaxHeight | null;
    maxDraft: IChangeMaxDraft | null;
    maxLength: IChangeMaxLength | null;
}
export interface IBerthsNamePayload {
    berthId: string | null | undefined;
    name: IChangeBerthName | null;
}
export interface IBerthsNotePayload {
    berthId: string | null | undefined;
    note: IChangeBerthNote | null;
}
export interface IBerthDescriptionPayload {
    berthId: string | null | undefined;
    description: IChangeBerthDescription | null;
}

export interface ISetMarinaList {
    marinaList: IMultiselectOption[] | [];
}
export interface ISetSectorList {
    sectorList: any[] | [];
}
export interface IMarinaList {
    marinas: IMultiselectOption[] | [];
}

export interface IChangeBerthActive {
    berthId: string | null;
    active: string | null;
}

export interface IChangeBookingEnabled {
    berthId: string | null;
    appBookingEnabled: string | null;
}
export interface IChangeBerthsMarine {
    berthId: string | null;
    marinaId: IChangeMarina | null;
}

export interface IFetchId {
    id: string | null;
    berthId: string | null;
}

export interface ISetIsActionSuccessful {
    isActionSuccessful: boolean;
}
export interface IChangeBerthEditWizardError {
    berthEditWizardError: string | null;
}

export interface IChangeBerthPolicyAndRule {
    readonly berthId: string | null;
    readonly berthPolicyAndRuleInput: IPolicyAndRulePayloadBerth;
}

const initialState: IBerthEditWizardState = {
    berth: null,
    marinaList: [],
    sectorList: [],
    currentlyEditedControlId: null,
    isBerthEditWizardInitialized: false,
    isBerthEditWizardLoading: false,
    berthEditWizardError: null,
    isActionSuccessful: false,
    isPhotosSectionLoading: false,
    isNameSectionLoading: false,
};

const berthEditWizardSlice = createSlice({
    name: 'berthEditWizard',
    initialState: initialState,
    reducers: {
        fetchBerthDetails: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (id: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },

        changeIsBookingEnabled: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (berthId: string | null | undefined, appBookingEnabled: boolean | undefined) => {
                return {
                    payload: {
                        berthId: berthId,
                        appBookingEnabled: {appBookingEnabled},
                    },
                };
            },
        },
        changeIsBerthActive: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (berthId: string | null | undefined, active: boolean | undefined) => {
                return {
                    payload: {
                        berthId: berthId,
                        active: {active},
                    },
                };
            },
        },
        changeMarinaList: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (berthId: string | null | undefined, marinaId: string | null | undefined) => {
                return {
                    payload: {
                        berthId: berthId,
                        marinaId: {marinaId},
                    },
                };
            },
        },
        fetchMarinaList: (state: IBerthEditWizardState) => {
            return {
                ...state,
            };
        },
        setMarinaList: {
            reducer: (state: IBerthEditWizardState, action: PayloadAction<ISetMarinaList>) => {
                if (isSameValue(action.payload.marinaList, state.marinaList)) {
                    return state;
                }
                return {
                    ...state,
                    marinaList: action.payload.marinaList,
                };
            },
            prepare: (marinaList: IMultiselectOption[]) => {
                return {
                    payload: {
                        marinaList: marinaList,
                    },
                };
            },
        },

        setSectorList: {
            reducer: (state: IBerthEditWizardState, action: PayloadAction<ISetSectorList>) => {
                if (isSameValue(action.payload.sectorList, state.sectorList)) {
                    return state;
                }
                return {
                    ...state,
                    sectorList: action.payload.sectorList,
                };
            },
            prepare: (sectorList: any[]) => {
                return {
                    payload: {
                        sectorList: sectorList,
                    },
                };
            },
        },
        changeBerthSize: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (
                berthId: string | null | undefined,
                maxWidth: number | null | undefined,
                maxLength: number | null | undefined,
                maxDraft: number | null | undefined,
                maxHeight: number | null | undefined
            ) => {
                return {
                    payload: {
                        berthId: berthId,
                        maxWidth: {maxWidth: centimeterNumberOrNull(maxWidth)},
                        maxLength: {maxLength: centimeterNumberOrNull(maxLength)},
                        maxDraft: {maxDraft: centimeterNumberOrNull(maxDraft)},
                        maxHeight: {maxHeight: centimeterNumberOrNull(maxHeight)},
                    },
                };
            },
        },
        changeBerthDescription: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (berthId: string | null | undefined, description: string | null | undefined) => {
                return {
                    payload: {
                        berthId: berthId,
                        description: {description},
                    },
                };
            },
        },
        archiveBerth: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (berthId: string | null | undefined) => {
                return {
                    payload: {
                        berthId: berthId,
                    },
                };
            },
        },
        changeBerthLocation: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (payload: IChangeBerthLocationPayload) => {
                return {
                    payload,
                };
            },
        },
        changeBerthName: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (berthId: string | null | undefined, name: string | null | undefined) => {
                return {
                    payload: {
                        berthId: berthId,
                        name: {name},
                    },
                };
            },
        },
        changeBerthNote: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (berthId: string | null | undefined, note: string | null | undefined) => {
                return {
                    payload: {
                        berthId: berthId,
                        note: {note},
                    },
                };
            },
        },
        changeGalleryOrder: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isPhotosSectionLoading: true,
                };
            },
            prepare: (orderPayload: ISetGalleryOrderPayload | null) => {
                return {
                    payload: {
                        berthId: orderPayload?.berthId,
                        order: {
                            photos: [
                                {
                                    id: orderPayload?.order?.photos[0].id,
                                    position: orderPayload?.order?.photos[0]?.position,
                                },
                            ],
                        },
                    },
                };
            },
        },
        addPhoto: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isPhotosSectionLoading: true,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        setIsActionSuccessful: {
            reducer: (state: IBerthEditWizardState, action: PayloadAction<ISetIsActionSuccessful>) => {
                return {
                    ...state,
                    isActionSuccessful: action.payload.isActionSuccessful,
                };
            },
            prepare: (isActionSuccessful: boolean) => {
                return {
                    payload: {
                        isActionSuccessful: isActionSuccessful,
                    },
                };
            },
        },
        changeCurrentlyEditedControlId: {
            reducer: (state: IBerthEditWizardState, action: PayloadAction<IChangeCurrentlyEditedControlId>) => {
                return {
                    ...state,
                    currentlyEditedControlId: action.payload.currentlyEditedControlId,
                };
            },
            prepare: (currentlyEditedControlId: string | null) => {
                return {
                    payload: {
                        currentlyEditedControlId: currentlyEditedControlId,
                    },
                };
            },
        },
        deletePhoto: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isPhotosSectionLoading: true,
                };
            },
            prepare: (id: string | null, berthId: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                        berthId: berthId,
                    },
                };
            },
        },
        changeIsPhotosSectionLoading: {
            reducer: (state: IBerthEditWizardState, action: PayloadAction<IChangeIsPhotosSectionLoading>) => {
                return {
                    ...state,
                    isPhotosSectionLoading: action.payload.isPhotosSectionLoading,
                };
            },
            prepare: (isPhotosSectionLoading: boolean) => {
                return {
                    payload: {
                        isPhotosSectionLoading: isPhotosSectionLoading,
                    },
                };
            },
        },
        setPhotoAsCover: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isPhotosSectionLoading: true,
                };
            },
            prepare: (id: string | null, berthId: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                        berthId: berthId,
                    },
                };
            },
        },
        fetchBerthEditWizard: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (id: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        changeBerthEditWizardError: {
            reducer: (state: IBerthEditWizardState, action: PayloadAction<IChangeBerthEditWizardError>) => {
                return {
                    ...state,
                    berthEditWizardError: action.payload.berthEditWizardError,
                };
            },
            prepare: (berthEditWizardError: string | null) => {
                return {
                    payload: {
                        berthEditWizardError: berthEditWizardError,
                    },
                };
            },
        },
        setBerthDetails: {
            reducer: (state: IBerthEditWizardState, action: PayloadAction<ISetBerthDetails>) => {
                return {
                    ...state,
                    berth: action.payload.berth,
                    isBerthEditWizardInitialized: true,
                };
            },
            prepare: (berth: any) => {
                return {
                    payload: {
                        berth: berth,
                    },
                };
            },
        },
        changeIsBerthEditWizardLoading: {
            reducer: (state: IBerthEditWizardState, action: PayloadAction<IChangeIsBerthEditWizardLoading>) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: action.payload.isBerthEditWizardLoading,
                };
            },
            prepare: (isBerthEditWizardLoading: boolean) => {
                return {
                    payload: {
                        isBerthEditWizardLoading: isBerthEditWizardLoading,
                    },
                };
            },
        },
        changeIsBerthEditWizardInitialized: {
            reducer: (state: IBerthEditWizardState, action: PayloadAction<IChangeIsBerthEditWizardInitialized>) => {
                return {
                    ...state,
                    isBerthEditWizardInitialized: action.payload.isBerthEditWizardInitialized,
                };
            },
            prepare: (isBerthEditWizardInitialized: boolean) => {
                return {
                    payload: {
                        isBerthEditWizardInitialized: isBerthEditWizardInitialized,
                    },
                };
            },
        },
        changeBerthPolicyAndRule: {
            reducer: (state: IBerthEditWizardState) => {
                return {
                    ...state,
                    isBerthEditWizardLoading: true,
                };
            },
            prepare: (berthId: string | null, berthPolicyAndRuleInput: IPolicyAndRulePayloadBerth) => {
                return {
                    payload: {
                        berthId: berthId,
                        berthPolicyAndRuleInput: berthPolicyAndRuleInput,
                    },
                };
            },
        },
        resetToInitialBerthEditWizardState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    fetchBerthDetails,
    addPhoto,
    changeBerthName,
    changeIsBookingEnabled,
    changeBerthDescription,
    changeBerthSize,
    fetchMarinaList,
    changeMarinaList,
    changeIsBerthActive,
    setMarinaList,
    changeIsBerthEditWizardInitialized,
    changeIsBerthEditWizardLoading,
    setBerthDetails,
    resetToInitialBerthEditWizardState,
    changeBerthEditWizardError,
    setPhotoAsCover,
    changeIsPhotosSectionLoading,
    deletePhoto,
    setIsActionSuccessful,
    changeGalleryOrder,
    changeCurrentlyEditedControlId,
    changeBerthLocation,
    changeBerthNote,
    archiveBerth,
    setSectorList,
    changeBerthPolicyAndRule,
} = berthEditWizardSlice.actions;

export default berthEditWizardSlice.reducer;
