import {Price} from 'marine-panel-common-web';
import {Reservation, Translation} from 'marine-panel-common-web';
import ReservationDateRange from './ReservationDateRange';

interface ReservationMainInfoProps {
    readonly reservation: Reservation;
}
function ReservationMainInfo(props: ReservationMainInfoProps) {
    return (
        <article className="reservation-details-wrapper">
            <h3 className="details-item-title">
                <Translation text={'mainMap.reservation.view.reservationMain'} />
            </h3>
            <dl className="information-details">
                <div className="information-details-row">
                    <dt className="information-details-label">
                        <Translation text={'mainMap.reservation.view.reservationId'} />
                    </dt>

                    <dd className="information-details-value">{props.reservation.id}</dd>
                </div>

                <ReservationDateRange dates={props.reservation.dates} />
                <div className="information-details-row">
                    <dt className="information-details-label">
                        <span>
                            <Translation text={'mainMap.reservation.view.reservationPrice'} />
                        </span>
                    </dt>
                    <dd className="information-details-value">
                        <Price price={props.reservation.price} />
                    </dd>
                </div>
            </dl>
        </article>
    );
}

export default ReservationMainInfo;
