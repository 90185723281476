import MarinaDetailsComponent from '../WizardContent/Marina/Details';
import DetailsBerthComponent from '../WizardContent/Berth/Details';
import GalleryBerthComponent from '../WizardContent/Berth/Gallery';
import GalleryMarinaComponent from '../WizardContent/Marina/Gallery';
import MarinaPoliciesAndRules from '../WizardContent/Marina/PoliciesAndRules';
import MarinaTags from '../WizardContent/Marina/Tags';
import BerthTags from '../WizardContent/Berth/Tags';
import BerthPricingAndAvailability from '../WizardContent/Berth/PricingAndAvailability';
import PoliciesAndRules from '../WizardContent/Berth/PoliciesAndRules';
import Note from '../WizardContent/Common/Note';
import MarinaExtras from '../WizardContent/Marina/Extras';

export type IWizardEditSideMenuItem = {
    id: string;
    title: string;
    navLink: string;
    sublinks: string[];
    component: any;
    icon?: any;
    onClick?: () => void;
    badge?: string;
    badgeText?: string;
    navLinkClassname?: string;
};

export const berthMenu = (): IWizardEditSideMenuItem[] => [
    {
        id: 'berth_details',
        title: 'editMenuItems.berthDetails',
        navLink: 'details',
        sublinks: ['berth_basics', 'berth_sizes', 'berth_status', 'berth_location'],
        component: <DetailsBerthComponent />,
    },
    {
        id: 'photo_gallery',
        title: 'editMenuItems.photoGallery',
        navLink: 'photo-gallery',
        sublinks: ['cover_photo', 'all_photos'],
        component: <GalleryBerthComponent />,
    },
    {
        id: 'berth_tags',
        title: 'editMenuItems.berthTags',
        navLink: 'berth-tags',
        sublinks: [],
        component: <BerthTags />,
    },
    {
        id: 'pricing-availability',
        title: 'editMenuItems.berthPricingAvailability',
        navLink: 'berth-pricing-availability',
        sublinks: [],
        component: <BerthPricingAndAvailability />,
    },
    {
        id: 'berth_policies',
        title: 'editMenuItems.berthPolicies',
        navLink: 'berth-policies',
        sublinks: [],
        component: <PoliciesAndRules />,
    },
    {
        id: 'berth_notes',
        title: 'editMenuItems.notes',
        navLink: 'berth-notes',
        sublinks: [],
        component: <Note />,
    },
];

export const marinaMenu = (): IWizardEditSideMenuItem[] => [
    {
        id: 'marina_details',
        title: 'editMenuItems.marinaDetails',
        navLink: 'details',
        sublinks: ['marina_basics', 'marina_contact', 'marina_status', 'marina_location'],
        component: <MarinaDetailsComponent />,
    },
    {
        id: 'photo_gallery',
        title: 'editMenuItems.photoGallery',
        navLink: 'photo-gallery',
        sublinks: ['cover_photo', 'all_photos'],
        component: <GalleryMarinaComponent />,
    },
    {
        id: 'marina_tags',
        title: 'editMenuItems.marinaTags',
        navLink: 'tags',
        sublinks: [],
        component: <MarinaTags />,
    },
    {
        id: 'extras',
        title: 'editMenuItems.marinaExtras',
        navLink: 'extras',
        sublinks: [],
        component: <MarinaExtras onCreate={() => null} />,
    },
    {
        id: 'marina_policies',
        title: 'editMenuItems.marinaPolicies',
        navLink: 'policies',
        sublinks: [],
        component: <MarinaPoliciesAndRules />,
    },
    // No notes for marina on backend
    // {
    //     id: 'marina_notes',
    //     title: 'editMenuItems.notes',
    //     navLink: 'notes',
    //     sublinks: [],
    //     component: <div>marina-notes</div>,
    // },
];
export default marinaMenu;
