import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IPolicyAndRulePayloadBerth {
    field: string;
    value: string | null;
}

export function changePoliciesAndRulesAPI(
    authToken: string | null,
    id: string | null,
    payload: IPolicyAndRulePayloadBerth
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/policy_and_rules`, payload, new RestQueryParams(), headers);
}
