import L, {LatLngExpression, PathOptions} from 'leaflet';
import {Berth, GeoPoint} from 'marine-panel-common-web';
import {Component} from 'react';
import {Marker, Polygon} from 'react-leaflet';

interface IBerthItemProps {
    readonly berth: Berth;
    readonly isSelected: boolean;
    readonly selectItem: (berth: Berth) => void;
}

class BerthItem extends Component<IBerthItemProps> {
    render() {
        const berth = this.props.berth;
        const coordinates = this.mapVertexesToCoordinates(berth.location.vertexes);
        return (
            <>
                {/* Marker below is for clustering only - it meant to be hidden */}
                <Marker
                    zIndexOffset={0}
                    opacity={0}
                    position={[berth.location.vertexes[0].latitude, berth.location.vertexes[0].longitude]}></Marker>
                <Polygon
                    positions={coordinates}
                    className={`berth_${berth.id}`}
                    pathOptions={this.getBerthOptions(berth)}
                    eventHandlers={{
                        click: (evt: any) => {
                            evt.originalEvent.stopPropagation();
                            L.DomEvent.stop(evt);
                            this.props.selectItem(berth);
                        },
                    }}></Polygon>
            </>
        );
    }
    private getBerthOptions(berth: Berth) {
        const options: PathOptions = {
            color: '#7367f0',
            fillColor: '#7367f0',
            fillOpacity: 0.2,
            weight: 2,
        };
        if (berth.active) {
            options.color = '#FF9F43';
            options.fillColor = '#FF9F43';
            options.fillOpacity = 0.3;
            options.weight = 2;
        }
        if (this.props.isSelected) {
            options.fillOpacity = 1;
            options.weight = 4;
        }
        return options;
    }
    private mapVertexesToCoordinates(vertexes: GeoPoint[]): LatLngExpression[] {
        if (!vertexes || vertexes.length === 0) {
            return [];
        }
        return vertexes.map((vertex: GeoPoint) => [vertex.latitude, vertex.longitude]);
    }
}

export default BerthItem;
