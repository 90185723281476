import {createSelector} from '@reduxjs/toolkit';
import {createTracing} from 'trace_events';
import {RootState} from '../reducers';
import {IBerthTagsState} from '../reducers/berthsTagsSlice';

export const selectBerthTagsSlice = (state: RootState) => {
    return state.berthTags;
};
export const isActionSuccessfulSelector = createSelector([selectBerthTagsSlice], (state: IBerthTagsState) => state.isActionSuccessful);
export const berthTagsSelector = createSelector([selectBerthTagsSlice], (state: IBerthTagsState) => state.tags);
export const isBerthTagsLoadingSelector = createSelector([selectBerthTagsSlice], (state: IBerthTagsState) => state.isBerthTagsLoading);
export const isBerthTagsInitializedSelector = createSelector(
    [selectBerthTagsSlice],
    (state: IBerthTagsState) => state.isBerthTagsInitialized
);
export const berthTagsErrorSelector = createSelector([selectBerthTagsSlice], (state: IBerthTagsState) => state.berthTagsError);
