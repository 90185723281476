import {deepCloneObject, Money, Price, PricingStrategyCalendarRule} from 'marine-panel-common-web';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {transformValueToPayloadPrice} from '../../../../../../../../service/helperFunctions';
import SetPricingStrategyInput from './SetPricingStrategyInput';

interface Props {
    readonly startDate?: Date;
    readonly endDate?: Date;
    readonly changeCalendarRules: (calendarRules: PricingStrategyCalendarRule[]) => void;
    readonly values: any;
}

const PricingStrategyDatepicker: React.FC<Props> = ({values, changeCalendarRules}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [calendarRules, setCalendarRules] = useState<PricingStrategyCalendarRule[]>();
    const [calendarRulesForMonth, setCalendarRulesForMonth] = useState<DatePrice[]>([]);
    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    type DatePrice = {
        date: Date;
        price: Money | null;
        active: boolean;
    };

    const renderDayContents = (day: number, date: Date | undefined, values: any) => {
        const tooltipText = `Tooltip for date: ${date}`;
        const customPriceStrategyForDay = calendarRulesForMonth?.filter((rule) => moment(rule.date).isSame(date, 'day'));
        let dayPrice = values.basePriceGross,
            active = true;

        if (customPriceStrategyForDay && customPriceStrategyForDay.length > 0) {
            const newestPriceStrategy = customPriceStrategyForDay[customPriceStrategyForDay.length - 1];
            dayPrice = newestPriceStrategy.price ? newestPriceStrategy.price : values.basePriceGross;
            active = newestPriceStrategy.active;
        }
        return (
            <div className="day-wrapper">
                <span className="day-item" title={tooltipText}>
                    {day}
                </span>
                <div className={`price-item ${active ? '' : 'disactive'}`}>
                    <Price
                        price={
                            customPriceStrategyForDay && customPriceStrategyForDay.length
                                ? dayPrice
                                : transformValueToPayloadPrice(dayPrice)
                        }
                    />
                </div>
            </div>
        );
    };
    const onPayloadSend = (payload: PricingStrategyCalendarRule) => {
        if (!calendarRules) return null;
        const calendarRulesWithAddedPayload = deepCloneObject(calendarRules);
        const calendarRulePayload = deepCloneObject(payload);
        calendarRulePayload.price = payload.price ? transformValueToPayloadPrice(payload.price.amount) : null;
        calendarRulesWithAddedPayload.push(calendarRulePayload);
        changeCalendarRules(calendarRulesWithAddedPayload);
        setStartDate(null);
        setEndDate(null);
    };

    function transformArray(arr: PricingStrategyCalendarRule[]): DatePrice[] {
        const result: DatePrice[] = [];

        for (const obj of arr) {
            const startDate = new Date(obj.startsAt);
            const endDate = new Date(obj.endsAt);
            const price = obj.price;

            for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
                result.push({
                    date: new Date(date),
                    price: price,
                    active: obj.active,
                });
            }
        }

        return result;
    }

    useEffect(() => {
        if (values.calendarRules !== null) {
            setCalendarRules(values.calendarRules);
        }
    }, [values.calendarRules]);

    useEffect(() => {
        if (calendarRules) {
            const transformedArray = transformArray(calendarRules);
            setCalendarRulesForMonth(transformedArray);
        }
    }, [calendarRules]);

    return (
        <>
            <DatePicker
                calendarClassName="pricing-strategy-calendar"
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                renderDayContents={(dayOfMonth: number, date?: Date | undefined) => renderDayContents(dayOfMonth, date, values)}
                selectsRange
                inline
            />
            {startDate && endDate && (
                <SetPricingStrategyInput
                    selectedStartDate={startDate}
                    selectedEndDate={endDate}
                    onPayloadSend={onPayloadSend}
                    values={values}
                />
            )}
        </>
    );
};

export default PricingStrategyDatepicker;
