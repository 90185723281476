import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {ISetPhotoPaylodad} from './setPhotoAsCover';

export function addBerthPhotoToGalleryAPI(
    id: string | null | undefined,
    payload: ISetPhotoPaylodad | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/add_photo`, payload, new RestQueryParams(), headers);
}
