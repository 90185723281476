import React, {useEffect, useState} from 'react';
import {Form, FormControlChangeType, IFormConfig, Loader, LoaderType} from 'marine-panel-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {connect} from 'react-redux';
import {transactionHistoryFiltersFormConfig} from './transactionHistoryFiltersFormConfig';
import {
    applyTransactionHistoryFilters,
    changeTransactionHistoryFilters,
    ITransactionHistoryFilters,
} from '../../../../store/reducers/transactionHistory';
import moment from 'moment';
import {RootState} from '../../../../store/reducers';
import {transactionHistoryFiltersSelector} from '../../../../store/selectors/transactionHistorySelectors';

interface IConnectedTransactionHistoryFiltersProps {
    readonly transactionHistoryFilters: ITransactionHistoryFilters | null;
    readonly changeTransactionHistoryFilters: typeof changeTransactionHistoryFilters;
    readonly applyTransactionHistoryFilters: typeof applyTransactionHistoryFilters;
}

interface ITransactionHistoryFiltersProps extends IConnectedTransactionHistoryFiltersProps {
    isLoading: boolean;
}

const TransactionHistoryFilters: React.FC<ITransactionHistoryFiltersProps> = ({
    isLoading,
    transactionHistoryFilters,
    changeTransactionHistoryFilters,
    applyTransactionHistoryFilters,
}) => {
    const [value, setValue] = useState<{[key: string]: any} | null>(null),
        [formConfig, setFormConfig] = useState<IFormConfig | null>(null),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        setFormConfig(transactionHistoryFiltersFormConfig());
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        onFormValueChange(data.value);
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    const onFormValueChange = (value: any) => {
        setValue(value);

        const defaultFilters = transactionHistoryFilters,
            startDate = defaultFilters ? defaultFilters.start_date : '',
            endDate = defaultFilters ? defaultFilters.end_date : '',
            formDate = value && value.date ? value.date : null,
            filters: ITransactionHistoryFilters = {
                start_date: formDate ? moment(value.date[0]).format('YYYY-MM-DD') : startDate,
                end_date: formDate ? moment(value.date[1]).format('YYYY-MM-DD') : endDate,
                status: value?.status,
            };

        changeTransactionHistoryFilters(filters);
        applyTransactionHistoryFilters();
    };

    return (
        <div>
            {formConfig && (
                <Form config={formConfig} onValueStateChange={onValueStateChange} value={value} controlName={'transactionFiltersForm'} />
            )}

            <Loader type={LoaderType.Local} showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        transactionHistoryFilters: transactionHistoryFiltersSelector(state),
    }),
    {
        changeTransactionHistoryFilters,
        applyTransactionHistoryFilters,
    }
)(TransactionHistoryFilters);
