import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {ICreateBerth} from '../../store/reducers/berthsSlice';

export function createBerthAPI(authToken: string | null, actionPayload?: ICreateBerth): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return marineHubAPI.post(`api/berths`, actionPayload, new RestQueryParams(), headers);
}
