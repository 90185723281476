import {PayloadAction} from '@reduxjs/toolkit';
import {addAlert, AlertType, authTokenSelector, deepCloneObject, GeoPoint, isNullOrUndefined} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, concatMap, filter, map, switchMap, tap} from 'rxjs/operators';
import {getMarinaAPI} from '../../api/marina/getMarina';
import {getMarinasAPI} from '../../api/marina/getMarinas';
import {RootState} from '../reducers';
import {changeMapCenter, changeMapZoom} from '../reducers/mapHostSlice';
import {
    changeIsMarinaInitialized,
    changeIsMarinaLoading,
    changeMarina,
    fetchAllMarinas,
    fetchMarinaDetails,
    fetchMarinas,
    IFetchMarinaDetails,
    setActiveMarina,
    setMarinaError,
} from '../reducers/marinasSlice';
import {applyReservationsFilters, changeReservationCountFilters, changeReservationsFilters} from '../reducers/reservationsSlice';
import {reservationCountFiltersSelector, reservationFiltersSelector} from '../selectors/reservationSelectors';

const fetchMarinaDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(fetchMarinaDetails.type),

        concatMap((action: PayloadAction<IFetchMarinaDetails>) => {
            const authToken = authTokenSelector(state$.value);
            return getMarinaAPI(action.payload.marinaId, authToken).pipe(
                switchMap((resp: any) => {
                    const mapCenter: GeoPoint = resp.location;
                    const actions = [setActiveMarina(resp), changeMapCenter(mapCenter), changeMapZoom(10), changeIsMarinaLoading(false)];

                    if (!isNullOrUndefined(action.payload.marinaSectorId)) {
                        const sectorId = action.payload.marinaSectorId;
                        const updatedReservationFilters = deepCloneObject(reservationFiltersSelector(state$.value));
                        const updatedReservationCountFilters = deepCloneObject(reservationCountFiltersSelector(state$.value));
                        if (updatedReservationFilters !== null && updatedReservationCountFilters !== null) {
                            updatedReservationFilters['berth.sector.id'] = sectorId;
                            updatedReservationCountFilters['berth.sector.id'] = sectorId;
                            const actions = [
                                setActiveMarina(resp),
                                changeMapCenter(mapCenter),
                                changeMapZoom(50),
                                changeIsMarinaLoading(false),
                                changeReservationsFilters(updatedReservationFilters),
                                applyReservationsFilters(),
                                changeReservationCountFilters(updatedReservationCountFilters),
                            ];

                            return of(...actions);
                        }
                    }
                    return of(...actions);
                }),
                catchError((error) => of(...getErrorMessage(error)))
            );
        }),
        catchError(() => {
            return of();
        })
    );

const resetActiveMarinaEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(setActiveMarina.type),
        concatMap((action: any) => {
            if (action.payload.activeMarina === null) {
                const mapCenter: GeoPoint = {latitude: 0, longitude: 0};
                const updatedReservationFilters = deepCloneObject(reservationFiltersSelector(state$.value));
                const updatedReservationCountFilters = deepCloneObject(reservationCountFiltersSelector(state$.value));
                let actions: any = [];
                if (updatedReservationFilters !== null && updatedReservationCountFilters !== null) {
                    delete updatedReservationFilters['berth.sector.id'];
                    delete updatedReservationCountFilters['berth.sector.id'];
                    actions = [
                        changeMapCenter(mapCenter),
                        changeMapZoom(3),
                        changeReservationsFilters(updatedReservationFilters),
                        applyReservationsFilters(),
                        changeReservationCountFilters(updatedReservationCountFilters),
                    ];
                    return of(...actions);
                }
            }
            return of();
        }),
        catchError(() => {
            return of();
        })
    );
const fetchMarinasEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, fetchMarinas, 'marinas', changeMarina, changeIsMarinaLoading, changeIsMarinaInitialized, setMarinaError);

const fetchAllMarinasEpic: Epic = (action$) =>
    action$.pipe(
        ofType(fetchAllMarinas.type),
        concatMap(() => {
            return of(fetchMarinas());
        }),
        catchError(() => {
            return of();
        })
    );

export type FetchAction = {
    token: string | null;
    dictionaryName: string | null;
    changeSliceList: any;
    changeIsSliceLoading: any;
    changeIsSliceInitialized: any;
    setSliceError: any;
};

const fetchSubject = new BehaviorSubject<FetchAction>({
    token: null,
    dictionaryName: null,
    changeSliceList: null,
    changeIsSliceLoading: null,
    changeIsSliceInitialized: null,
    setSliceError: null,
});

const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject
    .asObservable()
    .pipe(
        concatMap((fetch) => {
            if (isNullOrUndefined(fetch.token) || isNullOrUndefined(fetch.dictionaryName)) {
                return of(null);
            }

            return getMarinaList(
                fetch.changeSliceList,
                fetch.changeIsSliceLoading,
                fetch.changeIsSliceInitialized,
                fetch.setSliceError,
                fetch.token
            );
        }),
        tap((action) => resultsSubject.next(action))
    )
    .subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (
    state: RootState,
    dictionaryName: string,
    changeSliceList: null,
    changeIsSliceLoading: null,
    changeIsSliceInitialized: null,
    setSliceError: null
) => {
    const authToken = authTokenSelector(state);

    fetchSubject.next({
        token: authToken,
        dictionaryName: dictionaryName,
        changeSliceList: changeSliceList,
        changeIsSliceLoading: changeIsSliceLoading,
        changeIsSliceInitialized: changeIsSliceInitialized,
        setSliceError: setSliceError,
    });

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap((action) => of(action))
    );
};

const getMarinaList = (
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any,
    setSliceError: any,
    token: string | null
) => {
    return getList(
        getMarinasAPI(token),
        (resp: any) => fetchListSuccessActions(resp, changeSliceList, changeIsSliceLoading, changeIsSliceInitialized),
        (error: any) => fetchListErrorActions(error, setSliceError, changeIsSliceLoading)
    );
};

export const getList = (api: Observable<any>, successActions: (resp: any) => any[], errorActions: (error: any) => any[]) => {
    return api.pipe(
        switchMap((resp: any) => successActions(resp)),
        catchError((error: any) => of(...errorActions(error)))
    );
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    dictionaryName: string,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any,
    setSliceError: any
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap((state: RootState) => {
            return doFetch(state, dictionaryName, changeSliceList, changeIsSliceLoading, changeIsSliceInitialized, setSliceError);
        })
    );
};

export const fetchListSuccessActions = (
    resp: any,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any
): any[] => {
    return [changeSliceList(resp['hydra:member']), changeIsSliceLoading(false), changeIsSliceInitialized(true)];
};

export const fetchListErrorActions = (error: any, setSliceError: any, setSliceIsLoading: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        setSliceError(getErrorMessage(error)),
        setSliceIsLoading(false),
    ];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const marinasEpic = combineEpics(fetchMarinaDetailsEpic, fetchMarinasEpic, fetchAllMarinasEpic, resetActiveMarinaEpic);

export default marinasEpic;
