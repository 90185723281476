import {VesselPosition} from 'marine-panel-common-web';
import React, {FC, useEffect, useState} from 'react';
import {MapContainer, Marker, TileLayer} from 'react-leaflet';
import ChangeMapView from './ChangeMapView';
import L from 'leaflet';

interface IVesselPositionMapProps {
    readonly vesselLatestPosition: VesselPosition | null;
}

const VesselPositionMap: FC<IVesselPositionMapProps> = ({vesselLatestPosition}) => {
    const [vesselCoordinates, setVesselCoordinates] = useState<any>(null);

    useEffect(() => {
        if (vesselLatestPosition !== null && vesselLatestPosition.position !== null) {
            setVesselCoordinates([vesselLatestPosition.position.latitude, vesselLatestPosition.position.longitude]);
        }
    }, [vesselLatestPosition]);
    const markerIcon = L.icon({
        iconUrl: require('../../../../../../assets/images/boat-icon.png'),
        iconSize: [32, 32],
        iconAnchor: [32, 32],
    });
    return (
        <MapContainer
            center={vesselCoordinates !== null ? vesselCoordinates : [0, 0]}
            zoom={vesselCoordinates !== null ? 5 : 1}
            boundsOptions={{padding: [1, 1]}}>
            {vesselCoordinates ? <Marker icon={markerIcon} position={vesselCoordinates} /> : null}
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <ChangeMapView
                center={vesselCoordinates ? {lat: vesselCoordinates[0], lng: vesselCoordinates[1]} : {lat: 0, lng: 0}}
                zoom={vesselCoordinates ? 5 : 1}
            />
        </MapContainer>
    );
};

export default VesselPositionMap;
