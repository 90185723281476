import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function getVesselLatestPositionAPI(authToken: string | null, vesselId: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return marineHubAPI.get(`api/vessels/${vesselId}/latest_position`, new RestQueryParams(), headers);
}
