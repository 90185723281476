import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import {Observable} from 'rxjs';
import ChatHeader from './ChatHeader';
import ChatList from './ChatList';
import {ChatChannel, ChatMessage, MessageContentType} from '../../../WithSoccetChatConnection/chat.types';
import {ChatContact} from '@solvee/reactjs-websocket-text-chat';

interface IChatHostProps {
    getMessagesFromUser: (accountId: string, berthId: string, page?: number) => Observable<boolean>;
    peerConnection: ChatChannel | null; // PeerConnection | null;
    selectedChatRoomId: string | null;
    addMessage: (message: ChatMessage | string, room: string, berthId: string) => void;
    error: string | null;
    errorModuleHandle: (errorMessage: string) => void;
    accountId: string;
    userName: string;
    chatContact: ChatContact | undefined;
    confirmMessageRead: (messages: string[]) => void;
    showOnlineIndicator?: boolean;
    authToken: string;
    canSendMessagesToOffline: boolean;
    messagesNumberPerHistoryPage: number;
    avatarUrl?: string;
}

class Chat extends React.Component<IChatHostProps> {
    uuid = require('uuid');

    render() {
        const messages = this.props?.peerConnection?.messages || [];
        return (
            <div className="chat-app-window">
                <div className="active-chat">
                    {this.props.selectedChatRoomId && (
                        <ChatHeader
                            chatContact={this.props.chatContact}
                            peerUserName={this.props.userName}
                            showOnlineIndicator={this.props.showOnlineIndicator}
                        />
                    )}
                    <ChatList
                        avatarUrl={this.props.avatarUrl}
                        messagesNumberPerHistoryPage={this.props.messagesNumberPerHistoryPage}
                        canSendMessagesToOffline={this.props.canSendMessagesToOffline}
                        authToken={this.props.authToken}
                        confirmMessageRead={this.props.confirmMessageRead}
                        getMessagesFromUser={this.props.getMessagesFromUser}
                        chatContact={this.props.chatContact}
                        selectedChatRoomId={this.props.selectedChatRoomId}
                        messages={messages}
                        peerConnection={this.props.peerConnection}
                        addMessage={this.addMessage}
                        accountId={this.props.accountId}
                        error={this.props.error}
                        errorModuleHandle={this.props.errorModuleHandle}
                    />
                </div>
            </div>
        );
    }

    private addMessage = (message: ChatMessage | string) => {
        const berth = this.props.chatContact?.berthId;
        if (!message || !this.props.selectedChatRoomId || !berth) {
            return;
        }

        const textMessage = new ChatMessage({
            from: this.props.accountId,
            to: (this.props.chatContact as any).accountId, // toDo added any type
            messageType: MessageContentType.TEXT,
            messageContent: message as string,
            messageId: this.uuid.v4(),
            date: new Date(),
        });

        this.props.addMessage(textMessage, this.props.selectedChatRoomId, berth);
    };
}

export default Chat;
