import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Berth, BerthPricingStrategyInput, GeoPolygon, IMultiselectOption, isSameValue} from 'marine-panel-common-web';
import {IChangeMarina} from '../../api/berth/assignToMarina';
import {ICreateMarina} from './marinaEditWizardSlice';
export const enum InitWizardStep {
    BASE_INFORMATION = 'base_information',
    PHOTOS = 'photos',
    TAGS = 'tags',
    PRICING_STRATEGY = 'pricing_strategy',
    POLICIES_AND_RULES = 'policies_and_rules',
    WELL_DONE = 'well_done',
    CREATE_FIRST_MARINA = 'create_first_marina',
}

export interface IBerthInitWizardState {
    marinaList: IMultiselectOption[] | [];
    sectorList: any[] | [];
    currentlyEditedControlId: string | null;
    isBerthInitWizardLoading: boolean;
    isBerthInitWizardInitialized: boolean;
    berthInitWizardError: string | null;
    isActionSuccessful: boolean;
    currentWizardStep: InitWizardStep;
}

export interface ISetBerthDetails {
    berth: Berth;
}

export interface IChangeIsBerthInitWizardLoading {
    isBerthInitWizardLoading: boolean;
}

export interface IChangeIsBerthInitWizardInitialized {
    isBerthInitWizardInitialized: boolean;
}

export interface IChangeCurrentStep {
    currentWizardStep: InitWizardStep;
}

export interface IChangeCurrentlyEditedControlId {
    currentlyEditedControlId: string | null;
}

export interface ICreateFirstBerth {
    sectorId: string | null;
    name: string;
    location: GeoPolygon;
}

export interface IFetchId {
    id: string | null;
    berthId: string | null;
}

export interface ISetMarinaList {
    marinaList: IMultiselectOption[] | [];
}
export interface ISetSectorList {
    sectorList: any[] | [];
}
export interface IMarinaList {
    marinas: IMultiselectOption[] | [];
}

export interface IChangeBerthsMarine {
    berthId: string | null;
    marinaId: IChangeMarina | null;
}

export interface IFetchId {
    id: string | null;
    berthId: string | null;
}

export interface ISetIsActionSuccessful {
    isActionSuccessful: boolean;
}
export interface IChangeBerthInitWizardError {
    berthInitWizardError: string | null;
}

const initialState: IBerthInitWizardState = {
    marinaList: [],
    sectorList: [],
    currentlyEditedControlId: null,
    isBerthInitWizardInitialized: false,
    isBerthInitWizardLoading: false,
    berthInitWizardError: null,
    isActionSuccessful: false,
    currentWizardStep: InitWizardStep.BASE_INFORMATION,
};

const berthInitWizardSlice = createSlice({
    name: 'berthInitWizard',
    initialState: initialState,
    reducers: {
        createFirstBerth: {
            reducer: (state: IBerthInitWizardState) => {
                return {
                    ...state,
                    isBerthInitWizardLoading: true,
                };
            },
            prepare(sectorId: string | null, name: string, location: GeoPolygon | null) {
                return {
                    payload: {sectorId: sectorId, name: name, location: location},
                };
            },
        },
        createFirstPricingStrategy: {
            reducer: (state: IBerthInitWizardState) => {
                return {
                    ...state,
                    isPricingStrategiesLoading: true,
                };
            },
            prepare: (payload: BerthPricingStrategyInput) => {
                return {
                    payload: payload,
                };
            },
        },
        createFirstMarina: {
            reducer: (state: IBerthInitWizardState) => {
                return {
                    ...state,
                    isBerthInitWizardLoading: true,
                };
            },
            prepare(payload: ICreateMarina) {
                return {
                    payload: payload,
                };
            },
        },
        changeCurrentStep: {
            reducer: (state: IBerthInitWizardState, action: PayloadAction<IChangeCurrentStep>) => {
                return {
                    ...state,
                    currentWizardStep: action.payload.currentWizardStep,
                };
            },
            prepare: (currentWizardStep: InitWizardStep) => {
                return {
                    payload: {
                        currentWizardStep: currentWizardStep,
                    },
                };
            },
        },

        changeMarinaList: {
            reducer: (state: IBerthInitWizardState) => {
                return {
                    ...state,
                    isBerthInitWizardLoading: true,
                };
            },
            prepare: (berthId: string | null | undefined, marinaId: string | null | undefined) => {
                return {
                    payload: {
                        berthId: berthId,
                        marinaId: {marinaId},
                    },
                };
            },
        },
        fetchMarinaList: (state: IBerthInitWizardState) => {
            return {
                ...state,
            };
        },
        setMarinaList: {
            reducer: (state: IBerthInitWizardState, action: PayloadAction<ISetMarinaList>) => {
                if (isSameValue(action.payload.marinaList, state.marinaList)) {
                    return state;
                }
                return {
                    ...state,
                    marinaList: action.payload.marinaList,
                };
            },
            prepare: (marinaList: IMultiselectOption[]) => {
                return {
                    payload: {
                        marinaList: marinaList,
                    },
                };
            },
        },

        setSectorList: {
            reducer: (state: IBerthInitWizardState, action: PayloadAction<ISetSectorList>) => {
                if (isSameValue(action.payload.sectorList, state.sectorList)) {
                    return state;
                }
                return {
                    ...state,
                    sectorList: action.payload.sectorList,
                };
            },
            prepare: (sectorList: any[]) => {
                return {
                    payload: {
                        sectorList: sectorList,
                    },
                };
            },
        },

        setIsActionSuccessful: {
            reducer: (state: IBerthInitWizardState, action: PayloadAction<ISetIsActionSuccessful>) => {
                return {
                    ...state,
                    isActionSuccessful: action.payload.isActionSuccessful,
                };
            },
            prepare: (isActionSuccessful: boolean) => {
                return {
                    payload: {
                        isActionSuccessful: isActionSuccessful,
                    },
                };
            },
        },

        changeBerthInitWizardError: {
            reducer: (state: IBerthInitWizardState, action: PayloadAction<IChangeBerthInitWizardError>) => {
                return {
                    ...state,
                    berthInitWizardError: action.payload.berthInitWizardError,
                };
            },
            prepare: (berthInitWizardError: string | null) => {
                return {
                    payload: {
                        berthInitWizardError: berthInitWizardError,
                    },
                };
            },
        },

        changeIsBerthInitWizardLoading: {
            reducer: (state: IBerthInitWizardState, action: PayloadAction<IChangeIsBerthInitWizardLoading>) => {
                return {
                    ...state,
                    isBerthInitWizardLoading: action.payload.isBerthInitWizardLoading,
                };
            },
            prepare: (isBerthInitWizardLoading: boolean) => {
                return {
                    payload: {
                        isBerthInitWizardLoading: isBerthInitWizardLoading,
                    },
                };
            },
        },
        changeIsBerthInitWizardInitialized: {
            reducer: (state: IBerthInitWizardState, action: PayloadAction<IChangeIsBerthInitWizardInitialized>) => {
                return {
                    ...state,
                    isBerthInitWizardInitialized: action.payload.isBerthInitWizardInitialized,
                };
            },
            prepare: (isBerthInitWizardInitialized: boolean) => {
                return {
                    payload: {
                        isBerthInitWizardInitialized: isBerthInitWizardInitialized,
                    },
                };
            },
        },

        resetToInitialBerthInitWizardState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    createFirstBerth,
    createFirstPricingStrategy,
    createFirstMarina,
    fetchMarinaList,
    changeMarinaList,
    setMarinaList,
    changeIsBerthInitWizardInitialized,
    changeIsBerthInitWizardLoading,
    resetToInitialBerthInitWizardState,
    changeBerthInitWizardError,
    setIsActionSuccessful,
    setSectorList,
    changeCurrentStep,
} = berthInitWizardSlice.actions;

export default berthInitWizardSlice.reducer;
