import {Translation, Loader, LoaderType, IconSize} from 'marine-panel-common-web';
import React, {useEffect} from 'react';
import {Layout, Settings, Target} from 'react-feather';
import {
    generateOnboardingUrl,
    generateStripeCustomerPortalUrl,
    generateStripeLoginUrl,
    getPaymentInitialData,
} from '../../../store/reducers/paymentSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {isPaymentLoadingSelector, stripeChargesEnabledSelector} from '../../../store/selectors/paymentSelecotrs';
import styles from './styles.module.scss';

interface IExternalPaymentsProps {}

interface IConnectedPaymentProps {
    readonly stripeChargesEnabled: boolean;
    readonly isLoading: boolean;
    readonly getPaymentInitialData: typeof getPaymentInitialData;
    readonly generateOnboardingUrl: typeof generateOnboardingUrl;
    readonly generateStripeLoginUrl: typeof generateStripeLoginUrl;
    readonly generateStripeCustomerPortalUrl: typeof generateStripeCustomerPortalUrl;
}

interface IPaymentsProps extends IExternalPaymentsProps, IConnectedPaymentProps {}

const Payments: React.FC<IPaymentsProps> = ({
    stripeChargesEnabled,
    isLoading,
    getPaymentInitialData,
    generateOnboardingUrl,
    generateStripeLoginUrl,
    generateStripeCustomerPortalUrl,
}) => {
    useEffect(() => {
        getPaymentInitialData();
    }, []);

    const redirectToOnboarding = () => {
        generateOnboardingUrl();
    };

    const generateStripeLoginLink = () => {
        generateStripeLoginUrl();
    };

    const generateCustomerPortalLink = () => {
        generateStripeCustomerPortalUrl();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className={styles.stripeDetails}>
                        <div className={styles.detailsContainer}>
                            <div className={styles.header}>
                                <Layout size={IconSize.PaymentsHeader} />
                                <Translation text="settings.payments.stripe.title" />
                            </div>

                            <div className={styles.description}>
                                <p>
                                    <Translation text="settings.payments.stripe.description" />
                                </p>
                                <button className="btn btn-create stripe-login-button" onClick={() => generateStripeLoginLink()}>
                                    <Translation text="settings.payments.stripe.stripeLogin" />
                                </button>
                            </div>
                        </div>

                        <div className={styles.detailsContainer}>
                            <div className={styles.header}>
                                <Target size={IconSize.PaymentsHeader} />
                                <Translation text="settings.payments.onboarding.title" />
                            </div>
                            <div className={styles.description}>
                                {stripeChargesEnabled ? (
                                    <p className={styles.onboardingCompleted}>
                                        <Translation text="settings.payments.onboarding.onboardingCompleted" />
                                    </p>
                                ) : (
                                    <>
                                        <p className={styles.warning}>
                                            <Translation text="settings.payments.onboarding.onboardingNotCompleted" />
                                        </p>
                                        <p>
                                            <Translation text="settings.payments.onboarding.description" />
                                        </p>
                                        <p>
                                            <Translation text="settings.payments.onboarding.instruction" />
                                        </p>
                                        <button className="btn btn-create onboarding-button" onClick={() => redirectToOnboarding()}>
                                            <Translation text="settings.payments.onboarding.completeOnboarding" />
                                        </button>
                                    </>
                                )}
                            </div>

                            <Loader type={LoaderType.Local} showLoader={isLoading} />
                        </div>
                        <div className={styles.detailsContainer}>
                            <div className={styles.header}>
                                <Settings size={IconSize.PaymentsHeader} />
                                <Translation text="settings.payments.subscription.title" />
                            </div>

                            <div className={styles.description}>
                                <p>
                                    <Translation text="settings.payments.subscription.description" />
                                </p>
                                <button className="btn btn-create stripe-login-button" onClick={() => generateCustomerPortalLink()}>
                                    <Translation text="settings.payments.subscription.customerPortalLogin" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*ToDo add card */}
            {/*<div className="row">*/}
            {/*    <div className="col-12">*/}
            {/*        <div className={styles.stripeDetails}>*/}
            {/*            <div className={styles.detailsContainer}>*/}
            {/*                <div className={styles.header}>*/}
            {/*                    <CreditCard size={IconSize.PaymentsHeader} />*/}
            {/*                    <Translation text="settings.payments.cardDetails.title" />*/}
            {/*                </div>*/}

            {/*                <div className={styles.description}>*/}
            {/*                    <p>*/}
            {/*                        <Translation text="settings.payments.cardDetails.description" />*/}
            {/*                    </p>*/}
            {/*                    <button className="btn btn-create" onClick={() => generateStripeLoginLink()}>*/}
            {/*                        <Translation text="settings.payments.cardDetails.addPaymentMethod" />*/}
            {/*                    </button>*/}
            {/*                </div>*/}

            {/*                <AttachPaymentMethod />*/}

            {/*                <Loader type={LoaderType.Local} showLoader={isLoading} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
};

export default connect(
    (state: RootState) => ({
        stripeChargesEnabled: stripeChargesEnabledSelector(state),
        isLoading: isPaymentLoadingSelector(state),
    }),
    {
        getPaymentInitialData,
        generateOnboardingUrl,
        generateStripeLoginUrl,
        generateStripeCustomerPortalUrl,
    }
)(Payments);
