import {AccessibilityMenu, Tabs, Translation} from 'marine-panel-common-web';
import React, {useState} from 'react';
import {Card, CardBody} from 'reactstrap';
import LayoutWrapper from '../LayoutWrapper';
// import PaymentFormCard from "./PaymentFormCard";
import Payments from './Payments';
import ChangePassword from './ChangePassword';
import BillingData from './BillingData';
import AccountInformation from './AccountInformation';

interface ISettingsProps {}

const Settings: React.FC<ISettingsProps> = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const selectTab = (tabNumber: number) => {
        setSelectedTab(tabNumber);
    };

    return (
        <LayoutWrapper>
            <h2 className="section-title">
                <Translation text="settings.title" />
            </h2>
            <Card className="payment-details-card">
                <CardBody>
                    <Tabs chosenTab={selectedTab} selectedTab={selectTab}>
                        <div className="tab-headers">
                            <span>
                                <Translation text={'settings.information.title'} />
                            </span>
                            <span>
                                <Translation text={'settings.general.title'} />
                            </span>
                            <span>
                                <Translation text={'settings.payments.title'} />
                            </span>
                            <span>
                                <Translation text={'settings.billingData.title'} />
                            </span>
                        </div>
                        <div className="tabs-content">
                            <div>
                                <AccountInformation />
                            </div>
                            <div>
                                <ChangePassword />
                            </div>
                            <div>
                                <Payments />
                            </div>
                            <div>
                                <BillingData />
                            </div>
                        </div>
                    </Tabs>
                </CardBody>
            </Card>
            <AccessibilityMenu />
            {/*<PaymentFormCard />*/}
        </LayoutWrapper>
    );
};

export default Settings;
