import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject, Berth} from 'marine-panel-common-web';
import {StateObservable} from 'redux-observable';
import {ITagPayload} from '../../api/berth/addTag';

export interface IBerthTagsState {
    tags: any;
    isBerthTagsLoading: boolean;
    isBerthTagsInitialized: boolean;
    isActionSuccessful: boolean;
    berthTagsError: string | null;
}

export interface ISetBerthTags {
    tags: any;
}
export interface IChangeIsBerthTagsLoading {
    isBerthTagsLoading: boolean;
}

export interface IChangeIsBerthTagsInitialized {
    isBerthTagsInitialized: boolean;
}

export interface IChangeBerthTagsError {
    berthTagsError: string | null;
}

export interface IAddBerthTagPayload {
    berthId: string | null;
    berthTagId: ITagPayload | null;
}

export interface IChangeIsActionSuccessful {
    isActionSuccessful: boolean;
}

export interface IChangeTagDescription {
    berthId: string | null;
    tagPayload: {
        berthTagId: string | null;
        description: string | null;
    };
}

const initialState: IBerthTagsState = {
    tags: null,
    isBerthTagsInitialized: false,
    isActionSuccessful: false,
    isBerthTagsLoading: false,
    berthTagsError: null,
};

const berthTagsSlice = createSlice({
    name: 'berthTags',
    initialState: initialState,
    reducers: {
        changeTagDescription: {
            reducer: (state: IBerthTagsState) => {
                return {
                    ...state,
                    isBerthTagsLoading: true,
                };
            },
            prepare: (berthId: string | null, berthTagId: string | null, description: string | null) => {
                return {
                    payload: {
                        berthId: berthId,
                        tagPayload: {
                            berthTagId: berthTagId,
                            description: description,
                        },
                    },
                };
            },
        },
        changeIsActionSuccessful: {
            reducer: (state: IBerthTagsState, action: PayloadAction<IChangeIsActionSuccessful>) => {
                return {
                    ...state,
                    isActionSuccessful: action.payload.isActionSuccessful,
                };
            },
            prepare: (isActionSuccessful: boolean) => {
                return {
                    payload: {
                        isActionSuccessful: isActionSuccessful,
                    },
                };
            },
        },
        fetchBerthTags: (state: IBerthTagsState) => {
            return {
                ...state,
                isBerthTagsLoading: true,
            };
        },
        setBerthTags: {
            reducer: (state: IBerthTagsState, action: PayloadAction<ISetBerthTags>) => {
                return {
                    ...state,
                    tags: action.payload.tags,
                };
            },
            prepare: (tags: any) => {
                return {
                    payload: {
                        tags: tags,
                    },
                };
            },
        },
        removeTag: {
            reducer: (state: IBerthTagsState) => {
                return {
                    ...state,
                    isBerthTagsLoading: true,
                };
            },
            prepare: (berthId: string | null, berthTagId: string | null) => {
                return {
                    payload: {
                        berthId: berthId,
                        berthTagId: {berthTagId},
                    },
                };
            },
        },
        addTag: {
            reducer: (state: IBerthTagsState) => {
                return {
                    ...state,
                    isBerthTagsLoading: true,
                };
            },
            prepare: (berthId: string | null, berthTagId: string | null) => {
                return {
                    payload: {
                        berthId: berthId,
                        berthTagId: {berthTagId},
                    },
                };
            },
        },
        fetchBerthDetails: {
            reducer: (state: IBerthTagsState) => {
                return {
                    ...state,
                    isBerthTagsLoading: true,
                };
            },
            prepare: (id: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        changeIsBerthTagsLoading: {
            reducer: (state: IBerthTagsState, action: PayloadAction<IChangeIsBerthTagsLoading>) => {
                return {
                    ...state,
                    isBerthTagsLoading: action.payload.isBerthTagsLoading,
                };
            },
            prepare: (isBerthTagsLoading: boolean) => {
                return {
                    payload: {
                        isBerthTagsLoading: isBerthTagsLoading,
                    },
                };
            },
        },
        changeIsBerthTagsInitialized: {
            reducer: (state: IBerthTagsState, action: PayloadAction<IChangeIsBerthTagsInitialized>) => {
                return {
                    ...state,
                    isBerthTagsInitialized: action.payload.isBerthTagsInitialized,
                };
            },
            prepare: (isBerthTagsInitialized: boolean) => {
                return {
                    payload: {
                        isBerthTagsInitialized: isBerthTagsInitialized,
                    },
                };
            },
        },
        changeBerthTagsError: {
            reducer: (state: IBerthTagsState, action: PayloadAction<IChangeBerthTagsError>) => {
                return {
                    ...state,
                    berthTagsError: action.payload.berthTagsError,
                };
            },
            prepare: (berthTagsError: string | null) => {
                return {
                    payload: {
                        berthTagsError: berthTagsError,
                    },
                };
            },
        },
        returnToInitialBerthTagsState: (state: IBerthTagsState) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    returnToInitialBerthTagsState,
    changeIsActionSuccessful,
    changeBerthTagsError,
    changeIsBerthTagsInitialized,
    changeIsBerthTagsLoading,
    fetchBerthDetails,
    addTag,
    removeTag,
    setBerthTags,
    changeTagDescription,
    fetchBerthTags,
} = berthTagsSlice.actions;

export default berthTagsSlice.reducer;
