import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IChangeMaxDraft {
    maxDraft: string;
}

export function changeMaxDraftAPI(
    id: string | null | undefined,
    payload: IChangeMaxDraft | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/change_max_draft`, payload, new RestQueryParams(), headers);
}
