import {Form, FormControlChangeType, convertToMultiselectLabels, IFormConfig, MarinaBroad} from 'marine-panel-common-web';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {RootState} from '../../../../../store/reducers';
import {fetchMarinaDetails, setActiveMarina} from '../../../../../store/reducers/marinasSlice';
import {activeMarinaSelector, marinasSelector} from '../../../../../store/selectors/marinaSelectors';
import {marinaSelectorFormConfig} from './marinaSelectorFormConfig';

interface IConnectedMarinaSelectorProps {
    readonly marinas: MarinaBroad[] | null;
    readonly activeMarina: MarinaBroad | null;
    readonly fetchMarinaDetails: typeof fetchMarinaDetails;
    readonly setActiveMarina: typeof setActiveMarina;
}

interface IMarinaSelectorProps extends IConnectedMarinaSelectorProps, WithTranslation {}

interface IMarinaSelectorState {
    value: any;
    formConfig: IFormConfig | null;
    isLoading: boolean;
}

class MarinaSelector extends Component<IMarinaSelectorProps, IMarinaSelectorState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IMarinaSelectorProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(250),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: IMarinaSelectorProps) {
        if (this.props.marinas !== prevProps.marinas) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                {this.state.formConfig && (
                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={this.onValueStateChange}
                        value={this.state.value}
                        controlName={'marinaSelectorForm'}
                    />
                )}
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        if (value.selectedMarinaId === 'all') {
            this.props.setActiveMarina(null);
            return;
        }
        const selectedBroadMarina = this.props.marinas?.find((marina) => marina.id === value.selectedMarinaId),
            selectedSectorId = selectedBroadMarina?.sectors[0].id;
        this.props.fetchMarinaDetails(value.selectedMarinaId, selectedSectorId);
    };

    private setFormConfig = () => {
        const {t} = this.props,
            marinas = this.props.marinas ? convertToMultiselectLabels(this.props.marinas, t) : [],
            activeMarina = this.props.activeMarina;
        this.setState({formConfig: marinaSelectorFormConfig(marinas, activeMarina), value: {selectedMarinaId: activeMarina?.id}});
    };
}

export default connect(
    (state: RootState) => ({
        marinas: marinasSelector(state),
        activeMarina: activeMarinaSelector(state),
    }),
    {
        fetchMarinaDetails: fetchMarinaDetails,
        setActiveMarina: setActiveMarina,
    }
)(withTranslation()(MarinaSelector));
