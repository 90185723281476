import React from 'react';
import {Observable} from 'rxjs';
import styles from './styles.module.scss';
import ChatComponent from './ChatComponent';
import {of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import ChatUsersList from './ChatUsersList';
import {NodeErrorTypes} from '../../WithSoccetChatConnection/chat.types';
import {ChatContact, IWithChatConnection} from '@solvee/reactjs-websocket-text-chat';
import {Translation} from 'marine-panel-common-web';

export interface IExternalChatProps {
    authToken: string;
    accountId: string;
    allContactsList: any;
    errorHandler: any;
    avatarUrl: any;
    showOnlineIndicator: any;
    chatContactsList: any;
    messagesNumberPerHistoryPage: number;
}

export interface IConnectedChatProps {}

interface IChatProps extends IExternalChatProps, IConnectedChatProps, IWithChatConnection {}

interface IChatState {
    nodeErrors: typeof initialErrorsState;
    isLoading: boolean;
    error: string | null;
}

const initialErrorsState: {[key in NodeErrorTypes]: boolean} = {
    [NodeErrorTypes.ALREADY_ONLINE]: false,
    [NodeErrorTypes.EXPIRED_TOKEN]: false,
    [NodeErrorTypes.NOT_AUTHORIZED]: false,
    [NodeErrorTypes.NO_TOKEN]: false,
    [NodeErrorTypes.WEBSOCKET_ERROR]: false,
};

class Chat extends React.Component<IChatProps, IChatState> {
    subscription: Subscription;

    constructor(props: IChatProps) {
        super(props);
        this.state = {
            nodeErrors: initialErrorsState,
            isLoading: false,
            error: null,
        };
    }

    componentDidMount() {
        this.subscription = this.props.errors
            .pipe(
                map((error) => {
                    this.handleNodeErrors(error);
                    this.errorHandler(error);
                })
            )
            .subscribe();

        if (!this.props.selectedChatRoomId && this.props.hasUnreadMessages?.length > 0) {
            const selectedChat = this.props.hasUnreadMessages[0];
            this.props.setSelectedChatRoomId(selectedChat);
        }
    }

    componentDidUpdate(prevProps: IChatProps) {
        if (!this.props.selectedChatRoomId && this.props.chatContactsList?.length > 0) {
            const selectedChat = this.props.chatContactsList[0].roomId;
            this.props.setSelectedChatRoomId(selectedChat);
        }
        if (!prevProps.hasNodeConnection && this.props.hasNodeConnection) {
            this.setState({nodeErrors: initialErrorsState});
        }
    }

    componentWillUnmount() {
        this.props.setSelectedChatRoomId('');
        this.subscription.unsubscribe();
    }

    render() {
        return (
            <div className={''}>
                <div className={'chat-application'}>
                    <div className={`content-area-wrapper ${styles.chatWrapper}`}>
                        <ChatUsersList
                            avatarUrl={this.props.avatarUrl}
                            setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                            showOnlineIndicator={this.props.showOnlineIndicator}
                            peerConnections={this.props.peerConnections}
                            hasNodeConnection={this.props.hasNodeConnection}
                            selectedChatRoomId={this.props.selectedChatRoomId}
                            chatContactsList={this.props.chatContactsList || []}
                        />
                        <div className={`content-right ${styles.chatWindowWrapper}`}>
                            <div className="content-wrapper">
                                <div className="content-body">
                                    {this.props.hasNodeConnection ? (
                                        <ChatComponent
                                            avatarUrl={this.props.avatarUrl}
                                            authToken={this.props.authToken}
                                            peerConnection={
                                                this.props.peerConnections
                                                    ? this.props.peerConnections?.[this.props?.selectedChatRoomId]
                                                    : null
                                            }
                                            messagesNumberPerHistoryPage={this.props.messagesNumberPerHistoryPage}
                                            getMessagesFromUser={this.props.getMessagesFromUser}
                                            addMessage={this.props.addMessage}
                                            error={this.state.error}
                                            errorModuleHandle={this.setError}
                                            chatContact={this.chatPartnerAccount}
                                            userName={this.userName}
                                            confirmMessageRead={this.props.confirmMessageRead}
                                            accountId={this.props.accountId}
                                            showOnlineIndicator={
                                                this.props.showOnlineIndicator &&
                                                this.props.peerConnections?.[this.props?.selectedChatRoomId]?.peerIsOnline
                                            }
                                            selectedChatRoomId={this.props.selectedChatRoomId}
                                            canSendMessagesToOffline={this.props.canSendMessagesToOffline}
                                        />
                                    ) : (
                                        <div className="chat-app-window">
                                            <div className="active-chat">
                                                <div className={`col-12 ${styles.noChatInfo}`}>
                                                    <i className={'feather icon-alert-triangle'} />
                                                    <p>
                                                        <Translation text={'chat.noNodeConnection'} />
                                                    </p>
                                                    <p className={`${styles.activeError} mt-3`}>
                                                        <Translation text={'chat.noConnectionReasons'} />
                                                    </p>
                                                    {Object.keys(this.state.nodeErrors).map((error) => (
                                                        <p
                                                            key={error}
                                                            className={
                                                                (this.state.nodeErrors as any)[error]
                                                                    ? styles.activeError
                                                                    : styles.inactiveError
                                                            }>
                                                            <Translation text={`chat.${error}`} />
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleNodeErrors(error: any) {
        const message = error.message;
        if (message === NodeErrorTypes.NO_TOKEN) {
            this.setState((state) => ({
                nodeErrors: {...state.nodeErrors, [NodeErrorTypes.NO_TOKEN]: true},
            }));
        }

        if (message === NodeErrorTypes.EXPIRED_TOKEN) {
            this.setState((state) => ({
                nodeErrors: {...state.nodeErrors, [NodeErrorTypes.EXPIRED_TOKEN]: true},
            }));
        }

        if (message === NodeErrorTypes.ALREADY_ONLINE) {
            this.setState((state) => ({
                nodeErrors: {
                    ...state.nodeErrors,
                    [NodeErrorTypes.ALREADY_ONLINE]: true,
                },
            }));
        }

        if (message === NodeErrorTypes.NOT_AUTHORIZED) {
            this.setState((state) => ({
                nodeErrors: {
                    ...state.nodeErrors,
                    [NodeErrorTypes.NOT_AUTHORIZED]: true,
                },
            }));
        }

        if (message === NodeErrorTypes.WEBSOCKET_ERROR) {
            this.setState((state) => ({
                nodeErrors: {
                    ...state.nodeErrors,
                    [NodeErrorTypes.WEBSOCKET_ERROR]: true,
                },
            }));
        }
    }

    private errorHandler = (er: any): Observable<any> => {
        if (this.props.errorHandler) {
            this.props.errorHandler(er);
        } else {
            this.setError(er.message);
        }
        return of(er);
    };

    private setError = (error: string) => {
        this.setState({error: error});
    };

    private get chatPartnerAccount(): ChatContact | undefined {
        if (this.props.allContactsList && this.props.selectedChatRoomId) {
            return this.props.allContactsList.find((room: ChatContact) => room.roomId === this.props.selectedChatRoomId);
        }
        return;
    }

    private get userName(): string {
        if (this.props.allContactsList && this.props.selectedChatRoomId) {
            const currentRoom = this.props.allContactsList.find((room: any) => room.roomId === this.props.selectedChatRoomId);
            return currentRoom && currentRoom.firstName && currentRoom.lastName
                ? `${currentRoom.firstName} ${currentRoom.lastName}`
                : 'Unknown User';
        }
        return '';
    }
}

export default Chat;
