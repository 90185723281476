import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    IPricingStrategyDefinition,
    MultiSelectType,
} from 'marine-panel-common-web';

export const selectedPricingStrategyTemplateFormConfig = (pricingStrategyDefinitions: IPricingStrategyDefinition[]): IFormConfig => {
    let mutliselectStrategyDefinitions: IMultiselectOption[] = [];
    if (pricingStrategyDefinitions) {
        mutliselectStrategyDefinitions = pricingStrategyDefinitions.map((strategyDefinition: IPricingStrategyDefinition) => {
            const multiselectOption: IMultiselectOption = {value: strategyDefinition.id, label: strategyDefinition.name};

            return multiselectOption;
        });
    }

    return {
        controlType: 'form',
        class: 'default-form create-pricing-strategy',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'selected_pricing_strategy_template',
                controlType: 'group',
                class: 'row',
                controls: {
                    pricingStrategyTemplate: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: mutliselectStrategyDefinitions ? mutliselectStrategyDefinitions : [],
                        // validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        validationRules: [],
                        placeholder: 'modal.createPricingStrategy.inputs.usePricingStrategyTemplatePlaceholder',
                        isLabelHidden: true,
                        label: 'modal.createPricingStrategy.inputs.usePricingStrategyTemplate',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                    },
                },
            },
        ],
    };
};
