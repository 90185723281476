import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject, Berth, GeoPolygon, isSameValue} from 'marine-panel-common-web';

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface IChangePagination {
    pagination: IPaginationParams;
}

export interface IFetchBerthDetails {
    berthId: string;
}

export interface IBaseDictionaryState {
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

export interface ICreateBerth {
    sectorId: string | null;
    name: string;
    location: GeoPolygon;
}

export interface IChangeIsInitialized {
    isInitialized: boolean;
}

export interface ISetError {
    error: string | null;
}

export interface IChangeBerthsFilters {
    filters: IFormListFilters;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}
export interface IBerthsState extends IBaseDictionaryState {
    berths: Berth[] | null;
    activeBerth: Berth | null;
    metadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    filters: IFormListFilters | null;
}

export interface IFormListFilters {
    startDate?: string | null;
    endDate?: string | null;
    name?: string | null;
    published?: boolean | null;
    active?: boolean | null;
}

export type IFormListFiltersType = 'page' | 'itemsPerPage' | 'startDate' | 'endDate' | 'name' | 'published' | 'active';

export interface IChangeFormListFilters {
    readonly payload: {
        formListFilters: IFormListFilters;
    };
}

export interface ISetBerths {
    readonly berths: Berth[] | null;
}

export interface ISetActiveBerth {
    readonly activeBerth: Berth | null;
}

const initialState: IBerthsState = {
    berths: [],
    activeBerth: null,
    isLoading: false,
    isInitialized: false,
    error: null,
    pagination: {
        page: 1,
        itemsPerPage: 10,
    },
    metadata: null,
    filters: null,
};

const berthsSlice = createSlice({
    name: 'berths',
    initialState: initialState,
    reducers: {
        changePagination: {
            reducer: (state: IBerthsState, action: PayloadAction<IChangePagination>) => {
                return {
                    berths: state.berths,
                    activeBerth: state.activeBerth,
                    isLoading: true,
                    isInitialized: state.isInitialized,
                    error: state.error,
                    pagination: action.payload.pagination,
                    metadata: state.metadata,
                    filters: state.filters,
                };
            },
            prepare: (pagination: IPaginationParams) => {
                return {
                    payload: {
                        pagination: pagination,
                    },
                };
            },
        },
        setMetadata: {
            reducer: (state: IBerthsState, action: PayloadAction<ISetMetadata>) => {
                return {
                    berths: state.berths,
                    activeBerth: state.activeBerth,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                    pagination: state.pagination,
                    metadata: action.payload.metadata,
                    filters: state.filters,
                };
            },
            prepare: (metadata: IModelApiResponseViewObject | null) => {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeBerths: {
            reducer: (state: IBerthsState, action: PayloadAction<ISetBerths>) => {
                return {
                    berths: action.payload.berths,
                    activeBerth: state.activeBerth,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                    pagination: state.pagination,
                    metadata: state.metadata,
                    filters: state.filters,
                };
            },
            prepare(berths: Berth[] | null) {
                return {
                    payload: {berths: berths},
                };
            },
        },
        createBerth: {
            reducer: (state: IBerthsState) => {
                return {
                    ...state,
                };
            },
            prepare(sectorId: string | null, name: string, location: GeoPolygon | null) {
                return {
                    payload: {sectorId: sectorId, name: name, location: location},
                };
            },
        },
        changeIsBerthLoading: {
            reducer: (state: IBerthsState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    berths: state.berths,
                    activeBerth: state.activeBerth,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                    pagination: state.pagination,
                    metadata: state.metadata,
                    filters: state.filters,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsBerthInitialized: {
            reducer: (state: IBerthsState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    berths: state.berths,
                    activeBerth: state.activeBerth,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                    pagination: state.pagination,
                    metadata: state.metadata,
                    filters: state.filters,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setBerthError: {
            reducer: (state: IBerthsState, action: PayloadAction<ISetError>) => {
                return {
                    berths: state.berths,
                    activeBerth: state.activeBerth,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                    pagination: state.pagination,
                    metadata: state.metadata,
                    filters: state.filters,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchBerths: (state: IBerthsState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        fetchSpecificMarinaBerths: {
            reducer: (state: IBerthsState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(marinaId: string | null) {
                return {
                    payload: {marinaId: marinaId},
                };
            },
        },
        fetchBerthDetails: {
            reducer: (state: IBerthsState) => {
                return {
                    ...state,
                    isBerthsViewLoading: true,
                };
            },
            prepare(berthId: string | null | undefined) {
                return {
                    payload: {berthId: berthId},
                };
            },
        },
        fetchAllBerths: (state: IBerthsState) => {
            return {
                ...state,
            };
        },

        applyBerthFilters: (state: IBerthsState) => {
            return {
                ...state,
                isBerthsViewLoading: true,
            };
        },
        changeBerthFilters: {
            reducer: (state: IBerthsState, action: PayloadAction<IChangeBerthsFilters>) => {
                if (isSameValue(action.payload.filters, state.filters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isBerthsViewLoading: true,
                    filters: action.payload.filters,
                };
            },
            prepare(filters: IFormListFilters) {
                return {
                    payload: {
                        filters: {
                            startDate: filters.startDate,
                            endDate: filters.endDate,
                            name: filters.name,
                            published: filters.published,
                            active: filters.active,
                        },
                    },
                };
            },
        },
        setActiveBerth: {
            reducer: (state: IBerthsState, action: PayloadAction<ISetActiveBerth>) => {
                return {
                    berths: state.berths,
                    activeBerth: action.payload.activeBerth,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                    pagination: state.pagination,
                    metadata: state.metadata,
                    filters: state.filters,
                };
            },
            prepare(activeBerth: Berth | null) {
                return {
                    payload: {activeBerth: activeBerth},
                };
            },
        },
    },
});

export const {
    changeBerths,
    changePagination,
    setMetadata,
    setActiveBerth,
    fetchAllBerths,
    fetchBerths,
    setBerthError,
    fetchBerthDetails,
    changeIsBerthLoading,
    changeIsBerthInitialized,
    createBerth,
    fetchSpecificMarinaBerths,
    applyBerthFilters,
    changeBerthFilters,
} = berthsSlice.actions;

export default berthsSlice.reducer;
