import {useEffect, useState} from 'react';
import {withRouterWrapper, WithRouterWrapperProps, Berth, Marina} from 'marine-panel-common-web';
import {Card} from 'reactstrap';
import LayoutWrapper from '../../LayoutWrapper';
import marinaMenu, {berthMenu} from './WizardHost/edit-menu-items';
import EditItemBadges from './WizardHost/Badges';
import SideNavigation from './WizardHost/SideNavigation';
import HostRouting from './WizardHost/HostRouting';
import WizardEditRemoveButton from './WizardHost/RemoveButton';
import {useParams} from 'react-router-dom';
import {berthsSelector} from '../../../store/selectors/berthSelectors';
import {currentlyEditedBerth} from '../../../store/selectors/berthEditWizardSelectors';
import {marinasSelector} from '../../../store/selectors/marinaSelectors';
import {RootState} from '../../../store/reducers';
import {connect} from 'react-redux';
import {currentlyEditedMarina} from '../../../store/selectors/marinaEditWizardSelectors';

export const enum WizardEditHostType {
    MARINA = 'marina',
    BERTH = 'berth',
}

export interface IEditItemHostProps extends WithRouterWrapperProps {
    readonly editedItemType: WizardEditHostType;
    readonly berth: Berth | null;
    readonly marina: Marina | null;
    readonly berths: Berth[];
    readonly marinas: Marina[];
}

const WizardEdit = (props: IEditItemHostProps) => {
    const [activeSublink, setActiveSubmenu] = useState('');
    const [editedMarina, setActiveMarina] = useState<Marina | undefined>(undefined);
    const [editedBerth, setActiveBerth] = useState<Berth | undefined>(undefined);
    const itemTitle = editedMarina?.name || editedBerth?.name || '',
        sideMenuConfig = props.editedItemType === WizardEditHostType.MARINA ? marinaMenu : berthMenu,
        location = props.router.location;
    const params = useParams();
    useEffect(() => {
        const locationId = location.hash ? location.hash.slice(1) : '';
        if (activeSublink !== locationId) {
            setActiveSubmenu(locationId);
        }
    }, [activeSublink, location]);

    useEffect(() => {
        if (params && params.id) {
            if (props.editedItemType === WizardEditHostType.MARINA && props.marina) {
                setActiveMarina(props.marina);
            }
            if (props.editedItemType === WizardEditHostType.BERTH && props.berth) {
                setActiveBerth(props.berth);
            }
        }
    }, [params, props.berth, props.editedItemType, props.marina]);

    return (
        <LayoutWrapper>
            <Card className="wizard-edit-host-card">
                <section className="wizard-edit-host-wrapper">
                    <div className="wizard-edit-host-header" data-header>
                        <h3 className="wizard-edit-title">{itemTitle}</h3>
                        <EditItemBadges berth={editedBerth} marina={editedMarina} />
                        {params.id && <WizardEditRemoveButton type={props.editedItemType} id={params.id} />}
                    </div>
                    <div className="wizard-edit-host-content row">
                        <SideNavigation sideMenuConfig={sideMenuConfig()} activeSublink={activeSublink} />
                        <HostRouting
                            sideMenuConfig={sideMenuConfig()}
                            activeSublink={activeSublink}
                            onSubmenuChanged={(submenuId: string) => setActiveSubmenu(submenuId)}
                        />
                    </div>
                </section>
            </Card>
        </LayoutWrapper>
    );
};

export default connect(
    (state: RootState) => ({
        berth: currentlyEditedBerth(state),
        marina: currentlyEditedMarina(state),
        berths: berthsSelector(state),
        marinas: marinasSelector(state),
    }),
    {}
)(withRouterWrapper(WizardEdit));
