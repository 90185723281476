import {RootState} from '../reducers';
import {createSelector} from '@reduxjs/toolkit';
import {IPaymentState} from '../reducers/paymentSlice';

export const selectPaymentSlice = (state: RootState): IPaymentState => state.payment;

export const paymentAccountSelector = createSelector([selectPaymentSlice], (state) => state.paymentAccount);

export const paymentVendorAccountIdSelector = createSelector(
    [selectPaymentSlice],
    (state) => state.paymentAccount?.vendorPaymentAccounts[0]?.id
);

export const stripeChargesEnabledSelector = createSelector([selectPaymentSlice], (state) => state.stripeChargesEnabled);

export const isPaymentLoadingSelector = createSelector([selectPaymentSlice], (state) => state.isLoading);

export const isPaymentInitialisedSelector = createSelector([selectPaymentSlice], (state) => state.isInitialised);

export const paymentErrorSelector = createSelector([selectPaymentSlice], (state) => state.error);
