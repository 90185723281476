import {FormControlType, IFormConfig, InputType, ValidationRules} from 'marine-panel-common-web';

export const changeAccountInformationFormConfig = (): IFormConfig => ({
    controlType: 'form',
    class: 'default-form container',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'company_name',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'settings.information.form.placeholders.firstName',
                    label: 'settings.information.form.labels.firstName',
                    hostClass: 'col-xl-6 form-control',
                    type: InputType.TEXT,
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'settings.information.form.placeholders.lastName',
                    label: 'settings.information.form.labels.lastName',
                    hostClass: 'col-xl-6 form-control',
                    type: InputType.TEXT,
                },
            },
        },
        {
            controlType: 'group',
            key: 'country_phone',
            class: 'row',
            controls: {
                phone: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.MIN_LENGTH, params: {length: 9}}],
                    placeholder: 'settings.billingData.form.placeholders.mobile',
                    isLabelHidden: false,
                    label: 'settings.billingData.form.labels.mobile',
                    type: InputType.TEL,
                    hostClass: 'col-xl-6 form-control',
                },
            },
        },
    ],
});
