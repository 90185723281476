import {Berth, IMultiselectOption, Loader} from 'marine-panel-common-web';
import {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {RootState} from '../../../../../../store/reducers';
import {changeCurrentlyEditedControlId, fetchMarinaList} from '../../../../../../store/reducers/berthEditWizardSlice';
import {resetToInitialBerthEditWizardState, fetchBerthDetails} from '../../../../../../store/reducers/berthEditWizardSlice';
import {
    marinaListSelector,
    currentlyEditedBerth,
    isActionSuccessfulSelector,
    isBerthEditWizardLoadingSelector,
    isBerthEditWizardInitializedSelector,
} from '../../../../../../store/selectors/berthEditWizardSelectors';
import BerthBasics from './BerthBasics';
import BerthLocation from './BerthLocation';
import BerthSizes from './BerthSizes';
import BerthStatus from './BerthStatus';

export interface IDetailsBerthComponentProps {
    readonly berth: Berth | null;
    readonly marinasList: IMultiselectOption[] | [];
    readonly fetchBerthDetails: typeof fetchBerthDetails;
    readonly fetchMarinaList: typeof fetchMarinaList;
    readonly resetToInitialBerthEditWizardState: typeof resetToInitialBerthEditWizardState;
    readonly isLoading: boolean;
}

function DetailsBerthComponent(props: IDetailsBerthComponentProps) {
    const params = useParams(),
        berthId = params.id,
        dispatch = useDispatch();

    useEffect(() => {
        const subscriptions: Subscription[] = [];
        dispatch(fetchBerthDetails(berthId ? berthId : null));
        dispatch(fetchMarinaList());

        return () => {
            dispatch(resetToInitialBerthEditWizardState());
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [berthId, dispatch]);
    const berth = props.berth,
        marinasList = props.marinasList;

    return (
        <div className="details-edit-section-wrapper" data-scroller>
            <BerthBasics berth={berth} marinasList={marinasList} />
            <BerthSizes berth={berth} />
            <BerthStatus berth={berth} />
            <BerthLocation berth={berth} />
            <Loader showLoader={props.isLoading} />
        </div>
    );
}
export default connect(
    (state: RootState) => ({
        berth: currentlyEditedBerth(state),
        isLoading: isBerthEditWizardLoadingSelector(state),
        isActionSuccessful: isActionSuccessfulSelector(state),
        isBerthEditWizardInitialized: isBerthEditWizardInitializedSelector(state),
        marinasList: marinaListSelector(state),
    }),
    {
        fetchBerthDetails,
        resetToInitialBerthEditWizardState,
        fetchMarinaList,
    }
)(DetailsBerthComponent);
