import {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import TransactionsHistory from './TransactionsHistory';

class MarinaReports extends Component {
    render() {
        return (
            <LayoutWrapper>
                <div className="col-12 col-lg-9 col-xl-6">
                    <TransactionsHistory />
                </div>
            </LayoutWrapper>
        );
    }
}

export default MarinaReports;
