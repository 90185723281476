import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IPricingStrategiesState} from '../reducers/pricingStrategiesSlice';

export const selectPricingStrategiesSlice = (state: RootState) => {
    return state.pricingStrategies;
};
export const isActionSuccessfulSelector = createSelector(
    [selectPricingStrategiesSlice],
    (state: IPricingStrategiesState) => state.isActionSuccessful
);
export const pricingStrategiesSelector = createSelector(
    [selectPricingStrategiesSlice],
    (state: IPricingStrategiesState) => state.pricingStrategies
);
export const editedPricingStrategySelector = createSelector(
    [selectPricingStrategiesSlice],
    (state: IPricingStrategiesState) => state.editedPricingStrategy
);
export const pricingDefinitionsSelector = createSelector(
    [selectPricingStrategiesSlice],
    (state: IPricingStrategiesState) => state.pricingDefinitions
);
export const isPricingStrategiesLoadingSelector = createSelector(
    [selectPricingStrategiesSlice],
    (state: IPricingStrategiesState) => state.isPricingStrategiesLoading
);
export const isPricingStrategiesInitializedSelector = createSelector(
    [selectPricingStrategiesSlice],
    (state: IPricingStrategiesState) => state.isPricingStrategiesInitialized
);
export const pricingStrategiesErrorSelector = createSelector(
    [selectPricingStrategiesSlice],
    (state: IPricingStrategiesState) => state.pricingStrategyError
);
