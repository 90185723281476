import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeMarinaActive} from '../../store/reducers/marinaEditWizardSlice';

export interface IChangeIsMarinaActiveInput {
    active: string | null;
}

export function changeIsMarinaActiveAPI(payload: IChangeMarinaActive, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_active`, payload.active, new RestQueryParams(), headers);
}
