import {authTokenSelector, Berth, IconSize, Loader, Translation} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import {Trash2} from 'react-feather';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RootState} from '../../../../../../store/reducers';
import {
    addPhoto,
    changeGalleryOrder,
    fetchBerthDetails,
    ISetGalleryOrderPayload,
    resetToInitialBerthEditWizardState,
} from '../../../../../../store/reducers/berthEditWizardSlice';
import {changeIsModalOpen, getPhotoId, ModalNames, setBerth, setModalName} from '../../../../../../store/reducers/modalSlice';
import {
    currentlyEditedBerth,
    isActionSuccessfulSelector,
    isBerthEditWizardLoadingSelector,
    isBerthEditWizardInitializedSelector,
} from '../../../../../../store/selectors/berthEditWizardSelectors';
import styles from './style.module.scss';

interface IConnectedGalleryBerthComponentProps {
    readonly berth: Berth | null;
    readonly isLoading: boolean;
    readonly fetchBerthDetails: typeof fetchBerthDetails;
    readonly isGalleryBerthComponentInitialized: boolean;
    readonly resetToInitialBerthEditWizardState: typeof resetToInitialBerthEditWizardState;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly setModalName: typeof setModalName;
    readonly setBerth: typeof setBerth;
    readonly getPhotoId: typeof getPhotoId;
    readonly addPhoto: typeof addPhoto;
    readonly authToken: string;
    readonly isActionSuccessful: boolean;
    readonly changeGalleryOrder: typeof changeGalleryOrder;
    readonly isInitWizard?: boolean;
}

export interface IFileUploadListElement {
    name: string;
    fileKey: string;
    url: string;
}

const GalleryBerthComponent: FC<IConnectedGalleryBerthComponentProps> = ({
    berth,
    isLoading,
    fetchBerthDetails,
    isGalleryBerthComponentInitialized,
    resetToInitialBerthEditWizardState,
    changeIsModalOpen,
    setModalName,
    setBerth,
    getPhotoId,
    isActionSuccessful,
    changeGalleryOrder,
    isInitWizard,
}) => {
    const params = useParams(),
        dispatch = useDispatch();
    let berthId = params.id;

    if (isInitWizard) berthId = berth?.id;
    const [photos, setPhotos] = useState(berth?.photos ? berth?.photos : []);

    useEffect(() => {
        if (berthId) {
            dispatch(fetchBerthDetails(berthId ? berthId : null));
        }
        return () => {
            // dispatch(resetToInitialBerthEditWizardState());
        };
    }, [berthId, dispatch, fetchBerthDetails, isActionSuccessful, resetToInitialBerthEditWizardState]);

    useEffect(() => {
        if (berth?.photos) {
            const photosList = berth?.photos;
            setPhotos(photosList);
        }
    }, [berth]);

    // Drag&Drop section

    const onDragEnd = (result: DropResult) => {
        const {source, destination} = result;
        if (!destination) return;
        const payload: ISetGalleryOrderPayload = {
            berthId: berthId,
            order: {
                photos: [
                    {
                        id: result.draggableId,
                        position: destination.index,
                    },
                ],
            },
        };

        const items = Array.from(photos);
        const [newOrder] = items.splice(source.index, 1);
        items.splice(destination.index, 0, newOrder);

        setPhotos(items);
        changeGalleryOrder(payload);
    };
    // Modals
    function openDeletePhotoModal(id: string): void {
        setModalName(ModalNames.DELETE_PHOTO);
        changeIsModalOpen(true);
        getPhotoId(id);
        setBerth(berth);
    }
    function openChangeCoverPhoto(berth: Berth | null): void {
        setModalName(ModalNames.CHANGE_PHOTO);
        changeIsModalOpen(true);
        setBerth(berth);
    }
    function openAddPhotoModal(berth: Berth | null): void {
        setModalName(ModalNames.ADD_PHOTO);
        setBerth(berth);
        changeIsModalOpen(true);
    }
    return (
        <div className={styles.container} data-scroller>
            <h2 className="title main">
                <Translation text="berths.berthDetails.berthGallery.title" />
            </h2>
            <div className={styles.section} data-section="cover_photo">
                <div className={styles.galleryHeader}>
                    <div>
                        <h3>
                            <Translation text="berths.berthDetails.berthGallery.sectionTitleCoverPhoto" />
                        </h3>
                        <p>
                            <Translation text="berths.berthDetails.berthGallery.coverSubtitle" />
                        </p>
                    </div>

                    <div>
                        <button className="btn btn-underline" onClick={() => openChangeCoverPhoto(berth)}>
                            <Translation text="buttons.changePhoto" />
                        </button>
                    </div>
                </div>
                <div>
                    {!berth?.cover ? (
                        <div className={styles.coverPhotoPlaceholder}>
                            <div className={styles.coverPhotoPlaceholderText}>
                                <Translation text="berths.berthDetails.berthGallery.coverPlaceholder" />
                            </div>
                        </div>
                    ) : (
                        <img className={styles.coverPhoto} src={berth?.cover ? berth?.cover.fileUrls?.original : ''} alt=""></img>
                    )}
                </div>
            </div>
            <div className={styles.section} data-section="all_photos">
                <div className={styles.galleryHeader}>
                    <div>
                        <h3 className="title main">
                            <Translation text="berths.berthDetails.berthGallery.mediaTitle" />
                        </h3>
                        <p>
                            <Translation text="berths.berthDetails.berthGallery.mediaSubtitle" />
                        </p>
                    </div>
                    <div>
                        <button
                            disabled={berth?.photos.length === 6}
                            className="btn btn-underline"
                            onClick={() => openAddPhotoModal(berth)}>
                            <Translation text="buttons.addPhoto" />
                        </button>
                    </div>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="photos" direction="horizontal">
                        {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                                {photos !== null && photos.length > 0 && photos !== undefined
                                    ? photos.map((item: any, index: any) => {
                                          return (
                                              <Draggable key={item.file.id} draggableId={item.file.id} index={index}>
                                                  {(provided) => (
                                                      <li
                                                          key={item?.file.id}
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}>
                                                          <img
                                                              className={styles.galleryPhoto}
                                                              src={item?.file.fileUrls ? item?.file.fileUrls?.original : ''}
                                                              alt=""></img>
                                                          <button
                                                              onClick={() => openDeletePhotoModal(item?.file.id)}
                                                              disabled={item?.file.fileUrls?.original === berth?.cover?.fileUrls?.original}
                                                              className={`btn btn-icon ${styles.deleteBtn}`}>
                                                              <Trash2 id={item?.id} size={IconSize.BerthDeleteButton} />
                                                          </button>
                                                      </li>
                                                  )}
                                              </Draggable>
                                          );
                                      })
                                    : 'no data'}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <Loader showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        berth: currentlyEditedBerth(state),

        isLoading: isBerthEditWizardLoadingSelector(state),
        isActionSuccessful: isActionSuccessfulSelector(state),
        isGalleryBerthComponentInitialized: isBerthEditWizardInitializedSelector(state),
    }),
    {
        fetchBerthDetails,
        resetToInitialBerthEditWizardState,
        changeIsModalOpen,
        setModalName,
        setBerth,
        getPhotoId,
        addPhoto,
        changeGalleryOrder,
    }
)(GalleryBerthComponent);
