import {PayloadAction} from '@reduxjs/toolkit';
import {addAlert, AlertType, authTokenSelector, IMultiselectOption} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {addMarinaExtraAPI} from '../../api/marina/addMarinaExtra';
import {changeIsMarinaActiveAPI} from '../../api/marina/changeIsMarinaActive';
import {changeMarinaAddressAPI} from '../../api/marina/changeMarinaAddress';
import {changeMarinaAttractionsAPI} from '../../api/marina/changeMarinaAttractions';
import {changeMarinaDescriptionAPI} from '../../api/marina/changeMarinaDescription';
import {changeMarinaEmailAPI} from '../../api/marina/changeMarinaEmail';
import {changeMarinaExtraAPI} from '../../api/marina/changeMarinaExtra';
import {changeMarinaLocationAPI} from '../../api/marina/changeMarinaLocation';
import {changeMarinaNameAPI} from '../../api/marina/changeMarinaName';
import {changeMarinaPhoneAPI} from '../../api/marina/changeMarinaPhone';
import {changeMarinaPoliciesAndRulesAPI} from '../../api/marina/changeMarinaPoliciesAndRules';
import {changeMarinaRestaurantsAPI} from '../../api/marina/changeMarinaRestaurants';
import {createMarinaAPI} from '../../api/marina/createMarina';
import {getMarinaAPI} from '../../api/marina/getMarina';
import {getMarinaTagsAPI} from '../../api/marina/getMarinaTags';
import {removeMarinaExtraAPI} from '../../api/marina/removeMarinaExtra';
import {removeMarinaPhotoAPI} from '../../api/marina/removePhoto';
import {ISetPhotoPaylodad, setMarinaPhotoAsCoverAPI} from '../../api/marina/setPhotoAsCover';
import {unassignMarinaFromOperatorAPI} from '../../api/marina/unassignMarinaFromOperator';
import {RootState} from '../reducers';
import {
    changeExtra,
    changeExtraOrder,
    changeIsMarinaActive,
    changeIsMarinaEditWizardInitialized,
    changeIsMarinaEditWizardLoading,
    changeMarinaAddress,
    changeMarinaAttractions,
    changeMarinaDescription,
    changeMarinaEditWizardError,
    changeMarinaEmail,
    changeMarinaLocation,
    changeMarinaName,
    changeMarinaPhone,
    changeMarinaPolicyAndRule,
    changeMarinaRestaurants,
    changeRedirectToMarinaList,
    createExtra,
    createMarina,
    deleteMarinaPhoto,
    fetchMarinaDetails,
    fetchMarinaTags,
    IFetchId,
    removeExtra,
    setIsActionSuccessful,
    setMarinaDetails,
    setMarinaPhotoAsCover,
    setMarinaTags,
    unassignMarinaFromOperator,
} from '../reducers/marinaEditWizardSlice';
import {fetchAllMarinas} from '../reducers/marinasSlice';
import {changeIsModalOpen, setCurrentlyEditedItemId} from '../reducers/modalSlice';

const createEpic =
    (actionType: any, apiCall: any, successMessage: string, extraSuccessActions: any[] = []) =>
    (action$: any, state$: StateObservable<RootState>) => {
        return action$.pipe(
            ofType(actionType),
            switchMap((action: any) => {
                const authToken = authTokenSelector(state$.value);
                return apiCall(action.payload, authToken).pipe(
                    switchMap((resp: any) => {
                        const actions = successActions([
                            setIsActionSuccessful(true),
                            addAlert({message: successMessage, type: AlertType.SUCCESS}),
                            setMarinaDetails(resp),
                            changeIsModalOpen(false),
                            ...extraSuccessActions,
                        ]);
                        return of(...actions);
                    }),
                    catchError((error) => {
                        return of(...fetchListErrorActions(error, changeMarinaEditWizardError(error)));
                    })
                );
            }),
            catchError((error) => {
                return of(...fetchListErrorActions(error, changeMarinaEditWizardError(error)));
            })
        );
    };

const createExtraEpic: Epic = createEpic(createExtra.type, addMarinaExtraAPI, 'editMenuItems.alerts.marinaExtraCreateSuccess');
const changeExtraEpic: Epic = createEpic(changeExtra.type, changeMarinaExtraAPI, 'editMenuItems.alerts.marinaExtraEditSuccess', [
    setCurrentlyEditedItemId(null),
]);
const changePhoneEpic: Epic = createEpic(changeMarinaPhone.type, changeMarinaPhoneAPI, 'editMenuItems.alerts.marinaPhoneEditSuccess', [
    setCurrentlyEditedItemId(null),
]);
const changeEmailEpic: Epic = createEpic(changeMarinaEmail.type, changeMarinaEmailAPI, 'editMenuItems.alerts.marinaEmailEditSuccess', [
    setCurrentlyEditedItemId(null),
]);
const changeRestaurantsEpic: Epic = createEpic(
    changeMarinaRestaurants.type,
    changeMarinaRestaurantsAPI,
    'editMenuItems.alerts.marinaRestaurantsEditSuccess',
    [setCurrentlyEditedItemId(null)]
);
const changeAttractionsEpic: Epic = createEpic(
    changeMarinaAttractions.type,
    changeMarinaAttractionsAPI,
    'editMenuItems.alerts.marinaAttractionsEditSuccess',
    [setCurrentlyEditedItemId(null)]
);
const changeAddressEpic: Epic = createEpic(
    changeMarinaAddress.type,
    changeMarinaAddressAPI,
    'editMenuItems.alerts.marinaAddressEditSuccess',
    [setCurrentlyEditedItemId(null)]
);
const unassignMarinaFromOperatorEpic: Epic = createEpic(
    unassignMarinaFromOperator.type,
    unassignMarinaFromOperatorAPI,
    'editMenuItems.alerts.marinaUnassignSuccess',
    [setCurrentlyEditedItemId(null), changeRedirectToMarinaList(true)]
);
const changeIsMarinaActiveEpic: Epic = createEpic(changeIsMarinaActive.type, changeIsMarinaActiveAPI, '', []);
const createMarinaEpic: Epic = createEpic(createMarina.type, createMarinaAPI, 'editMenuItems.alerts.marinaCreateSuccess', [
    fetchAllMarinas(),
    changeIsModalOpen(false),
]);

export const fetchMarinaDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchMarinaDetails.type),
        switchMap((action: PayloadAction<any>) => {
            const authToken = authTokenSelector(state$.value);
            if (!action.payload.id) return of();
            return getMarinaAPI(action.payload.id, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setMarinaDetails(resp)]);
                    return of(...actions);
                }),
                catchError((error) => of(...fetchListErrorActions(error, changeMarinaEditWizardError(error))))
            );
        }),
        catchError((error) => of(...fetchListErrorActions(error, changeMarinaEditWizardError(error))))
    );
};

const changeExtraOrderEpic: Epic = createEpic(changeExtraOrder.type, changeMarinaExtraAPI, 'editMenuItems.alerts.marinaExtraEditSuccess', [
    changeIsMarinaEditWizardLoading(false),
]);

const removeExtraEpic: Epic = createEpic(removeExtra.type, removeMarinaExtraAPI, 'editMenuItems.alerts.marinaExtraRemoveSuccess', []);
const changeMarinaNameEpic: Epic = createEpic(
    changeMarinaName.type,
    changeMarinaNameAPI,
    'editMenuItems.alerts.marinaNameChangeSuccess',
    []
);

const setMarinaPhotoAsCoverEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(setMarinaPhotoAsCover.type),
        switchMap((action: PayloadAction<IFetchId>) => {
            const authToken = authTokenSelector(state$.value),
                fetchId: ISetPhotoPaylodad = {
                    id: action.payload.id,
                };
            return setMarinaPhotoAsCoverAPI(authToken, action.payload.marinaId, fetchId).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setMarinaDetails(resp), changeIsModalOpen(false), setIsActionSuccessful(true)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeMarinaEditWizardError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeMarinaEditWizardError(error)));
        })
    );
};
const changeMarinaLocationEpic: Epic = createEpic(
    changeMarinaLocation.type,
    changeMarinaLocationAPI,
    'editMenuItems.alerts.marinaLocationChangeSuccess',
    []
);

const changeMarinaDescriptionEpic: Epic = createEpic(changeMarinaDescription.type, changeMarinaDescriptionAPI, '', []);

const changeMarinaPolicyAndRuleEpic: Epic = createEpic(
    changeMarinaPolicyAndRule.type,
    changeMarinaPoliciesAndRulesAPI,
    'editMenuItems.sections.marina_policies.updatePolicySuccess',
    []
);

const fetchMarinaTagsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchMarinaTags.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);
            return getMarinaTagsAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const uniqueMarinaTags: IMultiselectOption[] = resp['hydra:member'].reduce((acc: any, item: any) => {
                        const foundItem = acc.find((i: any) => i.value === item.id);
                        if (!foundItem) {
                            acc.push({
                                value: item.id,
                                label: item.name,
                            });
                        }
                        return acc;
                    }, []);
                    const actions = successActions([setMarinaTags(uniqueMarinaTags)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeMarinaEditWizardError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeMarinaEditWizardError(error)));
        })
    );
};

const deleteMarinaPhotoEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(deleteMarinaPhoto.type),
        switchMap((action: PayloadAction<IFetchId>) => {
            const authToken = authTokenSelector(state$.value),
                fetchId: ISetPhotoPaylodad = {
                    id: action.payload.id,
                };
            return removeMarinaPhotoAPI(authToken, action.payload.marinaId, fetchId).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([changeIsModalOpen(false), setIsActionSuccessful(true), setMarinaDetails(resp)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeMarinaEditWizardError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeMarinaEditWizardError(error)));
        })
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsMarinaEditWizardLoading(false), changeIsMarinaEditWizardInitialized(true)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

export const fetchListErrorActions = (error: any, setSliceError: any): any[] => {
    return [addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsMarinaEditWizardLoading(false), setSliceError];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const marinaEditWizardEpic = combineEpics(
    fetchMarinaDetailsEpic,
    changeMarinaNameEpic,
    changeMarinaDescriptionEpic,
    createExtraEpic,
    changePhoneEpic,
    changeIsMarinaActiveEpic,
    changeMarinaLocationEpic,
    deleteMarinaPhotoEpic,
    changeEmailEpic,
    changeAddressEpic,
    fetchMarinaTagsEpic,
    createMarinaEpic,
    removeExtraEpic,
    changeExtraEpic,
    changeMarinaPolicyAndRuleEpic,
    changeExtraOrderEpic,
    changeRestaurantsEpic,
    changeAttractionsEpic,
    setMarinaPhotoAsCoverEpic,
    unassignMarinaFromOperatorEpic
);
export default marinaEditWizardEpic;
