import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskOutput, isSameValue, RouteOutput, VesselPosition} from 'marine-panel-common-web';
import {Reservation, IModelApiResponseViewObject, DateFormat} from 'marine-panel-common-web';
import moment from 'moment';
import {IReservationPayload} from '../../api/reservation/createReservation';

export interface IReservationFilters extends IReservationBaseFilters {
    start_date?: string;
    end_date?: string;
}

export interface IReservationCountFilters extends IReservationBaseFilters {
    date?: string;
}

interface IReservationBaseFilters {
    'berth.id'?: string;
    'berth.id[]'?: string[];
    'berth.sector.id'?: string;
    'berth.sector.id[]'?: string[];
    status?: string;
    'status[]'?: string[];
}

export interface IReservationCalendarDay {
    date: string;
    count: number;
}

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface IReservationsPageState {
    reservations: Reservation[] | null;
    reservationsMetadata: IModelApiResponseViewObject | null;
    activeReservation: Reservation | null;
    reservationsCount: IReservationCalendarDay[];
    reservationCountFilters: IReservationCountFilters | null;
    latestVesselPosition: VesselPosition | null;
    routeCalculationTask: AsyncTaskOutput<{} | RouteOutput> | null;
    pagination: IPaginationParams | null;
    reservationsFilters: IReservationFilters | null;
    isReservationsPageLoading: boolean;
    isReservationsPageInitialized: boolean;
    isReservationActionProcessing: boolean;
    isReservationActionComplete: boolean;
    reservationsError: string | null;
}

export interface ISetReservationCalendarDays {
    reservationsCount: IReservationCalendarDay[];
}

export interface ISetReservations {
    reservations: Reservation[] | null;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IFetchReservationDetails {
    reservationId: string;
}

export interface IChangePagination {
    pagination: IPaginationParams;
}

export interface IChangeIsReservationsPageLoading {
    isReservationsPageLoading: boolean;
}

export interface IChangeIsReservationsPageInitialized {
    isReservationsPageInitialized: boolean;
}

export interface IChangeReservation {
    reservationId: string;
    reservation: IReservationPayload;
}

export interface IChangeReservationsError {
    error: string | null;
}

export interface IChangeReservationMonth {
    monthDate: string;
}

export interface IChangeAvailabilityStatus {
    reservationId: string;
    isAvailable: boolean;
}

export interface IChangeReservationPaidStatus {
    reservationId: string;
    isPaid: boolean;
}

export interface IReservationAction {
    reservationId: string;
    actionPayload?: any;
}

export interface IChangeIsReservationActionProcessing {
    isReservationActionProcessing: boolean;
}

export interface IChangeIsReservationActionComplete {
    isReservationActionComplete: boolean;
}

export interface IChangeReservationsFilters {
    filters: IReservationFilters;
}

export interface IChangeReservationCountFilters {
    filters: IReservationCountFilters;
}

export interface ISetActiveReservation {
    readonly activeReservation: Reservation | null;
}

export interface ISetLatestVesselPosition {
    readonly latestVesselPosition: VesselPosition | null;
}

export interface IFetchLatestVesselPosition {
    readonly vesselId: string;
}

export interface IChangeRouteCalculationTask {
    readonly task: AsyncTaskOutput<{} | RouteOutput>;
}

const initialState: IReservationsPageState = {
    reservations: null,
    reservationsMetadata: null,
    activeReservation: null,
    reservationsCount: [],
    latestVesselPosition: null,
    routeCalculationTask: null,
    pagination: {
        itemsPerPage: 25,
        page: 1,
    },
    reservationsFilters: {
        start_date: new Date().toISOString(),
        end_date: new Date().toISOString(),
    },
    reservationCountFilters: {
        date: moment().format(DateFormat.ReservationDatepicker),
    },
    isReservationsPageLoading: false,
    isReservationsPageInitialized: false,
    isReservationActionProcessing: false,
    isReservationActionComplete: false,
    reservationsError: null,
};

const reservationsPageSlice = createSlice({
    name: 'reservationsPage',
    initialState: initialState,
    reducers: {
        setReservations: {
            reducer: (state: IReservationsPageState, action: PayloadAction<ISetReservations>) => {
                return {
                    ...state,
                    reservations: action.payload.reservations,
                };
            },
            prepare(reservations: Reservation[] | null) {
                return {
                    payload: {
                        reservations: reservations,
                    },
                };
            },
        },
        setReservationsCount: {
            reducer: (state: IReservationsPageState, action: PayloadAction<ISetReservationCalendarDays>) => {
                return {
                    ...state,
                    reservationsCount: action.payload.reservationsCount,
                };
            },
            prepare(reservationsCount: IReservationCalendarDay[]) {
                return {
                    payload: {
                        reservationsCount: reservationsCount,
                    },
                };
            },
        },
        setReservationsMetadata: {
            reducer: (state: IReservationsPageState, action: PayloadAction<ISetMetadata>) => {
                return {
                    ...state,
                    reservationsMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeReservationsPagination: {
            reducer: (state: IReservationsPageState, action: PayloadAction<IChangePagination>) => {
                return {
                    ...state,
                    isReservationsPageLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changeIsReservationsPageLoading: {
            reducer: (state: IReservationsPageState, action: PayloadAction<IChangeIsReservationsPageLoading>) => {
                return {
                    ...state,
                    isReservationsPageLoading: action.payload.isReservationsPageLoading,
                };
            },
            prepare(isReservationsPageLoading: boolean) {
                return {
                    payload: {isReservationsPageLoading: isReservationsPageLoading},
                };
            },
        },
        changeIsReservationsPageInitialized: {
            reducer: (state: IReservationsPageState, action: PayloadAction<IChangeIsReservationsPageInitialized>) => {
                return {
                    ...state,
                    isReservationsPageInitialized: action.payload.isReservationsPageInitialized,
                };
            },
            prepare(isReservationsPageInitialized: boolean) {
                return {
                    payload: {isReservationsPageInitialized: isReservationsPageInitialized},
                };
            },
        },
        changeReservationsError: {
            reducer: (state: IReservationsPageState, action: PayloadAction<IChangeReservationsError>) => {
                return {
                    ...state,
                    reservationsError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        closeReservation: {
            reducer: (state: IReservationsPageState) => {
                return {
                    ...state,
                    isReservationActionProcessing: true,
                };
            },
            prepare(reservationId: string) {
                return {
                    payload: {
                        reservationId: reservationId,
                    },
                };
            },
        },
        createReservation: {
            reducer: (state: IReservationsPageState) => {
                return {
                    ...state,
                    isReservationActionProcessing: true,
                };
            },
            prepare(actionPayload: any) {
                return {
                    payload: {
                        actionPayload,
                    },
                };
            },
        },
        updateReservation: {
            reducer: (state: IReservationsPageState) => {
                return {
                    ...state,
                    isReservationActionProcessing: true,
                };
            },
            prepare(actionPayload: IChangeReservation) {
                return {
                    payload: {
                        reservation: actionPayload.reservation,
                        reservationId: actionPayload.reservationId,
                    },
                };
            },
        },
        cancelReservation: {
            reducer: (state: IReservationsPageState) => {
                return {
                    ...state,
                    isReservationActionProcessing: true,
                };
            },
            prepare(reservationId: string) {
                return {
                    payload: {
                        reservationId: reservationId,
                    },
                };
            },
        },
        deleteReservation: {
            reducer: (state: IReservationsPageState) => {
                return {
                    ...state,
                    isReservationActionProcessing: true,
                };
            },
            prepare(reservationId: string) {
                return {
                    payload: {
                        reservationId: reservationId,
                    },
                };
            },
        },
        changeReservationAvailabilityStatus: {
            reducer: (state: IReservationsPageState) => {
                return {
                    ...state,
                    isReservationActionProcessing: true,
                };
            },
            prepare(availabilityStatusPayload: IChangeAvailabilityStatus) {
                return {
                    payload: {
                        reservationId: availabilityStatusPayload.reservationId,
                        isAvailable: availabilityStatusPayload.isAvailable,
                    },
                };
            },
        },
        changeReservationPaidStatus: {
            reducer: (state: IReservationsPageState) => {
                return {
                    ...state,
                    isReservationActionProcessing: true,
                };
            },
            prepare(paidStatusPayload: IChangeReservationPaidStatus) {
                return {
                    payload: {
                        reservationId: paidStatusPayload.reservationId,
                        isPaid: paidStatusPayload.isPaid,
                    },
                };
            },
        },
        changeIsReservationActionProcessing: {
            reducer: (state: IReservationsPageState, action: PayloadAction<IChangeIsReservationActionProcessing>) => {
                return {
                    ...state,
                    isReservationActionProcessing: action.payload.isReservationActionProcessing,
                };
            },
            prepare(isReservationActionProcessing: boolean) {
                return {
                    payload: {
                        isReservationActionProcessing: isReservationActionProcessing,
                    },
                };
            },
        },
        changeIsReservationActionComplete: {
            reducer: (state: IReservationsPageState, action: PayloadAction<IChangeIsReservationActionComplete>) => {
                return {
                    ...state,
                    isReservationActionComplete: action.payload.isReservationActionComplete,
                };
            },
            prepare(isReservationActionComplete: boolean) {
                return {
                    payload: {
                        isReservationActionComplete: isReservationActionComplete,
                    },
                };
            },
        },
        fetchReservations: (state: IReservationsPageState) => {
            return {
                ...state,
                isReservationsPageLoading: true,
            };
        },
        fetchReservationDetails: {
            reducer: (state: IReservationsPageState) => {
                return {
                    ...state,
                    isReservationsViewLoading: true,
                };
            },
            prepare(reservationId: string) {
                return {
                    payload: {reservationId: reservationId},
                };
            },
        },
        applyReservationsFilters: (state: IReservationsPageState) => {
            return {
                ...state,
                isReservationsPageLoading: true,
            };
        },
        changeReservationsFilters: {
            reducer: (state: IReservationsPageState, action: PayloadAction<IChangeReservationsFilters>) => {
                if (isSameValue(action.payload.filters, state.reservationsFilters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isReservationListLoading: true,
                    reservationsFilters: action.payload.filters,
                };
            },
            prepare(filters: IReservationFilters) {
                return {
                    payload: {
                        filters: {
                            'berth.id': filters['berth.id'],
                            'berth.id[]': filters['berth.id[]'],
                            'berth.sector.id': filters['berth.sector.id'],
                            'berth.sector.id[]': filters['berth.sector.id[]'],
                            status: filters['status'],
                            'status[]': filters['status[]'],
                            start_date: filters['start_date'],
                            end_date: filters['end_date'],
                        },
                    },
                };
            },
        },

        changeReservationCountFilters: {
            reducer: (state: IReservationsPageState, action: PayloadAction<IChangeReservationCountFilters>) => {
                if (isSameValue(action.payload.filters, state.reservationCountFilters)) {
                    return {
                        ...state,
                    };
                }
                return {
                    ...state,
                    isReservationListLoading: true,
                    reservationCountFilters: action.payload.filters,
                };
            },
            prepare(filters: IReservationCountFilters) {
                return {
                    payload: {
                        filters: {
                            'berth.id': filters['berth.id'],
                            'berth.id[]': filters['berth.id[]'],
                            'berth.sector.id': filters['berth.sector.id'],
                            'berth.sector.id[]': filters['berth.sector.id[]'],
                            status: filters['status'],
                            'status[]': filters['status[]'],
                            date: filters['date'],
                        },
                    },
                };
            },
        },
        resetToInitialReservationsPageState: () => {
            return {
                ...initialState,
            };
        },
        setActiveReservation: {
            reducer: (state: IReservationsPageState, action: PayloadAction<ISetActiveReservation>) => {
                return {
                    ...state,
                    activeReservation: action.payload.activeReservation,
                };
            },
            prepare(activeReservation: Reservation | null) {
                return {
                    payload: {activeReservation: activeReservation},
                };
            },
        },
        setLatestVesselPosition: {
            reducer: (state: IReservationsPageState, action: PayloadAction<ISetLatestVesselPosition>) => {
                return {
                    ...state,
                    latestVesselPosition: action.payload.latestVesselPosition,
                };
            },
            prepare(latestVesselPosition: VesselPosition | null) {
                return {
                    payload: {latestVesselPosition: latestVesselPosition},
                };
            },
        },
        fetchVesselLatestPosition: {
            reducer: (state: IReservationsPageState) => {
                return {
                    ...state,
                };
            },
            prepare(vesselId: string) {
                return {
                    payload: {vesselId: vesselId},
                };
            },
        },
        changeRouteCalculationTask: {
            reducer: (state: IReservationsPageState, action: PayloadAction<IChangeRouteCalculationTask>) => {
                return {
                    ...state,
                    routeCalculationTask: action.payload.task,
                };
            },
            prepare(task: AsyncTaskOutput<{} | RouteOutput>) {
                return {
                    payload: {task: task},
                };
            },
        },
    },
});

export const {
    setReservations,
    setReservationsCount,
    setReservationsMetadata,
    setActiveReservation,
    changeReservationsPagination,
    changeIsReservationsPageLoading,
    changeIsReservationsPageInitialized,
    changeReservationsError,
    changeReservationCountFilters,
    cancelReservation,
    closeReservation,
    updateReservation,
    deleteReservation,
    createReservation,
    changeIsReservationActionProcessing,
    changeIsReservationActionComplete,
    fetchReservations,
    fetchReservationDetails,
    changeReservationsFilters,
    applyReservationsFilters,
    resetToInitialReservationsPageState,
    changeReservationAvailabilityStatus,
    changeReservationPaidStatus,
    setLatestVesselPosition,
    fetchVesselLatestPosition,
    changeRouteCalculationTask,
} = reservationsPageSlice.actions;

export default reservationsPageSlice.reducer;
