import {
    deepCloneObject,
    Reservation,
    Translation,
    UserRole,
    userRolesSelector,
    ReservationStatus,
    DateFormat,
    IconSize,
    isNotNullOrUndefined,
} from 'marine-panel-common-web';
import moment from 'moment';
import React, {Component} from 'react';
import {Edit, Trash, X} from 'react-feather';
import {connect} from 'react-redux';
import {getSkipperData} from '../../../../service/helperFunctions';
import {RootState} from '../../../../store/reducers';
import {changeSelectedItem, ISelectedSidePanelItem, MapActionType, resetMapToInitialState} from '../../../../store/reducers/mapHostSlice';
import {IReservationFilters} from '../../../../store/reducers/reservationsSlice';
import {selectedMapItemSelector} from '../../../../store/selectors/mapHostSelectors';
import {reservationFiltersSelector} from '../../../../store/selectors/reservationSelectors';
import DeleteReservationModal from '../DeleteReservationModal';
import ReservationBerthDetails from './ReservationBerthDetails';
import ReservationMainInfo from './ReservationMainInfo';
import ReservationNote from './ReservationNote';
import ReservationSkipperDetails from './ReservationSkipperDetails';
import ReservationStatusButtons from './ReservationStatusButtons';
import VesselLatestPositionModal from './VesselLatestPositionModal';

interface ReservationViewCardProps {
    readonly activeReservation: Reservation | null;
    readonly reservationFilters: IReservationFilters | null;
    readonly currentlySelectedItem: ISelectedSidePanelItem | null;
    readonly userRoles: UserRole[] | null;
    readonly resetMapToInitialState: typeof resetMapToInitialState;
    readonly changeSelectedItem: typeof changeSelectedItem;
}
interface IReservationViewState {
    isDeleteReservationModalOpen: boolean;
    isVesselPositionModalOpen: boolean;
}
class ReservationViewCard extends Component<ReservationViewCardProps, IReservationViewState> {
    constructor(props: ReservationViewCardProps) {
        super(props);

        this.state = {
            isDeleteReservationModalOpen: false,
            isVesselPositionModalOpen: false,
        };
    }
    render() {
        const activeReservation = this.props.activeReservation;
        if (!activeReservation) return null;
        const currentReservationDate = this.props.reservationFilters?.start_date
                ? moment(this.props.reservationFilters.start_date).format(DateFormat.DateDefaultDisplay)
                : '',
            skipper = getSkipperData(activeReservation),
            vesselName = activeReservation.vessel ? activeReservation.vessel.name : activeReservation.customVessel?.name,
            berth = activeReservation.berth,
            vesselType = activeReservation.vesselType,
            isUserOperatorAdmin = this.props.userRoles?.includes(UserRole.OPERATOR_ADMIN),
            isDeletable = activeReservation.status === ReservationStatus.DRAFT || activeReservation.status || activeReservation.isManual;

        return (
            <>
                <div className="details-header reservation-view">
                    <span className="details-item-title">{currentReservationDate}</span>

                    <div className={`reservation-controller-buttons ${isUserOperatorAdmin ? 'admin-controls' : ''}`}>
                        {isUserOperatorAdmin && (
                            <>
                                <button
                                    className="close-delete-reservation-button"
                                    onClick={this.toggleDeleteReservationModal}
                                    disabled={!isDeletable}>
                                    <Trash size={IconSize.EditReservationButton} color={'red'} />
                                    <span className="sr-only">
                                        <Translation text={'buttons.delete'} />
                                    </span>
                                </button>
                                <button className="edit-reservation-button" onClick={() => this.changeMapAction()}>
                                    <Edit size={IconSize.EditReservationButton} />
                                    <span className="sr-only">
                                        <Translation text={'buttons.edit'} />
                                    </span>
                                </button>
                            </>
                        )}
                        <button className="close-delete-reservation-button" onClick={() => this.props.resetMapToInitialState()}>
                            <X size={IconSize.CloseCard} />
                            <span className="sr-only">
                                <Translation text={'buttons.close'} />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="details-body">
                    <h2 className="reservation-title">
                        {activeReservation.number}
                        <button className="btn btn-create last-position-button" onClick={() => this.toggleLastVesselPositionModal()}>
                            <Translation text="buttons.showLastPosition" />
                        </button>
                    </h2>
                    <ReservationStatusButtons reservation={activeReservation} />
                    <ReservationSkipperDetails skipper={skipper} vesselName={vesselName} vesselType={vesselType} />
                    <ReservationNote reservationNote={activeReservation.note} />

                    <ReservationBerthDetails berth={berth} />
                    <ReservationMainInfo reservation={activeReservation} />
                </div>
                {this.state.isDeleteReservationModalOpen ? (
                    <DeleteReservationModal
                        isModalOpen={this.state.isDeleteReservationModalOpen}
                        toggleModal={this.toggleDeleteReservationModal}
                        reservation={this.props.activeReservation}
                    />
                ) : null}

                {this.state.isVesselPositionModalOpen ? (
                    <VesselLatestPositionModal
                        isModalOpen={this.state.isVesselPositionModalOpen}
                        toggleModal={this.toggleLastVesselPositionModal}
                        reservation={this.props.activeReservation}
                    />
                ) : null}
            </>
        );
    }
    private changeMapAction = () => {
        const updatedSelectedItem = deepCloneObject(this.props.currentlySelectedItem);
        if (!updatedSelectedItem) return;
        updatedSelectedItem.selectedItemActionType = MapActionType.EDIT;
        this.props.changeSelectedItem(updatedSelectedItem);
    };

    private toggleDeleteReservationModal = () => {
        this.setState({
            isDeleteReservationModalOpen: !this.state.isDeleteReservationModalOpen,
        });
    };

    private toggleLastVesselPositionModal = () => {
        this.setState({
            isVesselPositionModalOpen: !this.state.isVesselPositionModalOpen,
        });
    };
}

export default connect(
    (state: RootState) => ({
        reservationFilters: reservationFiltersSelector(state),
        currentlySelectedItem: selectedMapItemSelector(state),
        userRoles: userRolesSelector(state),
    }),
    {
        resetMapToInitialState: resetMapToInitialState,
        changeSelectedItem,
    }
)(ReservationViewCard);
