import {
    Berth,
    BerthPricingStrategyInput,
    fetchVesselTypes,
    Form,
    FormControlChangeType,
    IModelDictionaryDatum,
    IMultiselectOption,
    IPricingStrategyDefinition,
    Loader,
    PricingStrategyCalendarRule,
    Translation,
    vesselTypesSelector,
} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {
    convertPriceToInputValue,
    formatHighPrecisionPrice,
    transformCalendarRulesToPayloadInputs,
    transformValueToMoneyInput,
} from '../../../../../service/helperFunctions';
import {RootState} from '../../../../../store/reducers';
import {changeCurrentStep, createFirstPricingStrategy, InitWizardStep} from '../../../../../store/reducers/berthInitWizardSlice';
import {fetchPricingDefinitions} from '../../../../../store/reducers/pricingStrategiesSlice';
import {currentlyEditedBerth} from '../../../../../store/selectors/berthEditWizardSelectors';
import {isBerthInitWizardLoadingSelector} from '../../../../../store/selectors/berthInitWizardSelectors';
import {pricingDefinitionsSelector} from '../../../../../store/selectors/pricingStrategiesSelectors';
import BasePriceDetails from '../../../WizardEdit/WizardContent/Berth/PricingAndAvailability/SharedComponents/BasePriceDetails';
import PricingStrategyDatepicker from '../../../WizardEdit/WizardContent/Berth/PricingAndAvailability/SharedComponents/PricingStrategyDatepicker';
import WizardFormNavigationButtons from '../../Common/WizardFormNavigationButtons';
import WizardFormWrapper from '../../Common/WizardFormWrapper';
import {pricingStrategyNameFormConfig} from './FormConfigs/pricingStrategyNameFormConfig';
import {selectedPricingStrategyTemplateFormConfig} from './FormConfigs/pricingStrategyTemplateFormConfig';
import {yachtTypeFormConfig} from './FormConfigs/yachtTypeFormConfig';
interface IWizardInitPricingStrategyProps {
    readonly yachtTypes: IModelDictionaryDatum[] | [];
    readonly berth: Berth | null;
    readonly pricingStrategyDefinitions: IPricingStrategyDefinition[] | [];
    readonly createFirstPricingStrategy: typeof createFirstPricingStrategy;
    readonly fetchPricingDefinitions: typeof fetchPricingDefinitions;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly isLoading: boolean;
}

interface IWizardInitPricingStrategyValues {
    berthId: string;
    name: string | null;
    basePrice: string | null;
    basePriceGross: string | null;
    commission: string | null;
    calendarRules: PricingStrategyCalendarRule[];
}
const WizardInitPricingStrategy: FC<IWizardInitPricingStrategyProps> = ({
    yachtTypes,
    pricingStrategyDefinitions,
    berth,
    createFirstPricingStrategy,
    isLoading,
    changeCurrentStep,
}) => {
    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }
    const [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [selectedPricingStrategyTemplate, setSelectedPricingStrategyTemplate] = useState<IPricingStrategyDefinition | null>(null),
        [values, setValue] = useState<IWizardInitPricingStrategyValues>({
            berthId: berth?.id ? berth.id : '',
            name: null,
            basePrice: null,
            basePriceGross: null,
            commission: '8',
            calendarRules: [],
        }),
        [yachtTypeValues, setYachtTypeValues] = useState<{yachtTypes: IMultiselectOption[]}>({yachtTypes: []}),
        dispatch = useDispatch();

    useEffect(() => {
        const subscriptions: Subscription[] = [];
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName === 'yachtTypes' && data.value?.yachtTypes) {
                        setYachtTypeValues({yachtTypes: data.value.yachtTypes});
                    } else if (data.controlName === 'selectedPricingStrategyTemplate' && pricingStrategyDefinitions) {
                        const selectedPricingDefinition = pricingStrategyDefinitions.find(
                            (item: IPricingStrategyDefinition) => item.id === data.value?.pricingStrategyTemplate
                        );
                        if (selectedPricingDefinition) setSelectedPricingStrategyTemplate(selectedPricingDefinition);
                    } else if (data.controlName) {
                        setValue((prevState: IWizardInitPricingStrategyValues) => {
                            const grossPrice =
                                    data.value && data.value.basePriceGross ? data.value.basePriceGross : prevState?.basePriceGross,
                                commission = 8;
                            const calculatedBasePrice = formatHighPrecisionPrice((grossPrice * 100) / (1 + commission / 100));

                            return {
                                berthId: berth?.id ? berth.id : '',
                                name: data.value && data.value.name ? data.value.name : prevState?.name,
                                basePrice: calculatedBasePrice,
                                basePriceGross: grossPrice,
                                commission: '8',
                                calendarRules: data.value && data.value.calendarRules ? data.value.calendarRules : prevState?.calendarRules,
                            };
                        });
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
        };
    }, [berth?.id, onValueStateChange$$, pricingStrategyDefinitions]);

    useEffect(() => {
        if (selectedPricingStrategyTemplate) {
            setValue(() => {
                const yachtTypesFromTemplate: IMultiselectOption[] = yachtTypes
                    .filter((item: IModelDictionaryDatum) => selectedPricingStrategyTemplate.yachtTypes.includes(item.id))
                    .map((item: IModelDictionaryDatum) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                setYachtTypeValues({yachtTypes: yachtTypesFromTemplate});
                const calendarRules: PricingStrategyCalendarRule[] = selectedPricingStrategyTemplate.calendarRules.map(
                    (calendarRule: PricingStrategyCalendarRule) => {
                        return {
                            startsAt: calendarRule.startsAt,
                            endsAt: calendarRule.endsAt,
                            price: calendarRule.price,
                            active: calendarRule.active,
                        };
                    }
                );

                return {
                    berthId: berth?.id ? berth.id : '',
                    pricingStrategyTemplate: selectedPricingStrategyTemplate.id,
                    name: selectedPricingStrategyTemplate.name,
                    basePrice: convertPriceToInputValue(selectedPricingStrategyTemplate.basePrice.amount),
                    basePriceGross: convertPriceToInputValue(selectedPricingStrategyTemplate.basePriceGross.amount),
                    commission: '8',
                    calendarRules: calendarRules,
                };
            });
        }
    }, [berth?.id, selectedPricingStrategyTemplate, yachtTypes]);

    useEffect(() => {
        const subscriptions: Subscription[] = [];
        dispatch(fetchPricingDefinitions());

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [dispatch]);

    let isDisabled = !values.name;

    const multiselectOptionVesselTypes = yachtTypes.map((vesselType: IModelDictionaryDatum) => {
        const multiselect: IMultiselectOption = {
            value: vesselType.id,
            label: vesselType.name,
        };
        return multiselect;
    });

    const changeCalendarRules = (calendarRules: PricingStrategyCalendarRule[]) => {
        setValue((prevState: IWizardInitPricingStrategyValues) => {
            const formValues = {
                berthId: prevState.berthId,
                name: prevState.name,
                basePrice: prevState.basePrice,
                basePriceGross: prevState.basePriceGross,
                commission: '8',
                calendarRules: calendarRules,
            };
            return formValues;
        });
    };

    const createFirstPricingStrategyAction = () => {
        let payload: BerthPricingStrategyInput | null = null;
        if (values.berthId && values.name && values.basePrice && values.basePriceGross && values.commission) {
            const yachtTypes =
                yachtTypeValues.yachtTypes && yachtTypeValues.yachtTypes.length > 0
                    ? yachtTypeValues.yachtTypes.map((item: IMultiselectOption) => item.value)
                    : [];
            payload = {
                berthId: values.berthId,
                name: values.name,
                basePrice: transformValueToMoneyInput(values.basePrice),
                basePriceGross: transformValueToMoneyInput(values.basePriceGross),
                commission: '8',
                calendarRules: transformCalendarRulesToPayloadInputs(values.calendarRules),
                yachtTypes: yachtTypes,
            };
        }
        if (payload) createFirstPricingStrategy(payload);
    };

    const isPricingStrategyCreated = berth?.pricingStrategies && berth.pricingStrategies.length > 0,
        pricingStrategyCreationForm = (
            <>
                <WizardFormWrapper
                    currentItemId={'template'}
                    titleTranslationKey="initWizard.pricing_strategy.formControls.template"
                    subTitleTranslationKey="initWizard.pricing_strategy.formControls.template_description"
                    children={
                        <Form
                            controlName={'selectedPricingStrategyTemplate'}
                            config={selectedPricingStrategyTemplateFormConfig(pricingStrategyDefinitions)}
                            value={values}
                            onValueStateChange={onValueStateChange}
                        />
                    }
                />
                <WizardFormWrapper
                    currentItemId={'name'}
                    titleTranslationKey="initWizard.pricing_strategy.formControls.name"
                    subTitleTranslationKey="initWizard.pricing_strategy.formControls.name_description"
                    children={
                        <Form
                            controlName={'pricingStrategy'}
                            config={pricingStrategyNameFormConfig()}
                            value={values}
                            onValueStateChange={onValueStateChange}
                        />
                    }
                />
                <WizardFormWrapper
                    currentItemId={'yacht_types'}
                    titleTranslationKey="initWizard.pricing_strategy.formControls.yacht_types"
                    subTitleTranslationKey="initWizard.pricing_strategy.formControls.yacht_types_description"
                    children={
                        <Form
                            controlName={'yachtTypes'}
                            config={yachtTypeFormConfig(multiselectOptionVesselTypes)}
                            value={yachtTypeValues}
                            onValueStateChange={onValueStateChange}
                        />
                    }
                />
                <BasePriceDetails values={values} onValueStateChange={onValueStateChange} />
                <div className="form-control init-pricing-strategy-calendar modal-form">
                    <PricingStrategyDatepicker
                        startDate={new Date()}
                        endDate={new Date()}
                        values={values}
                        changeCalendarRules={changeCalendarRules}
                    />
                </div>
            </>
        );
    if (isPricingStrategyCreated) {
        isDisabled = false;
    }

    return (
        <div className="create-pricing-strategy">
            <div className="body-wrapper">
                {isPricingStrategyCreated ? (
                    <p className="title">
                        <Translation text="initWizard.pricing_strategy.pricingStrategyCreated" />
                    </p>
                ) : (
                    pricingStrategyCreationForm
                )}
            </div>
            <WizardFormNavigationButtons
                submitAllowed={!isDisabled}
                currentStepId={InitWizardStep.PRICING_STRATEGY}
                handleNext={() =>
                    isPricingStrategyCreated ? changeCurrentStep(InitWizardStep.POLICIES_AND_RULES) : createFirstPricingStrategyAction()
                }
            />
            <Loader showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        yachtTypes: vesselTypesSelector(state),
        pricingStrategyDefinitions: pricingDefinitionsSelector(state),
        berth: currentlyEditedBerth(state),
        isLoading: isBerthInitWizardLoadingSelector(state),
    }),
    {
        createFirstPricingStrategy,
        fetchVesselTypes,
        fetchPricingDefinitions,
        changeCurrentStep,
    }
)(WizardInitPricingStrategy);
