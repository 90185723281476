import React from 'react';
import classnames from 'classnames';
import {Badge, CardText} from 'reactstrap';
import {User} from 'react-feather';
import {ChatContact} from '@solvee/reactjs-websocket-text-chat';
import {Avatar, AvatarColor, AvatarStatus} from 'marine-panel-common-web';

interface Props {
    chatContact: ChatContact;
    setSelectedChatRoomId: (chat: string) => void;
    selectedChatRoomId: string;
    unseenMessages: number;
    showOnlineIndicator?: boolean;
}

class ChatUsersListItem extends React.Component<Props, any> {
    render() {
        const item = this.props.chatContact;
        return (
            <li
                key={item.accountId}
                onClick={() => this.props.setSelectedChatRoomId(item.roomId)}
                className={classnames('d-flex', 'align-items-start', 'list-group-item', {
                    active: this.props.selectedChatRoomId === item.roomId,
                })}>
                <Avatar
                    icon={<User size={42} />}
                    img={item.avatarUrl ? item.avatarUrl : ''}
                    tag={'div'}
                    color={AvatarColor.PRIMARY}
                    imgHeight="42"
                    imgWidth="42"
                    status={this.props.showOnlineIndicator ? AvatarStatus.ONLINE : AvatarStatus.OFFLINE}
                />
                <div className="chat-info flex-grow-1">
                    <h5 className="mb-0">{item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : 'Unknown user'}</h5>
                    {item?.berthName && (
                        <CardText className="text-truncate">
                            {/*{item.chat.lastMessage ? item.chat.lastMessage.message : chats[chats.length - 1].message}*/}
                            {item.berthName}
                        </CardText>
                    )}
                </div>
                <div className="chat-meta text-nowrap">
                    {/*<small className='float-end mb-25 chat-time ms-25'>{time}</small>*/}
                    {this.props.unseenMessages >= 1 ? (
                        <Badge className="float-end" color="danger" pill>
                            {this.props.unseenMessages}
                        </Badge>
                    ) : null}
                </div>
            </li>
        );
    }
}

export default ChatUsersListItem;
