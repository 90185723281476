import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IAddSupportRequestPayload {
    subject: string;
    description: string;
}

export function addSupportRequestAPI(authToken: string | null, payload: IAddSupportRequestPayload): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.post(`api/support_requests`, payload, new RestQueryParams(), headers);
}
