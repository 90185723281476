import {Berth, Form, FormControlChangeType, IModelApiResponseViewObject, Translation} from 'marine-panel-common-web';
import React, {useEffect, useState} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect, useDispatch} from 'react-redux';
import {Card} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, share} from 'rxjs/operators';
import {RootState} from '../../store/reducers';
import {applyBerthFilters, changeBerthFilters, changeBerths, changePagination, fetchAllBerths} from '../../store/reducers/berthsSlice';
import {ModalNames, changeIsModalOpen, setModalName} from '../../store/reducers/modalSlice';
import {berthMetadataSelector, berthsLoading, berthsSelector} from '../../store/selectors/berthSelectors';
import LayoutWrapper from '../LayoutWrapper';
import BerthsList from './BerthsList';
import {berthSearchFormConfig} from './berthSearchFormConfig';

interface IConnectedBerthsProps {
    readonly berths: Berth[] | null;
    readonly fetchAllBerths: typeof fetchAllBerths;
    readonly isLoading: boolean;
    readonly changeBerths: typeof changeBerths;
    readonly metadata: IModelApiResponseViewObject | null;
    readonly changePagination: typeof changePagination;
    readonly changeBerthFilters: typeof changeBerthFilters;
    readonly applyBerthFilters: typeof applyBerthFilters;
    readonly setModalName: typeof setModalName;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
}
interface IBerthsProps extends IConnectedBerthsProps, WithTranslation {}
interface IBerthsState {
    searchValue: string;
}

const Berths: React.FC<IBerthsProps> = ({
    berths,
    fetchAllBerths,
    metadata,
    changePagination,
    changeBerthFilters,
    applyBerthFilters,
    setModalName,
    changeIsModalOpen,
}) => {
    const [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(debounceTime(750), share())),
        [values, setValue] = useState<IBerthsState>({searchValue: ''}),
        subscriptions: Subscription[] = [],
        dispatch = useDispatch();

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState: IBerthsState) => {
                            if (data.value && data.value.searchValue === prevState.searchValue) {
                                return prevState;
                            }
                            const formValues = {
                                searchValue: data.value && data.value.searchValue ? data.value.searchValue : prevState?.searchValue,
                            };

                            return formValues;
                        });
                    }
                })
        );
    }, []);

    useEffect(() => {
        dispatch(fetchAllBerths());
    }, []);

    useEffect(() => {
        if (values.searchValue) {
            dispatch(changeBerthFilters({name: values.searchValue}));
            dispatch(applyBerthFilters());
        }
    }, [applyBerthFilters, changeBerthFilters, dispatch, values.searchValue]);

    // const setInitWizardDisabled = () => {
    //     dispatch(changeMisc({misc: null}));
    // };

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    const openCreateBerthModal = () => {
        dispatch(setModalName(ModalNames.CREATE_BERTH));
        dispatch(changeIsModalOpen(true));
    };

    return (
        <LayoutWrapper>
            <div className="row">
                <div className="col-md-12 col-lg-10">
                    <Card className="berth-details-card">
                        <div className="berth-details-header">
                            <h3 className="berth-list-title">
                                <Translation text="berths.title" />
                            </h3>
                            <div className="actions-wrapper">
                                <Form
                                    controlName={'berthSearchValue'}
                                    config={berthSearchFormConfig()}
                                    value={values}
                                    onValueStateChange={onValueStateChange}
                                />
                                <button className="btn btn-create" onClick={openCreateBerthModal}>
                                    <Translation text="buttons.createBerth" />
                                </button>
                            </div>
                        </div>
                        <BerthsList berths={berths} metadata={metadata} changePagination={changePagination} />
                    </Card>
                </div>
            </div>
        </LayoutWrapper>
    );
};

export default connect(
    (state: RootState) => ({
        berths: berthsSelector(state),
        isLoading: berthsLoading(state),
        metadata: berthMetadataSelector(state),
    }),
    {
        fetchAllBerths,
        changeBerths,
        changePagination,
        changeBerthFilters,
        applyBerthFilters,
        setModalName,
        changeIsModalOpen,
    }
)(withTranslation()(Berths));
