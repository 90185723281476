import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    InputType,
    ValidationRules,
    FormControlType,
    IFormConfig,
    MultiSelectType,
    IMultiselectOption,
} from 'marine-panel-common-web';

export const createFirstMarinaFormConfig = (marinaTags: IMultiselectOption[]): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form create-marina',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'name',
                controlType: 'group',
                class: 'row',
                controls: {
                    name: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: 'modal.createMarina.placeholders.marinaName',
                        isLabelHidden: false,
                        label: 'modal.createMarina.inputs.name',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                key: 'restaurants',
                controlType: 'group',
                class: 'row',
                controls: {
                    restaurants: {
                        controlType: 'control',
                        formControlType: FormControlType.TEXTAREA,
                        validationRules: [],
                        placeholder: 'modal.createMarina.placeholders.restaurants',
                        isLabelHidden: false,
                        label: 'modal.createMarina.inputs.restaurants',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                key: 'description',
                controlType: 'group',
                class: 'row',
                controls: {
                    description: {
                        controlType: 'control',
                        formControlType: FormControlType.TEXTAREA,
                        validationRules: [],
                        placeholder: 'modal.createMarina.placeholders.description',
                        isLabelHidden: false,
                        label: 'modal.createMarina.inputs.description',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                key: 'attractions',
                controlType: 'group',
                class: 'row',
                controls: {
                    attractions: {
                        controlType: 'control',
                        formControlType: FormControlType.TEXTAREA,
                        validationRules: [],
                        placeholder: 'modal.createMarina.placeholders.attractions',
                        isLabelHidden: false,
                        label: 'modal.createMarina.inputs.attractions',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                key: 'phone',
                controlType: 'group',
                class: 'row',
                controls: {
                    phone: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.MIN_LENGTH, params: {length: 9}},
                            {name: ValidationRules.MAX_LENGTH, params: {length: 32}},
                        ],
                        placeholder: 'modal.createMarina.placeholders.phone',
                        isLabelHidden: false,
                        label: 'modal.createMarina.inputs.phone',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                key: 'email',
                controlType: 'group',
                class: 'row',
                controls: {
                    email: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.MAX_LENGTH, params: {length: 255}}],
                        placeholder: 'modal.createMarina.placeholders.email',
                        isLabelHidden: false,
                        label: 'modal.createMarina.inputs.email',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                key: 'marinaTagIds',
                controlType: 'group',
                class: 'row',
                controls: {
                    marinaTagIds: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SEARCHABLE,
                        multiselectOptions: marinaTags ? marinaTags : [],
                        // validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        validationRules: [],
                        placeholder: 'modal.createMarina.placeholders.tags',
                        isLabelHidden: false,
                        label: 'modal.createMarina.inputs.tags',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control tags-input',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                    },
                },
            },
        ],
    };
};
