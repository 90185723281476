import {Berth, GeoPointOutput, Translation} from 'marine-panel-common-web';
import {FC} from 'react';
import {connect, useDispatch} from 'react-redux';
import {RootState} from '../../../../../../../store/reducers';
import {changeIsBerthEditWizardLoading} from '../../../../../../../store/reducers/berthEditWizardSlice';
import {changeIsModalOpen, ModalNames, setModalName} from '../../../../../../../store/reducers/modalSlice';
import {nameSelector} from '../../../../../../../store/selectors/modalSelectors';
import {isBerthEditWizardInitializedSelector} from '../../../../../../../store/selectors/berthEditWizardSelectors';

interface IBerthLocationProps {
    readonly berth: Berth | null;
    readonly isBerthEditWizardInitialized: boolean;
    readonly setModalName: typeof setModalName;
    readonly modalName: ModalNames | null | undefined;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
}

const BerthLocation: FC<IBerthLocationProps> = ({berth}) => {
    const dispatch = useDispatch();

    return (
        <div id="berth_location" className="details-edit-section col-xl-6" data-section="berth_location">
            <div className="details-edit-form-control">
                <div className="edit-form-control-body-wrapper">
                    <div className="edit-form-control-header">
                        <h3 className="title main">
                            <Translation text="editMenuItems.sections.berth_location.title" />
                        </h3>
                        <div className="d-flex justify-content-between">
                            <button
                                className="btn btn-underline"
                                onClick={() => {
                                    dispatch(setModalName(ModalNames.SET_BERTH_LOCATION));
                                    dispatch(changeIsModalOpen(true));
                                }}>
                                <span className="secondary-title edit">
                                    <Translation text="buttons.edit" />
                                </span>
                            </button>
                        </div>
                    </div>

                    <ul className="location-list">
                        {berth?.location && berth?.location.vertexes.length > 0
                            ? berth?.location.vertexes.map((item: any) => {
                                  return (
                                      <li className="secondary-title description" key={item['@id']}>
                                          {getLocationCoords(item)}
                                      </li>
                                  );
                              })
                            : null}
                    </ul>
                </div>
            </div>
        </div>
    );
};

function getLocationCoords(vertex: GeoPointOutput) {
    return `${vertex.latitude} ${vertex.latitude <= 0 ? 'S' : 'N'}  ${vertex.longitude} ${vertex.longitude >= 0 ? 'E' : 'W'}`;
}
export default connect(
    (state: RootState) => ({
        isBerthEditWizardInitialized: isBerthEditWizardInitializedSelector(state),
        modalName: nameSelector(state),
    }),
    {
        changeIsBerthEditWizardLoading,
        setModalName,
        changeIsModalOpen,
    }
)(BerthLocation);
