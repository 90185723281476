import {Translation} from 'marine-panel-common-web';
import * as React from 'react';

interface IWizardFormWrapperProps {
    children: any;
    currentItemId: string;
    titleTranslationKey?: string;
    subTitleTranslationKey?: string;
    mainTitle?: boolean;
}

const WizardFormWrapper: React.FunctionComponent<IWizardFormWrapperProps> = (props) => {
    return (
        <section id={props.currentItemId} className="wizard-form-control-wrapper">
            <div className="wizard-form-control-body">
                <div className={`wizard-form-control-header ${!props.titleTranslationKey ? 'justify-content-end' : ''}`}>
                    {props.titleTranslationKey && (
                        <h3 className={`title ${props.mainTitle ? 'main' : ''}`}>
                            <Translation text={props.titleTranslationKey} />
                        </h3>
                    )}
                    {props.subTitleTranslationKey && (
                        <p className="description">
                            <Translation text={props.subTitleTranslationKey} />
                        </p>
                    )}
                </div>
                {props.children}
            </div>
        </section>
    );
};

export default WizardFormWrapper;
