import {Form, FormControlChangeType, Translation} from 'marine-panel-common-web';
import {FC} from 'react';
import {basePriceFormConfig} from './basePriceFormConfig';
import PriceSummaryDetails from '../PriceSummary';

interface IBasePriceDetailsProps {
    readonly values: any;
    onValueStateChange: (controlName: string, value: any, changeType: FormControlChangeType) => void;
}

const BasePriceDetails: FC<IBasePriceDetailsProps> = ({values, onValueStateChange}) => {
    return (
        <div className="price-summary-wrapper">
            <div className="price-summary item">
                <h4 className="main title">
                    <Translation text="modal.createPricingStrategy.inputs.price" />
                </h4>
                <p className="secondary-title description">
                    <Translation text="modal.createPricingStrategy.inputs.priceDescription" />
                </p>
                <Form controlName={'basePrice'} config={basePriceFormConfig()} value={values} onValueStateChange={onValueStateChange} />
            </div>
            <PriceSummaryDetails values={values} />
        </div>
    );
};

export default BasePriceDetails;
