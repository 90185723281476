import {Observable} from 'rxjs';
import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';

export interface IUpdateBillingDataPayload {
    companyName: string;
    email: string;
    taxId: number;
    mobile: number;
    address: string;
    state: string;
    city: string | null;
    zip: number;
    countryId: number;
}

export function updateBillingDataAPI(authToken: string | null, payload: IUpdateBillingDataPayload, accountId: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return marineHubAPI.put(`api/accounts/${accountId}/change_billing_info`, payload, new RestQueryParams(), headers);
}
