import {IRawRestQueryParams, marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function getReservationReportListingAPI(authToken: string | null, params?: IRawRestQueryParams | null): Observable<any> {
    const headers = {
            Authorization: `Bearer ${authToken}`,
        },
        queryParams = params ? new RestQueryParams(params) : new RestQueryParams();
    return marineHubAPI.get('api/reservations/report_listing', queryParams, headers);
}
