import React, {useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import LayoutWrapper from '../LayoutWrapper';
import {Translation, UserRole} from 'marine-panel-common-web';
import {Card, CardBody} from 'reactstrap';
import Chat from './Chat';
import {IWithChatConnection} from '@solvee/reactjs-websocket-text-chat';
import {getChatContacts} from '../../store/reducers/chatSlice';
import {connect} from 'react-redux';
import jwt_decode from 'jwt-decode';

interface IConnectedInboxProps {
    getChatContacts: typeof getChatContacts;
}

interface IInboxProps extends IConnectedInboxProps, IWithChatConnection {
    readonly authToken: string;
    readonly accountId: string;
    readonly allContactsList: any;
    readonly errorHandler: any;
    readonly avatarUrl: any;
    readonly showOnlineIndicator: any;
    readonly chatContactsList: any;
    readonly messagesNumberPerHistoryPage: number;
}

const Inbox: React.FC<IInboxProps> = ({...props}) => {
    useEffect(() => {
        if (canUseChat) {
            props.getChatContacts();
        }
    }, []);

    const canUseChat = ((jwt_decode(props.authToken) as any)?.roles as UserRole[])?.includes(UserRole.OWNER);

    return (
        <LayoutWrapper>
            <h2 className="section-title">
                <Translation text="inbox.title" />
            </h2>

            <Card className="chat-card">
                <CardBody>
                    <div className="col-12">
                        {canUseChat ? (
                            <Chat {...props} />
                        ) : (
                            <div className="d-flex flex-grow-1 section-subtitle align-items-center justify-content-center height-100">
                                <Translation text="inbox.noRightInfo" />
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        </LayoutWrapper>
    );
};

export default connect(() => ({}), {
    getChatContacts,
})(withTranslation()(Inbox));
