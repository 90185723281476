import {Loader, LoaderType, Price, Translation, IReservationsReportOutput, IReservationsReportItemOutput} from 'marine-panel-common-web';
import React, {useEffect} from 'react';
import {Card, CardBody} from 'reactstrap';
import {fetchTransactionHistory, resetToInitialTransactionHistoryState} from '../../../store/reducers/transactionHistory';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {isTransactionHistoryPageLoadingSelector, transactionHistorySelector} from '../../../store/selectors/transactionHistorySelectors';
import TransactionHistoryFilters from './TransactionHistoryFilters';
import ReservationsList from './ReservationsList';

interface IConnectedTransactionsHistoryProps {
    readonly isLoading: boolean;
    readonly transactionHistory: IReservationsReportOutput | null;
    readonly fetchTransactionHistory: typeof fetchTransactionHistory;
    readonly resetToInitialTransactionHistoryState: typeof resetToInitialTransactionHistoryState;
}

const TransactionsHistory: React.FC<IConnectedTransactionsHistoryProps> = ({
    isLoading,
    transactionHistory,
    fetchTransactionHistory,
    resetToInitialTransactionHistoryState,
}) => {
    useEffect(() => {
        fetchTransactionHistory();

        return () => {
            resetToInitialTransactionHistoryState();
        };
    }, []);

    const hasTransaction = () => {
        return transactionHistory && transactionHistory.reservations && transactionHistory.reservations.length;
    };

    return (
        <Card className="transactions-history">
            <CardBody>
                <h2 className="section-subtitle">
                    <Translation text="reservations.title" />
                </h2>

                <div>
                    <TransactionHistoryFilters isLoading={isLoading} />
                </div>

                <div className="transactions-details">
                    <p>
                        <span className="label">
                            <Translation text="reservations.netProfit" />
                        </span>
                        <>
                            {hasTransaction() ? (
                                <Price
                                    price={{
                                        amount: transactionHistory ? transactionHistory.paidOut.amount.toString() : '',
                                        currency: {name: transactionHistory ? transactionHistory?.paidOut.currency.name : ''},
                                    }}
                                />
                            ) : (
                                '---'
                            )}
                        </>
                    </p>

                    {/*ToDo export to CSV commented until implemented on backend*/}
                    {/*<button className="btn btn-primary-outline select-file-button">*/}
                    {/*    <Translation text="reservations.exportCsv" />*/}
                    {/*</button>*/}
                </div>

                {hasTransaction() ? (
                    transactionHistory?.reservations.map((item: IReservationsReportItemOutput) => (
                        <ReservationsList reservation={item} key={item.id} />
                    ))
                ) : (
                    <p className="no-data">
                        <Translation text="reservations.noTransactionData" />
                    </p>
                )}

                <Loader type={LoaderType.Local} showLoader={isLoading} />
            </CardBody>
        </Card>
    );
};

export default connect(
    (state: RootState) => ({
        isLoading: isTransactionHistoryPageLoadingSelector(state),
        transactionHistory: transactionHistorySelector(state),
    }),
    {
        fetchTransactionHistory,
        resetToInitialTransactionHistoryState,
    }
)(TransactionsHistory);
