import {AlertTriangle, Trash} from 'react-feather';
import {IconSize, Translation, operatorAccountIdSelector} from 'marine-panel-common-web';
import {WizardEditHostType} from '../..';
import {FC, useEffect, useState} from 'react';
import {archiveBerth} from '../../../../../store/reducers/berthEditWizardSlice';
import {connect, useDispatch, useSelector} from 'react-redux';
import {IUnassignMarinaFromOperator, unassignMarinaFromOperator} from '../../../../../store/reducers/marinaEditWizardSlice';
import {useNavigate} from 'react-router-dom';
import {redirectToMarinaList} from '../../../../../store/selectors/marinaEditWizardSelectors';

export interface IWizardEditRemoveButtonProps {
    type: WizardEditHostType;
    id: string;
    readonly archiveBerth: typeof archiveBerth;
}

const WizardEditRemoveButton: FC<IWizardEditRemoveButtonProps> = ({archiveBerth, type, id}) => {
    const [isPopupOpen, setPopupOpen] = useState<boolean>(false),
        dispatch = useDispatch(),
        operatorId = useSelector(operatorAccountIdSelector),
        redirectToMarinaListInitialized = useSelector(redirectToMarinaList),
        navigation = useNavigate();

    const confirmAction = () => {
        const berthId = id;

        if (type === WizardEditHostType.BERTH) {
            dispatch(archiveBerth(berthId));
            setPopupOpen(false);
        }
        if (type === WizardEditHostType.MARINA && operatorId) {
            const payload: IUnassignMarinaFromOperator = {
                operatorId: operatorId as string,
                marinaId: id,
            };
            dispatch(unassignMarinaFromOperator(payload));
            setPopupOpen(false);
        }
    };

    useEffect(() => {
        if (redirectToMarinaListInitialized === true) {
            navigation('/panel/administration/marina');
        }
    }, [redirectToMarinaListInitialized, navigation]);
    const renderActionPopup = (isOpen: boolean, type: WizardEditHostType) => {
        if (!isOpen) return null;
        if (type === WizardEditHostType.BERTH) {
            return (
                <div className="action-popup-wrapper">
                    <div className="popup-backdrop" onClick={() => setPopupOpen(false)}></div>

                    <div className="action-popup">
                        <div className="popup-header">
                            <p className="title main">
                                <Translation text="editMenuItems.deleteBerthPopup.title" />
                            </p>
                            <button className="btn btn-close" onClick={() => setPopupOpen(false)} />
                        </div>
                        <div className="popup-body">
                            <p className="warning-wrapper">
                                <AlertTriangle size={IconSize.DeleteReservationModal} color={'#EA5455'} />
                                <Translation text="mainMap.reservation.deleteModal.warning" />
                            </p>
                            <p className="edit-form-control-subtitle">
                                <Translation text="editMenuItems.deleteBerthPopup.message" />
                            </p>
                        </div>
                        <div className="action-popup-button-wrapper">
                            <button className="sm-btn smaller btn-black-white borderless" onClick={() => setPopupOpen(false)}>
                                <Translation text="buttons.cancel" />
                            </button>
                            <button className="btn btn-create sm-btn smaller" onClick={() => confirmAction()}>
                                <Translation text="buttons.delete" />
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else if (type === WizardEditHostType.MARINA) {
            return (
                <div className="action-popup-wrapper">
                    <div className="popup-backdrop" onClick={() => setPopupOpen(false)}></div>

                    <div className="action-popup">
                        <div className="popup-header">
                            <p className="title main">
                                <Translation text="editMenuItems.deleteBerthPopup.title" />
                            </p>
                            <button className="btn btn-close" onClick={() => setPopupOpen(false)} />
                        </div>
                        <div className="popup-body">
                            <p className="warning-wrapper">
                                <AlertTriangle size={IconSize.DeleteReservationModal} color={'#EA5455'} />
                                <Translation text="mainMap.reservation.deleteModal.warning" />
                            </p>
                            <p className="edit-form-control-subtitle">
                                <Translation text="editMenuItems.marina.stopAdministrationDescription" />
                            </p>
                        </div>
                        <div className="action-popup-button-wrapper">
                            <button className="sm-btn smaller btn-black-white borderless" onClick={() => setPopupOpen(false)}>
                                <Translation text="buttons.cancel" />
                            </button>
                            <button className="btn btn-create sm-btn smaller" onClick={() => confirmAction()}>
                                <Translation text="buttons.delete" />
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    };
    return (
        <div className="action-button-wrapper">
            <button className="btn btn-icon close-delete-reservation-button" onClick={() => setPopupOpen(!isPopupOpen)}>
                <Trash size={IconSize.EditReservationButton} color={'red'} />
                <span className="sr-only">
                    <Translation text={`buttons.${type === WizardEditHostType.BERTH ? 'delete' : 'stopAdministration'}`} />
                </span>
            </button>
            {renderActionPopup(isPopupOpen, type)}
        </div>
    );
};
export default connect(() => ({}), {archiveBerth})(WizardEditRemoveButton);
