import {
    Form,
    FormControlChangeType,
    IFormConfig,
    IModelDictionaryDatum,
    isNotNullOrUndefined,
    Reservation,
    Translation,
    vesselTypesSelector,
    convertToMultiselectLabels,
    Berth,
    convertPriceToInputValue,
    CustomSkipper,
    CustomVesselInput,
    IconSize,
} from 'marine-panel-common-web';
import React from 'react';
import {Trash, X} from 'react-feather';
import {connect} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {RootState} from '../../../../store/reducers';
import {resetMapToInitialState} from '../../../../store/reducers/mapHostSlice';
import {
    changeReservationAvailabilityStatus,
    changeReservationPaidStatus,
    IChangeAvailabilityStatus,
    IChangeReservationPaidStatus,
    updateReservation,
} from '../../../../store/reducers/reservationsSlice';
import {reservationAvailabilityFormConfig} from './reservationAvailabilityFormConfig';
import {reservationFormConfig} from './reservationFromConfig';
import {berthsSelector} from '../../../../store/selectors/berthSelectors';
import {withTranslation, WithTranslation} from 'react-i18next';
import {getSkipperData, SkipperDefault} from '../../../../service/helperFunctions';
import {IReservationPayload} from '../../../../api/reservation/createReservation';
import moment from 'moment';

interface IConnectedReservationEditCardProps extends WithTranslation {
    readonly activeReservation: Reservation | null;
    readonly vesselTypes: IModelDictionaryDatum[] | null;
    readonly berths: Berth[] | null;
    readonly resetMapToInitialState: typeof resetMapToInitialState;
    readonly changeReservationPaidStatus: typeof changeReservationPaidStatus;
    readonly changeReservationAvailabilityStatus: typeof changeReservationAvailabilityStatus;
    readonly updateReservation: typeof updateReservation;
}

interface IReservationEditCardState {
    availabilityFormConfig: IFormConfig;
    formConfig: IFormConfig;
    value: any;
    isPaidStatus: boolean;
    isArrived: boolean;
}

class ReservationEditCard extends React.Component<IConnectedReservationEditCardProps, IReservationEditCardState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: IConnectedReservationEditCardProps) {
        super(props);
        this.state = {
            availabilityFormConfig: reservationAvailabilityFormConfig(),
            formConfig: reservationFormConfig(),
            value: null,
            isPaidStatus: false,
            isArrived: false,
        };
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    distinctUntilChanged(),
                    tap((data: any) => {
                        data.controlName === 'reservationAvailabilityForm'
                            ? this.setAvailabilityStatus(data.value)
                            : this.setState({value: data.value});
                    })
                )

                .subscribe()
        );
        if (this.props.activeReservation) {
            this.setFormConfig();
            this.setFormValuesFromState();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const availabilityValue = {
            isAvailable: this.state.isArrived,
            isPaid: this.state.isPaidStatus,
        };
        return (
            <>
                <div className="details-header reservation-view">
                    <p className="details-item-title">{this.props.activeReservation?.number}</p>
                    <div className="reservation-controller-buttons">
                        <button className="delete-reservation-button">
                            <Trash size={IconSize.DeleteReservationButton} color={'red'} />
                            <span className="sr-only">
                                <Translation text={'buttons.delete'} />
                            </span>
                        </button>
                        <button className="close-edit-reservation-button" onClick={() => this.props.resetMapToInitialState()}>
                            <X size={IconSize.CloseCard} />
                            <span className="sr-only">
                                <Translation text={'buttons.close'} />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="details-body">
                    <div className="reservation-form-wrapper">
                        <Form
                            config={this.state.availabilityFormConfig}
                            controlName={'reservationAvailabilityForm'}
                            onValueStateChange={this.onValueStateChange}
                            value={availabilityValue}
                        />
                        <Form
                            config={this.state.formConfig}
                            controlName={'reservationEditForm'}
                            onValueStateChange={this.onValueStateChange}
                            onButtonClicked={() => this.updateReservation()}
                            value={this.state.value}
                        />
                    </div>
                </div>
                <div className="details-footer"></div>
            </>
        );
    }
    private setAvailabilityStatus = (value: any) => {
        if (isNotNullOrUndefined(value.isArrived) && value.isArrived !== this.state.isArrived) {
            const payload: IChangeAvailabilityStatus = {
                reservationId: this.props.activeReservation ? this.props.activeReservation.id : '',
                isAvailable: value.isArrived,
            };
            this.props.changeReservationAvailabilityStatus(payload);
        }
        if (isNotNullOrUndefined(value.isPaid) && value.isPaid !== this.state.isPaidStatus) {
            const payload: IChangeReservationPaidStatus = {
                reservationId: this.props.activeReservation ? this.props.activeReservation.id : '',
                isPaid: value.isPaid,
            };
            this.props.changeReservationPaidStatus(payload);
        }
    };

    private onValueStateChange = (controlName: string, value: any, changeType: FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private updateReservation = () => {
        const value = this.state.value,
            // isPaid = value.isPaid,
            // isArrived = value.isArrived,
            skipper: CustomSkipper = {
                name: value.skipperName,
                email: value.email,
                phone: value.phone,
            },
            vessel: CustomVesselInput = {
                name: value.vesselName,
                registrationNumber: value.registrationNumber,
                vesselTypeId: value.vesselType,
            },
            paymentAmount = value.price ? value.price * 100 : 0,
            creationDate = moment(value.date).set('hour', 12).toISOString(),
            endDate = moment(creationDate).add(1, 'days').toISOString(),
            payload: IReservationPayload = {
                // isPaid: isPaid,
                // isArrived: isArrived,
                dates: [creationDate, endDate],
                berthId: value.berth,
                customSkipper: skipper,
                customVessel: vessel,
                note: value.note,
                price: {
                    amount: paymentAmount.toString(),
                    currency: 'EUR',
                },
            };
        const reservationId = this.props.activeReservation ? this.props.activeReservation.id : '';
        this.props.updateReservation({reservation: payload, reservationId: reservationId});
    };

    private setFormValuesFromState = () => {
        const activeReservation = this.props.activeReservation,
            defaultSkipper: SkipperDefault = getSkipperData(activeReservation),
            vesselName = activeReservation?.vessel
                ? activeReservation.vessel.name
                : activeReservation?.customVessel
                ? activeReservation.customVessel.name
                : '',
            vesselNumber = activeReservation?.vessel
                ? activeReservation.vessel.registrationNumber
                : activeReservation?.customVessel
                ? activeReservation.customVessel.registrationNumber
                : '',
            vesselType = activeReservation?.customVessel?.vesselType ? activeReservation.customVessel.vesselType.id : '',
            values = {
                date: activeReservation?.dates?.[0] ? new Date(activeReservation.dates[0]) : '',
                berth: activeReservation && activeReservation.berth ? activeReservation.berth.id : null,
                skipperName: defaultSkipper.name,
                email: defaultSkipper.email,
                phone: defaultSkipper.phone,
                vesselName: vesselName,
                registrationNumber: vesselNumber,
                vesselType: vesselType,
                price: activeReservation?.price ? convertPriceToInputValue(activeReservation.price.amount) : '',
                note: activeReservation?.note,
            };
        const isPaid = !!activeReservation?.paidAt,
            isArrived = !!activeReservation?.arrivedAt;
        this.setState({value: values, isPaidStatus: isPaid, isArrived: isArrived});
    };

    private setFormConfig = () => {
        const {t} = this.props,
            berths = this.props.berths ? convertToMultiselectLabels(this.props.berths, t) : [],
            vesselTypesAsMultiselectOptions = this.props.vesselTypes ? convertToMultiselectLabels(this.props.vesselTypes as any, t) : [];
        this.setState({
            formConfig: reservationFormConfig(vesselTypesAsMultiselectOptions, berths),
            availabilityFormConfig: reservationAvailabilityFormConfig(this.props.activeReservation),
        });
    };
}

export default connect(
    (state: RootState) => ({
        vesselTypes: vesselTypesSelector(state),
        berths: berthsSelector(state),
    }),
    {
        resetMapToInitialState,
        changeReservationPaidStatus,
        changeReservationAvailabilityStatus,
        updateReservation,
    }
)(withTranslation()(ReservationEditCard));
