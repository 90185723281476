import {InputType} from 'marine-panel-common-web';
import {FormControlType} from 'marine-panel-common-web';
import {IFormConfig, ValidationRules} from 'marine-panel-common-web';

export const berthSizeFormConfig = (value?: any): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form size-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'skipper_details',
                controlType: 'group',
                class: 'column',
                controls: {
                    maxLength: {
                        controlType: 'control',
                        defaultValue: value?.maxLength ? value.maxLength : '',
                        hostClass: 'col-xl-2 form-control',
                        formControlType: FormControlType.QUANTITY_INPUT,
                        isLabelHidden: false,
                        validationRules: [{name: ValidationRules.IS_POSITIVE_NUMBER_OR_NULL, params: {}}],
                        label: 'editMenuItems.sections.berth_sizes.formControls.maxLength',
                        minValue: 0,
                        maxValue: 500,
                        stepValue: 1,
                        type: InputType.NUMBER,
                        disabled: false,
                        measurementUnit: 'm',
                    },
                    maxWidth: {
                        controlType: 'control',
                        defaultValue: value?.maxWidth ? value.maxWidth : '',
                        hostClass: 'col-xl-2 form-control flex-row',
                        formControlType: FormControlType.QUANTITY_INPUT,
                        isLabelHidden: false,
                        validationRules: [{name: ValidationRules.IS_POSITIVE_NUMBER_OR_NULL, params: {}}],
                        label: 'editMenuItems.sections.berth_sizes.formControls.maxWidth',
                        minValue: 0,
                        maxValue: 500,
                        stepValue: 1,
                        type: InputType.NUMBER,
                        measurementUnit: 'm',
                    },
                    maxDraft: {
                        controlType: 'control',
                        defaultValue: value?.maxDraft ? value.maxDraft : '',
                        hostClass: 'col-xl-2 form-control',
                        formControlType: FormControlType.QUANTITY_INPUT,
                        isLabelHidden: false,
                        validationRules: [{name: ValidationRules.IS_POSITIVE_NUMBER_OR_NULL, params: {}}],
                        label: 'editMenuItems.sections.berth_sizes.formControls.maxDraft',
                        minValue: 0,
                        maxValue: 500,
                        stepValue: 1,
                        type: InputType.NUMBER,
                        measurementUnit: 'm',
                    },
                    maxHeight: {
                        controlType: 'control',
                        defaultValue: value?.maxHeight ? value.maxHeight : '',
                        hostClass: 'col-xl-2 form-control',
                        formControlType: FormControlType.QUANTITY_INPUT,
                        isLabelHidden: false,
                        validationRules: [{name: ValidationRules.IS_POSITIVE_NUMBER_OR_NULL, params: {}}],
                        label: 'editMenuItems.sections.berth_sizes.formControls.maxHeight',
                        minValue: 0,
                        maxValue: 500,
                        stepValue: 1,
                        type: InputType.NUMBER,
                        measurementUnit: 'm',
                    },
                },
            },
        ],
    };
};
