import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {initCleanApiCall} from '../../utils/reduxHelpers';
import {PaymentAccount} from 'marine-panel-common-web';
import {AttachStripePaymentMethodInput} from '../../api/payments/attachPaymentMethod';
import {DetachStripePaymentMethodInput} from '../../api/payments/detachPaymentMethod';

export interface IPaymentState {
    paymentAccount: PaymentAccount | null;
    stripeChargesEnabled: boolean;
    error: string | null;
    isLoading: boolean;
    isInitialised: boolean;
}

const initialState: IPaymentState = {
    paymentAccount: null,
    stripeChargesEnabled: false,
    error: null,
    isLoading: false,
    isInitialised: false,
};

export interface ISetPaymentAccount {
    paymentAccount: PaymentAccount | null;
}

export interface ISetIsLoading {
    isLoading: boolean;
}

export interface IGenerateOnboardingUrl {
    paymentAccountId: string;
}

export interface IChangeStripeChargesEnabled {
    stripeChargesEnabled: boolean;
}

export interface IAttachPaymentMethod {
    method: AttachStripePaymentMethodInput;
}

export interface IDetachPaymentMethod {
    method: DetachStripePaymentMethodInput;
}

const paymentSlice = createSlice({
    name: 'Payment',
    initialState: initialState,
    reducers: {
        getPaymentInitialData: (state: IPaymentState) => initCleanApiCall(state),
        setPaymentAccountData: {
            reducer: (state: IPaymentState, action: PayloadAction<ISetPaymentAccount>) => {
                return {
                    ...state,
                    paymentAccount: action.payload.paymentAccount,
                };
            },
            prepare(paymentAccount: PaymentAccount | null) {
                return {
                    payload: {paymentAccount: paymentAccount},
                };
            },
        },
        changeStripeChargesEnabled: {
            reducer: (state: IPaymentState, action: PayloadAction<IChangeStripeChargesEnabled>) => {
                return {
                    ...state,
                    stripeChargesEnabled: action.payload.stripeChargesEnabled,
                };
            },
            prepare(stripeChargesEnabled: boolean) {
                return {
                    payload: {stripeChargesEnabled: stripeChargesEnabled},
                };
            },
        },
        setIsPaymentLoading: {
            reducer: (state: IPaymentState, action: PayloadAction<ISetIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        generateOnboardingUrl: (state: IPaymentState) => initCleanApiCall(state),
        generateStripeCustomerPortalUrl: (state: IPaymentState) => initCleanApiCall(state),
        generateStripeLoginUrl: (state: IPaymentState) => initCleanApiCall(state),
        attachPaymentMethod: {
            reducer: (state: IPaymentState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(method: AttachStripePaymentMethodInput) {
                return {
                    payload: {method: method},
                };
            },
        },
        detachPaymentMethod: {
            reducer: (state: IPaymentState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(method: DetachStripePaymentMethodInput) {
                return {
                    payload: {method: method},
                };
            },
        },
    },
});

export const {
    getPaymentInitialData,
    setPaymentAccountData,
    setIsPaymentLoading,
    generateOnboardingUrl,
    generateStripeLoginUrl,
    changeStripeChargesEnabled,
    attachPaymentMethod,
    detachPaymentMethod,
    generateStripeCustomerPortalUrl,
} = paymentSlice.actions;
export default paymentSlice.reducer;
