import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IBerthInitWizardState} from '../reducers/berthInitWizardSlice';

export const selectBerthInitWizardSlice = (state: RootState) => {
    return state.berthInitWizard;
};

export const isBerthInitWizardLoadingSelector = createSelector(
    [selectBerthInitWizardSlice],
    (state: IBerthInitWizardState) => state.isBerthInitWizardLoading
);
export const isBerthInitWizardInitializedSelector = createSelector(
    [selectBerthInitWizardSlice],
    (state: IBerthInitWizardState) => state.isBerthInitWizardInitialized
);

export const isActionSuccessfulSelector = createSelector(
    [selectBerthInitWizardSlice],
    (state: IBerthInitWizardState) => state.isActionSuccessful
);

export const berthInitWizardErrorSelector = createSelector(
    [selectBerthInitWizardSlice],
    (state: IBerthInitWizardState) => state.berthInitWizardError
);

export const currentWizardStepSelector = createSelector(
    [selectBerthInitWizardSlice],
    (state: IBerthInitWizardState) => state.currentWizardStep
);

export const marinaListSelector = createSelector([selectBerthInitWizardSlice], (state: IBerthInitWizardState) => state.marinaList);
export const sectorListSelector = createSelector([selectBerthInitWizardSlice], (state: IBerthInitWizardState) => state.sectorList);
