import {Berth, Form, FormControlChangeType, Translation, Loader} from 'marine-panel-common-web';
import {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../store/reducers';
import {changeBerthNote, fetchBerthDetails} from '../../../../../../store/reducers/berthEditWizardSlice';
import {currentlyEditedBerth, isBerthEditWizardLoadingSelector} from '../../../../../../store/selectors/berthEditWizardSelectors';
import EditFormControl from '../EditFormControl';

import {noteFormConfig} from './noteFormConfig';

interface INoteComponentProps {
    readonly berth: Berth | null;
    readonly changeBerthNote: typeof changeBerthNote;
    readonly isLoading: boolean;
}

const NoteComponent: React.FunctionComponent<INoteComponentProps> = ({berth, changeBerthNote, isLoading}) => {
    const dispatch = useDispatch(),
        params = useParams(),
        berthId = params.id,
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<{note: string | null}>(),
        [editedId, setEditedId] = useState<string | null>(null);

    const subscriptions: Subscription[] = [];
    useEffect(() => {
        dispatch(fetchBerthDetails(berthId ? berthId : ''));
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);
    useEffect(() => {
        if (berth !== null) {
            setValue({note: berth.note ? berth.note : ''});
        }
    }, [berth]);

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState: any) => {
                            const formValues = {
                                note: data.value && data.value.note ? data.value.note : prevState?.note,
                            };
                            return formValues;
                        });
                    }
                })
        );
    }, []);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }
    const onConfirmAction = (action: any) => {
        dispatch(action);
        setEditedId(null);
    };
    return (
        <div className="details-edit-section-wrapper">
            <div className="details-edit-section col-xl-6">
                <h3 className="sr-only">
                    <Translation text="editMenuItems.sections.berth_note.title" />
                </h3>

                <EditFormControl
                    editedItemId={editedId}
                    setEditedItemId={(editedItemId: string | null) => setEditedId(editedItemId)}
                    currentItemId={'berth_note'}
                    titleTranslationKey="editMenuItems.sections.berth_note.title"
                    onConfirm={() => onConfirmAction(changeBerthNote(berth?.id, values?.note))}
                    editedChildren={
                        <Form
                            controlName={'berthNameForm'}
                            config={noteFormConfig(values?.note)}
                            value={values?.note}
                            onValueStateChange={onValueStateChange}
                        />
                    }
                    // displayChildren={<p className="secondary-title description">{berth?.note}</p>}
                    displayChildren={
                        <Form
                            controlName={'berthNameForm'}
                            config={noteFormConfig(values?.note, true)}
                            value={values?.note}
                            onValueStateChange={onValueStateChange}
                        />
                    }
                />
            </div>
            <Loader showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        berth: currentlyEditedBerth(state),
        isLoading: isBerthEditWizardLoadingSelector(state),
    }),
    {changeBerthNote}
)(NoteComponent);
