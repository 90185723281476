import {createRoot} from 'react-dom/client';
import Moment from 'react-moment';
import {Provider} from 'react-redux';
import {Routes} from 'react-router-dom';
import {HistoryRouter as Router} from 'redux-first-history/rr6';
import {PersistGate} from 'redux-persist/lib/integration/react';
import './i18n';
import './index.scss';
import routes from './routes/routes';
import * as serviceWorker from './serviceWorker';
import store, {history, persistor} from './store';
import {AccessibilityWrapper} from 'marine-panel-common-web';

Moment.startPooledTimer(1000);
const container = document.getElementById('root');
const root = createRoot(container as any);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <AccessibilityWrapper>
                <Router history={history}>
                    <Routes>{routes}</Routes>
                </Router>
            </AccessibilityWrapper>
        </PersistGate>
    </Provider>
);

serviceWorker.unregister();
