import {Berth, IconSize, Marina, Translation} from 'marine-panel-common-web';
import {FC} from 'react';
import {Plus} from 'react-feather';
import {connect} from 'react-redux';
import {ModalBody, ModalHeader} from 'reactstrap';
import {RootState} from '../../../../store/reducers';
import {setPhotoAsCover} from '../../../../store/reducers/berthEditWizardSlice';
import {setMarinaPhotoAsCover} from '../../../../store/reducers/marinaEditWizardSlice';
import {currentlyEditedBerth} from '../../../../store/selectors/berthEditWizardSelectors';
import {currentlyEditedMarina} from '../../../../store/selectors/marinaEditWizardSelectors';
import styles from '../style.module.scss';

interface IChangePhotoModalProps {
    readonly setPhotoAsCover: typeof setPhotoAsCover;
    readonly setMarinaPhotoAsCover: typeof setMarinaPhotoAsCover;
    readonly berth?: Berth | null;
    readonly marina?: Marina | null;
    readonly photoId?: string | null;
    toggleModalOpen: () => void;
}

const ChangePhotoModal: FC<IChangePhotoModalProps> = ({setPhotoAsCover, setMarinaPhotoAsCover, berth, marina, toggleModalOpen}) => {
    let fileList: any[] = [];
    if (berth && berth.photos.length > 0) {
        fileList = berth.photos;
    }
    if (marina && marina.photos.length > 0) {
        fileList = marina.photos;
    }

    const updateAction = (fileId: string) => {
        if (!berth && !marina) return;
        if (berth) {
            setPhotoAsCover(fileId, berth.id);
        }
        if (marina) {
            setMarinaPhotoAsCover(fileId, marina.id);
        }
    };

    return (
        <div className="modal-wrapper">
            <ModalHeader toggle={() => toggleModalOpen()}>
                <Translation text="modal.changePhoto.title" />
            </ModalHeader>
            <ModalBody className={styles.container}>
                <div className={styles.content}>
                    <ul>
                        {fileList.length > 0 ? (
                            fileList.map((item: any) => {
                                return (
                                    <li key={item.file.id}>
                                        <img className={styles.galleryPhoto} alt="" src={item.file.fileUrls.original}></img>
                                        <button
                                            className={`btn btn-icon ${styles.changeCoverPhoto}`}
                                            onClick={() => updateAction(item.file.id)}>
                                            <Plus id={item.file.id} size={IconSize.ChangePhotoModalButton} />
                                        </button>
                                    </li>
                                );
                            })
                        ) : (
                            <li key="no-data" className={styles.noData}>
                                <Translation text="modal.changePhoto.noPhotosUploaded" />
                            </li>
                        )}
                    </ul>
                </div>
            </ModalBody>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        berth: currentlyEditedBerth(state),
        marina: currentlyEditedMarina(state),
    }),
    {
        setPhotoAsCover,
        setMarinaPhotoAsCover,
    }
)(ChangePhotoModal);
