import {RestQueryParams, marineHubAPI, IRawRestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export enum OnboardingType {
    Onboarding = 'account_onboarding',
    Update = 'account_update',
}

export interface IStripeOnboardingPayload {
    refreshUrl: string;
    returnUrl: string;
    onboardingMode: OnboardingType;
}

export function generateOnboardingLink(
    authToken: string | null,
    accountId: string,
    payload: IStripeOnboardingPayload,
    params?: IRawRestQueryParams
): Observable<any> {
    const queryParams: RestQueryParams = params ? new RestQueryParams(params) : new RestQueryParams(),
        headers = {
            Authorization: `Bearer ${authToken}`,
        };

    return marineHubAPI.put(
        `api/vendor_payment_accounts/stripe/${accountId}/generate_stripe_onboarding_link`,
        payload,
        queryParams,
        headers
    );
}
