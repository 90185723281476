import {Translation, MarinaBroad, isNotNullOrUndefined} from 'marine-panel-common-web';
import {Link} from 'react-router-dom';

const MarinaItem = ({marina}: {marina: MarinaBroad}) => {
    const renderDescription = (marina: {[key: string]: any}) => {
        let photoUrl = null;
        if (marina.cover) {
            photoUrl = isNotNullOrUndefined(marina.cover.fileUrls.original) ? marina.cover.fileUrls.original : null;
        }
        return (
            <>
                <div
                    className={`marina-image ${photoUrl === null ? 'placeholder' : null}`}
                    style={{backgroundImage: `url(${photoUrl})`}}></div>

                <div className="marina-description">
                    <div className="title-container">
                        <p className="title">{marina.name}</p>
                    </div>
                    <p className="description">{marina.description}</p>
                </div>
            </>
        );
    };
    return (
        <tr key={marina.id} className="marina-item-wrapper">
            <td className="marina-description-wrapper">{renderDescription(marina)}</td>
            <td className="marina-details">
                <div>
                    <Link className="edit-marina-link" to={`/panel/edit/marina/${marina.id}/details`}>
                        <p>
                            <Translation text="administration.marinas.buttons.edit" />
                        </p>
                    </Link>
                </div>
            </td>
        </tr>
    );
};

export default MarinaItem;
