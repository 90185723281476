import {
    Berth,
    BerthPricingStrategyInput,
    fetchVesselTypes,
    Form,
    FormControlChangeType,
    IModelDictionaryDatum,
    IMultiselectOption,
    IPricingStrategyDefinition,
    PricingStrategyCalendarRule,
    PricingStrategyDefinitionInput,
    Translation,
    vesselTypesSelector,
    Loader,
} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {
    convertPriceToInputValue,
    formatHighPrecisionPrice,
    transformCalendarRulesToPayloadInputs,
    transformValueToMoneyInput,
} from '../../../../../../../service/helperFunctions';
import {RootState} from '../../../../../../../store/reducers';
import {
    changeIsPricingStrategiesLoading,
    createPricingStrategy,
    createPricingStrategyDefinition,
    fetchPricingDefinitions,
} from '../../../../../../../store/reducers/pricingStrategiesSlice';
import {currentlyEditedBerth} from '../../../../../../../store/selectors/berthEditWizardSelectors';
import {
    pricingDefinitionsSelector,
    isPricingStrategiesLoadingSelector,
} from '../../../../../../../store/selectors/pricingStrategiesSelectors';
import BasePriceDetails from '../SharedComponents/BasePriceDetails';
import PricingStrategyDatepicker from '../SharedComponents/PricingStrategyDatepicker';
import {pricingStrategyNameFormConfig} from '../SharedComponents/FormConfigs/pricingStrategyNameFormConfig';
import {selectedPricingStrategyTemplateFormConfig} from '../SharedComponents/FormConfigs/pricingStrategyTemplateFormConfig';

import {yachtTypeFormConfig} from '../SharedComponents/FormConfigs/yachtTypeFormConfig';
interface ICreatePricingStrategyModalProps {
    readonly yachtTypes: IModelDictionaryDatum[] | [];
    readonly berth: Berth | null;
    readonly pricingStrategyDefinitions: IPricingStrategyDefinition[] | [];
    readonly createPricingStrategy: typeof createPricingStrategy;
    readonly createPricingStrategyDefinition: typeof createPricingStrategyDefinition;
    readonly fetchPricingDefinitions: typeof fetchPricingDefinitions;
    readonly isLoading: boolean;

    toggleModalOpen: () => void;
}

interface ICreatePricingStrategyModalValues {
    berthId: string;
    name: string | null;
    basePrice: string | null;
    basePriceGross: string | null;
    commission: string | null;
    calendarRules: PricingStrategyCalendarRule[];
}
const CreatePricingStrategyModal: FC<ICreatePricingStrategyModalProps> = ({
    yachtTypes,
    pricingStrategyDefinitions,
    createPricingStrategyDefinition,
    berth,
    createPricingStrategy,
    isLoading,
    toggleModalOpen,
}) => {
    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }
    const [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [selectedPricingStrategyTemplate, setSelectedPricingStrategyTemplate] = useState<IPricingStrategyDefinition | null>(null),
        [values, setValue] = useState<ICreatePricingStrategyModalValues>({
            berthId: berth?.id ? berth.id : '',
            name: null,
            basePrice: null,
            basePriceGross: null,
            commission: '8',
            calendarRules: [],
        }),
        [yachtTypeValues, setYachtTypeValues] = useState<{yachtTypes: IMultiselectOption[]}>({yachtTypes: []}),
        subscriptions: Subscription[] = [],
        dispatch = useDispatch();

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName === 'yachtTypes' && data.value?.yachtTypes) {
                        setYachtTypeValues({yachtTypes: data.value.yachtTypes});
                    } else if (data.controlName === 'selectedPricingStrategyTemplate' && pricingStrategyDefinitions) {
                        const selectedPricingDefinition = pricingStrategyDefinitions.find(
                            (item: IPricingStrategyDefinition) => item.id === data.value?.pricingStrategyTemplate
                        );
                        if (selectedPricingDefinition) setSelectedPricingStrategyTemplate(selectedPricingDefinition);
                    } else if (data.controlName) {
                        setValue((prevState: ICreatePricingStrategyModalValues) => {
                            const grossPrice =
                                    data.value && data.value.basePriceGross ? data.value.basePriceGross : prevState?.basePriceGross,
                                commission = 8;
                            const calculatedBasePrice = formatHighPrecisionPrice((grossPrice * 100) / (1 + commission / 100));

                            return {
                                berthId: berth?.id ? berth.id : '',
                                name: data.value && data.value.name ? data.value.name : prevState?.name,
                                basePrice: calculatedBasePrice,
                                basePriceGross: grossPrice,
                                commission: '8',
                                calendarRules: data.value && data.value.calendarRules ? data.value.calendarRules : prevState?.calendarRules,
                            };
                        });
                    }
                })
        );
    }, [onValueStateChange$$, pricingStrategyDefinitions]);

    useEffect(() => {
        if (selectedPricingStrategyTemplate) {
            setValue(() => {
                const yachtTypesFromTemplate: IMultiselectOption[] = yachtTypes
                    .filter((item: IModelDictionaryDatum) => selectedPricingStrategyTemplate.yachtTypes.includes(item.id))
                    .map((item: IModelDictionaryDatum) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                setYachtTypeValues({yachtTypes: yachtTypesFromTemplate});
                const calendarRules: PricingStrategyCalendarRule[] = selectedPricingStrategyTemplate.calendarRules.map(
                    (calendarRule: PricingStrategyCalendarRule) => {
                        return {
                            startsAt: calendarRule.startsAt,
                            endsAt: calendarRule.endsAt,
                            price: calendarRule.price,
                            active: calendarRule.active,
                        };
                    }
                );

                const formValues = {
                    berthId: berth?.id ? berth.id : '',
                    pricingStrategyTemplate: selectedPricingStrategyTemplate.id,
                    name: selectedPricingStrategyTemplate.name,
                    basePrice: convertPriceToInputValue(selectedPricingStrategyTemplate.basePrice.amount),
                    basePriceGross: convertPriceToInputValue(selectedPricingStrategyTemplate.basePriceGross.amount),
                    commission: '8',
                    calendarRules: calendarRules,
                };
                return formValues;
            });
        }
    }, [selectedPricingStrategyTemplate]);

    useEffect(() => {
        dispatch(fetchPricingDefinitions());

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [dispatch]);

    const isDisabled = !values.name;

    const multiselectOptionVesselTypes = yachtTypes.map((vesselType: IModelDictionaryDatum) => {
        const multiselect: IMultiselectOption = {
            value: vesselType.id,
            label: vesselType.name,
        };
        return multiselect;
    });

    const changeCalendarRules = (calendarRules: PricingStrategyCalendarRule[]) => {
        setValue((prevState: ICreatePricingStrategyModalValues) => {
            const formValues = {
                berthId: prevState.berthId,
                name: prevState.name,
                basePrice: prevState.basePrice,
                basePriceGross: prevState.basePriceGross,
                commission: '8',
                calendarRules: calendarRules,
            };
            return formValues;
        });
    };

    const createPricingStrategyAction = () => {
        let payload: BerthPricingStrategyInput | null = null;
        if (values.berthId && values.name && values.basePrice && values.basePriceGross && values.commission) {
            const yachtTypes =
                yachtTypeValues.yachtTypes && yachtTypeValues.yachtTypes.length > 0
                    ? yachtTypeValues.yachtTypes.map((item: IMultiselectOption) => item.value)
                    : [];
            payload = {
                berthId: values.berthId,
                name: values.name,
                basePrice: transformValueToMoneyInput(values.basePrice),
                basePriceGross: transformValueToMoneyInput(values.basePriceGross),
                commission: '8',
                calendarRules: transformCalendarRulesToPayloadInputs(values.calendarRules),
                yachtTypes: yachtTypes,
            };
        }
        if (payload) createPricingStrategy(payload);
    };

    const createPricingStrategyDefinitionAction = () => {
        let payload: PricingStrategyDefinitionInput | null = null;
        if (values.name && values.basePrice && values.basePriceGross && values.commission) {
            const yachtTypes =
                yachtTypeValues.yachtTypes && yachtTypeValues.yachtTypes.length > 0
                    ? yachtTypeValues.yachtTypes.map((item: IMultiselectOption) => item.value)
                    : [];
            payload = {
                name: values.name + ' definition',
                basePrice: transformValueToMoneyInput(values.basePrice),
                basePriceGross: transformValueToMoneyInput(values.basePriceGross),
                commission: '8',
                calendarRules: transformCalendarRulesToPayloadInputs(values.calendarRules),
                yachtTypes: yachtTypes,
            };
            if (payload) createPricingStrategyDefinition(payload);
        }
    };

    return (
        <div className="modal-wrapper create-pricing-strategy">
            <Loader showLoader={isLoading} />
            <ModalHeader tag={'h3'}>
                <Translation text="modal.createPricingStrategy.title" />
                <button className="btn-close" onClick={() => toggleModalOpen()}></button>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="form-control offers-form-control modal-form">
                        <h4 className="main title">
                            <Translation text="modal.createPricingStrategy.inputs.usePricingStrategyTemplate" />
                        </h4>
                        <p className="secondary-title description">
                            <Translation text="modal.createPricingStrategy.inputs.usePricingStrategyTemplateDescription" />
                        </p>
                        <Form
                            controlName={'selectedPricingStrategyTemplate'}
                            config={selectedPricingStrategyTemplateFormConfig(pricingStrategyDefinitions)}
                            value={values}
                            onValueStateChange={onValueStateChange}
                        />
                    </div>
                    <div className="form-control offers-form-control modal-form">
                        <h4 className="main title">
                            <Translation text="modal.createPricingStrategy.inputs.name" />
                        </h4>
                        <p className="secondary-title description">
                            <Translation text="modal.createPricingStrategy.inputs.nameDescription" />
                        </p>
                        <Form
                            controlName={'pricingStrategy'}
                            config={pricingStrategyNameFormConfig()}
                            value={values}
                            onValueStateChange={onValueStateChange}
                        />
                    </div>
                    <div className="form-control offers-form-control modal-form">
                        <h4 className="main title">
                            <Translation text="modal.createPricingStrategy.inputs.yachtTypes" />
                        </h4>
                        <p className="secondary-title description">
                            <Translation text="modal.createPricingStrategy.inputs.yachtTypesDescription" />
                        </p>
                        <Form
                            controlName={'yachtTypes'}
                            config={yachtTypeFormConfig(multiselectOptionVesselTypes)}
                            value={yachtTypeValues}
                            onValueStateChange={onValueStateChange}
                        />
                    </div>
                    <BasePriceDetails values={values} onValueStateChange={onValueStateChange} />
                    <div className="form-control offers-form-control modal-form">
                        <PricingStrategyDatepicker
                            startDate={new Date()}
                            endDate={new Date()}
                            values={values}
                            changeCalendarRules={changeCalendarRules}
                        />
                    </div>
                    <div className="form-control offers-form-control modal-form">
                        <h4 className="main title">
                            <Translation text="modal.createPricingStrategy.inputs.saveAsTemplate" />
                        </h4>
                        <p className="secondary-title description">
                            <Translation text="modal.createPricingStrategy.inputs.saveAsTemplateDescription" />
                        </p>
                        <button className="sm-btn btn btn-create mt-2 save-tempale-buttons" onClick={createPricingStrategyDefinitionAction}>
                            <Translation text="modal.createPricingStrategy.buttons.saveAsTemplate" />
                        </button>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="sm-btn btn btn-underline btn-underline-primary close-modal-button" onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button
                    className="sm-btn btn btn-create create-pricing-strategy-button"
                    disabled={isDisabled}
                    onClick={() => createPricingStrategyAction()}>
                    <Translation text="buttons.create" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        yachtTypes: vesselTypesSelector(state),
        pricingStrategyDefinitions: pricingDefinitionsSelector(state),
        berth: currentlyEditedBerth(state),
        isLoading: isPricingStrategiesLoadingSelector(state),
    }),
    {
        createPricingStrategy,
        createPricingStrategyDefinition,
        fetchVesselTypes,
        fetchPricingDefinitions,
    }
)(CreatePricingStrategyModal);
