import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {
    accountSlice,
    alertSlice,
    authSlice,
    changePasswordSlice,
    countrySlice,
    initLogout,
    loaderSlice,
    loginSlice,
    sagaSlice,
    vesselTypeSlice,
    vesselModelSlice,
} from 'marine-panel-common-web';
import {createReduxHistoryContext} from 'redux-first-history';
import berthsSlice from './berthsSlice';
import marinasSlice from './marinasSlice';
import sectorsSlice from './sectorsSlice';
import mapHostSlice from './mapHostSlice';
import modalSlice from './modalSlice';
import berthEditWizardSlice from './berthEditWizardSlice';
import reservationsSlice from './reservationsSlice';
import paymentSlice from './paymentSlice';
import settingsPageSlice from './settingsPageSlice';
import berthTagsSlice from './berthsTagsSlice';
import transactionHistorySlice from './transactionHistory';
import supportPageSlice from './supportPageSlice';
import chat from './chatSlice';
import marinaEditWizardSlice from './marinaEditWizardSlice';
import marinaTagsSlice from './marinasTagsSlice';
import pricingStrategiesSlice from './pricingStrategiesSlice';
import routeFind from './routeFindSlice';
import berthInitWizardSlice from './berthInitWizardSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    account: accountSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    saga: sagaSlice,
    country: countrySlice,
    vesselType: vesselTypeSlice,
    vesselModel: vesselModelSlice,
    berths: berthsSlice,
    marinas: marinasSlice,
    sectors: sectorsSlice,
    router: routerReducer,
    mapHost: mapHostSlice,
    modal: modalSlice,
    berthEditWizard: berthEditWizardSlice,
    berthInitWizard: berthInitWizardSlice,
    marinaEditWizard: marinaEditWizardSlice,
    loader: loaderSlice,
    reservations: reservationsSlice,
    payment: paymentSlice,
    settings: settingsPageSlice,
    transactionHistory: transactionHistorySlice,
    supportPage: supportPageSlice,
    berthTags: berthTagsSlice,
    marinaTags: marinaTagsSlice,
    pricingStrategies: pricingStrategiesSlice,
    chat: chat,
    routeFind: routeFind,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
