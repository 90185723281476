import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IChangeBerthDescription {
    description: string;
}

export function changeBerthDescriptionAPI(
    id: string | null | undefined,
    payload: IChangeBerthDescription | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/change_description`, payload, new RestQueryParams(), headers);
}
