import {Observable} from 'rxjs';
import {marineHubAPI, RestQueryParams, RouteInput} from 'marine-panel-common-web';

export function createNavigationRouteApi(authToken: string | null, route: RouteInput): Observable<{id: string}> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return marineHubAPI.post('api/weather_routing/route', route, new RestQueryParams(), headers);
}
