import {Reservation, Translation, IconSize} from 'marine-panel-common-web';
import {Search} from 'react-feather';
import {connect} from 'react-redux';
import {changeSelectedItem, ISelectedSidePanelItem, MapActionType, SelectedItemType} from '../../../../../../store/reducers/mapHostSlice';
import ReservationItemBadges from './ReservationItemBadges';

interface IMarinaReservationItemProps {
    readonly reservation: Reservation;
    readonly changeSelectedItem: typeof changeSelectedItem;
}

const MarinaReservationItem = (props: IMarinaReservationItemProps) => {
    const selectActiveReservation = (reservation: Reservation) => {
        const selectedItem: ISelectedSidePanelItem = {
            selectedItemType: SelectedItemType.RESERVATION,
            selectedItemActionType: MapActionType.VIEW,
        };
        props.changeSelectedItem(selectedItem, reservation);
    };
    return (
        <li className="marina-reservation-item" key={props.reservation.id}>
            <div className="item-status">
                <h3 className="item-title">{props.reservation.number}</h3>
                <ReservationItemBadges reservation={props.reservation} />
            </div>
            <div className="item-details-wrapper">
                <div className="item-details">
                    <span>{getSkipperName(props.reservation)}</span>
                    <span className="spacer">|</span>
                    <span>{getVesselName(props.reservation)}</span>
                </div>
                <button className="btn btn-view reservation-view-button" onClick={() => selectActiveReservation(props.reservation)}>
                    <Search size={IconSize.ViewReservationButton} />
                    <span className="sr-only">
                        <Translation text="buttons.view" />
                    </span>
                </button>
            </div>
        </li>
    );
};
function getSkipperName(reservation: Reservation) {
    if (reservation.skipper && reservation.skipper.firstName && reservation.skipper.lastName) {
        return `${reservation.skipper.firstName} ${reservation.skipper.lastName}`;
    } else if (reservation.customSkipper && reservation.customSkipper.name) {
        return `${reservation.customSkipper.name}`;
    }
    return <Translation text="mainMap.reservation.view.noSkipper" />;
}
function getVesselName(reservation: Reservation) {
    if (reservation.vessel) {
        return `${reservation.vessel.name}`;
    } else if (reservation.customVessel) {
        return `${reservation.customVessel.name}`;
    }
    return <Translation text="mainMap.reservation.view.noVessel" />;
}

export default connect(() => ({}), {
    changeSelectedItem,
})(MarinaReservationItem);
