import {RestQueryParams, marineHubAPI, IRawRestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function getSelectedMarinaBerthsAPI(authToken: string | null, sectorId: string): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.get(`api/berths/list_mine?sector.id=${sectorId}`, new RestQueryParams(), headers);
}
