import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, concatMap, debounceTime, filter, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, ObservableInput, of} from 'rxjs';
import {RootState} from '../reducers';
import {
    addAlert,
    AlertType,
    ApiError,
    authTokenSelector,
    deepCloneObject,
    flattenObj,
    handleApiError,
    IRawRestQueryParams,
    isNullOrUndefined,
} from 'marine-panel-common-web';
import {getReservationReportListingAPI} from '../../api/reservation/getReservationReportListing';
import {
    applyTransactionHistoryFilters,
    changeTransactionHistoryPageError,
    changeTransactionHistoryPageLoading,
    fetchTransactionHistory,
    setTransactionHistory,
} from '../reducers/transactionHistory';
import {transactionHistoryFiltersSelector} from '../selectors/transactionHistorySelectors';

const errorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), changeTransactionHistoryPageLoading(false), changeTransactionHistoryPageError(errorObj.message)];
};

const fetchTransactionHistoryEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getTransactionHistory(action$, state$, fetchTransactionHistory);
};

const successActions = (changeSliceList: any): any[] => {
    return [changeSliceList, changeTransactionHistoryPageLoading(false)];
};

const applyTransactionHistoryFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, applyTransactionHistoryFilters, doFetch);

export type FetchAction = {token: string | null; flattenedParams: any};
const fetchSubject = new BehaviorSubject<FetchAction>({token: null, flattenedParams: null});
const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject
    .asObservable()
    .pipe(
        debounceTime(250),
        switchMap((fetch) => {
            if (isNullOrUndefined(fetch.token)) {
                return of(null);
            }

            return getTransactionsList(fetch.token as string, fetch.flattenedParams);
        }),
        tap((action) => resultsSubject.next(action))
    )
    .subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (state: RootState) => {
    const authToken = authTokenSelector(state),
        filters = deepCloneObject(transactionHistoryFiltersSelector(state));

    const flattened = filters ? flattenObj(filters) : null;

    fetchSubject.next({token: authToken, flattenedParams: flattened});

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap((action) => of(action, changeTransactionHistoryPageLoading(false)))
    );
};

const getTransactionHistory = (action$: Observable<any>, state$: StateObservable<any>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                filterParams = transactionHistoryFiltersSelector(state$.value),
                params = filterParams ? flattenObj(filterParams) : null;

            return getTransactionsList(authToken, params);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    doFetch: (state: RootState) => ObservableInput<any>
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap(doFetch)
    );
};

export const getTransactionsList = (authToken: string, params?: IRawRestQueryParams | null) => {
    return getReservationReportListingAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const transactions = resp['hydra:member'],
                actions = successActions(setTransactionHistory(transactions[0]));
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const transactionsHistoryEpic = combineEpics(fetchTransactionHistoryEpic, applyTransactionHistoryFiltersEpic);

export default transactionsHistoryEpic;
