import {Observable} from 'rxjs';
import {MessageMapperResult} from '../../components/WithSoccetChatConnection/chat.types';
import {IRawRestQueryParams, marineHubServerMessagesAPI, RestQueryParams} from 'marine-panel-common-web';

export function getMessagesApi(
    accountId: string,
    berthId: string,
    authToken: string,
    page?: number,
    messagesNumberPerHistoryPage?: number
): Observable<MessageMapperResult> {
    const params: IRawRestQueryParams = [
        {path: 'berth.id', val: berthId},
        {path: 'account.id', val: accountId},
    ];
    if (page) {
        params.push({path: 'page', val: page});
    }
    if (messagesNumberPerHistoryPage) {
        params.push({path: 'itemsPerPage', val: messagesNumberPerHistoryPage});
    }
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return marineHubServerMessagesAPI(accountId).get('api/messages', new RestQueryParams(params), headers);
}
