import {Berth, Price, Translation, BerthDayPricingOutput, deepCloneObject, IconSize} from 'marine-panel-common-web';
import {Shield} from 'react-feather';
import {Link} from 'react-router-dom';

interface ReservationBerthDetailsProps {
    readonly berth: Berth | null;
}
function ReservationBerthDetails(props: ReservationBerthDetailsProps) {
    if (!props.berth) return null;
    const berthName = props.berth.name;
    return (
        <article className="reservation-details-wrapper">
            <div className="reservation-details-header">
                <h3 className="details-item-title">
                    <Translation text={'mainMap.reservation.view.berth'} />
                </h3>
                <Link className="btn btn-create berth-edit-link" to={`/panel/edit/berth/${props.berth.id}/details`}>
                    <Translation text="buttons.showBerthInfo" />
                </Link>
            </div>

            <dl className="information-details">
                <div className="information-details-row">
                    <dt className="information-details-label">
                        <span className="sr-only">
                            <Translation text={'mainMap.reservation.view.berthName'} />
                        </span>
                        <span className="details-icon">
                            <Shield size={IconSize.BerthDetailsIcon} />
                        </span>
                    </dt>
                    <dd className="information-details-value">{berthName}</dd>
                </div>
                <div className="information-details-row">
                    <dt className="information-details-label">
                        <span className="sr-only">
                            <Translation text={'mainMap.reservation.view.berthPricingStrategyPrice'} />
                        </span>
                        <span className="details-icon">
                            <ShieldWithDollarIcon size={IconSize.BerthDetailsIcon} />
                        </span>
                    </dt>
                    {renderBerthPrices(props.berth)}
                </div>
            </dl>
        </article>
    );
}

function renderBerthPrices(berth: Berth) {
    if (!berth.prices || !berth.prices.length) return null;
    if (berth.prices.length === 1) {
        const price = berth.prices[0];
        return (
            <dd className="information-details-value price">
                <Price price={price.grossPrice} />
            </dd>
        );
    }
    const sortedPrices = deepCloneObject(berth.prices).sort(sortByGrossPrice);
    return (
        <dd className="information-details-value price">
            <span>
                <Price price={sortedPrices[0].grossPrice} />
            </span>
            <span> - </span>
            <span>
                <Price price={sortedPrices[sortedPrices.length - 1].grossPrice} />
            </span>
        </dd>
    );
}

function sortByGrossPrice(a: BerthDayPricingOutput, b: BerthDayPricingOutput) {
    return Number(a.grossPrice.amount) - Number(b.grossPrice.amount);
}

function ShieldWithDollarIcon({size}: {size: number}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 18 18">
            <path
                fill="#000"
                fillRule="evenodd"
                d="M10.042 8.583h-.625V6.5h1.666c.25 0 .417-.167.417-.417 0-.25-.167-.416-.417-.416H9.417v-1.25C9.417 4.167 9.25 4 9 4c-.25 0-.417.167-.417.417v1.25h-.625a1.867 1.867 0 00-1.875 1.875c0 1.041.834 1.875 1.875 1.875h.625V11.5H6.5c-.25 0-.417.167-.417.417 0 .25.167.416.417.416h2.083v1.25c0 .25.167.417.417.417.25 0 .417-.167.417-.417v-1.25h.625a1.867 1.867 0 001.875-1.875 1.867 1.867 0 00-1.875-1.875zm-2.084 0a1.032 1.032 0 01-1.041-1.041c0-.584.458-1.042 1.041-1.042h.625v2.083h-.625zM9.417 11.5h.625c.583 0 1.041-.458 1.041-1.042 0-.583-.458-1.041-1.041-1.041h-.625V11.5z"
                clipRule="evenodd"></path>
            <mask id="mask0_103_140" style={{maskType: 'alpha'}} width="6" height="10" x="6" y="4" maskUnits="userSpaceOnUse">
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M10.042 8.583h-.625V6.5h1.666c.25 0 .417-.167.417-.417 0-.25-.167-.416-.417-.416H9.417v-1.25C9.417 4.167 9.25 4 9 4c-.25 0-.417.167-.417.417v1.25h-.625a1.867 1.867 0 00-1.875 1.875c0 1.041.834 1.875 1.875 1.875h.625V11.5H6.5c-.25 0-.417.167-.417.417 0 .25.167.416.417.416h2.083v1.25c0 .25.167.417.417.417.25 0 .417-.167.417-.417v-1.25h.625a1.867 1.867 0 001.875-1.875 1.867 1.867 0 00-1.875-1.875zm-2.084 0a1.032 1.032 0 01-1.041-1.041c0-.584.458-1.042 1.041-1.042h.625v2.083h-.625zM9.417 11.5h.625c.583 0 1.041-.458 1.041-1.042 0-.583-.458-1.041-1.041-1.041h-.625V11.5z"
                    clipRule="evenodd"></path>
            </mask>
            <g mask="url(#mask0_103_140)">
                <path fill="#1E78C1" d="M4 4H14V14H4z"></path>
            </g>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M9.15.75l6 1.5c.375.075.6.375.6.75v6c0 4.875-6.15 8.025-6.45 8.175-.075.075-.15.075-.3.075s-.225 0-.3-.075c-.3-.15-6.45-3.3-6.45-8.175V3c0-.375.225-.675.525-.75l6-1.5h.375zM9 15.675c1.2-.75 5.25-3.3 5.25-6.675V3.6L9 2.25 3.75 3.6V9c0 3.45 4.05 6 5.25 6.675z"
                clipRule="evenodd"></path>
            <mask id="mask1_103_140" style={{maskType: 'alpha'}} width="14" height="18" x="2" y="0" maskUnits="userSpaceOnUse">
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M9.15.75l6 1.5c.375.075.6.375.6.75v6c0 4.875-6.15 8.025-6.45 8.175-.075.075-.15.075-.3.075s-.225 0-.3-.075c-.3-.15-6.45-3.3-6.45-8.175V3c0-.375.225-.675.525-.75l6-1.5h.375zM9 15.675c1.2-.75 5.25-3.3 5.25-6.675V3.6L9 2.25 3.75 3.6V9c0 3.45 4.05 6 5.25 6.675z"
                    clipRule="evenodd"></path>
            </mask>
            <g mask="url(#mask1_103_140)">
                <path fill="#1E78C1" d="M0 0H18V18H0z"></path>
            </g>
        </svg>
    );
}

export default ReservationBerthDetails;
