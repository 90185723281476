import {FormControlType, IFormConfig, InputType, ValidationRules} from 'marine-panel-common-web';
import {IChangeExtraModalValues} from './index';
export const changeExtraFormConfig = (value: IChangeExtraModalValues): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form create-marina',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'price',
                controlType: 'group',
                class: 'row',
                controls: {
                    price: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [],
                        defaultValue: value.price,
                        customNumberIndicator: '€',
                        placeholder: 'modal.createExtra.placeholders.price',
                        isLabelHidden: false,
                        label: 'modal.createExtra.inputs.price',
                        type: InputType.NUMBER,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                key: 'description',
                controlType: 'group',
                class: 'row',
                controls: {
                    description: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: '',
                        defaultValue: value.description,
                        isLabelHidden: false,
                        label: 'modal.createExtra.inputs.description',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
        ],
    };
};
