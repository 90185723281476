import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function getMarinasAPI(authToken: string | null, params?: any): Observable<any> {
    const queryParams = params ? params : new RestQueryParams(),
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    return marineHubAPI.get('api/marinas/list_mine', queryParams, headers);
}
