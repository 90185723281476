import {
    FormControlType,
    IFormConfig,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
    IMultiselectOption,
    MarinaBroad,
} from 'marine-panel-common-web';

export const marinaSelectorFormConfig = (marinas?: IMultiselectOption[] | [], activeMarina?: MarinaBroad | null): IFormConfig => {
    return {
        controlType: 'form',
        class: 'marina-select-form default-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'selected_Marina',
                controlType: 'group',
                class: 'row',
                controls: {
                    selectedMarinaId: {
                        controlType: 'control',
                        defaultValue: activeMarina ? activeMarina.id : null,
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: marinas ? marinas : [],
                        validationRules: [],
                        isLabelHidden: true,
                        placeholder: 'mainMap.marinaSelector.placeholder',
                        hostClass: 'col-xl-12 form-control marina-selector-input',
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                    },
                },
            },
        ],
    };
};
