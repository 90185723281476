import {PayloadAction} from '@reduxjs/toolkit';
import {AlertType, authTokenSelector, addAlert, IMultiselectOption} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of, forkJoin} from 'rxjs';
import {catchError, switchMap, takeLast, mergeMap} from 'rxjs/operators';
import {archiveBerthAPI} from '../../api/berth/archiveBerth';
import {assignToMarinaAPI} from '../../api/berth/assignToMarina';
import {changeBerthDescriptionAPI} from '../../api/berth/changeBerthDescription';
import {changeBerthLocationAPI} from '../../api/berth/changeBerthLocation';
import {changeBerthNameAPI} from '../../api/berth/changeBerthName';
import {changeBerthNoteAPI} from '../../api/berth/changeBerthNote';
import {changeIsBerthActiveAPI} from '../../api/berth/changeIsBerthActive';
import {changeIsBookingEnabledAPI} from '../../api/berth/changeIsBookingEnabled';
import {changeMaxDraftAPI} from '../../api/berth/changeMaxDraft';
import {changeMaxHeightAPI} from '../../api/berth/changeMaxHeight';
import {changeMaxLengthAPI} from '../../api/berth/changeMaxLength';
import {changeMaxWidthAPI} from '../../api/berth/changeMaxWidth';
import {createBerthAPI} from '../../api/berth/createBerth';
import {getBerthAPI} from '../../api/berth/getBerth';
import {getMarinasAPI} from '../../api/marina/getMarinas';
import {ISetPhotoPaylodad} from '../../api/marina/setPhotoAsCover';
import {removePhotoAPI} from '../../api/berth/removePhoto';
import {RootState} from '../reducers';
import {
    changeBerthDescription,
    changeBerthEditWizardError,
    changeBerthName,
    changeBerthSize,
    changeMarinaList,
    changeIsBerthActive,
    changeIsBerthEditWizardInitialized,
    changeIsBerthEditWizardLoading,
    changeIsBookingEnabled,
    fetchMarinaList,
    IBerthsNamePayload,
    IBerthsSizePayload,
    IChangeBerthActive,
    IChangeBerthsMarine,
    IChangeBookingEnabled,
    setMarinaList,
    setSectorList,
    IBerthDescriptionPayload,
    setIsActionSuccessful,
    fetchBerthDetails,
    setBerthDetails,
    changeBerthLocation,
    IChangeBerthLocationPayload,
    IBerthsNotePayload,
    changeBerthNote,
    archiveBerth,
    deletePhoto,
    changeBerthPolicyAndRule,
    IChangeBerthPolicyAndRule,
    setPhotoAsCover,
    changeGalleryOrder,
    ISetGalleryOrderPayload,
} from '../reducers/berthEditWizardSlice';
import {createBerth, fetchAllBerths, ICreateBerth} from '../reducers/berthsSlice';
import {IFetchId} from '../reducers/berthEditWizardSlice';
import {changeIsModalOpen} from '../reducers/modalSlice';
import {createBrowserHistory} from 'history';
import {changePoliciesAndRulesAPI} from '../../api/berth/changePoliciesAndRules';
import {setPhotoAsCoverAPI} from '../../api/berth/setPhotoAsCover';
import {changeGalleryOrderAPI, ISetOrderPayload} from '../../api/berth/changeGalleryOrder';

const fetchBerthDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchBerthDetails.type),
        switchMap((action: PayloadAction<any>) => {
            const authToken = authTokenSelector(state$.value);
            if (!action.payload.id) return of();
            return getBerthAPI(action.payload.id, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setBerthDetails(resp)]);
                    return of(...actions);
                }),
                catchError((error) => of(...fetchListErrorActions(error)))
            );
        }),
        catchError((error) => of(...fetchListErrorActions(error)))
    );
};

const changeIsBookingEnabledEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeIsBookingEnabled.type),
        switchMap((action: PayloadAction<IChangeBookingEnabled>) => {
            const authToken = authTokenSelector(state$.value);
            return changeIsBookingEnabledAPI(action.payload.berthId, action.payload.appBookingEnabled, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({
                            message: `editMenuItems.alerts.${
                                resp.appBookingEnabled ? 'berthAllowedAppBooking' : 'berthDisallowedAppBooking'
                            }`,
                            type: AlertType.SUCCESS,
                        }),
                        setBerthDetails(resp),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const changeIsBerthActiveEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeIsBerthActive.type),
        switchMap((action: PayloadAction<IChangeBerthActive>) => {
            const authToken = authTokenSelector(state$.value);
            return changeIsBerthActiveAPI(action.payload.berthId, action.payload.active, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({
                            message: `editMenuItems.alerts.${resp.active ? 'berthActivated' : 'berthDeactivated'}`,
                            type: AlertType.SUCCESS,
                        }),
                        setBerthDetails(resp),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const createBerthEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(createBerth.type),
        switchMap((action: PayloadAction<ICreateBerth>) => {
            const authToken = authTokenSelector(state$.value);
            return createBerthAPI(authToken, action.payload).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.alerts.berthCreateSuccess', type: AlertType.SUCCESS}),
                        setBerthDetails(resp),
                        fetchAllBerths(),
                        changeIsModalOpen(false),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const archiveBerthEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(archiveBerth.type),
        switchMap((action: PayloadAction<IChangeBerthActive>) => {
            const authToken = authTokenSelector(state$.value);
            return archiveBerthAPI(authToken, action.payload.berthId).pipe(
                switchMap(() => {
                    const history = createBrowserHistory();
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.alerts.archiveBerthSuccess', type: AlertType.SUCCESS}),
                        history.back(),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const changeBerthsMarineEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeMarinaList.type),
        switchMap((action: PayloadAction<IChangeBerthsMarine>) => {
            const authToken = authTokenSelector(state$.value);
            return assignToMarinaAPI(action.payload.berthId, action.payload.marinaId, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: '', type: AlertType.SUCCESS}),
                        setBerthDetails(resp),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const fetchMarinasOptionsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchMarinaList.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);
            return getMarinasAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const uniqueSectorList: any[] = [];
                    const uniqueMarinaList: IMultiselectOption[] = resp['hydra:member'].reduce((acc: any, item: any) => {
                        const foundItem = acc.find((i: any) => i.value === item.id);
                        if (!foundItem) {
                            acc.push({
                                value: item.id,
                                label: item.name,
                            });
                            const sectorId = item.sectors[0].id,
                                location = item.location ? [item.location.latitude, item.location.longitude] : null;
                            uniqueSectorList.push({
                                value: sectorId,
                                label: item.name,
                                location: location,
                            });
                        }
                        return acc;
                    }, []);
                    const actions = successActions([setMarinaList(uniqueMarinaList), setSectorList(uniqueSectorList)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const changeBerthNameEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeBerthName.type),
        mergeMap((action: PayloadAction<IBerthsNamePayload>) => {
            const authToken = authTokenSelector(state$.value);
            return changeBerthNameAPI(action.payload.berthId, action.payload.name, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.alerts.berthNameChangeSuccess', type: AlertType.SUCCESS}),
                        setBerthDetails(resp),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, 'editMenuItems.alerts.berthNameChangeError'));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, 'editMenuItems.alerts.berthNameChangeError'));
        })
    );
};

const changeGalleryOrderEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeGalleryOrder.type),
        switchMap((action: PayloadAction<ISetGalleryOrderPayload>) => {
            const authToken = authTokenSelector(state$.value),
                fetchPhotoPosition: ISetOrderPayload = {
                    photos: [
                        {
                            id: action?.payload?.order?.photos[0]?.id,
                            position: action?.payload?.order?.photos[0]?.position,
                        },
                    ],
                };
            return changeGalleryOrderAPI(action.payload?.berthId, fetchPhotoPosition, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setBerthDetails(resp)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const setPhotoAsCoverEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(setPhotoAsCover.type),
        switchMap((action: PayloadAction<IFetchId>) => {
            const authToken = authTokenSelector(state$.value),
                fetchId: ISetPhotoPaylodad = {
                    id: action.payload.id,
                };
            return setPhotoAsCoverAPI(authToken, action.payload.berthId, fetchId).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setBerthDetails(resp), changeIsModalOpen(false), setIsActionSuccessful(true)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const deletePhotoEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(deletePhoto.type),
        switchMap((action: PayloadAction<IFetchId>) => {
            const authToken = authTokenSelector(state$.value),
                fetchId: ISetPhotoPaylodad = {
                    id: action.payload.id,
                };
            return removePhotoAPI(authToken, action.payload.berthId, fetchId).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([changeIsModalOpen(false), setIsActionSuccessful(true), setBerthDetails(resp)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const changeBerthNoteEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeBerthNote.type),
        switchMap((action: PayloadAction<IBerthsNotePayload>) => {
            const authToken = authTokenSelector(state$.value);

            return changeBerthNoteAPI(action.payload.berthId, action.payload.note, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.alerts.berthNoteChangeSuccess', type: AlertType.SUCCESS}),
                        setBerthDetails(resp),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, 'editMenuItems.alerts.berthNoteChangeSuccess'));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, 'editMenuItems.alerts.berthNoteChangeSuccess'));
        })
    );
};

const changeBerthLocationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeBerthLocation.type),
        switchMap((action: PayloadAction<IChangeBerthLocationPayload>) => {
            const authToken = authTokenSelector(state$.value);

            return changeBerthLocationAPI(action.payload.berthId, action.payload, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.alerts.berthLocationChangeSuccess', type: AlertType.SUCCESS}),
                        setBerthDetails(resp),
                        changeIsModalOpen(false),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, 'editMenuItems.alerts.berthLocationChangeError'));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, 'editMenuItems.alerts.berthLocationChangeError'));
        })
    );
};

const changeBerthDescriptionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeBerthDescription.type),
        switchMap((action: PayloadAction<IBerthDescriptionPayload>) => {
            const authToken = authTokenSelector(state$.value);
            return changeBerthDescriptionAPI(action.payload.berthId, action.payload.description, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: '', type: AlertType.SUCCESS}),
                        setBerthDetails(resp),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const changeBerthSizeEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeBerthSize.type),
        switchMap((action: PayloadAction<IBerthsSizePayload>) => {
            const authToken = authTokenSelector(state$.value);
            const requests = [
                changeMaxWidthAPI(action.payload.berthId, action.payload.maxWidth, authToken),
                changeMaxDraftAPI(action.payload.berthId, action.payload.maxDraft, authToken),
                changeMaxHeightAPI(action.payload.berthId, action.payload.maxHeight, authToken),
                changeMaxLengthAPI(action.payload.berthId, action.payload.maxLength, authToken),
            ];
            const allRequests = forkJoin(requests);

            return allRequests.pipe(
                takeLast(1),
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: '', type: AlertType.SUCCESS}),
                        setBerthDetails(resp[0]),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const changeBerthPolicyAndRuleEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeBerthPolicyAndRule.type),
        switchMap((action: PayloadAction<IChangeBerthPolicyAndRule>) => {
            const authToken = authTokenSelector(state$.value);
            return changePoliciesAndRulesAPI(authToken, action.payload.berthId, action.payload.berthPolicyAndRuleInput).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([
                        setIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.sections.berth_policies.updatePolicySuccess', type: AlertType.SUCCESS}),
                        setBerthDetails(resp),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error));
        })
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsBerthEditWizardLoading(false), changeIsBerthEditWizardInitialized(true)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

export const fetchListErrorActions = (error: any, customError?: string): any[] => {
    return [
        changeIsBerthEditWizardLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeBerthEditWizardError(getErrorMessage(customError ? customError : error)),
    ];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const berthEditWizardEpic = combineEpics(
    fetchBerthDetailsEpic,
    changeBerthNameEpic,
    changeBerthDescriptionEpic,
    changeBerthSizeEpic,
    fetchMarinasOptionsEpic,
    changeBerthsMarineEpic,
    changeIsBerthActiveEpic,
    changeIsBookingEnabledEpic,
    changeBerthLocationEpic,
    changeBerthNoteEpic,
    archiveBerthEpic,
    createBerthEpic,
    deletePhotoEpic,
    changeBerthPolicyAndRuleEpic,
    setPhotoAsCoverEpic,
    changeGalleryOrderEpic
);
export default berthEditWizardEpic;
