import {combineEpics} from 'redux-observable';
import {
    accountEpic,
    changePasswordEpic,
    dictionaryDataEpic,
    loginEpic,
    reauthenticateEpic,
    renewAuthTokenEpic,
    authEpic,
} from 'marine-panel-common-web';
import berthsEpic from './berthsEpic';
import marinasEpic from './marinasEpic';
import reservationsEpic from './reservationsEpic';
import paymentEpic from './paymentEpic';
import settingsEpic from './settingsEpic';
import berthTagsEpic from './berthsTagsEpic';
import transactionsHistoryEpic from './transactionHistoryEpic';
import berthEditWizardEpic from './berthEditWizardEpic';
import supportPageEpic from './supportPageEpic';
import chatEpic from './chatEpic';
import marinaEditWizardEpic from './marinaEditWizardEpic';
import marinaTagsEpic from './marinasTagsEpic';
import pricingStrategiesEpic from './pricingStrategiesEpic';
import routeFindEpic from './routeFindEpic';
import berthInitWizardEpic from './berthInitWizardEpic';

export const rootEpic = combineEpics(
    berthsEpic,
    dictionaryDataEpic,
    marinasEpic,
    reservationsEpic,
    loginEpic,
    renewAuthTokenEpic,
    authEpic,
    reauthenticateEpic,
    changePasswordEpic,
    accountEpic,
    paymentEpic,
    settingsEpic,
    transactionsHistoryEpic,
    berthEditWizardEpic,
    berthInitWizardEpic,
    supportPageEpic,
    berthTagsEpic,
    marinaTagsEpic,
    chatEpic,
    marinaEditWizardEpic,
    pricingStrategiesEpic,
    routeFindEpic
);
