import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Sector} from 'marine-panel-common-web';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './berthsSlice';

export interface ISectorsState extends IBaseDictionaryState {
    sectors: Sector[] | null;
    activeSector: Sector | null;
}

export interface IFormListFilters {
    page?: string;
    itemsPerPage?: string;
    startDate?: string | null;
    endDate?: string | null;
    searchPhrase?: string | null;
    published?: boolean | null;
    active?: boolean | null;
}

export type IFormListFiltersType = 'page' | 'itemsPerPage' | 'startDate' | 'endDate' | 'searchPhrase' | 'published' | 'active';

export interface IChangeFormListFilters {
    readonly payload: {
        formListFilters: IFormListFilters;
    };
}

export interface ISetSectors {
    readonly sectors: Sector[] | null;
}

export interface ISetActiveSector {
    readonly activeSector: Sector | null;
}

const initialState: ISectorsState = {
    sectors: [],
    activeSector: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const sectorsSlice = createSlice({
    name: 'sectors',
    initialState: initialState,
    reducers: {
        changeSector: {
            reducer: (state: ISectorsState, action: PayloadAction<ISetSectors>) => {
                return {
                    sectors: action.payload.sectors,
                    activeSector: state.activeSector,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(sectors: Sector[] | null) {
                return {
                    payload: {sectors: sectors},
                };
            },
        },

        changeIsSectorLoading: {
            reducer: (state: ISectorsState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    sectors: state.sectors,
                    activeSector: state.activeSector,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsSectorInitialized: {
            reducer: (state: ISectorsState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    sectors: state.sectors,
                    activeSector: state.activeSector,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setSectorError: {
            reducer: (state: ISectorsState, action: PayloadAction<ISetError>) => {
                return {
                    sectors: state.sectors,
                    activeSector: state.activeSector,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchSectors: (state: ISectorsState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        fetchAllSectors: (state: ISectorsState) => {
            return {
                ...state,
            };
        },
        setActiveSector: {
            reducer: (state: ISectorsState, action: PayloadAction<ISetActiveSector>) => {
                return {
                    sectors: state.sectors,
                    activeSector: action.payload.activeSector,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(activeSector: Sector | null) {
                return {
                    payload: {activeSector: activeSector},
                };
            },
        },
    },
});

export const {
    changeSector,
    changeIsSectorLoading,
    changeIsSectorInitialized,
    setSectorError,
    fetchSectors,
    fetchAllSectors,
    setActiveSector,
} = sectorsSlice.actions;

export default sectorsSlice.reducer;
