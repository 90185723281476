import {Form, FormControlChangeType, GeoPoint, Translation, IMultiselectOption} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../store/reducers';
import {tagListSelector} from '../../../../../store/selectors/marinaEditWizardSelectors';
import {fetchMarinaTags, ICreateMarina} from '../../../../../store/reducers/marinaEditWizardSlice';
import {createFirstMarinaFormConfig} from './createFirstMarinaFormConfig';
import LocationInput from '../../../../Shared/WizardEdit/WizardContent/Common/SetLocationModal/LocationInput';
import {SelectedItemType} from '../../../../../store/reducers/mapHostSlice';
import {changeCurrentStep, createFirstMarina, InitWizardStep} from '../../../../../store/reducers/berthInitWizardSlice';
import WizardFormNavigationButtons from '../../Common/WizardFormNavigationButtons';
interface IWizardInitCreateFirstMarinaProps {
    readonly tagList: any[] | [];
    readonly createFirstMarina: typeof createFirstMarina;
    readonly changeCurrentStep: typeof changeCurrentStep;
}

interface IWizardInitCreateFirstMarinaValues {
    name: string;
    restaurants: string | null;
    description: string | null;
    attractions: string | null;
    phone: string | null;
    email: string | null;
    location: GeoPoint | null;
    marinaTagIds: IMultiselectOption[] | null;
}
const WizardInitCreateFirstMarina: FC<IWizardInitCreateFirstMarinaProps> = ({tagList, createFirstMarina}) => {
    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState: IWizardInitCreateFirstMarinaValues) => {
                            const formValues = {
                                name: data.value && data.value.name ? data.value.name : prevState?.name,
                                restaurants: data.value && data.value.restaurants ? data.value.restaurants : prevState?.restaurants,
                                description: data.value && data.value.description ? data.value.description : prevState?.description,
                                attractions: data.value && data.value.attractions ? data.value.attractions : prevState?.attractions,
                                phone: data.value && data.value.phone ? data.value.phone : prevState?.phone,
                                email: data.value && data.value.email ? data.value.email : prevState?.email,
                                location: prevState?.location ? prevState?.location : null,
                                marinaTagIds: data.value && data.value.marinaTagIds ? data.value.marinaTagIds : prevState?.marinaTagIds,
                            };
                            return formValues;
                        });
                    }
                })
        );
    }, []);

    const [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<IWizardInitCreateFirstMarinaValues>({
            name: '',
            restaurants: null,
            description: null,
            attractions: null,
            phone: null,
            email: null,
            location: null,
            marinaTagIds: null,
        }),
        subscriptions: Subscription[] = [],
        dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMarinaTags());

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [dispatch]);

    const isDisabled = !values.name || !values.location;
    const goBackToInitWizard = () => {
        dispatch(changeCurrentStep(InitWizardStep.BASE_INFORMATION));
    };
    const createFirstMarinaAction = () => {
        const locationPayload: GeoPoint | null = values.location ? values.location : null;

        let payload: ICreateMarina | null = null;
        if (values.name && locationPayload) {
            const getMarinaTagIdPayload = (selectedTags: IMultiselectOption[]) => selectedTags.map((tag) => tag.value);

            payload = {
                name: values.name,
                location: locationPayload,
                restaurants: values.restaurants,
                description: values.description,
                attractions: values.attractions,
                phone: values.phone,
                email: values.email,
                marinaTagIds: values.marinaTagIds ? getMarinaTagIdPayload(values.marinaTagIds) : [],
            };
        }
        if (payload) createFirstMarina(payload);
    };
    return (
        <div id="create_first_marina" className="wizard-init-form-wrapper">
            <div>
                <div className="form-control offers-form-control modal-form">
                    <Form
                        controlName={'marinaNameForm'}
                        config={createFirstMarinaFormConfig(tagList)}
                        value={values?.name}
                        onValueStateChange={onValueStateChange}
                    />
                </div>
                <div className="form-control offers-form-control modal-form">
                    <p className="main title mb-2">
                        <Translation text={`modal.createMarina.inputs.location`} />
                    </p>
                    <div className="location-input-wrapper">
                        <LocationInput
                            updatedCoords={
                                (coords: any) => console.log(coords)
                                // setValue((prevState: any) => ({...prevState, location: coords}))
                            }
                            createdCoords={(coords: any) => setValue((prevState: any) => ({...prevState, location: coords}))}
                            locationItem={SelectedItemType.MARINA}
                            predefinedLocation={null}
                        />
                    </div>
                </div>
            </div>
            <WizardFormNavigationButtons
                submitAllowed={!isDisabled}
                currentStepId={InitWizardStep.CREATE_FIRST_MARINA}
                handleBack={goBackToInitWizard}
                handleNext={createFirstMarinaAction}
            />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        tagList: tagListSelector(state),
    }),
    {
        createFirstMarina,
        fetchMarinaTags,
        changeCurrentStep,
    }
)(WizardInitCreateFirstMarina);
