import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function getReservationMonthlyCountAPI(authToken: string | null, params?: any[]): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    let basicRestQueryParams: RestQueryParams = new RestQueryParams();
    if (params && params.length > 0) {
        params.forEach((param: any) => {
            basicRestQueryParams = basicRestQueryParams.add(param.path, param.val);
        });
    }

    return marineHubAPI.get(`api/reservations/monthly_count`, basicRestQueryParams, headers);
}
