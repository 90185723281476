import React from 'react';
import classnames from 'classnames';
import {User} from 'react-feather';
import ChatListItemContent from './ChatListItemContent';
import styles from './styles.module.scss';
import {ChatMessage, ChatType} from '../../../../WithSoccetChatConnection/chat.types';
import {ChatContact} from '@solvee/reactjs-websocket-text-chat';
import {Avatar, AvatarColor} from 'marine-panel-common-web';

interface IChatListItemProps {
    readonly message: ChatMessage;
    readonly authorType: ChatType;
    readonly prevMessageAuthorType: ChatType | null;
    readonly errorModuleHandle: (errorMessage: string) => void;
    readonly chatContact: ChatContact | undefined;
    readonly authToken: string;
    readonly avatarUrl?: string;
}

class ChatListItem extends React.Component<IChatListItemProps> {
    private menuElt: any;

    constructor(props: IChatListItemProps) {
        super(props);
        this.menuElt = React.createRef();
    }

    render() {
        const message = this.props.message,
            sameAuthorOfPrevMessage = this.props.prevMessageAuthorType === this.props.authorType;

        return (
            <React.Fragment>
                <div
                    className={classnames(
                        'chat',
                        {
                            'chat-left': this.props.authorType === ChatType.RESPONSE,
                        },
                        {[styles[`chat-left`]]: this.props.authorType === ChatType.RESPONSE}
                    )}>
                    {!sameAuthorOfPrevMessage && (
                        <div className={`chat-avatar ${styles.avatar}`}>
                            <Avatar
                                imgWidth={36}
                                imgHeight={36}
                                tag={'div'}
                                color={AvatarColor.PRIMARY}
                                className="box-shadow-1 cursor-pointer"
                                img={
                                    this.props.authorType === ChatType.RESPONSE
                                        ? this.props.chatContact?.avatarUrl
                                            ? this.props.chatContact.avatarUrl
                                            : undefined
                                        : this.props.avatarUrl
                                }
                                icon={<User />}
                            />
                        </div>
                    )}
                    <div className={`chat-body ${styles.chatBody}`}>
                        <div className={`chat-content ${styles.chatContent}`}>
                            <ChatListItemContent
                                authToken={this.props.authToken}
                                errorModuleHandle={this.props.errorModuleHandle}
                                message={message}
                                contentType={this.props.message.messageType}
                                messageType={this.props.authorType}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ChatListItem;
