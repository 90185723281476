import React from 'react';
import styles from './styles.module.scss';
import {ChatMessage, ChatType, MessageContentType} from '../../../../../WithSoccetChatConnection/chat.types';
import {Loader, LoaderType, ReactstrapColors, Translation} from 'marine-panel-common-web';

interface IChatListItemMenuProps {
    readonly authToken: string;
    readonly contentType: MessageContentType;
    readonly errorModuleHandle: (errorMessage: string) => void;
    readonly message: ChatMessage;
    readonly messageType?: ChatType;
}

class ChatListItemContent extends React.Component<IChatListItemMenuProps> {
    render() {
        const messageType = this.props.message.messageType;
        return (
            <React.Fragment>
                {messageType === MessageContentType.IMAGE && (
                    <img src={this.props.message.messageContent} alt="" className={styles.chatImage} />
                )}
                {messageType === MessageContentType.TEXT && (
                    <div
                        className={`${this.props.messageType === ChatType.RESPONSE ? styles.responseMessage : styles.chatMessage}
                            ${this.props.messageType === ChatType.MESSAGE ? styles.message : ''}`}>
                        {this.props.message.messageContent}
                    </div>
                )}
                {messageType === MessageContentType.ERROR && (
                    <div
                        className={`${this.props.messageType === ChatType.RESPONSE ? styles.responseMessage : styles.chatMessage}
                            ${this.props.messageType === ChatType.MESSAGE ? styles.message : ''}`}>
                        <Translation text={'chat.errorMessage'} />
                    </div>
                )}
                {messageType === MessageContentType.PLACEHOLDER && (
                    <div className={styles.loaderWrapper}>
                        <Loader
                            color={this.props.messageType === ChatType.RESPONSE ? ReactstrapColors.PRIMARY : ReactstrapColors.LIGHT}
                            transparent={true}
                            showLoader={true}
                            type={LoaderType.Local}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default ChatListItemContent;
