import {marineHubAPI, RestQueryParams, BerthPricingStrategyInput} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function updatePricingStrategyAPI(
    authToken: string | null,
    actionPayload: BerthPricingStrategyInput,
    pricingStrategyId: string
): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return marineHubAPI.put(`api/berth_pricing_strategies/${pricingStrategyId}`, actionPayload, new RestQueryParams(), headers);
}
