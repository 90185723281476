import React, {Component} from 'react';
import {AccountImageHost, Form, FormControlChangeType, IFormConfig, Translation, IconSize} from 'marine-panel-common-web';
import {personalInformationFormConfig} from './personalInformationFormConfig';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {Button} from 'reactstrap';
import {ArrowLeft, Save} from 'react-feather';

interface IPersonalInformationProps {
    stepper: any;
}

interface IPersonalInformationState {
    value: any;
    formConfig: IFormConfig | null;
    isLoading: boolean;
}

class PersonalInformation extends Component<IPersonalInformationProps, IPersonalInformationState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IPersonalInformationProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: personalInformationFormConfig(),
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap(() => this.setState({isLoading: true})),
                    debounceTime(500),
                    tap((data: any) => this.changePersonalData(data.value))
                )
                .subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className="wizard-content">
                <div className="personal-information-section">
                    <div className="header">
                        <div>
                            <p className="title">Personal Information</p>
                            <p className="sub-title">Enter your information</p>
                        </div>
                    </div>

                    <div className="image-upload-host">
                        {/*MD: wymagany props - dodałem mockowy userRole={UserRole.OWNER} */}
                        <AccountImageHost />
                    </div>
                </div>

                <div>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'profileForm'}
                        />
                    )}
                </div>

                <div className="wizard-actions">
                    <Button color="secondary" className="btn-prev previous-step-button" outline disabled>
                        <ArrowLeft size={IconSize.ProfileStepper} className="align-middle me-sm-25 me-0" />
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.previous" />
                        </span>
                    </Button>
                    <Button color="primary" className="btn-next next-step-button" onClick={() => this.props.stepper.next()}>
                        <Save size={IconSize.ProfileStepper} className="align-middle ms-sm-25 me-25" />
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.save" />
                        </span>
                    </Button>
                </div>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private changePersonalData = (data: {[key: string]: any}) => {
        this.setState({value: data, isLoading: false});
    };
}

export default PersonalInformation;
