const en = {
    app: {
        title: 'Marine Panel',
        exception: {
            payment_account: {
                payment_vendor_account: {
                    stripe: {
                        onboarding_not_completed: 'Onboarding process is not completed',
                    },
                },
            },
        },
    },
    accessibilityMenu: {
        title: 'Accessibility',
        contrastMode: {
            title: 'High contrast',
            toggleButton: 'Toggle contrast mode',
        },
        fontSize: {
            title: 'Font Size',
            increase: 'Increase Font Size',
            decrease: 'Decrease Font Size',
        },
    },
    administration: {
        title: 'Administration',
        marinas: {
            title: 'Marinas',
            table: {
                counts: {
                    gallery: 'Gallery: ',
                    berthsNumber: 'Berths number: ',
                },
                headers: {
                    project: 'Project',
                    city: 'City',
                    salary: 'Salary',
                    publication: 'Publication',
                },
            },
            buttons: {
                create: '+ Create Marina',
                edit: 'Edit Marina',
                delete: 'Delete Marina',
            },
        },
        pricingStrategies: {
            title: 'Pricing Strategies',
            table: {
                headers: {
                    name: 'Name',
                    type: 'Type',
                    maxSizes: 'Max Sizes',
                    currentPrice: 'Current Price',
                    actions: 'Actions',
                },
            },
            buttons: {
                create: 'Create Strategy',
                edit: 'Edit Strategy',
                delete: 'Delete Strategy',
            },
        },
        extras: {
            title: 'Extras',
            description: 'Add extra service that can be purchased at your Marina',
            table: {
                headers: {
                    name: 'Name',
                    currentPrice: 'Current Price',
                    actions: 'Actions',
                },
            },
            buttons: {
                create: 'Create Extras',
                edit: 'Edit Extras',
                delete: 'Delete Extras',
            },
        },
        internalTags: {
            title: 'Internal Tags',
            table: {
                headers: {
                    name: 'Name',
                    actions: 'Actions',
                },
            },
            buttons: {
                create: 'Create Tag',
                edit: 'Edit Tag',
                delete: 'Delete Tag',
            },
        },
    },
    account: {
        changeAccountDataSuccess: 'Data has been successfully changed.',
        changeLocaleSuccess: 'Language has been successfully changed.',
        changePasswordSuccess: 'Password has been successfully changed.',
        imageAddSuccess: 'Image has been successfully added.',
    },
    berths: {
        title: 'Berths',
        name: 'Name',
        marina: 'Marina',
        search: 'Search berth',
        emptyMarina: 'Does not belong to any marinas',
        status: 'Status',
        appBooking: 'App Booking',
        currentPrice: 'Current Price',
        tags: 'tags',
        noBerths: 'There are no berths added',
        berthDetails: {
            berthGallery: {
                title: 'Photo',
                sectionTitleCoverPhoto: 'Cover photo',
                coverSubtitle: 'First impression for your Berth',
                coverPlaceholder: 'Add cover photo',
                mediaTitle: 'All photos',
                mediaSubtitle: 'Drag and drop your media to change the order',
            },
            berthsDetails: {
                title: 'Berth basics',
                sectionName: {
                    berthName: 'Berth name',
                    berthDescription: 'Berth description',
                    marine: 'Marine',
                    maxYachtSizes: 'Max yacht sizes',
                    status: 'Status',
                    location: 'Location',
                },
                subtitles: {
                    sizes: 'Set max yacht sizes fitting your Berth',
                    nameForm: "Give name to your Berth. it will be used in Skipper's mobile application.",
                    descriptionForm: 'Write few words about your Berth. Encourage Skippers to reserve it.',
                    marineForm: 'Find your Marine. Attach your Berth to it, so it is visible on Marine view',
                    activeStatus: 'Enable or disable Berth from usage.',
                    bookingStatus: "Allow booking in Skipper's mobile application.",
                },
                form: {
                    length: 'Length',
                    width: 'Width',
                    draft: 'Draft',
                    height: 'Height',
                    noMarine: "Couldn't find your Marine?",
                },
            },
            berthTags: {
                title: 'Tags',
                tagSubtitle: 'Write few fword about this tag. Explain the details.',
                noTags: 'There are no tags added',
            },
        },
    },
    reports: {
        title: 'Reports',
    },
    reservations: {
        title: 'Transaction History',
        netProfit: 'Net Profit:',
        exportCsv: 'Export CSV',
        berthName: 'Berth name',
        yachtNo: 'Yacht registration no',
        reservationId: 'Reservation ID',
        price: 'Price',
        priceSystemFee: 'Price system fee',
        amountNetprofit: 'Amount netprofit',
        lastFour: 'Last four',
        form: {
            paid: 'Paid',
            arrived: 'Arrived',
            date: 'Date',
            berth: 'Berth',
            selectDate: 'Select Date',
            selectBerth: 'Select Berth',
            skipperName: 'Skipper Name',
            skipperEmail: 'Skipper Email',
            skipperPhone: 'Skipper Phone',
            yachtName: 'Yacht Name',
            yacht: 'Yacht',
            yachtType: 'Yacht Type',
            yachtTypePlaceholder: 'Select Yacht Type',
            price: 'Price',
            note: 'Note',
        },
        transactionHistoryForm: {
            labels: {
                date: 'From / To',
                status: 'Status',
            },
            placeholders: {
                date: 'Select date',
                status: 'Select status',
            },
        },
        noTransactionData: 'There is no transaction data for selected period',
    },
    mainMap: {
        marinaSelector: {
            title: 'Marina selector',
            placeholder: 'Select marina',
            allMarinas: 'All marinas',
        },
        reservation: {
            title: 'Reservation',
            user: 'Reserved by: {{userName}}',
            startDate: 'from: {{date}}',
            endDate: 'to: {{date}}',
            view: {
                berth: 'Berth',
                berthName: 'Berth name',
                berthPricingStrategyPrice: 'Berth pricing strategy price',
                skipperName: 'Skipper name',
                skipperEmail: 'Skipper email',
                skipperMobile: 'Skipper phone number',
                vesselName: 'Vessel name',
                reservationMain: 'Main reservation',
                reservationId: 'ID:',
                reservationPrice: 'Price:',
                reservationDateRange: 'Date range:',
                note: 'Note:',
                noReservations: 'No reservations',
                noSkipper: 'No name',
            },
            deleteModal: {
                title: 'Delete reservation',
                warning: 'This operation is irreversible.',
                confirmMessage: 'Confirm to delete reservation ',
            },
            create: {
                title: 'New reservation',
            },
            vesselPosition: {
                title: 'Latest position',
                estimatedArrival: 'Estimated arrival time',
                currentPosition: 'Current yacht position',
                noEstimatedArrivalData: "There is no data on yacht's estimated arrival.",
                noEstimatedPositionData: "There is no data on yacht's position.",
            },
            actionMessages: {
                deleteSuccess: 'Reservation deleted successfully',
                deleteError: 'Reservation delete error',
                createSuccess: 'Reservation created successfully',
                createError: 'Reservation create error',
                reservationPaidStatusChanged: 'Reservation paid status changed',
                reservationArrivedStatusChanged: 'Reservation arrived status changed',
                reservationUpdated: 'Reservation updated successfully',
            },
        },
        legend: {
            title: 'Legend',
            disabled: 'Disabled',
            available: 'Available',
            reserved: 'Reserved',
            selected: 'Selected',
        },
        berthInfoCard: {
            title: 'Berth info - {{berthName}}',
            name: 'Name: {{name}}',
            description: 'Description: {{description}}',
            disabled: 'Berth is out of order',
            reserved: 'Berth is reserved',
            owner: 'Owner: {{owner}}',
            editBerthButton: 'Edit berth',
            closeCardButton: 'Close',
            maxSizes: {
                title: 'Max sizes:',
                length: 'Length: {{size}} ft',
                width: 'Width: {{size}} ft',
                height: 'Height: {{size}} ft',
                draft: 'Draft: {{size}} ft',
            },
            status: {
                active: 'Berth active',
                inactive: 'Berth inactive',
                app_booking: 'App booking',
                available: 'Available',
                temp_reserved: 'Temp. Reserved',
                reserved: 'Reserved',
                paid: 'Paid',
                yacht_arrived: 'Yacht arrived',
            },
            tags: 'Tags:',
            note: 'Note:',
        },
    },
    dashboard: {
        bestMatch: {
            title: 'Best match',
            table: {
                headers: {
                    project: 'Project',
                    city: 'City',
                    salary: 'Salary',
                    publication: 'Publication',
                },
            },
        },
        applications: {
            title: 'Your applications',
            table: {
                headers: {
                    project: 'Project',
                    city: 'City',
                    salary: 'Salary',
                    status: 'Status',
                },
            },
        },
        profile: {
            title: 'Profile',
        },
        calendar: {
            title: 'Calendar',
            eventCalendar: {
                title: 'Events',
                noEvents: 'There are no events',
            },
        },
    },
    auth: {
        formControls: {
            placeholder: 'Type',
            email: 'Email',
            password: 'Password',
            newPassword: 'New password',
            confirmPassword: 'Confirm Password',
            repeatPassword: 'Repeat password',
            placeholderConfirmPassword: 'Confirm Password',
            registrationTermsAgreement: ``,
            phone: 'Phone',
            firstName: 'First name',
            lastName: 'Last name',
            registrationTerms: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr.`,
            placeholders: {
                email: 'Email',
                password: 'Password',
                repeatPassword: 'Repeat password',
            },
        },
        header: {
            skipper: 'Skipper',
            operator: 'Marina operator',
            admin: 'Admin',
            boatsman: 'Boatsman',
        },
        register: {
            title: 'Register',
            haveAccount: 'Already have an account? ',
            logIn: 'Back to login',
        },
        confirmRegistration: {
            title: 'Confirm Registration',
            subtitle: 'In order to complete registration on the platform click button bellow',
        },
        login: {
            title: 'Login',
            subtitle: '',
            haveAccount: 'New on our platform? ',
            register: 'Create an account.',
            resetPassword: 'Forgot password?',
            loginWith: {
                title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zaloguj się przez Facebook',
                google: 'Zaloguj się przez Google',
            },
        },
        resetPassword: {
            title: 'Reset Password',
            subtitle: 'Please provide an email used during registration process. Further instructions will be sent to your email.',
        },
        newPassword: {
            title: 'Change Password',
            subtitle: 'Your new password must be different from previously used passwords',
        },
        alert: {
            loginSuccess: 'Login successful.',
            loginFailed: 'Incorrect login or password. Please try again.',
            logout: 'You have been logged out.',
            registrationSuccess:
                'Registration data was successfully processed. Confirm registration by clicking on the link provided in the email.',
            confirmRegistrationSuccess: 'Your email has been confirmed. You can now login to the application.',
            sendResetPasswordMailSuccess: 'Please check your email for password reset link.',
            confirmResetPasswordSuccess: 'Your password has been changed. You can log in to the panel.',
            authenticationError: 'You must be logged in to access the data.',
        },
        authFooter: {
            srTitle: 'Footer containing contact information and social links',
            addressColumn: {
                title: 'MarineHub',
                address1: 'ul. Zygmunta Krasińskiego 2',
                address2: '20-709 Lublin',
                nip: 'NIP: 9662131616',
            },
            copyright: 'MarineHub {{date}} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                facebook: 'Link to Facebooku profile',
                instagram: 'Link to Instagram profile',
                linkedin: 'Link to Linkedin profile',
            },
        },
    },
    settings: {
        title: 'Settings',
        changePassword: {
            title: 'Profile Details',
            form: {
                labels: {
                    currentPassword: 'Current password',
                    newPassword: 'New password',
                    confirmPassword: 'Confirm new password',
                },
                placeholders: {
                    currentPassword: 'Enter current password',
                    newPassword: 'Enter new password',
                    confirmPassword: 'Confirm your new password',
                },
            },
            passwordRequirements: {
                title: 'Password requirements:',
                length: 'Minimum 8 characters long - the more, the better',
                uppercase: 'At least one lowercase character',
                characters: 'At least one number, symbol, or whitespace character',
            },
        },
        information: {
            title: 'Information',
            form: {
                placeholders: {
                    firstName: 'First name',
                    lastName: 'Last name',
                },
                labels: {
                    firstName: 'First name',
                    lastName: 'Last name',
                },
            },
        },
        general: {
            title: 'General',
        },
        payments: {
            title: 'Payments',
            stripe: {
                title: 'Stripe details',
                description: 'In order to login to your Stripe account, use the button below:',
                stripeLogin: 'Login to Stripe',
            },
            onboarding: {
                title: 'Stripe Onboarding',
                onboardingCompleted: 'Onboarding is completed',
                onboardingNotCompleted: 'Onboarding process is not completed',
                description: 'If you would like to receive payments, you need to connect your account with our payment tool - Stripe.',
                instruction:
                    'Click the button below and next you will be redirected to the Stripe onboarding process. There you will be able to provide us with your bank account information and your company details.',
                completeOnboarding: 'Complete Onboarding',
            },
            subscription: {
                title: 'Stripe subscription',
                description: 'In order to manage your subscription, use the button below:',
                customerPortalLogin: 'Login to Customer Portal',
            },
            cardDetails: {
                title: 'Card details',
                description: 'There is no card attached. Click the button below to add a card',
                addPaymentMethod: 'Add payment method',
            },
        },
        billingData: {
            title: 'Billing data',
            form: {
                labels: {
                    companyName: 'Company Name',
                    billingEmail: 'Billing Email',
                    taxId: 'Tax ID',
                    mobile: 'Mobile',
                    country: 'Country',
                    billingAddress: 'Billing Address',
                    city: 'City',
                    state: 'State',
                    zipCode: 'Zip Code',
                },
                placeholders: {
                    companyName: 'Enter company name',
                    billingEmail: 'Enter billing email',
                    taxId: 'Enter tax ID',
                    mobile: 'Enter mobile number',
                    country: 'Select country',
                    billingAddress: 'Enter billing address',
                    city: 'Enter city',
                    state: 'Enter state',
                    zipCode: 'Enter zip code',
                },
            },
            alerts: {
                updated: 'Billing information was successfully updated',
            },
        },
    },
    paymentCard: {
        form: {
            labels: {
                cardholderName: 'Cardholder name',
                cardNumber: 'Card number',
            },
            placeholders: {
                cardholderName: 'Cardholder name',
            },
        },
    },

    modal: {
        createBerth: {
            title: 'Create New Berth',
            inputs: {
                nameBerth: 'Name your Berth',
                nameBerthSub: 'It will be displayed to the Skippers',
                setMarina: 'Set your Marina',
                setMarinaSub: 'Find your Berth Marina. It will setup the map below. You can also do it later.',
            },
            locationInput: {
                title: 'Draw your Berth',
                subtitle: 'Click on the map to place your Berth',
                description: `Adjust Berth shape by dragging berth's corners`,
            },
        },
        createExtra: {
            title: 'Create New Extra',
            inputs: {
                name: 'Name your Extra',
                price: 'Price',
                description: 'Description',
            },
            placeholders: {
                name: 'Name',
                price: 'Price',
                description: 'Description',
            },
        },
        changeExtra: {
            title: 'Change extra details',
        },
        createMarina: {
            title: 'Create New Marina',
            description: 'New Marina requires admin aproval.',
            inputs: {
                name: 'Name your Marina',
                phone: 'Contact phone',
                email: 'Contact email',
                description: 'Description',
                attractions: 'Attractions near Marina',
                restaurants: 'Food & Restaurants close to Marina',
                location: 'Location',
                tags: 'Tags',
            },
            placeholders: {
                marinaName: 'Marina name',
                description: 'Describe your Marina',
                attractions: 'Describe attractions near Marina',
                restaurants: 'Describe food & restaurants close to Marina',
                phone: 'Marina contact phone',
                email: 'Marina email',
                tags: 'Select marina tags',
            },
        },
        createPricingStrategy: {
            title: 'Create Pricing Strategy',
            inputs: {
                active: 'Active',
                inactive: 'Inactive',
                usePricingStrategyTemplate: 'Use existing Pricing Strategy Template',
                usePricingStrategyTemplateDescription: 'It will be copied for this Berth. You can edit it below.',
                usePricingStrategyTemplatePlaceholder: 'Price Strategy name',
                name: 'Pricing Strategy name',
                nameDescription: 'It will be shown to the Skippers',
                yachtTypes: 'Yacht Type',
                yachtTypesDescription: `Select boats' types proper for your Pricing Strategy`,
                price: 'Base Price',
                priceDescription: 'Set gross default price for every day. It is a final price for the skipper. See summary on the right.',
                priceSummary: 'Price Summary',
                priceSummaryDescription: 'The price is divided into:',
                noData: 'No price to summarize',
                berthNetPrice: 'Your Berth net price:',
                commission: 'Our commission:',
                pricesAndAvailability: 'Prices and Availability',
                pricesAndAvailabilityDescription: 'Select a date range and add prices and availability.',
                pricingStrategyTemplate: 'Pricing Strategy Template',
                pricingStategyCalendar: 'Pricing Strategy Calendar',
                saveAsTemplate: 'Save this Pricing Strategy as a Template',
                saveAsTemplateDescription: 'You will be able to use it for other Berths',
                setAvailability: 'Set Availability',
                setAvailabilityDescription: 'Decide if berth is available during selected days.',
                setDateRangePrice: 'Set Date Range Price',
                setDateRangePriceDescription: 'It is a final price for the skipper. See summary on the right.',
                dateRangePrice: 'Set Date Range Price',
                dateRangePriceDescription: 'It is a final price for the skipper. See summary on the right.',
            },
            buttons: {
                setPrice: 'Set Price',
                saveAsTemplate: 'Save for future use',
            },
        },
        editPricingStrategy: {
            title: 'Edit Pricing Strategy',
            inputs: {
                active: 'Disable Pricing Strategy',
                inactive: 'Enable Pricing Strategy',
                usePricingStrategyTemplate: 'Use existing Pricing Strategy Template',
                usePricingStrategyTemplateDescription: 'It will be copied for this Berth. You can edit it below.',
                usePricingStrategyTemplatePlaceholder: 'Price Strategy name',
                name: 'Pricing Strategy name',
                nameDescription: 'It will be shown to the Skippers',
                yachtTypes: 'Yacht Type',
                yachtTypesDescription: `Select boats' types proper for your Pricing Strategy`,
                price: 'Base Price',
                priceDescription: 'Set gross default price for every day. It is a final price for the skipper. See summary on the right.',
                priceSummary: 'Price Summary',
                priceSummaryDescription: 'The price is divided into:',
                noData: 'No price to summarize',
                berthNetPrice: 'Your Berth net price:',
                commission: 'Our commission:',
                pricesAndAvailability: 'Prices and Availability',
                pricesAndAvailabilityDescription: 'Select a date range and add prices and availability.',
                pricingStrategyTemplate: 'Pricing Strategy Template',
                pricingStategyCalendar: 'Pricing Strategy Calendar',
                saveAsTemplate: 'Save this Pricing Strategy as a Template',
                saveAsTemplateDescription: 'You will be able to use it for other Berths',
                setAvailability: 'Set Availability',
                setAvailabilityDescription: 'Decide if berth is available during selected days.',
                setDateRangePrice: 'Set Date Range Price',
                setDateRangePriceDescription: 'It is a final price for the skipper. See summary on the right.',
                dateRangePrice: 'Set Date Range Price',
                dateRangePriceDescription: 'It is a final price for the skipper. See summary on the right.',
            },
            buttons: {
                setPrice: 'Set Price',
                saveAsTemplate: 'Save for future use',
            },
        },
        deletePhoto: {
            title: 'Delete photo',
            description: 'Are you sure you want to delete this photo?',
        },
        changePhoto: {
            title: 'Which photo do you choose?',
            noPhotosUploaded: 'There are no photos uploaded yet',
        },
        addPhoto: {
            title: 'Choose photo for upload',
        },
        setLocation: {
            titleBerth: 'Berth location',
            titleMarina: 'Marina location',
            titleLocation: 'Berth location',
            subtitleBerth: 'Draw your Berth',
            subtitleMarina: 'Mark your Marina',
            instructionsBerth: 'Click on the map to place your Berth',
            instructionsMarina: 'Click on the map to place your Marina',
            description: `Adjust Berth shape by dragging berth's corners`,
        },
    },
    services: {
        title: 'My services',
        table: {
            headers: {
                invoice: 'Invoice',
                service: 'Service',
                info: 'Info',
                serviceDescription: 'Service description',
                cost: 'Cost',
                status: 'Status',
                actions: 'Actions',
            },
            tooltips: {
                buyAgain: 'Buy again',
                details: 'Details',
            },
        },
    },
    support: {
        title: 'Contact us',
        form: {
            labels: {
                subject: 'Subject',
                message: 'Message',
            },
            placeholders: {
                subject: 'Type your subject',
                message: 'Type your message',
            },
        },
        privacyPolicyTitle: 'Privacy Policy',
        privacyPolicyMessage: 'Privacy Policy',
        readLink: 'READ',
        formSent: 'Your message has been successfully sent.',
    },
    inbox: {
        title: 'Chat',
        noRightInfo: 'Only admin accounts can use chat. To use it log into such account.',
        chat: {
            contacts: 'Chats/Contacts',
        },
    },
    videoChat: {
        consultationStart: 'Meeting starts at ',
        consultationTimeZone: 'Time zone: Eastern European Time',
        wailTillConsultationStarts: 'Wait for the admin to let you in.',
        noAccess: 'You cannot join the consultation.',
        contactAdmin: 'Check URL or contact admin.',
        consultationEnded: 'Meeting is already over',
        rescheduleConsultation: 'In case you have missed the meeting, please contact the admin.',
        settings: {
            title: 'Settings',
            select: 'Select',
            selectCamera: 'Choose a camera',
            selectSpeaker: 'Choose a speaker',
            selectMicrophone: 'Choose a microphone',
            testMicrophone: 'Say something to test a microphone',
            poor: 'poor',
            excellent: 'excellent',
            saveChanges: 'Save preferences',
        },
        chat: {
            placeholder: 'Write a message',
            noMessages: 'You do not have any messages',
            status: {
                online: 'Online',
            },
        },
    },
    chat: {
        noUserListProvided: 'There are no contacts added.',
        fileIsReadyToSent: 'Files are ready to be sent:',
        fileSize: 'File size:',
        noNodeConnection: 'Server connection failed. Chat is currently unavailable.',
        noConnectionReasons: 'Possible Reasons:',
        already_online: 'Someone else is using this account.',
        expired_token: 'Token has expired. You have to relog your account',
        not_authorized: 'You have no authorisation rights to use chat. Contact with admin',
        no_token: 'No token provided. Relog your account',
        'websocket error': 'Unexpected connection error. Try again later',
        connectionFailed: 'Server connection failed. Chat is currently unavailable.',
        errorMessage: 'The message was not sent',
        placeholder: 'Write a message',
        noMessages: 'You do not have any messages',
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        upload: 'Upload',
        more: 'More',
        delete: 'Delete',
        login: 'Login',
        edit: 'Edit',
        saveChanges: 'Save changes',
        discard: 'Discard',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Read less',
        readMore: 'Read more',
        close: 'Close',
        join: 'Join',
        showAll: 'Show all',
        next: 'Next',
        previous: 'Previous',
        submit: 'Submit',
        change: 'Change',
        remove: 'Remove',
        save: 'Save',
        resetPassword: 'Reset password',
        setNewPassword: 'Set new password',
        register: 'Register',
        backToLogin: 'Back to login',
        createBerth: '+ Create Berth',
        createReservation: '+ Reservation',
        updateReservation: 'Update Reservation',
        cancel: 'Cancel',
        create: 'Create',
        addPhoto: 'Add photo',
        changePhoto: 'Change photo',
        showBerthInfo: 'Show Berth info',
        showLastPosition: 'Show last position',
        confirm: 'Confirm',
        update: 'Update',
        createMarine: '+ Create Marine',
        srDecrementButton: 'Decrement value button',
        srIncrementButton: 'Increment value button',
        changeDescription: 'Change description',
        send: 'Send',
        stopAdministration: 'Stop administration',
        back: 'Back',
        closeWizard: 'Close wizard',
    },
    dateRangePicker: {
        config: {
            previousMonth: 'Previous month',
            lastWeek: 'Last week',
            today: 'Today',
            currentMonth: 'Current month',
        },
    },
    formValidation: {
        errors: {
            minLength: 'The value is too short',
            isEmailValid: 'Email is not valid',
            isRequired: 'Field is required',
            isCheckedTrue: 'Field should be selected',
            isTrue: 'Field should be selected',
            isPasswordSame: 'Passwords cannot be different',
            isStartDateValid: 'Please select future date',
            isStartDateGreaterOrEvenValid: 'Please select future date',
            isEndDateValid: 'Data powrotu musi być po dacie wylotu',
            isNumber: 'Provided value should be a number',
        },
    },
    footer: {
        copyright: `MarineHunter {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony',
    },
    menuItems: {
        berths: 'Berths',
        reservations: 'Reservations',
        inbox: 'Inbox',
        reports: 'Reports',
        administration: 'Administration',
        support: 'Support',
        settings: 'Settings',
        logout: 'Logout',
    },
    editMenuItems: {
        berthDetails: 'Berth details',
        photoGallery: 'Photos',
        berthTags: 'Tags',
        berthPricingAvailability: 'Pricing and Availability',
        berthPolicies: 'Policies and rules',
        notes: 'Note',
        marinaDetails: 'Marina details',
        marinaTags: 'Tags',
        marinaPricingAvailability: 'Pricing and Availability',
        marinaExtras: 'Extras',
        marinaPolicies: 'Policies and rules',
        badges: {
            active: 'Active',
            inactive: 'Inactive',
            app_booking: 'App booking',
        },
        submenu: {
            berth_basics: 'Berth basics',
            berth_sizes: 'Berth sizes',
            berth_status: 'Status',
            berth_location: 'Location',
            marina_basics: 'Marina basics',
            marina_sizes: 'Sizes',
            marina_contact: 'Marina contact & address',
            marina_status: 'Status',
            marina_location: 'Location',
            cover_photo: 'Cover photo',
            all_photos: 'All photos',
        },
        deleteBerthPopup: {
            title: 'Confirm',
            message: 'Are you sure you want to delete this Berth?',
            irreversibleInfo: 'This action is irreversible.',
        },
        deletePricingStrategyPopup: {
            title: 'Confirm',
            message: 'Are you sure you want to delete this PricingStrategy?',
            irreversibleInfo: 'This action is irreversible.',
        },
        sections: {
            berth_basics: {
                title: 'Berth basics',
                formControls: {
                    berth_name: 'Berth name',
                    berth_name_description: "Give name to your Berth. it will be used in Skipper's mobile application.",
                    berth_description: 'Berth description',
                    berth_description_description: 'Write few words about your Berth. Encourage Skippers to reserve it.',
                    berth_marina: 'Marina',
                    berth_marina_description: 'Find your Marina. Attach your Berth to it, so it is visible on Marine view',
                    berth_location: 'Draw your Berth',
                    berth_location_description: 'Click on the map to place your Berth.',
                },
                noMarinaConnected: 'Does not belong to any marinas',
                cantFindMarina: `Couldn't find your Marina?`,
            },
            berth_description: {
                title: 'Berth description',
                description: 'Write few words about your Berth. Encourage Skippers to reserve it.',
            },
            berth_status: {
                title: 'Status',
                formControls: {
                    activeStatus: 'Enable or disable Berth from usage.',
                    bookingStatus: "Allow booking in Skipper's mobile application.",
                },
            },
            berth_sizes: {
                title: 'Max yacht sizes',
                max_yacht_sizes: 'Max yacht sizes',
                description: 'Set max yacht sizes fitting this berth',
                formControls: {
                    maxLength: 'Length',
                    maxWidth: 'Width',
                    maxDraft: 'Draft',
                    maxHeight: 'Height',
                },
            },
            berth_location: {
                title: 'Location',
                description: 'Set location of your Berth',
            },
            berth_policies: {
                title: 'Policies and rules',
                formControls: {
                    checkInTime: 'Check-in time',
                    checkOutTime: 'Check-out time',
                    additionalRules: 'Additional rules',
                },
                noPolicySet: 'That policy is not set',
                updatePolicySuccess: 'Rules and policies for selected berth have been successfully updated.',
            },
            berth_pricing: {
                title: 'Pricing and Availability',
                noPricingStrategies: 'No pricing strategies',
                addPricingStrategy: 'Add pricing strategy',
            },
            berth_note: {
                title: `Berth's Note`,
                description: 'No one will see that but you',
            },
            marina_basics: {
                title: 'Marina basics',
                formControls: {
                    marina_name: 'Marina name',
                    marina_name_description: 'Give name to your Marina.',
                    marina_description: 'Marina description',
                    marina_description_description: 'Write few words about your Marina.',
                    marina_attractions: 'Nearby attractions',
                    marina_attractions_description: 'Write few words about nearby attractions.',
                    marina_restaurants: 'Foods & restaurants',
                    marina_restaurants_description: 'Write few words about nearby restaurants.',
                },
            },
            marina_contact: {
                title: 'Marina contact & address',
                formControls: {
                    marina_email: 'Email',
                    marina_email_description: 'Email address of your Marina.',
                    marina_phone: 'Phone',
                    marina_phone_description: 'Phone number of your Marina.',
                    marina_address: 'Address',
                    marina_address_description: 'Address of your Marina.',
                },
            },
            marina_status: {
                title: 'Status',
                formControls: {
                    activeStatus: 'Enable or disable Marina from usage.',
                },
            },
            marina_location: {
                title: 'Location',
            },
            marina_gallery: {
                title: 'Photo',
                sectionTitleCoverPhoto: 'Cover photo',
                coverSubtitle: 'First impression for your Marina',
                mediaTitle: 'All photos',
                mediaSubtitle: 'Drag and drop your media to change the order',
            },
            marina_policies: {
                title: 'Policies and rules',
                formControls: {
                    gateOpensAt: "Marina's gate opens at:",
                    gateClosesAt: "Marina's gate closes at:",
                    additionalRules: 'Additional rules:',
                },
                noPolicySet: 'That policy is not set',
                updatePolicySuccess: 'Rules and policies for selected marina have been successfully updated.',
            },
            marina_extras: {
                title: 'Extras',
                description: 'Add extra service that can be purchased at your Marina',
                table: {},
            },
        },
        marina: {
            stopAdministration: 'Stop administration',
            stopAdministrationDescription: 'Stop administration of this marina',
        },
        alerts: {
            archiveBerthSuccess: 'Berth removed successfully',
            berthNameChangeSuccess: 'Berth name changed successfully',
            berthNameChangeError: 'Berth name change failed',
            berthNoteChangeSuccess: 'Berth note changed successfully',
            berthNoteChangeError: 'Berth note change failed',
            berthDescriptionChangeSuccess: 'Berth description changed successfully',
            berthDescriptionChangeError: 'Berth description change failed',
            berthStatusChangeSuccess: 'Berth status changed successfully',
            berthStatusChangeError: 'Berth status change failed',
            berthMaxSizesChangeSuccess: 'Berth max sizes changed successfully',
            berthMaxSizesChangeError: 'Berth max sizes change failed',
            berthLocationChangeSuccess: 'Berth location changed successfully',
            marinaLocationChangeSuccess: 'Marina location changed successfully',
            marinaUnassignSuccess: 'Operator unassigned from marina successfully',
            berthLocationChangeError: 'Berth location change failed',
            marinaLocationChangeError: 'Marina location change failed',
            berthTagStatusChangeSuccess: 'Berth tag status changed successfully',
            berthAllowedAppBooking: 'Booking in App enabled',
            berthDisallowedAppBooking: 'Booking in App disabled',
            berthActivated: 'Berth activated',
            berthDeactivated: 'Berth deactivated',
            berthCreateSuccess: 'Berth created successfully',
            marinaCreateSuccess: 'Marina created successfully',
            removeTagSuccess: 'Berth tag removed successfully',
            removeTagError: 'Berth tag remove failed',
            addTagSuccess: 'Berth tag added successfully',
            addTagError: 'Berth tag add failed',
            tagDescriptionUpdateSuccess: 'Berth tag description updated successfully',
            tagDescriptionUpdateError: 'Berth tag description update failed',
            marinaRemoveTagSuccess: 'Marina tag removed successfully',
            marinaRemoveTagError: 'Marina tag remove failed',
            marinaAddTagSuccess: 'Marina tag added successfully',
            marinaAddTagError: 'Marina tag add failed',
            marinaTagDescriptionUpdateSuccess: 'Marina tag description updated successfully',
            marinaExtraCreateSuccess: 'Marina extra service has been added',
            marinaExtraRemoveSuccess: 'Marina extra service has been removed',
            marinaTagDescriptionUpdateError: 'Marina tag description update failed',
            marinaPhoneEditSuccess: 'Marina phone number has been updated',
            marinaEmailEditSuccess: 'Marina email has been updated',
            pricingStrategyCreateSuccess: 'Pricing strategy created successfully',
            pricingStrategyUpdateSuccess: 'Pricing strategy updated successfully',
            pricingStrategyDefinitionCreateSuccess: 'Pricing strategy definition created successfully',
            marinaExtraEditSuccess: 'Marina extra service have been updated',
            marinaExtraEditOrderSuccess: 'Order of marina extra services has been changed',
            pricingStrategyActiveStatusChangeSuccess: 'Pricing strategy active status changed successfully',
            pricingStrategyDeleteSuccess: 'Pricing strategy deleted successfully',
            marinaNameChangeSuccess: 'Marina name has been successfully updated',
            marinaRestaurantsEditSuccess: 'Food and restaurants details have been updated',
            marinaAttractionsEditSuccess: 'Attractions details have been updated',
            marinaAddressEditSuccess: 'Address details have been updated',
        },
    },
    initWizard: {
        base_information: {
            title: 'Setup your first berth',
            description: 'Start operating on MarineHub with ease worthy of a true maritime master!',
            secondary_title: 'Describe your mooring location',
            secondary_description: 'Provide basic information such as where the facility is located and the maximum yacht size',
            additional_title: 'Provide information about the Berth',
            additional_description: 'Provide information about the Berth. In case the marina is not listed, click on the Create button.',
            formName: 'Basic information',
            closeButtonMessage: 'Quit berth wizard',
        },
        create_first_marina: {
            title: 'Create Marina',
            closeButtonMessage: 'Quit marina wizard',
            formName: 'Marina',
        },
        photos: {
            title: 'Setup your photos',
            description: 'Start operating on MarineHub with ease worthy of a true maritime master!',
            secondary_title: 'Raise your chances to rent out your berth',
            secondary_description: 'Tell us about your marina by selecting tags that describe your marina and adding photos.',
            additional_title: 'Add photos',
            additional_description: `Increase the attractiveness of your location and attract more customers by adding up to 6 photos. Don't worry if you don't have all of them yet, you can always add them later.`,
            closeButtonMessage: 'Quit photos wizard',
            formName: 'Photos',
        },
        tags: {
            title: 'Tags setup',
            closeButtonMessage: 'Quit tags wizard',
            formName: 'Tags',
        },
        pricing_strategy: {
            title: 'Setup your Pricing strategy',
            description: 'Start operating on MarineHub with ease worthy of a true maritime master!',
            secondary_title: 'Great! Berth data has been filled',
            secondary_description: `Now let's focus on more detailed information. You can change the data in the berth edition.`,
            additional_title: 'Setup your Pricing strategy',
            additional_description:
                'Enter a preliminary pricing strategy or proceed further and set the rules. Then you can enjoy the created offer.',
            closeButtonMessage: 'Quit pricing wizard',
            pricingStrategyCreated: 'Pricing strategy already created. You will be able to edit it after you finish this wizard.',
            formName: 'Pricing strategy',
            formControls: {
                template: 'Template',
                template_description: 'It will be copied for this berth. You can edit it below.',
                name: 'Pricing Strategy Name',
                name_description: 'It will be shown to the skippers.',
                yacht_types: 'Yacht types',
                yacht_types_description: 'Select boat types proper for your Pricing Strategy.',
            },
        },
        policies_and_rules: {
            title: 'Policies and rules setup',
            closeButtonMessage: 'Quit rules wizard',
            formName: 'Policies and rules',
        },
        well_done: {
            title: 'Berth "{{name}}" has been created',
            formName: 'The berth has been successfully created and is now available in our offer for skippers.',
            closeButtonMessage: 'Close wizard',
        },
    },
    marinas: {
        marinaDetails: {
            marinaTags: {
                title: 'Marina tags',
                noTags: 'Selected Marina does not have any tags yet.',
            },
        },
    },
    alerts: {
        warning: 'Warning!',
        error: 'Error!',
        success: 'Success!',
        info: 'Info',
        authError: 'The username or password you entered is incorrect. Please try again',
        noAccessError: 'You are not authorized to login to panel.',
        baseError: 'Something went wrong. Please try again later.',
        loginError: 'An error occurred while logging in',
        tryAgain: 'Please try again',
        tokenRefreshed: 'Your session has been renewed',
        unknown: 'Unknown error occurred.',
    },
    popovers: {
        title: {
            deleteInfo: 'Delete Info',
            confirm: 'Confirm',
        },
        alertSection: {
            noDelete: "You can't delete it.",
            irreversible: 'This operation is irreversible',
        },
        futureReservation: 'This Berth has future Reservation',
        confirmDelete: 'Are you sure you wish to delete this Berth?',
    },
    statusBadge: {
        badge: {
            active: 'Berth active',
            inactive: 'Berth inactive',
            app_booking: 'App booking',
            available: 'Available',
            temp_reserved: 'Temp. Reserved',
            reserved: 'Reserved',
            paid: 'Paid',
            yacht_arrived: 'Yacht arrived',
        },
        reservation: {
            draft: 'Draft',
            active: 'Reservation Active',
            inactive: 'Reservation Inactive',
            cancelled: 'Reservation Cancelled',
            finished: 'Reservation Finished',
            app_booking: 'App booking',
            yacht_arrived: 'Yacht arrived',
            paid: 'Paid',
            is_manual: 'Reservation created manually',
        },
    },
    country: {
        AF: 'Afghanistan',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas (the)',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia (Plurinational State of)',
        BQ: 'Bonaire, Sint Eustatius and Saba',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory (the)',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        KY: 'Cayman Islands (the)',
        CF: 'Central African Republic (the)',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands (the)',
        CO: 'Colombia',
        KM: 'Comoros (the)',
        CD: 'Congo (the Democratic Republic of the)',
        CG: 'Congo (the)',
        CK: 'Cook Islands (the)',
        CR: 'Costa Rica',
        HR: 'Croatia',
        CU: 'Cuba',
        CW: 'Curaçao',
        CY: 'Cyprus',
        CZ: 'Czechia',
        CI: "Côte d'Ivoire",
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic (the)',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        SZ: 'Eswatini',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (the) [Malvinas]',
        FO: 'Faroe Islands (the)',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories (the)',
        GA: 'Gabon',
        GM: 'Gambia (the)',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and McDonald Islands',
        VA: 'Holy See (the)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran (Islamic Republic of)',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: "Korea (the Democratic People's Republic of)",
        KR: 'Korea (the Republic of)',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: "Lao People's Democratic Republic (the)",
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands (the)',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia (Federated States of)',
        MD: 'Moldova (the Republic of)',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands (the)',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger (the)',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands (the)',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestine, State of',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines (the)',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        MK: 'Republic of North Macedonia',
        RO: 'Romania',
        RU: 'Russian Federation (the)',
        RW: 'Rwanda',
        RE: 'Réunion',
        BL: 'Saint Barthélemy',
        SH: 'Saint Helena, Ascension and Tristan da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin (French part)',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten (Dutch part)',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and the South Sandwich Islands',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan (the)',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan (Province of China)',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands (the)',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates (the)',
        GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
        UM: 'United States Minor Outlying Islands (the)',
        US: 'United States of America (the)',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela (Bolivarian Republic of)',
        VN: 'Viet Nam',
        VG: 'Virgin Islands (British)',
        VI: 'Virgin Islands (U.S.)',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
        AX: 'Åland Islands',
    },
};

export default en;
