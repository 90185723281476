import {
    Berth,
    BerthPricingStrategyBroad,
    fetchVesselTypes,
    Form,
    FormControlChangeType,
    IModelDictionaryDatum,
    Loader,
    Translation,
    vesselTypesSelector,
} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../store/reducers';
import {fetchBerthDetails} from '../../../../../../store/reducers/berthEditWizardSlice';
import {changeIsModalOpen, ModalNames, setCurrentlyEditedItemId, setModalName} from '../../../../../../store/reducers/modalSlice';
import {currentlyEditedBerth, isBerthEditWizardLoadingSelector} from '../../../../../../store/selectors/berthEditWizardSelectors';
import EditFormControl from '../../Common/EditFormControl';
import {pricingDefaultFormConfig} from './pricingDefaultFormConfig';

interface IBerthPricingAndAvailabilityProps {
    readonly berth: Berth | null;
    readonly yachtTypes: IModelDictionaryDatum[];
    readonly isLoading: boolean;
    readonly fetchBerthDetails: typeof fetchBerthDetails;
    readonly fetchVesselTypes: typeof fetchVesselTypes;
    readonly setCurrentlyEditedItemId: typeof setCurrentlyEditedItemId;
    readonly setModalName: typeof setModalName;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly isInitWizard?: boolean;
}
const BerthPricingAndAvailability: FC<IBerthPricingAndAvailabilityProps> = ({
    berth,
    fetchBerthDetails,
    yachtTypes,
    isLoading,
    fetchVesselTypes,
    setCurrentlyEditedItemId,
    setModalName,
    changeIsModalOpen,
}) => {
    const params = useParams(),
        berthId = params.id ? params.id : berth?.id,
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [],
        dispatch = useDispatch();
    const [currentlyEditedItem, setCurrentlyEditedItem] = useState<any>(null);
    useEffect(() => {
        dispatch(fetchBerthDetails(berthId));
        dispatch(fetchVesselTypes());
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    useEffect(() => {
        if (berth) {
            setCurrentlyEditedItem(berth);
        }
    }, [berth]);

    const [editedItemId, setEditedItemId] = useState<string | null>(null);
    const [value, setValue] = useState<any>(null);
    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue({
                            description: data?.value?.berthYachtTypes,
                        });
                    }
                })
        );
    }, []);
    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }
    if (!berthId) return null;

    const onConfirmAction = (berthId: string, pricingStrategy: string, value: string | null) => {
        // TODO PT : dispatch action - create action in store.
        // dispatch(action);
        setEditedItemId(null);
    };

    const renderPoliciesList = (pricingStrategies: BerthPricingStrategyBroad[]) => {
        if (pricingStrategies.length === 0) return null;
        const openEditModalAction = (pricingStrategyId: string) => {
            setCurrentlyEditedItemId(pricingStrategyId);
            setModalName(ModalNames.EDIT_PRICING_STRATEGY);
            changeIsModalOpen(true);
        };
        return pricingStrategies.map((pricingStrategy) => {
            const vesselTypes = pricingStrategy.yachtTypes;
            const currentTypesInString = vesselTypes.map((vesselType) => vesselType.name).join(', ');
            return (
                <EditFormControl
                    key={pricingStrategy.id}
                    editedItemId={editedItemId}
                    setEditedItemId={() => openEditModalAction(pricingStrategy.id)}
                    currentItemId={pricingStrategy.id}
                    titleTranslationKey={pricingStrategy.name}
                    onConfirm={() => null}
                    editedChildren={
                        <Form
                            controlName={'berthYachtTypes'}
                            config={pricingDefaultFormConfig(vesselTypes, yachtTypes)}
                            value={value}
                            onValueStateChange={onValueStateChange}
                        />
                    }
                    displayChildren={<p className="secondary-title description">{currentTypesInString}</p>}
                />
            );
        });
    };

    const openAddPricingStrategyModal = () => {
        dispatch(setModalName(ModalNames.CREATE_PRICING_STRATEGY));
        dispatch(changeIsModalOpen(true));
    };
    return (
        <div className="details-edit-section-wrapper">
            <div className="details-edit-section col-xl-6">
                <div className="details-header">
                    <h2 className="title main mb-1">
                        <Translation text="editMenuItems.sections.berth_pricing.title" />
                    </h2>
                    <button className="sm-btn btn btn-create" onClick={() => openAddPricingStrategyModal()}>
                        <Translation text="editMenuItems.sections.berth_pricing.addPricingStrategy" />
                    </button>
                </div>
                <div>
                    {berth && berth.pricingStrategies.length ? (
                        renderPoliciesList(berth.pricingStrategies)
                    ) : (
                        <span>
                            <Translation text="editMenuItems.sections.berth_pricing.noPricingStrategies" />
                        </span>
                    )}
                </div>
                <Loader showLoader={isLoading} />
            </div>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        berth: currentlyEditedBerth(state),
        yachtTypes: vesselTypesSelector(state),
        isLoading: isBerthEditWizardLoadingSelector(state),
    }),
    {fetchBerthDetails, fetchVesselTypes, setCurrentlyEditedItemId, setModalName, changeIsModalOpen}
)(BerthPricingAndAvailability);
