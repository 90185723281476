import {RootState} from '../reducers';
import {IRouteFindState} from '../reducers/routeFindSlice';
import {createSelector} from '@reduxjs/toolkit';
import {AsyncWaterApiStatus} from 'marine-panel-common-web';

export const selectRouteFind = (state: RootState): IRouteFindState => {
    return state.routeFind;
};
export const findRouteErrorSelector = createSelector([selectRouteFind], (state: IRouteFindState) => state.error);
export const calculatedRoutesSelector = createSelector([selectRouteFind], (state: IRouteFindState) => state.calculatedRoutes);
export const findRouteIsLoadingSelector = createSelector([selectRouteFind], (state: IRouteFindState) => state.isLoading);
export const routesQueueSelector = createSelector([selectRouteFind], (state: IRouteFindState) => state.routesQueue);
export const isCalculatingRouteSelector = createSelector(
    [selectRouteFind],
    (state: IRouteFindState): boolean =>
        !!Object.keys(state.calculatedRoutes).find((key) => state.calculatedRoutes[key].status === AsyncWaterApiStatus.DRAFT)
);
