import {Form, FormControlChangeType, Translation, Marina, MoneyInput} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../../store/reducers';
import {createExtra} from '../../../../../../../store/reducers/marinaEditWizardSlice';
import {currentlyEditedMarina, isMarinaEditWizardLoadingSelector} from '../../../../../../../store/selectors/marinaEditWizardSelectors';
import {createExtraFormConfig} from './createExtraFormConfig';
import styles from './style.module.scss';
interface ICreateExtraModalProps {
    readonly marina: Marina | null;
    readonly createExtra: typeof createExtra;
    toggleModalOpen: () => void;
}

interface ICreateExtraModalValues {
    name: string;
    price: number | null;
    description: string | null;
    order: number;
}

export interface IExtraPayload {
    name: string;
    price: MoneyInput | null;
    description: string | null;
    order: number | null;
}

const CreateExtraModal: FC<ICreateExtraModalProps> = ({createExtra, marina, toggleModalOpen}) => {
    const [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<ICreateExtraModalValues>({
            name: '',
            price: null,
            description: null,
            order: 0,
        }),
        subscriptions: Subscription[] = [];

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState: ICreateExtraModalValues) => {
                            const formValues = {
                                name: data.value && data.value.name ? data.value.name : prevState?.name,
                                price: data.value && data.value.price ? data.value.price : prevState?.price,
                                description: data.value && data.value.description ? data.value.description : prevState?.description,
                                order: prevState?.order,
                            };
                            return formValues;
                        });
                    }
                })
        );

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    // useEffect(() => {
    //     const subscriptions: Subscription[] = [];
    //     dispatch(fetchMarinaTags());
    // }, [dispatch]);

    function submitExtra() {
        let payload: IExtraPayload | null = null;
        let price: MoneyInput | null = null;
        if (values.price) {
            price = {
                amount: (values.price * 100).toString(),
                currency: 'EUR',
            };
        }

        payload = {
            name: values.name,
            price: price,
            description: values.description,
            order: values.order,
        };

        if (marina && payload) {
            createExtra({marinaId: marina.id, payload: payload});
        }
    }

    return (
        <div className="modal-wrapper create-marina">
            <div className={styles.headerContainer}>
                <ModalHeader>
                    <p className="main title">
                        <Translation text="modal.createExtra.title" />
                    </p>
                </ModalHeader>
                <button className={`${styles.closeBtn} toggle-modal-button`} onClick={() => toggleModalOpen()}></button>
            </div>
            <ModalBody className={styles.container}>
                <div className={styles.content}>
                    <div className="form-control offers-form-control modal-form">
                        <Form
                            controlName={'marinaNameForm'}
                            config={createExtraFormConfig()}
                            value={values}
                            onValueStateChange={onValueStateChange}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="sm-btn btn-black-white borderless" onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className="sm-btn btn-black-white" onClick={() => submitExtra()}>
                    <Translation text="buttons.create" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        marina: currentlyEditedMarina(state),
        isLoading: isMarinaEditWizardLoadingSelector(state),
    }),
    {
        createExtra,
    }
)(CreateExtraModal);
