import {Marina, Form, FormControlChangeType, IMultiselectOption, Translation} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../../store/reducers';
import {
    changeMarinaDescription,
    changeMarinaName,
    changeIsMarinaEditWizardLoading,
    resetToInitialMarinaEditWizardState,
    changeCurrentlyEditedControlId,
    changeMarinaAttractions,
    changeMarinaRestaurants,
} from '../../../../../../../store/reducers/marinaEditWizardSlice';
import {
    currentlyEditedControlId,
    isActionSuccessfulSelector,
    isMarinaEditWizardInitializedSelector,
} from '../../../../../../../store/selectors/marinaEditWizardSelectors';
import {changeIsModalOpen} from '../../../../../../../store/reducers/modalSlice';
import EditFormControl from '../../../Common/EditFormControl';
import {marinaDescriptionFormConfig} from './marinaDescriptionFormConfig';
import {marinaNameFormConfig} from './marinaNameFormConfig';
import {marinaAttractionsFormConfig} from './marinaAttractionsFormConfig';
import {marinaRestaurantsFormConfig} from './marinaRestaurantsFormConfig';

interface IMarinaBasicsProps {
    readonly marina: Marina | null;
    readonly isMarinaEditWizardInitialized: boolean;
    readonly resetToInitialMarinaEditWizardState: typeof resetToInitialMarinaEditWizardState;
    readonly isActionSuccessful: boolean;
    readonly currentlyEditedControlId: string | null;
    readonly changeMarinaName: typeof changeMarinaName;
    readonly changeMarinaDescription: typeof changeMarinaDescription;
    readonly changeMarinaAttractions: typeof changeMarinaAttractions;
    readonly changeMarinaRestaurants: typeof changeMarinaRestaurants;
    readonly changeCurrentlyEditedControlId: typeof changeCurrentlyEditedControlId;
}

interface IMarinaBasicsValues {
    value: {
        name?: string | null;
        description?: string | null;
        restaurants?: string | null;
        attractions?: string | null;
    };
}

const MarinaBasics: FC<IMarinaBasicsProps> = ({marina, currentlyEditedControlId}) => {
    const params = useParams(),
        marinaId = params.id,
        dispatch = useDispatch(),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<IMarinaBasicsValues>();

    const subscriptions: Subscription[] = [];
    const editedItemId = currentlyEditedControlId;

    useEffect(() => {
        if (marina !== null) {
            setValue({
                value: {
                    name: marina ? marina.name : null,
                    description: marina ? marina.description : null,
                    restaurants: marina ? marina.restaurants : null,
                    attractions: marina ? marina.attractions : null,
                },
            });
        }
    }, [marina]);

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState) => {
                            const formValues = {
                                value: {
                                    name: data.value && data.value.name ? data.value.name : prevState?.value.name,
                                    description:
                                        data.value && data.value.description ? data.value.description : prevState?.value.description,
                                    restaurants:
                                        data.value && data.value.restaurants ? data.value.restaurants : prevState?.value.restaurants,
                                    attractions:
                                        data.value && data.value.attractions ? data.value.attractions : prevState?.value.attractions,
                                },
                            };
                            return formValues;
                        });
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [onValueStateChange$$]);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    const onConfirmAction = (action: any) => {
        dispatch(action);
        dispatch(changeCurrentlyEditedControlId(null));
    };
    return (
        <div id="marina_basics" className="details-edit-section col-xl-6" data-section="marina_basics">
            <h3 className="title main">
                <Translation text="editMenuItems.sections.marina_basics.title" />
            </h3>

            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'marina_name'}
                titleTranslationKey="editMenuItems.sections.marina_basics.formControls.marina_name"
                onConfirm={() => onConfirmAction(changeMarinaName(marinaId, values?.value?.name))}
                confirmButtonsDisabled={true}
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.marina_basics.formControls.marina_name_description" />
                        </p>
                        <Form
                            controlName={'marinaNameForm'}
                            config={marinaNameFormConfig(values?.value.name, () => dispatch(changeCurrentlyEditedControlId(null)))}
                            value={values?.value.name}
                            onValueStateChange={onValueStateChange}
                            submitForm={() => onConfirmAction(changeMarinaName(marinaId, values?.value.name))}
                        />
                    </>
                }
                displayChildren={<p className="secondary-title description">{marina?.name}</p>}
            />
            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'marina_description'}
                onConfirm={() => onConfirmAction(changeMarinaDescription(marinaId, values?.value.description))}
                titleTranslationKey="editMenuItems.sections.marina_basics.formControls.marina_description"
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.marina_basics.formControls.marina_description_description" />
                        </p>
                        <Form
                            controlName={'marinaDescriptionForm'}
                            config={marinaDescriptionFormConfig(values?.value.description)}
                            value={values?.value.description}
                            onValueStateChange={onValueStateChange}
                        />
                    </>
                }
                displayChildren={<p className="secondary-title description">{marina?.description}</p>}
            />
            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'marina_attractions'}
                onConfirm={() => onConfirmAction(changeMarinaAttractions(marinaId, values?.value.attractions))}
                titleTranslationKey="editMenuItems.sections.marina_basics.formControls.marina_attractions"
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.marina_basics.formControls.marina_attractions" />
                        </p>
                        <Form
                            controlName={'marinaAttractionsForm'}
                            config={marinaAttractionsFormConfig(values?.value.attractions)}
                            value={values?.value.attractions}
                            onValueStateChange={onValueStateChange}
                        />
                    </>
                }
                displayChildren={<p className="secondary-title description">{marina?.attractions}</p>}
            />
            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'marina_restaurants'}
                onConfirm={() => onConfirmAction(changeMarinaRestaurants(marinaId, values?.value.restaurants))}
                titleTranslationKey="editMenuItems.sections.marina_basics.formControls.marina_restaurants"
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.marina_basics.formControls.marina_restaurants" />
                        </p>
                        <Form
                            controlName={'marinaRestaurantsForm'}
                            config={marinaRestaurantsFormConfig(values?.value.restaurants)}
                            value={values?.value.restaurants}
                            onValueStateChange={onValueStateChange}
                        />
                    </>
                }
                displayChildren={<p className="secondary-title description">{marina?.restaurants}</p>}
            />
        </div>
    );
};
export default connect(
    (state: RootState) => ({
        isActionSuccessful: isActionSuccessfulSelector(state),
        isMarinaEditWizardInitialized: isMarinaEditWizardInitializedSelector(state),
        currentlyEditedControlId: currentlyEditedControlId(state),
    }),
    {
        resetToInitialMarinaEditWizardState,
        changeIsModalOpen,
        changeMarinaName,
        changeIsMarinaEditWizardLoading,
        changeMarinaDescription,
        changeMarinaAttractions,
        changeMarinaRestaurants,
        changeCurrentlyEditedControlId,
    }
)(MarinaBasics);
