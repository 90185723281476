import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IUnassignMarinaFromOperator} from '../../store/reducers/marinaEditWizardSlice';

export function unassignMarinaFromOperatorAPI(payload: IUnassignMarinaFromOperator, authToken: string): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(
        `api/marinas/${payload.marinaId}/unassign_operator`,
        {operatorId: payload.operatorId},
        new RestQueryParams(),
        headers
    );
}
