import {Translation, changeMisc} from 'marine-panel-common-web';
import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {changeCurrentStep, InitWizardStep} from '../../../../../store/reducers/berthInitWizardSlice';
import {initWizardSteps} from '../../WizardInitPanel/init-wizard-steps';

interface WizardFormNavigationButtonsProps {
    submitAllowed: boolean;
    currentStepId: InitWizardStep;
    handleNext?: () => void;
    handleBack?: () => void;
    visible?: boolean;
}

const WizardFormNavigationButtons: FC<WizardFormNavigationButtonsProps> = ({
    submitAllowed,
    currentStepId,
    handleNext,
    handleBack,
    visible,
}) => {
    const dispatch = useDispatch();
    const isFirstStep = () => {
        return currentStepId === initWizardSteps[0].step;
    };
    if (visible === false) return null;
    const currentStepIndex = initWizardSteps.findIndex((step) => step.step === currentStepId),
        previousStep = initWizardSteps[currentStepIndex - 1]?.step,
        nextStep = initWizardSteps[currentStepIndex + 1]?.step,
        goToNextStep = () => {
            if (nextStep) {
                dispatch(changeCurrentStep(nextStep as InitWizardStep));
            }
        },
        goToPreviousStep = () => {
            if (previousStep) {
                dispatch(changeCurrentStep(previousStep as InitWizardStep));
            }
        },
        changeInitWizardDisabled = () => {
            dispatch(changeMisc({misc: 'berthInitPassed'}));
        };

    const renderNavButtons = () => {
        if (currentStepId === InitWizardStep.WELL_DONE) {
            return (
                <button onClick={changeInitWizardDisabled} className="btn btn-primary">
                    <Translation text="buttons.closeWizard" />
                </button>
            );
        } else {
            return (
                <>
                    <button disabled={isFirstStep()} onClick={handleBack ? handleBack : goToPreviousStep} className="btn btn-black-white">
                        <Translation text="buttons.back" />
                    </button>
                    <button disabled={!submitAllowed} onClick={handleNext ? handleNext : goToNextStep} className="btn btn-primary">
                        <Translation text="buttons.next" />
                    </button>
                </>
            );
        }
    };
    return <div className="wizard-navigation-buttons">{renderNavButtons()}</div>;
};

export default WizardFormNavigationButtons;
