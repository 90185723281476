import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeReservation} from '../../store/reducers/reservationsSlice';

export function updateReservationAPI(authToken: string | null, reservationId?: string, payload?: IChangeReservation): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return marineHubAPI.put(`api/reservations/${reservationId}`, payload?.reservation, new RestQueryParams(), headers);
}
