import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IChangePricingStrategyActiveStatus {
    active: boolean;
}

export function changePricingStrategyActiveStatusAPI(
    berthPricingStrategyId: string | null | undefined,
    payload: IChangePricingStrategyActiveStatus | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(
        `api/berth_pricing_strategies/${berthPricingStrategyId}/change_active`,
        payload,
        new RestQueryParams(),
        headers
    );
}
