import {BerthStatus, Form, Reservation, ReservationStatus} from 'marine-panel-common-web';
import StatusBadge from '../../../../Shared/StatusBadge';
import {reservationStatusFromConfig} from './reservationStatusFromConfig';

interface ReservationStatusButtonsProps {
    readonly reservation: Reservation | null;
}
function ReservationStatusButtons(props: ReservationStatusButtonsProps) {
    if (!props.reservation) return null;
    return (
        <>
            <div>
                <div className="reservation-details">
                    <StatusBadge currentStatus={props.reservation.status} id={props.reservation.id} />
                    {props.reservation.arrivedAt && props.reservation.status !== ReservationStatus.YACHT_ARRIVED && (
                        <StatusBadge currentStatus={ReservationStatus.YACHT_ARRIVED} id={props.reservation.id} />
                    )}
                    {props.reservation.paidAt && props.reservation.status !== ReservationStatus.PAID && (
                        <StatusBadge currentStatus={BerthStatus.PAID} id={props.reservation.id} />
                    )}

                    {props.reservation.finishedAt && props.reservation.status !== ReservationStatus.FINISHED && (
                        <StatusBadge currentStatus={ReservationStatus.FINISHED} id={props.reservation.id} />
                    )}
                </div>
            </div>
            <div className="reservation-form-wrapper">
                <Form config={reservationStatusFromConfig()} controlName="reservationStatusForm" value={null} />
            </div>
        </>
    );
}

export default ReservationStatusButtons;
