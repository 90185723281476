import {Observable} from 'rxjs';
import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';

export function confirmMessageReadApi(messagesIds: string[], authToken?: string): Observable<null> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    const confirm = {ids: messagesIds};

    return marineHubAPI.post('api/messages/mark_chat_messages_as_read', confirm, new RestQueryParams(), headers);
}
