import {BerthStatus, Reservation, ReservationStatus} from 'marine-panel-common-web';
import StatusBadge from '../../../../../../Shared/StatusBadge';

interface ReservationItemBadgesProps {
    readonly reservation: Reservation | null;
}
function ReservationItemBadges(props: ReservationItemBadgesProps) {
    if (!props.reservation) return null;
    const statusArray = getReservationStatusArray(props.reservation);
    return (
        <div className="reservation-status-wrapper">
            {statusArray.map((reservationStatus: any, index: number) => (
                <StatusBadge key={`${reservationStatus}_${index}`} currentStatus={reservationStatus} id={index} />
            ))}
        </div>
    );
}
function getReservationStatusArray(reservation: Reservation) {
    const statusArray = [];
    if (reservation.status) {
        statusArray.push(reservation.status);
    }
    if (reservation.arrivedAt && reservation.status !== ReservationStatus.YACHT_ARRIVED) {
        statusArray.push(BerthStatus.YACHT_ARRIVED);
    }
    if (reservation.paidAt && reservation.status !== ReservationStatus.PAID) {
        statusArray.push(BerthStatus.PAID);
    }
    if (reservation.finishedAt && reservation.status !== ReservationStatus.FINISHED) {
        statusArray.push(ReservationStatus.FINISHED);
    }
    if (reservation.isManual && reservation.status !== ReservationStatus.IS_MANUAL) {
        statusArray.push(ReservationStatus.IS_MANUAL);
    }
    return statusArray;
}

export default ReservationItemBadges;
