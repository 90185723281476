import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {RootState} from '../reducers';
import {addAlert, AlertType, ApiError, authTokenSelector, handleApiError} from 'marine-panel-common-web';
import {PayloadAction} from '@reduxjs/toolkit';
import {changeIsSupportPageLoading, ISendSupportRequest, sendContactForm, setSupportPageError} from '../reducers/supportPageSlice';
import {addSupportRequestAPI} from '../../api/addSupportRequest';

const sendSupportRequestEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(sendContactForm.type),
        switchMap((action: PayloadAction<ISendSupportRequest>): any => {
            const authToken = authTokenSelector(state$.value),
                payload = {
                    subject: action.payload.subject,
                    description: action.payload.description,
                };

            return addSupportRequestAPI(authToken, payload).pipe(
                switchMap(() => {
                    const successMessage = 'support.formSent',
                        actions = successActions(addAlert({message: successMessage}));
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (changeSliceList: any): any[] => {
    return [changeSliceList, changeIsSupportPageLoading(false)];
};

const errorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), changeIsSupportPageLoading(false), setSupportPageError(errorObj.message)];
};

const supportPageEpic = combineEpics(sendSupportRequestEpic);

export default supportPageEpic;
