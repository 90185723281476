import {Berth, Switch, Translation} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {RootState} from '../../../../../../../store/reducers';
import {
    changeIsBerthActive,
    changeIsBerthEditWizardLoading,
    changeIsBookingEnabled,
} from '../../../../../../../store/reducers/berthEditWizardSlice';
import {isBerthEditWizardInitializedSelector} from '../../../../../../../store/selectors/berthEditWizardSelectors';

interface IBerthStatusProps {
    readonly berth: Berth | null;
    readonly isBerthEditWizardInitialized: boolean;
    readonly changeIsBerthActive: typeof changeIsBerthActive;
    readonly changeIsBookingEnabled: typeof changeIsBookingEnabled;
}

interface IBerthStatusValues {
    active: boolean;
    appBookingEnabled: boolean;
}

const BerthStatus: FC<IBerthStatusProps> = ({berth, isBerthEditWizardInitialized, changeIsBerthActive, changeIsBookingEnabled}) => {
    const params = useParams(),
        berthId = params.id;
    const [values, setValues] = useState<IBerthStatusValues>({
        active: false,
        appBookingEnabled: false,
    });

    function changeActive() {
        const newActive = !values.active;
        changeIsBerthActive(berthId, newActive);
    }
    function changeBookingStatus() {
        const newBookingStatus = !values.appBookingEnabled;
        changeIsBookingEnabled(berthId, newBookingStatus);
    }

    useEffect(() => {
        if (berth !== null) {
            setValues({
                active: berth.active ?? false,
                appBookingEnabled: berth.appBookingEnabled ?? false,
            });
        }
    }, [berth, isBerthEditWizardInitialized]);

    return (
        <section id="berth_status" className="details-edit-section col-xl-6" data-section="berth_status">
            <div className="details-edit-form-control">
                <div className="edit-form-control-body-wrapper status-switch">
                    <h3 className="title main">
                        <Translation text="editMenuItems.sections.berth_status.title" />
                    </h3>

                    <div className="d-flex justify-content-between">
                        <span className="secondary-title description">
                            <Translation text="berths.berthDetails.berthsDetails.subtitles.activeStatus" />
                        </span>
                        {berth && <Switch name="active" checked={berth.active} handleChange={changeActive} />}
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="secondary-title description">
                            <Translation text="berths.berthDetails.berthsDetails.subtitles.bookingStatus" />
                        </span>
                        {berth && <Switch name="appBookingEnabled" checked={berth.appBookingEnabled} handleChange={changeBookingStatus} />}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default connect(
    (state: RootState) => ({
        isBerthEditWizardInitialized: isBerthEditWizardInitializedSelector(state),
    }),
    {
        changeIsBerthEditWizardLoading,
        changeIsBerthActive,
        changeIsBookingEnabled,
    }
)(BerthStatus);
