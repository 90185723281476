import {Translation} from 'marine-panel-common-web';
import {useState} from 'react';
import {connect} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {changeBerthLocation, IChangeBerthLocationPayload} from '../../../../../../store/reducers/berthEditWizardSlice';
import {SelectedItemType} from '../../../../../../store/reducers/mapHostSlice';
import {changeMarinaLocation} from '../../../../../../store/reducers/marinaEditWizardSlice';
import {changeIsModalOpen, resetToInitialModalState} from '../../../../../../store/reducers/modalSlice';
import LocationInput from './LocationInput';
import styles from './style.module.scss';

interface ILocationModalProps {
    locationType: SelectedItemType;
    toggleModalOpen: () => void;
    readonly changeBerthLocation: typeof changeBerthLocation;
    readonly changeMarinaLocation: typeof changeMarinaLocation;
}

const SetLocationModal: React.FC<ILocationModalProps> = ({locationType, toggleModalOpen, changeBerthLocation, changeMarinaLocation}) => {
    const [locationPayload, setLocationPayload] = useState<IChangeBerthLocationPayload | null>(null);

    const isBerth = locationType === SelectedItemType.BERTH,
        modalTitleText = isBerth ? 'modal.setLocation.titleBerth' : 'modal.setLocation.titleMarina';
    const handleClick = () => {
        if (locationType === SelectedItemType.BERTH && locationPayload) {
            changeBerthLocation(locationPayload);
        } else if (locationType === SelectedItemType.MARINA && locationPayload) {
            changeMarinaLocation({
                marinaId: locationPayload.berthId,
                location: locationPayload.location,
            });
        }
    };
    return (
        <div className="modal-wrapper">
            <ModalHeader>
                <p className={styles.title}>
                    <Translation text={modalTitleText} />
                </p>
                <button className="btn-close" onClick={() => toggleModalOpen()}></button>
            </ModalHeader>
            <ModalBody className={styles.container}>
                <div className={styles.content}>
                    <div className="form-control offers-form-control modal-form">
                        <p className="title">
                            <Translation text={`modal.setLocation.${isBerth ? 'subtitleBerth' : 'subtitleMarina'}`} />
                        </p>
                        <p className="secondary-title">
                            <Translation text={`modal.setLocation.${isBerth ? 'instructionsBerth' : 'instructionsMarina'}`} />
                        </p>
                        {isBerth && (
                            <p className="secondary-title">
                                <Translation text="modal.setLocation.description" />
                            </p>
                        )}
                        <LocationInput
                            updatedCoords={(payload: IChangeBerthLocationPayload) => setLocationPayload(payload)}
                            createdCoords={(payload: IChangeBerthLocationPayload) => setLocationPayload(payload)}
                            locationItem={locationType}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className={`${styles.cancelBtn} close-modal-button`} onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className={`${styles.btn} action-button`} onClick={handleClick}>
                    <Translation text="buttons.update" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(() => ({}), {
    resetToInitialModalState,
    changeIsModalOpen,
    changeBerthLocation,
    changeMarinaLocation,
})(SetLocationModal);
