import {createSelector} from '@reduxjs/toolkit';
import {ISupportPageState} from '../reducers/supportPageSlice';
import {RootState} from '../reducers';

export const selectSupportPageSlice = (state: RootState): ISupportPageState => {
    return state.supportPage;
};

export const isSupportPageLoadingSelector = createSelector([selectSupportPageSlice], (state: ISupportPageState) => state.isLoading);

export const isSupportPageInitializedSelector = createSelector([selectSupportPageSlice], (state: ISupportPageState) => state.isInitialized);

export const supportPageErrorSelector = createSelector([selectSupportPageSlice], (state: ISupportPageState) => state.error);
