import {authTokenSelector, Berth, Marina, Translation} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Uploader} from 'rsuite';
import {of, Subscription} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {addPhotoAPI} from '../../../../api/addPhoto';
import {addBerthPhotoToGalleryAPI} from '../../../../api/berth/addPhotoToGallery';
import {RootState} from '../../../../store/reducers';
import {setBerthDetails, setIsActionSuccessful} from '../../../../store/reducers/berthEditWizardSlice';
import {changeIsModalOpen} from '../../../../store/reducers/modalSlice';
import {currentlyEditedBerth} from '../../../../store/selectors/berthEditWizardSelectors';
import styles from '../style.module.scss';
import {currentlyEditedMarina} from '../../../../store/selectors/marinaEditWizardSelectors';
import {addMarinaPhotoToGalleryAPI, ISetPhotoPayload} from '../../../../api/marina/addMarinaPhoto';
import {setMarinaDetails} from '../../../../store/reducers/marinaEditWizardSlice';

interface IAddPhotoModalProps {
    readonly berth?: Berth | null;
    readonly marina?: Marina | null;
    readonly setIsActionSuccessful: typeof setIsActionSuccessful;
    readonly setBerthDetails: typeof setBerthDetails;
    readonly setMarinaDetails: typeof setMarinaDetails;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly photoId?: string | null;
    readonly authToken: string;
    toggleModalOpen: () => void;
}

interface IImageHostState {
    image?: string | Blob | null;
    value?: any;
    isLoading?: boolean;
}

const AddPhotoModal: FC<IAddPhotoModalProps> = ({berth, marina, changeIsModalOpen, setIsActionSuccessful, authToken, toggleModalOpen}) => {
    const subscriptions: Subscription[] = [],
        dispatch = useDispatch();

    useEffect(() => {
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);
    const [uploadImg, setUploadImg] = useState<IImageHostState>({
            image: null,
            value: null,
            isLoading: false,
        }),
        [fileList, setFileList] = useState<File[]>([]);
    function addFile(file: any) {
        if (file.length > 0) {
            setFileList([file[file.length - 1]]);
        } else {
            setFileList([]);
        }
        return setUploadImg({
            value: file.slice(file.length - 1),
            image: file.length > 1 ? file[1].blobFile : file[0].blobFile,
        });
    }
    function removeFile() {
        setUploadImg({
            value: null,
            image: null,
            isLoading: false,
        });
        return toggleModalOpen();
    }
    function addImage() {
        const verificationFile = uploadImg.value,
            verificationFileTypeId = uploadImg.value;

        if (verificationFile && verificationFile.length && verificationFileTypeId && authToken) {
            const formData = new FormData();
            formData.append('file', verificationFile[0].blobFile);
            formData.append('typeId', verificationFileTypeId);

            setUploadImg({isLoading: true});
            subscriptions.push(
                addPhotoAPI(authToken, formData)
                    .pipe(
                        switchMap((resp: any) => {
                            changeIsModalOpen(false);
                            const photoId = {
                                id: resp.response.id,
                            };

                            if (marina) {
                                const payload: ISetPhotoPayload = {
                                    marinaId: marina?.id,
                                    id: resp.response.id,
                                };
                                return addMarinaPhotoToGalleryAPI(payload, authToken).pipe(
                                    switchMap((resp: any) => {
                                        const actions = [
                                            dispatch(setMarinaDetails(resp)),
                                            dispatch(setIsActionSuccessful(true)),
                                            setUploadImg({image: null, value: null}),
                                        ];
                                        return of(...actions);
                                    }),
                                    catchError((error) => {
                                        return of(...error);
                                    })
                                );
                            }
                            if (berth) {
                                return addBerthPhotoToGalleryAPI(berth?.id, photoId, authToken).pipe(
                                    switchMap((resp: any) => {
                                        const actions = [
                                            dispatch(setBerthDetails(resp)),
                                            dispatch(setIsActionSuccessful(true)),
                                            setUploadImg({image: null, value: null}),
                                        ];
                                        return of(...actions);
                                    }),
                                    catchError((error) => {
                                        return of(...error);
                                    })
                                );
                            }
                            return of();
                        })
                    )
                    .subscribe()
            );
        }
    }
    return (
        <div className="modal-wrapper">
            <ModalHeader toggle={() => toggleModalOpen()}>
                <p className={styles.title}>
                    <Translation text="modal.addPhoto.title" />
                </p>
            </ModalHeader>
            <ModalBody className={styles.container}>
                <div className={styles.content}>
                    <Uploader
                        fileList={fileList}
                        className={styles.uploader}
                        draggable
                        action={''}
                        removable={false}
                        multiple={true}
                        accept="image/png, image/jpeg"
                        listType="picture"
                        autoUpload={false}
                        shouldUpload={() => false}
                        onChange={addFile}></Uploader>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-underline btn-underline-primary close-modal-button" onClick={() => removeFile()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className="btn btn-create add-photo-button" onClick={() => addImage()}>
                    <Translation text="buttons.add" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        berth: currentlyEditedBerth(state),
        marina: currentlyEditedMarina(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeIsModalOpen,
        setIsActionSuccessful,
        setBerthDetails,
        setMarinaDetails,
    }
)(AddPhotoModal);
