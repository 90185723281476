import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {ISetPhotoPaylodad} from './setPhotoAsCover';

export function removeMarinaPhotoAPI(
    authToken: string | null,
    marinaId: string | null,
    photoIdPayload: ISetPhotoPaylodad
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${marinaId}/remove_photo`, photoIdPayload, new RestQueryParams(), headers);
}
