import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeAvailabilityStatus} from '../../store/reducers/reservationsSlice';

export interface IArrivedAtReservationPayload {
    arrivedAt: boolean;
}
export function changeReservationArrivalStatusAPI(
    authToken: string | null,
    reservationId?: string,
    payload?: IChangeAvailabilityStatus
): Observable<any> {
    let headers = undefined;
    const arrivedPayload: IArrivedAtReservationPayload = {
        arrivedAt: payload?.isAvailable || false,
    };
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return marineHubAPI.put(`api/reservations/${reservationId}/set_arrived_at`, arrivedPayload, new RestQueryParams(), headers);
}
