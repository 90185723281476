import {Money, Price, Translation} from 'marine-panel-common-web';
import {FC} from 'react';
import {transformValueToPayloadPrice, formatHighPrecisionPrice} from '../../../../../../../../service/helperFunctions';

interface IPriceSummaryDetailsProps {
    readonly values: any;
    readonly changedGrossPrice?: string;
}

const PriceSummaryDetails: FC<IPriceSummaryDetailsProps> = ({values, changedGrossPrice}) => {
    const basePrice = values.basePrice,
        basePriceGross = changedGrossPrice ? changedGrossPrice : values.basePriceGross,
        commission = values.commission;
    const areValuesPresent = basePrice && basePriceGross && commission;

    if (!areValuesPresent) {
        return (
            <p className="main title">
                <Translation text="modal.createPricingStrategy.inputs.noData" />
            </p>
        );
    }
    function calculatePrices(grossPrice: number, commission: number): {netPrice: Money; commissionPrice: Money} {
        const netPrice = Math.round((grossPrice * 100) / (1 + commission / 100)),
            commissionPrice = Math.round(grossPrice * 100 - netPrice),
            netPriceObject = transformValueToPayloadPrice(formatHighPrecisionPrice(netPrice)),
            commissionPriceObject = transformValueToPayloadPrice(formatHighPrecisionPrice(commissionPrice));

        return {
            netPrice: netPriceObject,
            commissionPrice: commissionPriceObject,
        };
    }

    return (
        <div className="price-summary-details item">
            <h4 className="main title">
                <Translation text="modal.createPricingStrategy.inputs.priceSummary" />
            </h4>
            <p className="secondary-title description small">
                <Translation text="modal.createPricingStrategy.inputs.priceSummaryDescription" />
            </p>
            <dl className="price-summary-list">
                <div className="summary-list-row">
                    <dt className="price-summary-item-name">
                        <Translation text="modal.createPricingStrategy.inputs.berthNetPrice" />
                    </dt>
                    <dd className="price-summary-item-value">
                        <Price price={calculatePrices(basePriceGross, commission).netPrice} />
                    </dd>
                </div>
                <div className="summary-list-row">
                    <dt className="price-summary-item-name">
                        <Translation text="modal.createPricingStrategy.inputs.commission" />
                    </dt>
                    <dd className="price-summary-item-value">
                        <Price price={calculatePrices(basePriceGross, commission).commissionPrice} />
                    </dd>
                </div>
            </dl>
            <span className="main title gross-price">
                <Price price={transformValueToPayloadPrice(basePriceGross)} />
            </span>
        </div>
    );
};

export default PriceSummaryDetails;
