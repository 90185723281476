import {PayloadAction} from '@reduxjs/toolkit';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of, Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {addAlert, AlertType, authTokenSelector} from 'marine-panel-common-web';
import {
    addTag,
    changeBerthTagsError,
    changeIsActionSuccessful,
    changeIsBerthTagsLoading,
    changeTagDescription,
    fetchBerthTags,
    IAddBerthTagPayload,
    IChangeTagDescription,
    removeTag,
    setBerthTags,
} from '../reducers/berthsTagsSlice';
import {getBerthTagsAPI} from '../../api/berth/getBerthTags';
import {addTagAPI} from '../../api/berth/addTag';
import {removeTagAPI} from '../../api/berth/removeTag';
import {changeTagDescriptionAPI} from '../../api/berth/changeTagDescription';

const removeTagEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return changeTag(
        action$,
        state$,
        removeTagAPI,
        removeTag,
        'editMenuItems.alerts.removeTagSuccess',
        'editMenuItems.alerts.removeTagError'
    );
};

const addTagEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return changeTag(action$, state$, addTagAPI, addTag, 'editMenuItems.alerts.addTagSuccess', 'editMenuItems.alerts.addTagError');
};
const changeTag = (
    action$: any,
    state$: StateObservable<any>,
    api: any,
    type: any,
    successTranslationKey: string,
    errorTranslationKey: string
) => {
    return action$.pipe(
        ofType(type.type),
        switchMap((action: PayloadAction<IAddBerthTagPayload>) => {
            const authToken = authTokenSelector(state$.value);
            return api(action.payload.berthId, authToken, action.payload.berthTagId).pipe(
                switchMap(() => {
                    const actions = successActions([
                        changeIsActionSuccessful(true),
                        addAlert({message: successTranslationKey ? successTranslationKey : '', type: AlertType.SUCCESS}),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeBerthTagsError(errorTranslationKey)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeBerthTagsError(errorTranslationKey)));
        })
    );
};
const changeTagDescriptionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeTagDescription.type),
        switchMap((action: PayloadAction<IChangeTagDescription>) => {
            const authToken = authTokenSelector(state$.value);
            return changeTagDescriptionAPI(action.payload.berthId, authToken, action.payload.tagPayload).pipe(
                switchMap(() => {
                    const actions = successActions([
                        changeIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.alerts.tagDescriptionUpdateSuccess', type: AlertType.SUCCESS}),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeBerthTagsError('editMenuItems.alerts.tagDescriptionUpdateError')));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeBerthTagsError('editMenuItems.alerts.tagDescriptionUpdateError')));
        })
    );
};
const fetchBerthTagsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchBerthTags.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);
            return getBerthTagsAPI(authToken).pipe(
                switchMap((resp): any => {
                    const actions = successActions([setBerthTags(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeBerthTagsError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeBerthTagsError(error)));
        })
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsBerthTagsLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

export const fetchListErrorActions = (error: any, setSliceError: any): any[] => {
    return [addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsBerthTagsLoading(false)];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const berthTagsEpic = combineEpics(fetchBerthTagsEpic, addTagEpic, removeTagEpic, changeTagDescriptionEpic);

export default berthTagsEpic;
