import {marineHubAPI, RestQueryParams, BerthPricingStrategyInput} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function createPricingStrategyAPI(authToken: string | null, actionPayload?: BerthPricingStrategyInput): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return marineHubAPI.post(`api/berth_pricing_strategies`, actionPayload, new RestQueryParams(), headers);
}
