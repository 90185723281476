import {deepCloneObject, Form, FormControlChangeType, IFormConfig, DateFormat} from 'marine-panel-common-web';
import moment from 'moment';
import React from 'react';
import {connect} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {RootState} from '../../../../../store/reducers';
import {
    applyReservationsFilters,
    changeReservationCountFilters,
    changeReservationsFilters,
    IReservationCalendarDay,
    IReservationCountFilters,
    IReservationFilters,
} from '../../../../../store/reducers/reservationsSlice';
import {
    reservationCountFiltersSelector,
    reservationCountSelector,
    reservationFiltersSelector,
} from '../../../../../store/selectors/reservationSelectors';
import {calendarFormConfig} from './calendarFormConfig';

interface IConnectedReservationCalendarProps {
    readonly reservationFilters: IReservationFilters | null;
    readonly applyReservationsFilters: typeof applyReservationsFilters;
    readonly changeReservationsFilters: typeof changeReservationsFilters;
    readonly changeReservationCountFilters: typeof changeReservationCountFilters;
    readonly reservationCountFilters: IReservationCountFilters | null;
    readonly reservationsCount: IReservationCalendarDay[];
}

interface IReservationCalendarState {
    formConfig: IFormConfig;
    value: any;
    currentMonth: number;
}

class ReservationCalendar extends React.Component<IConnectedReservationCalendarProps, IReservationCalendarState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: IConnectedReservationCalendarProps) {
        super(props);
        this.state = {
            formConfig: calendarFormConfig([]),
            value: null,
            currentMonth: new Date().getMonth(),
        };
    }

    componentDidMount() {
        if (this.props.reservationCountFilters !== null) {
            this.props.changeReservationCountFilters(this.props.reservationCountFilters);
        }
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    distinctUntilChanged(),
                    tap((data: any) => this.setState({value: data.value})),
                    tap((data: any) => {
                        const updatedDate = new Date(data.value.date.value).toDateString();
                        const updatedReservationFilters = deepCloneObject(this.props.reservationFilters);
                        if (updatedReservationFilters !== null) {
                            updatedReservationFilters.start_date = updatedDate;
                            updatedReservationFilters.end_date = updatedDate;
                        }
                        const currentMonth = this.state.currentMonth;
                        if (currentMonth !== new Date(updatedDate).getMonth()) {
                            const formattedDate = moment(updatedDate).format(DateFormat.ReservationDatepicker);
                            const updatedReservationCountFilters = deepCloneObject(this.props.reservationCountFilters);
                            if (updatedReservationCountFilters !== null) {
                                updatedReservationCountFilters.date = formattedDate;
                                this.props.changeReservationCountFilters(updatedReservationCountFilters);
                            }
                            this.setState({currentMonth: new Date(updatedDate).getMonth()});
                        }
                        if (updatedReservationFilters !== null) {
                            this.props.changeReservationsFilters(updatedReservationFilters);
                        }
                        this.props.applyReservationsFilters();
                    })
                )

                .subscribe()
        );
        this.setFormConfig([]);
        if (null !== this.props.reservationsCount) {
            this.setFormConfig(this.props.reservationsCount);
        }
    }

    componentDidUpdate(prevProps: Readonly<IConnectedReservationCalendarProps>) {
        if (this.props.reservationsCount !== prevProps.reservationsCount && this.props.reservationsCount) {
            this.setFormConfig(this.props.reservationsCount);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className="reservation-datepicker-wrapper">
                <Form
                    config={this.state.formConfig}
                    controlName={'calendarForm'}
                    onValueStateChange={this.onValueStateChange}
                    value={this.state.value}
                />
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = (reservationsCount?: any[]) => {
        const eventDates = this.getRecommendationOccurrence(reservationsCount);
        this.setState({
            formConfig: calendarFormConfig(eventDates),
        });
    };

    private getRecommendationOccurrence(data: any) {
        if (!data || !data.length) {
            return null;
        }

        const map = data.reduce((acc: any, e: any) => acc.set(e.date.split('T')[0].replace(/-0/g, '-'), e.count), new Map());
        return [...map.entries()];
    }
}

export default connect(
    (state: RootState) => ({
        reservationFilters: reservationFiltersSelector(state),
        reservationsCount: reservationCountSelector(state),
        reservationCountFilters: reservationCountFiltersSelector(state),
    }),
    {
        changeReservationsFilters,
        applyReservationsFilters,
        changeReservationCountFilters,
    }
)(ReservationCalendar);
