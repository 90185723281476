import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IChangeIsBerthActive {
    active: string | null;
}

export function changeIsBerthActiveAPI(id: string | null | undefined, payload: string | null, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/change_active`, payload, new RestQueryParams(), headers);
}
