import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface ISetPhotoPayload {
    marinaId: string | null;
    id: string | null;
}
export function addMarinaPhotoToGalleryAPI(payload: ISetPhotoPayload | null, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload?.marinaId}/add_photo`, {id: payload?.id}, new RestQueryParams(), headers);
}
