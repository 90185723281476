import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {ISetPhotoPaylodad} from './setPhotoAsCover';

export function removePhotoAPI(authToken: string | null, berthId: string | null, photoIdPayload: ISetPhotoPaylodad): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${berthId}/remove_photo`, photoIdPayload, new RestQueryParams(), headers);
}
