import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem, IconSize} from 'marine-panel-common-web';
import {Anchor, MapPin, MessageSquare, Tool, TrendingUp} from 'react-feather';

const menu = (): (ISideMenuGroup | ISideMenuItem | ISideMenuHeader)[] => [
    {
        id: 'reservations',
        title: 'menuItems.reservations',
        icon: <MapPin size={IconSize.MainMenuItems} />,
        navLink: '/panel/reservations',
        navLinkClassname: 'reservations-link',
    },
    {
        id: 'berths',
        title: 'menuItems.berths',
        icon: <Anchor size={IconSize.MainMenuItems} />,
        navLink: '/panel/berths',
        navLinkClassname: 'berths-link',
    },
    {
        id: 'inbox',
        title: 'menuItems.inbox',
        icon: <MessageSquare size={IconSize.MainMenuItems} />,
        navLink: '/panel/inbox',
        navLinkClassname: 'inbox-link',
    },
    {
        id: 'reports',
        title: 'menuItems.reports',
        icon: <TrendingUp size={IconSize.MainMenuItems} />,
        navLink: '/panel/reports',
        navLinkClassname: 'reports-link',
    },
    {
        id: 'administration',
        title: 'menuItems.administration',
        icon: <Tool size={IconSize.MainMenuItems} />,
        navLink: '/panel/administration/marina',
        navLinkClassname: 'administration-link',
    },
];
export default menu;
