import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface ITagPayload {
    berthTagId: string;
}

export function addTagAPI(id: string | null, authToken: string | null, payload: ITagPayload | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/add_tag`, payload, new RestQueryParams(), headers);
}
