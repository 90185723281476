import {Berth, IModelApiResponseViewObject, Pagination, Price, Translation, BerthDayPricingOutput} from 'marine-panel-common-web';
import {Link} from 'react-router-dom';
import {Badge, Table} from 'reactstrap';
import {changePagination} from '../../../store/reducers/berthsSlice';
import LazyImage from '../../Shared/LazyImage';

enum StatusAndBooking {
    ACTIVE = 'Active',
    DISABLED = 'Disabled',
    ON = 'On',
    OFF = 'Off',
}

type changePagination = typeof changePagination;

const BerthList = ({
    berths,
    metadata,
    changePagination,
}: {
    berths: Berth[] | null;
    metadata: IModelApiResponseViewObject | null;
    changePagination: changePagination;
}) => {
    const rows: any[] = [];
    function renderMatchesTableRows() {
        if (!berths || !Array.isArray(berths) || berths.length === 0) {
            rows.push(
                <tr key={`no-data`}>
                    <td colSpan={6}>
                        <p className="no-data-warning">
                            <Translation text="berths.noBerths" />
                        </p>
                    </td>
                </tr>
            );
            return rows;
        }

        berths.forEach((berth: Berth) => {
            const photoUrl = berth.cover?.fileUrls.original !== null ? berth.cover?.fileUrls.original : null;
            rows.push(
                <tr key={berth.id} className="table-item">
                    <td>
                        <Link className="link" to={`/panel/edit/berth/${berth.id}/photo-gallery`}>
                            {photoUrl ? (
                                <LazyImage src={photoUrl} alt="" className="lazy-image-wrapper" />
                            ) : (
                                <div className="thumbnail-base"></div>
                            )}
                        </Link>
                    </td>
                    <td>
                        <Link className="link" to={`/panel/edit/berth/${berth.id}/details`}>
                            {berth.name}
                        </Link>
                    </td>
                    <td>{berth.sector !== null ? berth.sector?.marina.name : <Translation text="berths.emptyMarina" />}</td>
                    <td>
                        {berth.active ? (
                            <Badge color="light-primary" className="status">
                                {StatusAndBooking.ACTIVE}
                            </Badge>
                        ) : (
                            <Badge color="light-danger" className="status">
                                {StatusAndBooking.DISABLED}
                            </Badge>
                        )}
                    </td>
                    <td>
                        {berth.appBookingEnabled ? (
                            <Badge color="" bg="" className="enabled status">
                                {StatusAndBooking.ON}
                            </Badge>
                        ) : (
                            <Badge color="light-secondary" className="status">
                                {StatusAndBooking.OFF}
                            </Badge>
                        )}
                    </td>
                    <td className="price-section">
                        {berth.prices.map((price: BerthDayPricingOutput, index) => {
                            return (
                                <p key={index}>
                                    <Price
                                        price={{
                                            amount: price.grossPrice.amount,
                                            currency: {name: price.grossPrice.currency.name},
                                        }}
                                    />
                                    <span className="strategy">{`( ${price.strategyName} )`}</span>
                                </p>
                            );
                        })}
                    </td>
                    <td>
                        <Link className="link" to={`/panel/edit/berth/${berth.id}/berth-tags`}>
                            {`( ${berth.tags.length} )`}
                        </Link>
                    </td>
                </tr>
            );
        });
        return rows;
    }
    return (
        <>
            <Table responsive striped className="berths-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <Translation text="berths.name" />
                        </th>
                        <th>
                            <Translation text="berths.marina" />
                        </th>
                        <th>
                            <Translation text="berths.status" />
                        </th>
                        <th>
                            <Translation text="berths.appBooking" />
                        </th>
                        <th>
                            <Translation text="berths.currentPrice" />
                        </th>
                        <th>
                            <Translation text="berths.tags" />
                        </th>
                    </tr>
                </thead>
                <tbody>{renderMatchesTableRows()}</tbody>
            </Table>
            <Pagination listMetadata={metadata} changePage={changePagination} />
        </>
    );
};

export default BerthList;
