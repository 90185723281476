import React, {useState, useEffect} from 'react';

interface LazyImageProps {
    src: string;
    alt: string;
    className?: string;
}

const LazyImage: React.FC<LazyImageProps> = ({src, alt, className}) => {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const image = new Image();
        image.src = src;
        image.onload = () => {
            setLoading(false);
        };
    }, [src]);

    return <img className={`${className} ${loading ? 'loading' : ''}`} src={src} alt={alt} />;
};

export default LazyImage;
