import {combineEpics, Epic, ofType} from 'redux-observable';
import {catchError, filter, mergeMap, repeat, retryWhen, switchMap, take, tap} from 'rxjs/operators';
import {PayloadAction} from '@reduxjs/toolkit';
import {of, throwError, timer} from 'rxjs';
import {
    AsyncWaterApiStatus,
    AsyncTaskOutput,
    MarinaRouteNavigation,
    RouteOutput,
    addAlert,
    handleApiError,
    authTokenSelector,
} from 'marine-panel-common-web';
import {
    cancelTask,
    findRoute,
    findRouteError,
    findRouteSuccess,
    ICancelTask,
    IFindRoute,
    removeMarinaFromQueue,
} from '../reducers/routeFindSlice';
import {routesQueueSelector} from '../selectors/routeFindSelectors';
import {DEFAULT_USER_LOCATION, WATER_API_STATUS_CALL_DELAY} from '../../config/config';
import {createNavigationRouteApi} from '../../api/createNavigationRouteApi';
import {checkStatusApi} from '../../api/asyncTasks/checkStatusApi';
import {cancelAsyncTaskAPI} from '../../api/asyncTasks/cancelAsyncTaskApi';
import {changeRouteCalculationTask} from '../reducers/reservationsSlice';
import store from '../index';
import {vesselLatestPositionSelector} from '../selectors/reservationSelectors';

const getRouteEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(findRoute.type),
        mergeMap((action: PayloadAction<IFindRoute>) => {
            const authToken = authTokenSelector(state$.value);
            return createNavigationRouteApi(authToken, action.payload.routeInput).pipe(
                switchMap((res) => {
                    const taskId = res.id;
                    if (!taskId) {
                        return throwError(() => new Error('alerts.errorMessage'));
                    }
                    return checkStatusApi<RouteOutput>(authToken, taskId).pipe(
                        repeat({delay: WATER_API_STATUS_CALL_DELAY}),
                        tap((resp) => {
                            store.dispatch(changeRouteCalculationTask(resp));
                        }),
                        filter((data: AsyncTaskOutput<RouteOutput>) => {
                            return data?.status === AsyncWaterApiStatus.COMPLETED || data?.status === AsyncWaterApiStatus.FAILED;
                        }),
                        take(1),
                        mergeMap((res: AsyncTaskOutput<RouteOutput>) => {
                            const finalRouteObject: {[marinaId: string]: MarinaRouteNavigation} = {
                                [action.payload.marinaId]: {
                                    marinaId: action.payload.marinaId,
                                    status: res.status,
                                    route: res.output,
                                },
                            };
                            const queue = routesQueueSelector(state$.value);

                            if (queue.length > 0) {
                                const latestVesselPosition = vesselLatestPositionSelector(state$.value),
                                    location =
                                        latestVesselPosition !== null
                                            ? {
                                                  latitude: latestVesselPosition.position.latitude,
                                                  longitude: latestVesselPosition.position.longitude,
                                              }
                                            : DEFAULT_USER_LOCATION,
                                    activeYachtTypeId = action.payload.routeInput.vesselTypeId,
                                    first_element_in_queue = queue[0];

                                const getNext = findRoute({
                                    routeInput: {
                                        waypoints: [Object.values(first_element_in_queue)[0], location],
                                        vesselTypeId: activeYachtTypeId,
                                    },
                                    marinaId: Object.keys(first_element_in_queue)[0],
                                });
                                return of(
                                    findRouteSuccess(finalRouteObject),
                                    removeMarinaFromQueue({marinaId: Object.keys(first_element_in_queue)[0]}),
                                    getNext
                                );
                            }

                            return of(findRouteSuccess(finalRouteObject));
                        }),
                        catchError((error: Error) =>
                            of(
                                addAlert(handleApiError(error)),
                                findRouteError(error.message, action.payload.marinaId),
                                removeMarinaFromQueue({marinaId: action.payload.marinaId})
                            )
                        )
                    );
                }),
                retryWhen((errors) =>
                    errors.pipe(
                        switchMap((error) => {
                            if (error.status !== 429) {
                                return throwError(error);
                            }
                            return of(timer(1000));
                        })
                    )
                ),
                catchError((error: Error) => of(addAlert(handleApiError(error)), findRouteError(error.message, action.payload.marinaId)))
            );
        })
    );

const cancelTaskEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(cancelTask.type),
        mergeMap((action: PayloadAction<ICancelTask>) => {
            const authToken = authTokenSelector(state$.value);
            if (action.payload?.task?.status !== AsyncWaterApiStatus.COMPLETED && action.payload.task) {
                return cancelAsyncTaskAPI(authToken, action.payload.task.id).pipe(
                    switchMap(() => {
                        return of();
                    }),
                    catchError((error: Error) => of(addAlert(handleApiError(error))))
                );
            }
            return of();
        })
    );

const routeFindEpic = combineEpics(getRouteEpic, cancelTaskEpic);

export default routeFindEpic;
