import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface ITagDescriptionPayload {
    berthTagId: string | null;
    description: string | null;
}

export function changeTagDescriptionAPI(
    id: string | null,
    authToken: string | null,
    payload: ITagDescriptionPayload | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/change_tag_description`, payload, new RestQueryParams(), headers);
}
