import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Berth, GeoPoint, Marina, Sector, Reservation} from 'marine-panel-common-web';

export enum SelectedItemType {
    GENERAL = 'general',
    SECTOR = 'sector',
    BERTH = 'berth',
    RESERVATION = 'reservation',
    MARINA = 'marina',
}

export enum MapActionType {
    EDIT = 'edit',
    CREATE = 'create',
    VIEW = 'view',
}

export interface ISelectedSidePanelItem {
    selectedItemType: SelectedItemType;
    selectedItemActionType: MapActionType;
}

export interface IMapHostState {
    selectedItem: ISelectedSidePanelItem | null;
    mapType: SelectedItemType;
    mapCenter: GeoPoint;
    mapZoom: number;
}

export interface IChangeSelectedMapItem {
    selectedItem: ISelectedSidePanelItem | null;
    activatedItem?: Marina | Sector | Berth | Reservation;
}

export interface IChangeISelectedMapItem {
    mapType: SelectedItemType;
}

export interface IChangeMapCenter {
    mapCenter: GeoPoint;
}

export interface IChangeMapZoom {
    mapZoom: number;
}

export interface IChangeShowLoader {
    readonly payload: {
        showLoader: boolean;
    };
}

const initialState: IMapHostState = {
    selectedItem: {
        selectedItemType: SelectedItemType.GENERAL,
        selectedItemActionType: MapActionType.VIEW,
    },
    mapType: SelectedItemType.GENERAL,
    mapCenter: {latitude: 0, longitude: 0},
    mapZoom: 3,
};

const mapHostSlice = createSlice({
    name: 'mapHost',
    initialState: initialState,
    reducers: {
        changeSelectedItem: {
            reducer: (state: IMapHostState, action: PayloadAction<IChangeSelectedMapItem>) => {
                return {
                    selectedItem: action.payload.selectedItem,
                    mapType: state.mapType,
                    mapCenter: state.mapCenter,
                    mapZoom: state.mapZoom,
                };
            },
            prepare(selectedItem: ISelectedSidePanelItem | null, activatedItem?: Marina | Sector | Berth | Reservation) {
                return {
                    payload: {selectedItem: selectedItem, activatedItem: activatedItem ? activatedItem : undefined},
                };
            },
        },
        changeMapType: {
            reducer: (state: IMapHostState, action: PayloadAction<IChangeISelectedMapItem>) => {
                return {
                    selectedItem: state.selectedItem,
                    mapType: action.payload.mapType,
                    mapCenter: state.mapCenter,
                    mapZoom: state.mapZoom,
                };
            },
            prepare(mapType: SelectedItemType) {
                return {
                    payload: {mapType: mapType},
                };
            },
        },
        changeMapCenter: {
            reducer: (state: IMapHostState, action: PayloadAction<IChangeMapCenter>) => {
                return {
                    selectedItem: state.selectedItem,
                    mapType: state.mapType,
                    mapCenter: action.payload.mapCenter,
                    mapZoom: state.mapZoom,
                };
            },
            prepare(mapCenter: GeoPoint) {
                return {
                    payload: {mapCenter: mapCenter},
                };
            },
        },
        changeMapZoom: {
            reducer: (state: IMapHostState, action: PayloadAction<IChangeMapZoom>) => {
                return {
                    selectedItem: state.selectedItem,
                    mapType: state.mapType,
                    mapCenter: state.mapCenter,
                    mapZoom: action.payload.mapZoom,
                };
            },
            prepare(mapZoom: number) {
                return {
                    payload: {mapZoom: mapZoom},
                };
            },
        },
        resetMapToInitialState: () => {
            return {
                ...initialState,
            };
        },
        returnToGeneralView: (state: IMapHostState) => {
            return {
                selectedItem: {
                    selectedItemType: SelectedItemType.GENERAL,
                    selectedItemActionType: MapActionType.VIEW,
                },
                mapType: SelectedItemType.GENERAL,
                mapCenter: state.mapCenter,
                mapZoom: state.mapZoom,
            };
        },
    },
});

export const {changeSelectedItem, changeMapType, changeMapCenter, changeMapZoom, resetMapToInitialState, returnToGeneralView} =
    mapHostSlice.actions;

export default mapHostSlice.reducer;
