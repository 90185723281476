import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject, Marina} from 'marine-panel-common-web';
import {StateObservable} from 'redux-observable';
import {ITagPayload} from '../../api/berth/addTag';

export interface IMarinaTagsState {
    tags: any;
    isMarinaTagsLoading: boolean;
    isMarinaTagsInitialized: boolean;
    isActionSuccessful: boolean;
    marinaTagsError: string | null;
}

export interface ISetMarinaTags {
    tags: any;
}
export interface IChangeIsMarinaTagsLoading {
    isMarinaTagsLoading: boolean;
}

export interface IChangeIsMarinaTagsInitialized {
    isMarinaTagsInitialized: boolean;
}

export interface IChangeMarinaTagsError {
    marinaTagsError: string | null;
}

export interface IAddMarinaTagPayload {
    marinaId: string | null;
    marinaTagId: ITagPayload | null;
}

export interface IChangeIsActionSuccessful {
    isActionSuccessful: boolean;
}

export interface IChangeTagDescription {
    marinaId: string | null;
    tagPayload: {
        marinaTagId: string | null;
        description: string | null;
    };
}

const initialState: IMarinaTagsState = {
    tags: null,
    isMarinaTagsInitialized: false,
    isActionSuccessful: false,
    isMarinaTagsLoading: false,
    marinaTagsError: null,
};

const marinaTagsSlice = createSlice({
    name: 'marinaTags',
    initialState: initialState,
    reducers: {
        changeTagDescription: {
            reducer: (state: IMarinaTagsState) => {
                return {
                    ...state,
                    isMarinaTagsLoading: true,
                };
            },
            prepare: (marinaId: string | null, marinaTagId: string | null, description: string | null) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        tagPayload: {
                            marinaTagId: marinaTagId,
                            description: description,
                        },
                    },
                };
            },
        },
        changeIsActionSuccessful: {
            reducer: (state: IMarinaTagsState, action: PayloadAction<IChangeIsActionSuccessful>) => {
                return {
                    ...state,
                    isActionSuccessful: action.payload.isActionSuccessful,
                };
            },
            prepare: (isActionSuccessful: boolean) => {
                return {
                    payload: {
                        isActionSuccessful: isActionSuccessful,
                    },
                };
            },
        },
        fetchMarinaTags: (state: IMarinaTagsState) => {
            return {
                ...state,
                isMarinaTagsLoading: true,
            };
        },
        setMarinaTags: {
            reducer: (state: IMarinaTagsState, action: PayloadAction<ISetMarinaTags>) => {
                return {
                    ...state,
                    tags: action.payload.tags,
                };
            },
            prepare: (tags: any) => {
                return {
                    payload: {
                        tags: tags,
                    },
                };
            },
        },
        removeTag: {
            reducer: (state: IMarinaTagsState) => {
                return {
                    ...state,
                    isMarinaTagsLoading: true,
                };
            },
            prepare: (marinaId: string | null, marinaTagId: string | null) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        marinaTagId: {marinaTagId},
                    },
                };
            },
        },
        addTag: {
            reducer: (state: IMarinaTagsState) => {
                return {
                    ...state,
                    isMarinaTagsLoading: true,
                };
            },
            prepare: (marinaId: string | null, marinaTagId: string | null) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        marinaTagId: {marinaTagId},
                    },
                };
            },
        },
        fetchMarinaDetails: {
            reducer: (state: IMarinaTagsState) => {
                return {
                    ...state,
                    isMarinaTagsLoading: true,
                };
            },
            prepare: (id: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        changeIsMarinaTagsLoading: {
            reducer: (state: IMarinaTagsState, action: PayloadAction<IChangeIsMarinaTagsLoading>) => {
                return {
                    ...state,
                    isMarinaTagsLoading: action.payload.isMarinaTagsLoading,
                };
            },
            prepare: (isMarinaTagsLoading: boolean) => {
                return {
                    payload: {
                        isMarinaTagsLoading: isMarinaTagsLoading,
                    },
                };
            },
        },
        changeIsMarinaTagsInitialized: {
            reducer: (state: IMarinaTagsState, action: PayloadAction<IChangeIsMarinaTagsInitialized>) => {
                return {
                    ...state,
                    isMarinaTagsInitialized: action.payload.isMarinaTagsInitialized,
                };
            },
            prepare: (isMarinaTagsInitialized: boolean) => {
                return {
                    payload: {
                        isMarinaTagsInitialized: isMarinaTagsInitialized,
                    },
                };
            },
        },
        changeMarinaTagsError: {
            reducer: (state: IMarinaTagsState, action: PayloadAction<IChangeMarinaTagsError>) => {
                return {
                    ...state,
                    marinaTagsError: action.payload.marinaTagsError,
                };
            },
            prepare: (marinaTagsError: string | null) => {
                return {
                    payload: {
                        marinaTagsError: marinaTagsError,
                    },
                };
            },
        },
        returnToInitialMarinaTagsState: (state: IMarinaTagsState) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    returnToInitialMarinaTagsState,
    changeIsActionSuccessful,
    changeMarinaTagsError,
    changeIsMarinaTagsInitialized,
    changeIsMarinaTagsLoading,
    fetchMarinaDetails,
    addTag,
    removeTag,
    setMarinaTags,
    changeTagDescription,
    fetchMarinaTags,
} = marinaTagsSlice.actions;

export default marinaTagsSlice.reducer;
