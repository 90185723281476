import L, {LatLng, LatLngExpression, LatLngTuple} from 'leaflet';
import {Berth, GeoPoint, Marina, MarinaBroad, deepCloneObject} from 'marine-panel-common-web';
import {useEffect, useState} from 'react';
import {FeatureGroup, MapContainer, Marker, Polygon, TileLayer, useMap} from 'react-leaflet';
import {EditControl} from 'react-leaflet-draw';
import {connect, useDispatch} from 'react-redux';
import {RootState} from '../../../../../../../store/reducers';
import {IChangeBerthLocationPayload, resetToInitialBerthEditWizardState} from '../../../../../../../store/reducers/berthEditWizardSlice';
import {SelectedItemType} from '../../../../../../../store/reducers/mapHostSlice';
import {currentlyEditedBerth} from '../../../../../../../store/selectors/berthEditWizardSelectors';
import {berthsLoading, berthsSelector} from '../../../../../../../store/selectors/berthSelectors';
import {currentlyEditedMarina} from '../../../../../../../store/selectors/marinaEditWizardSelectors';
import {marinasSelector} from '../../../../../../../store/selectors/marinaSelectors';

interface ILocationInputProps {
    readonly locationItem: SelectedItemType;
    readonly berths: Berth[] | null;
    readonly berth: Berth | null;
    readonly marina: Marina | null;
    readonly berthsLoading: boolean;
    readonly marinas: MarinaBroad[] | null;
    readonly resetToInitialBerthEditWizardState: typeof resetToInitialBerthEditWizardState;
    readonly predefinedLocation?: LatLngExpression | null;
    updatedCoords: (payload: IChangeBerthLocationPayload) => void;
    createdCoords: (payload: any) => void;
}
const LocationInput = (props: ILocationInputProps) => {
    const isBerth = props.locationItem === SelectedItemType.BERTH,
        [currentlyEditedMarinaCoords, setCurrentlyEditedMarinaCoords] = useState<LatLngExpression | null>(null),
        [currentlyEditedBerthCoords, setCurrentlyEditedBerthCoords] = useState<LatLngExpression[] | null>(null),
        [mapCenterLocation, setMapCenterLocation] = useState<LatLngExpression | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.berth) {
            const berthLocationPoint = props.berth.location.vertexes[0];
            setMapCenterLocation([berthLocationPoint.latitude, berthLocationPoint.longitude]);
            const berthVertexes = props.berth.location.vertexes;
            const berthVertexesLatLng = berthVertexes.map((vertex: GeoPoint) => [vertex.latitude, vertex.longitude] as LatLngExpression);
            setCurrentlyEditedBerthCoords(berthVertexesLatLng);
        }
        if (props.predefinedLocation) {
            setMapCenterLocation(props.predefinedLocation);
        }
        if (props.marina) {
            const marinaLocation = props.marina.location;
            setMapCenterLocation([marinaLocation.latitude, marinaLocation.longitude]);
            setCurrentlyEditedMarinaCoords([marinaLocation.latitude, marinaLocation.longitude]);
        }
        return () => {
            setCurrentlyEditedBerthCoords(null);
            dispatch(resetToInitialBerthEditWizardState());
            setCurrentlyEditedMarinaCoords(null);
            setMapCenterLocation(null);
        };
    }, [props.berth, props.marina, props.predefinedLocation, dispatch]);

    const markerIcon = L.icon({
        iconUrl: require('../../../../../../../assets/images/marina-icon.png'),
        iconSize: [16, 16],
        iconAnchor: [16, 16],
    });

    const renderBerths = (berths: Berth[] | null, isBerth: boolean, currentyEditedItem: Berth | null) => {
        if (!berths || berths.length === 0 || !isBerth) return null;
        const berthPolygons = berths
            .filter((berth: Berth) => currentyEditedItem?.id !== berth.id)
            .map((berth: Berth) => (
                <Polygon
                    key={`berth-disabled-polygon-key-${berth.id}`}
                    className="disabled-polygon"
                    positions={berth.location.vertexes.map((vertex: GeoPoint) => [vertex.latitude, vertex.longitude])}
                />
            ));

        return berthPolygons;
    };

    const renderEditWrapper = (editedBerth: Berth | null, editedMarina: Marina | null) => {
        const renderEditedItem = (
            currentlyEditedBerthCoords: LatLngExpression[] | null,
            currentlyEditedMarinaCoords: LatLngExpression | null
        ) => {
            if (currentlyEditedBerthCoords) {
                return (
                    <Polygon
                        key={`berth-edited-polygon-key-${editedBerth ? editedBerth.id : editedMarina ? editedMarina.id : ''}`}
                        positions={[currentlyEditedBerthCoords]}
                    />
                );
            }
            if (currentlyEditedMarinaCoords) {
                return (
                    <Marker
                        icon={markerIcon}
                        key={`marina-edited-polygon-key-${editedBerth ? editedBerth.id : editedMarina ? editedMarina.id : ''}`}
                        position={currentlyEditedMarinaCoords}
                    />
                );
            }
            return null;
        };
        const isCreateMode = !currentlyEditedBerthCoords && !currentlyEditedMarinaCoords;
        const saveEditedCoords = (edited: any) => {
            if (!isBerth) {
                const layer = edited.layers.getLayers()[0],
                    latLngs = layer.getLatLng();

                const coords: GeoPoint = changeLatLngToGeoPoint(latLngs);
                const editedCoords: LatLngTuple = [coords.latitude, coords.longitude];
                setCurrentlyEditedMarinaCoords(editedCoords);
                setMapCenterLocation(editedCoords);

                const payload: IChangeBerthLocationPayload = {
                    berthId: editedMarina?.id,
                    location: coords,
                };
                props.updatedCoords(payload);
            }
            if (isBerth) {
                const layer = edited.layers.getLayers()[0],
                    latLngs = layer.getLatLngs()[0];
                const coords: any[] = [];

                latLngs.forEach((latLng: LatLng) => coords.push(changeLatLngToGeoPoint(latLng)));
                const coordsDuplicate = deepCloneObject(coords);
                const latLngExpressionArray: LatLngExpression[] = coordsDuplicate.map(
                    (coord: GeoPoint) => [coord.latitude, coord.longitude] as LatLngExpression
                );
                setCurrentlyEditedBerthCoords(latLngExpressionArray);
                const endDot = coords[0];
                coords.push(endDot);
                const payload: IChangeBerthLocationPayload = {
                    berthId: editedBerth?.id,
                    location: coords,
                };
                props.updatedCoords(payload);
            }
        };
        const saveCreatedCoords = (created: any) => {
            if (created.layerType === 'marker') {
                const layer = created.layer,
                    latLngs = layer.getLatLng();
                // (latLngs);

                const coords: GeoPoint = changeLatLngToGeoPoint(latLngs);
                const editedCoords: LatLngTuple = [coords.latitude, coords.longitude];
                setCurrentlyEditedMarinaCoords(editedCoords);
                setMapCenterLocation(editedCoords);

                props.createdCoords(coords);
            }
            if (created.layerType === 'polygon') {
                const layer = created.layer,
                    latLngs = layer.getLatLngs()[0];
                const coords: any[] = [];
                latLngs.map((latLng: any) => {
                    const latitude = parseFloat(Number(latLng.lat).toFixed(7)),
                        longitude = parseFloat(Number(latLng.lng).toFixed(7));
                    return coords.push({latitude, longitude});
                });
                const coordsDuplicate = deepCloneObject(coords);
                const latLngExpressionArray: LatLngExpression[] = coordsDuplicate.map(
                    (coord: GeoPoint) => [coord.latitude, coord.longitude] as LatLngExpression
                );
                setCurrentlyEditedBerthCoords(latLngExpressionArray);
                setMapCenterLocation(latLngExpressionArray[0]);

                const endDot = coords[0];
                coords.push(endDot);
                props.createdCoords(coords);
            }
        };
        return (
            <FeatureGroup>
                <EditControl
                    position="bottomright"
                    edit={{
                        remove: false,
                    }}
                    onEdited={(edited: any) => saveEditedCoords(edited)}
                    onCreated={(created: any) => saveCreatedCoords(created)}
                    draw={{
                        polygon: isBerth && isCreateMode,
                        polyline: false,
                        circle: false,
                        marker: !isBerth && isCreateMode ? {icon: markerIcon} : false,
                        rectangle: false,
                        circlemarker: false,
                    }}
                />
                {renderEditedItem(currentlyEditedBerthCoords, currentlyEditedMarinaCoords)}
            </FeatureGroup>
        );
    };
    function changeLatLngToGeoPoint(latLngs: LatLng): GeoPoint {
        const latitude = parseFloat(Number(latLngs.lat).toFixed(7)),
            longitude = parseFloat(Number(latLngs.lng).toFixed(7));
        return {latitude: latitude, longitude: longitude};
    }

    const MapController = () => {
        const map = useMap();
        useEffect(() => {
            if (mapCenterLocation) {
                map.setView(mapCenterLocation, 70);
            }
        }, [map]);

        return <></>;
    };

    return (
        <div className="location-map-wrapper">
            <MapContainer center={[0, 0]} zoom={mapCenterLocation ? 70 : 1} boundsOptions={{padding: [1, 1]}} maxZoom={22}>
                <MapController />
                {renderBerths(props.berths, isBerth, props.berth)}
                {renderEditWrapper(props.berth, props.marina)}
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxNativeZoom={22} maxZoom={22} />
            </MapContainer>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        berths: berthsSelector(state),
        berth: currentlyEditedBerth(state),
        marina: currentlyEditedMarina(state),
        marinas: marinasSelector(state),
        berthsLoading: berthsLoading(state),
    }),
    {
        resetToInitialBerthEditWizardState,
    }
)(LocationInput);
