import React from 'react';
import {User} from 'react-feather';
import {ChatContact} from '@solvee/reactjs-websocket-text-chat';
import {Avatar, AvatarColor, AvatarStatus} from 'marine-panel-common-web';

interface IChatHeaderProps {
    readonly peerUserName: string;
    readonly showOnlineIndicator?: boolean;
    readonly chatContact: ChatContact | undefined;
}

class ChatHeader extends React.Component<IChatHeaderProps> {
    render() {
        return (
            <React.Fragment>
                <div className={`chat-navbar`}>
                    <header className="chat-header">
                        <div className={`d-flex align-items-center`}>
                            <Avatar
                                imgHeight="36"
                                imgWidth="36"
                                img={this.props.chatContact ? this.props.chatContact.avatarUrl : undefined}
                                icon={<User size={36} />}
                                className="avatar-border"
                                color={AvatarColor.PRIMARY}
                                status={this.props.showOnlineIndicator ? AvatarStatus.ONLINE : AvatarStatus.OFFLINE}
                                tag={'div'}
                            />
                            <h6 className="mx-2">{this.props.peerUserName}</h6>
                        </div>
                    </header>
                </div>
            </React.Fragment>
        );
    }
}

export default ChatHeader;
