import {DateComponent, Price, Translation, IReservationsReportItemOutput} from 'marine-panel-common-web';
import styles from './styles.module.scss';

const ReservationsList = ({reservation}: {reservation: IReservationsReportItemOutput}) => {
    const statusClass = reservation.status ? `${styles[reservation.status]}` : '';

    return (
        <div className={styles.itemContainer}>
            <div className={styles.itemTitle}>
                {/*ToDo which date in array should be displayed*/}
                {/*{reservations.dates.map((date) => (*/}
                {/*    <DateComponent date={date} format="MMMM DD, YYYY" key={date} />*/}
                {/*))}*/}

                <p className={styles.itemDate}>
                    <DateComponent date={reservation.dates[0]} format="MMMM DD, YYYY" />
                </p>
                <p className={`${styles.itemStatus} ${statusClass}`}>{reservation.status}</p>
            </div>
            <ul className={styles.itemData}>
                <li>
                    <p>
                        <Translation text="reservations.berthName" />
                    </p>
                    <p className={styles.berthName}>{reservation.berth.name}</p>
                </li>
                <li>
                    <p>
                        <Translation text="reservations.yachtNo" />
                    </p>
                    <p>{reservation.number}</p>
                </li>
                <li>
                    <p>
                        <Translation text="reservations.reservationId" />
                    </p>
                    <p>{reservation.id}</p>
                </li>
                <li>
                    <p>
                        <Translation text="reservations.price" />
                    </p>
                    <p>
                        <Price
                            separateWithNbsp={true}
                            price={{
                                amount: reservation.price.amount.toString(),
                                currency: reservation.price.currency,
                            }}
                        />
                    </p>
                </li>
                <li>
                    <p>
                        <Translation text="reservations.amountNetprofit" />
                    </p>
                    <p className={styles.priceSection}>
                        <Price
                            separateWithNbsp={true}
                            price={{
                                amount: reservation.netPrice.amount.toString(),
                                currency: reservation.netPrice.currency,
                            }}
                        />
                    </p>
                </li>
                <li>
                    <p>
                        <Translation text="reservations.lastFour" />
                    </p>
                    <p>****{reservation.cardNumber}</p>
                </li>
            </ul>
        </div>
    );
};

export default ReservationsList;
