import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiSelectType,
    VesselType,
    IModelDictionaryDatum,
} from 'marine-panel-common-web';
export const pricingDefaultFormConfig = (currentVesselTypes: VesselType[], allVesselTypes: IModelDictionaryDatum[]): IFormConfig => {
    const multiselectOptionVesselTypes = allVesselTypes.map((vesselType: IModelDictionaryDatum) => {
        const multiselect: IMultiselectOption = {
            value: vesselType.id,
            label: vesselType.name,
        };
        return multiselect;
    });
    const multiselectOptionCurrentVesselTypes = currentVesselTypes.map((vesselType: VesselType) => {
        const multiselect: IMultiselectOption = {
            value: vesselType.id,
            label: vesselType.name,
        };
        return multiselect;
    });
    return {
        controlType: 'form',
        class: 'default-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'berth_yacht_types',
                controlType: 'group',
                class: 'row',
                controls: {
                    berthYachtTypes: {
                        controlType: 'control',
                        defaultValue: multiselectOptionCurrentVesselTypes,
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SEARCHABLE,
                        multiselectOptions: multiselectOptionVesselTypes,
                        // validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        validationRules: [],
                        placeholder: '',
                        isLabelHidden: false,
                        label: 'modal.createMarina.inputs.tags',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                    },
                },
            },
        ],
    };
};
