import {
    Berth,
    CustomSkipper,
    Form,
    FormControlChangeType,
    IFormConfig,
    IMultiselectOption,
    IModelDictionaryDatum,
    Translation,
    vesselTypesSelector,
    convertToMultiselectLabels,
    CustomVesselInput,
    deepCloneObject,
    IconSize,
} from 'marine-panel-common-web';
import moment from 'moment';
import React from 'react';
import {X} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {IReservationPayload} from '../../../../api/reservation/createReservation';
import {RootState} from '../../../../store/reducers';
import {returnToGeneralView} from '../../../../store/reducers/mapHostSlice';
import {createReservation} from '../../../../store/reducers/reservationsSlice';
import {berthsSelector} from '../../../../store/selectors/berthSelectors';
import {createReservationFormConfig} from './createReservationFromConfig';

interface IConnectedReservationCreateCardProps {
    readonly returnToGeneralView: typeof returnToGeneralView;
    readonly createReservation: typeof createReservation;
    readonly berths: Berth[] | null;
    readonly vesselTypes: IModelDictionaryDatum[] | null;
}

interface IReservationCreateCardProps extends IConnectedReservationCreateCardProps, WithTranslation {}

interface IReservationCreateCardState {
    formConfig: IFormConfig;
    value: any;
}

class ReservationCreateCard extends React.Component<IReservationCreateCardProps, IReservationCreateCardState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: IReservationCreateCardProps) {
        super(props);
        this.state = {
            formConfig: createReservationFormConfig(),
            value: null,
        };
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    distinctUntilChanged(),
                    tap((data: any) => this.setState({value: data.value}))
                )

                .subscribe()
        );
        const filteredActiveBerths = this.props.berths ? deepCloneObject(this.props.berths).filter((berth) => berth.active) : [];
        const {t} = this.props,
            berths = filteredActiveBerths ? convertToMultiselectLabels(filteredActiveBerths, t) : [],
            vesselTypes = this.props.vesselTypes ? convertToMultiselectLabels(this.props.vesselTypes, t) : [];
        this.setFormConfig(berths, vesselTypes);
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className="details-header">
                    <p className="details-item-title">
                        <Translation text={'mainMap.reservation.create.title'} />
                    </p>

                    <div className="reservation-controller-buttons">
                        <button className="close-reservation-panel-button" onClick={() => this.props.returnToGeneralView()}>
                            <X size={IconSize.CloseCard} />
                            <span className="sr-only">
                                <Translation text={'buttons.close'} />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="details-body">
                    <div className="reservation-form-wrapper">
                        <Form
                            config={this.state.formConfig}
                            controlName={'reservationEditForm'}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            onButtonClicked={() => this.createReservation()}
                        />
                    </div>
                </div>
                <div className="details-footer"></div>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = (reservationData: any, vesselTypes: IMultiselectOption[]) => {
        this.setState({
            formConfig: createReservationFormConfig(reservationData, vesselTypes),
        });
    };

    private createReservation() {
        const value = this.state.value,
            // isPaid = value.isPaid,
            // isArrived = value.isArrived,
            skipper: CustomSkipper = {
                name: value.skipperName,
                email: value.email,
                phone: value.phone,
            },
            vessel: CustomVesselInput = {
                name: value.vesselName,
                registrationNumber: value.registrationNumber,
                vesselTypeId: value.vesselType,
            },
            paymentAmount = value.price ? value.price * 100 : 0,
            creationDate = moment(value.date).set('hour', 12).toISOString(),
            endDate = moment(creationDate).add(1, 'days').toISOString(),
            payload: IReservationPayload = {
                // isPaid: isPaid,
                // isArrived: isArrived,
                dates: [creationDate, endDate],
                berthId: value.berth,
                customSkipper: skipper,
                customVessel: vessel,
                note: value.note,
                price: {
                    amount: paymentAmount.toString(),
                    currency: 'EUR',
                },
            };
        this.props.createReservation(payload);
    }
}

export default connect(
    (state: RootState) => ({
        berths: berthsSelector(state),
        vesselTypes: vesselTypesSelector(state),
    }),
    {
        returnToGeneralView,
        createReservation,
    }
)(withTranslation()(ReservationCreateCard));
