import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IModelDictionaryDatum,
    IMultiselectOption,
    InputType,
    MultiSelectType,
} from 'marine-panel-common-web';

export const yachtTypeFormConfig = (vesselTypes: IMultiselectOption[]): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form create-pricing-strategy',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'yachtTypes',
                controlType: 'group',
                class: 'row',
                controls: {
                    yachtTypes: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SEARCHABLE,
                        multiselectOptions: vesselTypes ? vesselTypes : [],
                        // validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        validationRules: [],
                        placeholder: '',
                        isLabelHidden: true,
                        label: 'modal.createPricingStrategy.inputs.yachtTypes',
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
        ],
    };
};
