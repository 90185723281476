import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ChatContact} from '@solvee/reactjs-websocket-text-chat';
// import {ChatContact} from '../../types';
import {initCleanApiCall} from '../../utils/reduxHelpers';

export interface IChatState {
    contacts: ChatContact[];
    isLoading: boolean;
    error: string | null;
}

export interface IGetChatContactsSuccess {
    contacts: ChatContact[];
}

export interface ISetChatApiError {
    error: string;
}

export interface IChangeIsChatLoading {
    isLoading: boolean;
}

const initialState: IChatState = {
    contacts: [],
    isLoading: false,
    error: null,
};

const chatSlice = createSlice({
    name: 'chat',
    initialState: initialState,
    reducers: {
        getChatContactsSuccess: {
            reducer: (state: IChatState, action: PayloadAction<IGetChatContactsSuccess>) => {
                return {
                    ...state,
                    isLoading: false,
                    error: null,
                    contacts: action.payload.contacts,
                };
            },
            prepare(contacts) {
                return {
                    payload: {contacts},
                };
            },
        },
        setChatError: {
            reducer: (state: IChatState, action: PayloadAction<ISetChatApiError>) => {
                return {
                    ...state,
                    isLoading: false,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {error},
                };
            },
        },
        changeIsLoading: {
            reducer: (state: IChatState, action: PayloadAction<IChangeIsChatLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading},
                };
            },
        },
        getChatContacts: (state: IChatState) => initCleanApiCall(state),
    },
});

export const {getChatContacts, getChatContactsSuccess, setChatError, changeIsLoading} = chatSlice.actions;
export default chatSlice.reducer;
