import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeReservationPaidStatus} from '../../store/reducers/reservationsSlice';

export interface IPaidAtReservationPayload {
    paidAt: boolean;
}
export function changeReservationPaidStatusAPI(
    authToken: string | null,
    reservationId?: string,
    payload?: IChangeReservationPaidStatus
): Observable<any> {
    let headers = undefined;
    const paidPayload: IPaidAtReservationPayload = {
        paidAt: payload?.isPaid || false,
    };
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return marineHubAPI.put(`api/reservations/${reservationId}/set_paid_at`, paidPayload, new RestQueryParams(), headers);
}
