import {
    IFormConfig,
    InputType,
    FormControlType,
    ValidationRules,
    MultiSelectType,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormButtonType,
    IMultiselectOption,
} from 'marine-panel-common-web';

export const billingInformationFormConfig = (countryList?: IMultiselectOption[] | []): IFormConfig => ({
    controlType: 'form',
    class: 'default-form payment',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'company_name',
            class: 'row',
            controls: {
                companyName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'settings.billingData.form.placeholders.companyName',
                    label: 'settings.billingData.form.labels.companyName',
                    hostClass: 'col-xl-6 form-control',
                    type: InputType.TEXT,
                },
                companyEmail: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_EMAIL, params: {}}],
                    placeholder: 'settings.billingData.form.placeholders.billingEmail',
                    isLabelHidden: false,
                    label: 'settings.billingData.form.labels.billingEmail',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-6 form-control',
                },
            },
        },
        {
            controlType: 'group',
            key: 'tax_id',
            class: 'row',
            controls: {
                taxId: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.MIN_LENGTH, params: {length: 8}}],
                    placeholder: 'settings.billingData.form.placeholders.taxId',
                    label: 'settings.billingData.form.labels.taxId',
                    hostClass: 'col-xl-6 form-control',
                    type: InputType.TEXT,
                },
            },
        },
        {
            controlType: 'group',
            key: 'country_phone',
            class: 'row',
            controls: {
                phone: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.MIN_LENGTH, params: {length: 9}}],
                    placeholder: 'settings.billingData.form.placeholders.mobile',
                    isLabelHidden: false,
                    label: 'settings.billingData.form.labels.mobile',
                    type: InputType.TEL,
                    hostClass: 'col-xl-6 form-control',
                },
                country: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: countryList ? countryList : [],
                    validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                    placeholder: 'settings.billingData.form.placeholders.country',
                    label: 'settings.billingData.form.labels.country',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            controlType: 'group',
            key: 'address',
            class: 'row',
            controls: {
                address: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'settings.billingData.form.placeholders.billingAddress',
                    label: 'settings.billingData.form.labels.billingAddress',
                    hostClass: 'col-xl-6 form-control',
                    type: InputType.TEXT,
                },
                city: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'settings.billingData.form.placeholders.city',
                    label: 'settings.billingData.form.labels.city',
                    hostClass: 'col-xl-6 form-control',
                    type: InputType.TEXT,
                },
            },
        },
        {
            controlType: 'group',
            key: 'state_code',
            class: 'row',
            controls: {
                state: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'settings.billingData.form.placeholders.state',
                    label: 'settings.billingData.form.labels.state',
                    hostClass: 'col-xl-6 form-control',
                    type: InputType.TEXT,
                },
                postCode: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.MIN_LENGTH, params: {length: 4}}],
                    placeholder: 'settings.billingData.form.placeholders.zipCode',
                    label: 'settings.billingData.form.labels.zipCode',
                    hostClass: 'col-xl-6 form-control',
                    type: InputType.TEXT,
                },
            },
        },
        {
            key: 'submit',
            controlType: 'group',
            class: 'row justify-content-end',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn btn-create',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 mt-1 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.saveChanges',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
                // discard: {
                //     controlType: 'control',
                //     formControlType: FormControlType.BUTTON,
                //     buttonType: FormButtonType.SUBMIT,
                //     inputStyles: 'btn-outline-secondary',
                //     defaultContainerStyles: '',
                //     hostClass: 'mb-0 mt-1 align-items-end w-auto',
                //     containerStyles: '',
                //     defaultValue: null,
                //     isLabelHidden: true,
                //     btnText: 'buttons.discard',
                //     buttonDisabled: false,
                // },
            },
        },
    ],
});
