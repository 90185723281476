import {
    Anchor,
    Clipboard,
    CheckSquare,
    DollarSign,
    LifeBuoy,
    Smartphone,
    Shield,
    AlertTriangle,
    Slash,
    Check,
    UserPlus,
} from 'react-feather';
import {Tooltip, BerthStatus, ReservationStatus, IconSize} from 'marine-panel-common-web';
import {Badge} from 'reactstrap';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IStatusBadgeProps extends WithTranslation {
    readonly currentStatus: ReservationStatus | BerthStatus;
    readonly id: any;
    readonly isBerthStatus?: boolean;
}

function StatusBadge(props: IStatusBadgeProps) {
    const {t} = props,
        status = t(`statusBadge.${props.isBerthStatus ? 'berth' : 'reservation'}.${props.currentStatus}`);
    return (
        <div className="status-badge-wrapper">
            <Tooltip target={`item_${props.id}_${props.currentStatus}`} innerClassName={props.currentStatus} tooltipText={status} />
            <Badge
                color={getBadgeColor(props.currentStatus)}
                id={`item_${props.id}_${props.currentStatus}`}
                pill
                className={`badge-item ${props.currentStatus}`}>
                {getBadgeIcon(props.currentStatus)}
            </Badge>
        </div>
    );
}

function getBadgeIcon(currentStatus: ReservationStatus | BerthStatus) {
    switch (currentStatus) {
        case ReservationStatus.ACTIVE:
            return <Shield className="badge-icon" size={IconSize.StatusBadge} />;
        case ReservationStatus.DRAFT:
            return <Clipboard className="badge-icon" size={IconSize.StatusBadge} />;
        case ReservationStatus.CANCELLED:
            return <Slash className="badge-icon" size={IconSize.StatusBadge} />;
        case ReservationStatus.FINISHED:
            return <Check className="badge-icon" size={IconSize.StatusBadge} />;
        case ReservationStatus.IS_MANUAL:
            return <UserPlus className="badge-icon" size={IconSize.StatusBadge} />;
        case ReservationStatus.INACTIVE:
            return <AlertTriangle className="badge-icon" size={IconSize.StatusBadge} />;
        case BerthStatus.YACHT_ARRIVED || ReservationStatus.YACHT_ARRIVED:
            return <Anchor className="badge-icon" size={IconSize.StatusBadge} />;
        case ReservationStatus.APP_BOOKING:
            return <Smartphone className="badge-icon" size={IconSize.StatusBadge} />;
        case BerthStatus.RESERVED:
            return <CheckSquare className="badge-icon" size={IconSize.StatusBadge} />;
        case BerthStatus.PAID || ReservationStatus.PAID:
            return <DollarSign className="badge-icon" size={IconSize.StatusBadge} />;
        case BerthStatus.AVAILABLE:
            return <LifeBuoy className="badge-icon" size={IconSize.StatusBadge} />;
    }
}

function getBadgeColor(currentStatus: ReservationStatus | BerthStatus) {
    switch (currentStatus) {
        case ReservationStatus.ACTIVE:
            return 'light-primary';
        case ReservationStatus.DRAFT:
            return 'light-secondary';
        case ReservationStatus.CANCELLED:
            return 'light-danger';
        case ReservationStatus.FINISHED:
            return 'light-warning';
        case ReservationStatus.IS_MANUAL:
            return 'light-success';
        case ReservationStatus.INACTIVE:
            return 'light-dark';
        case BerthStatus.YACHT_ARRIVED || ReservationStatus.YACHT_ARRIVED:
            return 'light-success';
        case ReservationStatus.APP_BOOKING:
            return 'light-info';
        case BerthStatus.RESERVED:
            return 'light-primary';
        case BerthStatus.PAID || ReservationStatus.PAID:
            return 'light-success';
        case BerthStatus.AVAILABLE:
            return 'light-success';
    }
}

export default withTranslation()(StatusBadge);
