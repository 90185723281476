import {marineHubAPI, RestQueryParams, CustomSkipper, CustomVesselInput, MoneyInput} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IReservationPayload {
    // isPaid: boolean;
    // isArrived: boolean;
    dates: string[];
    berthId: string;
    customSkipper: CustomSkipper;
    customVessel: CustomVesselInput;
    note: string;
    price: MoneyInput;
}

export function createReservationAPI(authToken: string | null, reservationId?: string, actionPayload?: any): Observable<any> {
    let headers = undefined;
    // uncomment when paid as and availablity is implemented - PT TODO
    // const changedPayload = actionPayload.delete('isPaid').delete('isArrived');
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return marineHubAPI.post(`api/reservations`, actionPayload.actionPayload, new RestQueryParams(), headers);
}
