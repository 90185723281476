import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface ISetPhotoPaylodad {
    id: string | null;
}

export function setMarinaPhotoAsCoverAPI(
    authToken: string | null,
    marinaId: string | null,
    photoIdPayload: ISetPhotoPaylodad
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${marinaId}/set_photo_as_cover`, photoIdPayload, new RestQueryParams(), headers);
}
