import React, {useEffect, useState} from 'react';
import {
    convertToMultiselectLabels,
    countriesSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    IModelDictionaryDatum,
    Loader,
    LoaderType,
    sortMultiselectLabels,
    IBillingInfo,
} from 'marine-panel-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {billingInformationFormConfig} from './billingInformationFormConfig';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {IUpdateBillingDataPayload} from '../../../api/updateBillingData';
import {
    fetchBillingInformation,
    resetToInitialBillingInformationState,
    updateBillingInformation,
} from '../../../store/reducers/settingsPageSlice';
import {billingInformationSelector, isBillingInformationLoadingSelector} from '../../../store/selectors/settingsPageSelectors';

interface IConnectedBillingDataProps {
    readonly isLoading: boolean;
    readonly billingInfo: IBillingInfo | null;
    readonly fetchBillingInformation: typeof fetchBillingInformation;
    readonly resetToInitialBillingInformationState: typeof resetToInitialBillingInformationState;
    readonly updateBillingInformation: typeof updateBillingInformation;
    readonly countryList: IModelDictionaryDatum[] | null;
}

interface IBillingDataProps extends IConnectedBillingDataProps, WithTranslation {}

const BillingData: React.FC<IBillingDataProps> = ({
    isLoading,
    billingInfo,
    fetchBillingInformation,
    updateBillingInformation,
    countryList,
    ...props
}) => {
    const [value, setValue] = useState<{[key: string]: any} | null>(null),
        [formConfig, setFormConfig] = useState<IFormConfig | null>(null),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        fetchBillingInformation();
    }, [fetchBillingInformation]);

    useEffect(() => {
        setBillingFormConfig();
    }, [billingInfo]);

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        setValue(data.value);
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    function submitBillingInfoForm() {
        if (value === null) {
            return;
        }

        const payload: IUpdateBillingDataPayload = {
            companyName: value.companyName,
            email: value.companyEmail,
            taxId: value.taxId,
            mobile: value.phone,
            address: value.address,
            state: value.state,
            city: value.city,
            zip: value.postCode,
            countryId: value.country,
        };
        updateBillingInformation(payload);
    }

    function setBillingFormConfig() {
        const {t} = props,
            formValues = {
                address: billingInfo?.address,
                companyName: billingInfo?.companyName,
                companyEmail: billingInfo?.email,
                country: billingInfo?.country?.id,
                phone: billingInfo?.mobile,
                postCode: billingInfo?.zip,
                state: billingInfo?.state,
                city: billingInfo?.city,
                taxId: billingInfo?.taxId,
            },
            countries = countryList ? sortMultiselectLabels(convertToMultiselectLabels(countryList, t)) : [],
            formConfig = billingInformationFormConfig(countries);

        setFormConfig(formConfig);
        setValue(formValues);
    }

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    return (
        <div>
            {formConfig && (
                <Form
                    config={formConfig}
                    onValueStateChange={onValueStateChange}
                    value={value}
                    submitForm={() => submitBillingInfoForm()}
                    controlName={'changeBillingDetailsForm'}
                />
            )}

            <Loader type={LoaderType.Local} showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        isLoading: isBillingInformationLoadingSelector(state),
        billingInfo: billingInformationSelector(state),
        countryList: countriesSelector(state),
    }),
    {
        fetchBillingInformation,
        resetToInitialBillingInformationState,
        updateBillingInformation,
    }
)(withTranslation()(BillingData));
