import {createSelector} from '@reduxjs/toolkit';
import {IMapHostState} from '../reducers/mapHostSlice';

export const selectMapHostSlice = (state: any): IMapHostState => {
    return state.mapHost;
};

export const selectedMapItemSelector = createSelector([selectMapHostSlice], (state: IMapHostState) => state.selectedItem);
export const mapType = createSelector([selectMapHostSlice], (state: IMapHostState) => state.mapType);
export const mapCenterSelector = createSelector([selectMapHostSlice], (state: IMapHostState) => state.mapCenter);
export const mapZoomSelector = createSelector([selectMapHostSlice], (state: IMapHostState) => state.mapZoom);
