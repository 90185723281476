import {AlertTriangle} from 'react-feather';
import {IconSize, Translation} from 'marine-panel-common-web';
import {FC, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {removePricingStrategy} from '../../../../../../../../store/reducers/pricingStrategiesSlice';

export interface IPricingStrategyRemoveButtonProps {
    id: string;
    readonly removePricingStrategy: typeof removePricingStrategy;
}

const PricingStrategyRemoveButton: FC<IPricingStrategyRemoveButtonProps> = ({removePricingStrategy, id}) => {
    const [isPopupOpen, setPopupOpen] = useState<boolean>(false),
        dispatch = useDispatch();

    const confirmAction = () => {
        dispatch(removePricingStrategy(id));
        setPopupOpen(false);
    };
    const renderActionPopup = (isOpen: boolean) => {
        if (!isOpen) return null;

        return (
            <div className="action-popup">
                <p className="title main">
                    <Translation text="editMenuItems.deletePricingStrategyPopup.title" />
                </p>
                <p className="edit-form-control-subtitle">
                    <Translation text="editMenuItems.deletePricingStrategyPopup.message" />
                </p>
                <p className="warning-wrapper">
                    <AlertTriangle size={IconSize.DeleteReservationModal} color={'#EA5455'} />
                    <Translation text="mainMap.reservation.deleteModal.warning" />
                </p>
                <div className="action-popup-button-wrapper">
                    <button className="sm-btn smaller btn-black-white borderless" onClick={() => setPopupOpen(false)}>
                        <Translation text="buttons.cancel" />
                    </button>
                    <button className="btn-black-white sm-btn smaller" onClick={() => confirmAction()}>
                        <Translation text="buttons.delete" />
                    </button>
                </div>
            </div>
        );
    };
    return (
        <div className="action-button-wrapper">
            <button className="btn btn-red borderless" onClick={() => setPopupOpen(!isPopupOpen)}>
                <span>
                    <Translation text="buttons.delete" />
                </span>
            </button>
            {renderActionPopup(isPopupOpen)}
        </div>
    );
};
export default connect(() => ({}), {removePricingStrategy})(PricingStrategyRemoveButton);
