import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function deletePricingStrategyAPI(authToken: string | null, id: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return marineHubAPI.delete(`api/berth_pricing_strategies/${id}`, new RestQueryParams(), headers);
}
