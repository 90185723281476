import {Berth, Form, FormControlChangeType, IMultiselectOption, Translation} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../../store/reducers';
import {
    changeBerthDescription,
    changeBerthName,
    changeCurrentlyEditedControlId,
    changeIsBerthEditWizardLoading,
    changeMarinaList,
    resetToInitialBerthEditWizardState,
} from '../../../../../../../store/reducers/berthEditWizardSlice';
import {changeIsModalOpen} from '../../../../../../../store/reducers/modalSlice';
import {currentlyEditedControlId, isActionSuccessfulSelector} from '../../../../../../../store/selectors/berthEditWizardSelectors';
import EditFormControl from '../../../Common/EditFormControl';
import {berthDescriptionFormConfig} from './berthDescriptionFormConfig';
import {berthMarinaFormConfig} from './berthMarinaFormConfig';
import {berthNameFormConfig} from './berthNameFormConfig';

interface IBerthBasicsProps {
    readonly berth: Berth | null;
    readonly marinasList: IMultiselectOption[] | [];
    readonly resetToInitialBerthEditWizardState: typeof resetToInitialBerthEditWizardState;
    readonly isActionSuccessful: boolean;
    readonly currentlyEditedControlId: string | null;
    readonly changeBerthName: typeof changeBerthName;
    readonly changeBerthDescription: typeof changeBerthDescription;
    readonly changeMarinaList: typeof changeMarinaList;
    readonly changeCurrentlyEditedControlId: typeof changeCurrentlyEditedControlId;
}

interface IBerthBasicsValues {
    name: string | null;
    description: string | null;
    marina: string | undefined;
}

const BerthBasics: FC<IBerthBasicsProps> = ({berth, marinasList, changeMarinaList, currentlyEditedControlId}) => {
    const params = useParams(),
        berthId = params.id,
        dispatch = useDispatch(),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<IBerthBasicsValues>({
            name: null,
            description: null,
            marina: undefined,
        });

    const editedItemId = currentlyEditedControlId;

    useEffect(() => {
        if (berth !== null) {
            setValue({
                name: berth ? berth.name : null,
                description: berth ? berth.description : null,
                marina: berth?.sector ? berth?.sector?.marina?.name : undefined,
            });
        }
    }, [berth]);

    useEffect(() => {
        const subscriptions: Subscription[] = [];

        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState) => {
                            const formValues = {
                                name: data.value && data.value.name ? data.value.name : prevState?.name,
                                description: data.value && data.value.description ? data.value.description : prevState?.description,
                                marina: data?.value?.marina && data?.value?.marina,
                            };
                            return formValues;
                        });
                    }
                })
        );
    }, [onValueStateChange$$]);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    const defaultMarina = {
        value: berth?.sector?.marina ? berth?.sector?.marina?.id : '',
        label: berth?.sector?.marina ? berth?.sector?.marina?.name : '',
    };

    const onConfirmAction = (action: any) => {
        dispatch(action);
        dispatch(changeCurrentlyEditedControlId(null));
    };
    return (
        <div id="berth_basics" className="details-edit-section col-xl-6" data-section="berth_basics">
            <h3 className="title main">
                <Translation text="editMenuItems.sections.berth_basics.title" />
            </h3>

            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'berth_name'}
                titleTranslationKey="editMenuItems.sections.berth_basics.formControls.berth_name"
                onConfirm={() => onConfirmAction(changeBerthName(berthId, values?.name))}
                confirmButtonsDisabled={true}
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.berth_basics.formControls.berth_name_description" />
                        </p>
                        <Form
                            controlName={'berthNameForm'}
                            config={berthNameFormConfig(values?.name, () => dispatch(changeCurrentlyEditedControlId(null)))}
                            value={values?.name}
                            onValueStateChange={onValueStateChange}
                            submitForm={() => onConfirmAction(changeBerthName(berthId, values?.name))}
                        />
                    </>
                }
                displayChildren={<p className="secondary-title description">{berth?.name}</p>}
            />
            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'berth_description'}
                onConfirm={() => onConfirmAction(changeBerthDescription(berthId, values?.description))}
                titleTranslationKey="editMenuItems.sections.berth_basics.formControls.berth_description"
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.berth_basics.formControls.berth_description_description" />
                        </p>
                        <Form
                            controlName={'berthDescriptionForm'}
                            config={berthDescriptionFormConfig(values?.description)}
                            value={values?.description}
                            onValueStateChange={onValueStateChange}
                        />
                    </>
                }
                displayChildren={<p className="secondary-title description">{berth?.description}</p>}
            />
            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'berth_marina'}
                onConfirm={() => onConfirmAction(changeMarinaList(berthId, values?.marina))}
                titleTranslationKey="editMenuItems.sections.berth_basics.formControls.berth_marina"
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.berth_basics.formControls.berth_marina_description" />
                        </p>
                        <Form
                            controlName="berthMarinaForm"
                            value={values?.marina}
                            config={berthMarinaFormConfig(values?.marina, marinasList, defaultMarina)}
                            onValueStateChange={onValueStateChange}
                        />
                        {/* hidden - for presentation purposes - button is not connected to any view - PT */}
                        {/* <div className="create-marina-wrapper">
                            <p className="d-flex align-items-center">
                                <Translation text="editMenuItems.sections.berth_basics.cantFindMarina" />
                            </p>
                            <button className="sm-btn smaller btn-black-white">
                                <Translation text="buttons.createMarine" />
                            </button>
                        </div> */}
                    </>
                }
                displayChildren={
                    <p className="secondary-title description">
                        {berth?.sector ? (
                            berth?.sector.marina.name
                        ) : (
                            <Translation text="editMenuItems.sections.berth_basics.noMarinaConnected" />
                        )}
                    </p>
                }
            />
        </div>
    );
};
export default connect(
    (state: RootState) => ({
        isActionSuccessful: isActionSuccessfulSelector(state),
        currentlyEditedControlId: currentlyEditedControlId(state),
    }),
    {
        resetToInitialBerthEditWizardState,
        changeIsModalOpen,
        changeBerthName,
        changeIsBerthEditWizardLoading,
        changeBerthDescription,
        changeMarinaList,
        changeCurrentlyEditedControlId,
    }
)(BerthBasics);
