import {Observable} from 'rxjs';
import {ApiResponseBase, marineHubChatContactsAPI, RestQueryParams} from 'marine-panel-common-web';
import {ChatContact} from '@solvee/reactjs-websocket-text-chat';

export function getChatContactsApi(accountId: string, authToken?: string | null): Observable<ApiResponseBase<ChatContact[]>> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return marineHubChatContactsAPI(accountId).get('api/accounts/chat_contacts', new RestQueryParams(), headers);
}
