import {isNotNullOrUndefined, WithLocation, withLocation, WizardComponent, IconSize} from 'marine-panel-common-web';
import React, {Component} from 'react';
import {ChevronRight, CreditCard, User} from 'react-feather';
import PersonalInformation from './PersonalInformation';
import LayoutWrapper from '../LayoutWrapper';
import Preferences from './Preferences';

declare type WithLocationProps = WithLocation;

interface IProfileState {
    activeTab: number;
    stepper: any;
}

class Profile extends Component<WithLocationProps, IProfileState> {
    private readonly elementRef: any;

    constructor(props: WithLocationProps) {
        super(props);

        this.state = {
            activeTab: 0,
            stepper: null,
        };
        this.elementRef = React.createRef();
    }

    componentDidMount(): void {
        if (isNotNullOrUndefined(this.props.location) && isNotNullOrUndefined(this.props.location.state)) {
            this.setState({activeTab: this.props.location.state?.activeTab});
        }
    }

    render() {
        const steps = [
            {
                id: 'personal',
                title: 'Personal',
                subtitle: 'Lorem ipsum sit dolor',
                className: 'personal-step-button',
                icon: <User size={IconSize.ProfileSteps} />,
                content: <PersonalInformation stepper={this.state.stepper} />,
            },
            {
                id: 'preferences',
                title: 'Preferences',
                className: 'preferences-step-button',
                subtitle: 'Lorem ipsum sit dolor',
                icon: <CreditCard size={IconSize.ProfileSteps} />,
                content: <Preferences stepper={this.state.stepper} />,
            },
        ];

        return (
            <LayoutWrapper>
                <div className="modern-vertical-wizard">
                    <WizardComponent
                        type="modern-vertical"
                        ref={this.elementRef}
                        steps={steps}
                        options={{linear: false}}
                        separator={<ChevronRight size={IconSize.StepperSeparator} />}
                        instance={(el: any) => this.setState({stepper: el})}
                        activeTab={this.state.activeTab}
                    />
                </div>
            </LayoutWrapper>
        );
    }
}

export default withLocation(Profile);
