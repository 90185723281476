import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Berth, Marina} from 'marine-panel-common-web';

export enum ModalNames {
    CREATE_BERTH = 'CreateBerth',
    CREATE_MARINA = 'CreateMarina',
    DELETE_PHOTO = 'DeletePhoto',
    CHANGE_PHOTO = 'ChangePhoto',
    ADD_PHOTO = 'AddPhoto',
    CREATE_EXTRA = 'CreateExtra',
    CREATE_RESERVATION = 'CreateReservation',
    EDIT_EXTRA = 'EditExtra',
    SET_MARINA_LOCATION = 'SetMarinaLocation',
    SET_BERTH_LOCATION = 'SetBerthLocation',
    CREATE_PRICING_STRATEGY = 'CreatePricingStrategy',
    EDIT_PRICING_STRATEGY = 'EditPricingStrategy',
}

export interface ISetModalName {
    name: ModalNames | null | undefined;
}

export interface IChangeIsModalLoading {
    isLoading: boolean;
}

export interface IChangeIsModalOpen {
    isOpen: boolean;
}
export interface IGetPhotoId {
    photoId: string | null;
}

export interface ISetCurrentlyEditedItemId {
    currentlyEditedItemId: string | null;
}

export interface ISetBerth {
    berth: Berth | null;
}

export interface ISetMarina {
    marina: Marina | null;
}
export interface IModalState {
    name: ModalNames | null | undefined;
    isLoading: boolean;
    isOpen: boolean;
    berth: Berth | null;
    marina: Marina | null;
    photoId: string | null;
    currentlyEditedItemId: string | null;
}

const initialState: IModalState = {
    name: null,
    isLoading: false,
    isOpen: false,
    berth: null,
    marina: null,
    photoId: null,
    currentlyEditedItemId: null,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        getPhotoId: {
            reducer: (state: IModalState, action: PayloadAction<IGetPhotoId>) => {
                return {
                    ...state,
                    photoId: action.payload.photoId,
                };
            },
            prepare: (photoId: string | null) => {
                return {
                    payload: {
                        photoId: photoId,
                    },
                };
            },
        },
        setBerth: {
            reducer: (state: IModalState, action: PayloadAction<ISetBerth>) => {
                return {
                    ...state,
                    berth: action.payload.berth,
                };
            },
            prepare: (berth: Berth | null) => {
                return {
                    payload: {
                        berth: berth,
                    },
                };
            },
        },
        setCurrentlyEditedItemId: {
            reducer: (state: IModalState, action: PayloadAction<ISetCurrentlyEditedItemId>) => {
                return {
                    ...state,
                    currentlyEditedItemId: action.payload.currentlyEditedItemId,
                };
            },
            prepare: (currentlyEditedItemId: string | null) => {
                return {
                    payload: {
                        currentlyEditedItemId: currentlyEditedItemId,
                    },
                };
            },
        },
        setMarina: {
            reducer: (state: IModalState, action: PayloadAction<ISetMarina>) => {
                return {
                    ...state,
                    marina: action.payload.marina,
                };
            },
            prepare: (marina: Marina | null) => {
                return {
                    payload: {
                        marina: marina,
                    },
                };
            },
        },
        setModalName: {
            reducer: (state: IModalState, action: PayloadAction<ISetModalName>) => {
                return {
                    ...state,
                    name: action.payload.name,
                };
            },
            prepare: (name: ModalNames | null | undefined) => {
                return {
                    payload: {
                        name: name,
                    },
                };
            },
        },
        changeIsModalLoading: {
            reducer: (state: IModalState, action: PayloadAction<IChangeIsModalLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare: (isLoading: boolean) => {
                return {
                    payload: {
                        isLoading: isLoading,
                    },
                };
            },
        },
        changeIsModalOpen: {
            reducer: (state: IModalState, action: PayloadAction<IChangeIsModalOpen>) => {
                return {
                    ...state,
                    isOpen: action.payload.isOpen,
                };
            },
            prepare: (isOpen: boolean) => {
                return {
                    payload: {
                        isOpen: isOpen,
                    },
                };
            },
        },
        resetToInitialModalState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setModalName,
    changeIsModalLoading,
    resetToInitialModalState,
    setCurrentlyEditedItemId,
    changeIsModalOpen,
    setBerth,
    setMarina,
    getPhotoId,
} = modalSlice.actions;
export default modalSlice.reducer;
