import {Observable} from 'rxjs';
import {ApiSingleResponseBase, marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {ChatServerMessage, ChatServerMessagePayload} from '../../components/WithSoccetChatConnection/chat.types';

export function saveMessageApi(
    message: ChatServerMessagePayload,
    authToken?: string
): Observable<ApiSingleResponseBase<ChatServerMessage>> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return marineHubAPI.post('api/messages', message, new RestQueryParams(), headers);
}
