import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    applyReservationsFilters,
    changeIsReservationActionComplete,
    changeIsReservationActionProcessing,
    changeIsReservationsPageLoading,
    changeReservationAvailabilityStatus,
    changeReservationCountFilters,
    changeReservationPaidStatus,
    changeReservationsError,
    changeReservationsPagination,
    createReservation,
    deleteReservation,
    fetchReservationDetails,
    fetchReservations,
    fetchVesselLatestPosition,
    IFetchLatestVesselPosition,
    IFetchReservationDetails,
    IReservationAction,
    IReservationCountFilters,
    setActiveReservation,
    setLatestVesselPosition,
    setReservations,
    setReservationsCount,
    setReservationsMetadata,
    updateReservation,
} from '../reducers/reservationsSlice';
import {addAlert, AlertType, authTokenSelector, deepCloneObject, flattenObj} from 'marine-panel-common-web';
import {getErrorMessage} from './berthsEpic';
import {PayloadAction} from '@reduxjs/toolkit';
import {getMetadataDetails, isNotNullOrUndefined, Reservation} from 'marine-panel-common-web';
import {changeReservationArrivalStatusAPI} from '../../api/berth/chageReservationArrivalStatus';
import {changeReservationPaidStatusAPI} from '../../api/reservation/changeReservationPaidStatus';
import {createReservationAPI} from '../../api/reservation/createReservation';
import {deleteReservationAPI} from '../../api/reservation/deleteReservation';
import {getReservationAPI} from '../../api/reservation/getReservation';
import {getReservationMonthlyCountAPI} from '../../api/reservation/getReservationMonthlyCount';
import {getReservationsAPI} from '../../api/reservation/getReservations';
import {changeSelectedItem, IChangeSelectedMapItem, resetMapToInitialState, SelectedItemType} from '../reducers/mapHostSlice';
import {reservationFiltersSelector, reservationsPaginationSelector} from '../selectors/reservationSelectors';
import {updateReservationAPI} from '../../api/reservation/updateReservation';
import {getVesselLatestPositionAPI} from '../../api/getVesselLatestPosition';

const fetchReservationsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getReservations(action$, state$, fetchReservations);
};

const fetchReservationDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(fetchReservationDetails.type),

        concatMap((action: PayloadAction<IFetchReservationDetails>) => {
            const authToken = authTokenSelector(state$.value);
            return getReservationAPI(action.payload.reservationId, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = [setActiveReservation(resp), changeIsReservationsPageLoading(false)];
                    return of(...actions);
                }),
                catchError((error) => of(...getErrorMessage(error)))
            );
        }),
        catchError(() => {
            return of();
        })
    );

const changeReservationsPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getReservations(action$, state$, changeReservationsPagination);
};

const changeReservationPaidStatusEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return reservationAction(
        action$,
        state$,
        changeReservationPaidStatus,
        'mainMap.reservation.actionMessages.reservationPaidStatusChanged',
        changeReservationPaidStatusAPI
    );
};

const changeReservationAvailabilityStatusEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return reservationAction(
        action$,
        state$,
        changeReservationAvailabilityStatus,
        'mainMap.reservation.actionMessages.reservationArrivedStatusChanged',
        changeReservationArrivalStatusAPI
    );
};
const updateReservationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return reservationAction(
        action$,
        state$,
        updateReservation,
        'mainMap.reservation.actionMessages.reservationUpdated',
        updateReservationAPI
    );
};

const makeReservationActiveEpic: Epic = (action$) => {
    return action$.pipe(
        ofType(changeSelectedItem.type),
        switchMap((action: PayloadAction<IChangeSelectedMapItem>) => {
            if (action.payload.selectedItem?.selectedItemType === SelectedItemType.RESERVATION && action.payload.activatedItem) {
                return of(setActiveReservation(action.payload.activatedItem as Reservation));
            }
            return of();
        })
    );
};
const deleteReservationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return reservationAction(action$, state$, deleteReservation, 'mainMap.reservation.actionMessages.deleteSuccess', deleteReservationAPI);
};

const applyReservationFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getReservations(action$, state$, applyReservationsFilters);
};

const createReservationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return reservationAction(action$, state$, createReservation, 'mainMap.reservation.actionMessages.createSuccess', createReservationAPI);
};

const getReservations = (action$: Observable<any>, state$: StateObservable<any>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = reservationsPaginationSelector(state$.value),
                filters = deepCloneObject(reservationFiltersSelector(state$.value)),
                filterObj = {
                    ...filters,
                    ...paginationParams,
                },
                flattened: any = flattenObj(filterObj);
            return getReservationsAPI(authToken, flattened).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setReservations(resp['hydra:member']), setReservationsMetadata(metadata)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...updateListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...updateListErrorActions(error));
        })
    );
};

const getReservationCountEpic = (action$: Observable<any>, state$: StateObservable<any>) => {
    return action$.pipe(
        ofType(changeReservationCountFilters),
        switchMap((action: any) => {
            const authToken = authTokenSelector(state$.value),
                filters: IReservationCountFilters = action.payload.filters;
            const filterArray = Object.entries(filters)
                .filter(([key, value]) => value)
                .map(([key, value]) => {
                    return {path: key, val: value};
                });
            return getReservationMonthlyCountAPI(authToken, filterArray).pipe(
                switchMap((resp: any) => {
                    const actions = [setReservationsCount(resp['hydra:member'])];
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...updateListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...updateListErrorActions(error));
        })
    );
};

const getVesselLatestPositionEpic = (action$: Observable<any>, state$: StateObservable<any>) => {
    return action$.pipe(
        ofType(fetchVesselLatestPosition),
        switchMap((action: PayloadAction<IFetchLatestVesselPosition>) => {
            const authToken = authTokenSelector(state$.value),
                vesselId = action.payload.vesselId;

            return getVesselLatestPositionAPI(authToken, vesselId).pipe(
                switchMap((resp: any) => {
                    const actions = [setLatestVesselPosition(resp)];
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...updateListErrorActions(error));
                })
            );
        }),
        catchError((error) => {
            return of(...updateListErrorActions(error));
        })
    );
};

const reservationAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    successMessage: string,
    api: (authToken: string | null, reservationId?: string, payload?: any) => Observable<any>
) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((action: PayloadAction<IReservationAction>) => {
            const authToken = authTokenSelector(state$.value),
                reservationId = action.payload.reservationId,
                payload = isNotNullOrUndefined(action.payload) ? action.payload : {};

            return api(authToken, reservationId, payload).pipe(
                switchMap((resp: any) => {
                    const actions = [
                        changeIsReservationActionComplete(true),
                        changeIsReservationActionProcessing(false),
                        addAlert({message: successMessage}),
                        fetchReservations(),
                        resetMapToInitialState(),
                    ];
                    return of(...actions);
                }),
                catchError((error) => of(...reservationActionErrorList(error)))
            );
        }),
        catchError((error) => of(...reservationActionErrorList(error)))
    );
};

const successActions = (changeSliceList?: any[]): any[] => {
    const actions = [changeIsReservationsPageLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeIsReservationsPageLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeReservationsError(getErrorMessage(error)),
    ];
};

const reservationActionErrorList = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeIsReservationActionComplete(true),
        changeIsReservationActionProcessing(false),
    ];
};

const reservationsEpic = combineEpics(
    fetchReservationsEpic,
    fetchReservationDetailsEpic,
    changeReservationsPaginationEpic,
    getReservationCountEpic,
    deleteReservationEpic,
    updateReservationEpic,
    createReservationEpic,
    changeReservationPaidStatusEpic,
    changeReservationAvailabilityStatusEpic,
    applyReservationFiltersEpic,
    makeReservationActiveEpic,
    getVesselLatestPositionEpic
);

export default reservationsEpic;
