import {ChatContact, IWithChatConnection, withChatConnection} from '@solvee/reactjs-websocket-text-chat';
import classnames from 'classnames';
import {
    ISideMenuHeader,
    NotFound,
    OperatorFullInfo,
    Sidebar,
    SkipperFullInfo,
    Toast,
    Translation,
    UserRole,
    accountSelector,
    accountUserIdSelector,
    authTokenSelector,
    changeMenuCollapsed,
    changeMenuHidden,
    initLogout,
    menuCollapsedSelector,
    menuHiddenSelector,
    userRolesSelector,
    usernameSelector,
} from 'marine-panel-common-web';
import React from 'react';
import {Menu} from 'react-feather';
import {connect} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {NavItem, NavLink} from 'reactstrap';
import {Subscription} from 'rxjs';
import {confirmMessageReadApi} from '../../api/chat/confirmMessageReadApi';
import {getChatContactsApi} from '../../api/chat/getChatContactsApi';
import {getMessagesApi} from '../../api/chat/getMessagesApi';
import {saveMessageApi} from '../../api/chat/saveMessageApi';
import {MESSAGES_NUMBER_PER_HISTORY_PAGE} from '../../config/config';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import {fetchAllBerths} from '../../store/reducers/berthsSlice';
import {ModalNames, changeIsModalOpen, setModalName} from '../../store/reducers/modalSlice';
import {chatContactsSelector} from '../../store/selectors/chatSelectors';
import {nameSelector} from '../../store/selectors/modalSelectors';
import Marinas from '../Administration/Marinas';
import Berths from '../Berths';
import Inbox from '../Inbox';
import MainMap from '../MainMap';
import Profile from '../Profile';
import MarinaReports from '../Reports';
import Settings from '../Settings';
import ModalComponent from '../Shared/ModalComponent';
import WizardEdit, {WizardEditHostType} from '../Shared/WizardEdit';
import BerthTags from '../Shared/WizardEdit/WizardContent/Berth/Tags';
import Support from '../Support';
import menu from './menu-items';
import subMenu from './sub-menu-items';
import themeConfig from './themeConfig';

interface IConnectedPanelHostProps {
    readonly account: OperatorFullInfo | SkipperFullInfo;
    readonly accountId: string;
    readonly accountUserId: string;
    readonly authToken: string;
    readonly username: string;
    readonly userRoles: UserRole[];
    readonly initLogout: typeof initLogout;
    readonly menuHidden: boolean;
    readonly menuCollapsed: boolean;
    readonly changeMenuHidden: typeof changeMenuHidden;
    readonly changeMenuCollapsed: typeof changeMenuCollapsed;
    readonly fetchAllBerths: typeof fetchAllBerths;
    readonly modalName: ModalNames | null | undefined;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly setModalName: typeof setModalName;
    readonly channelsToJoin: ChatContact[];
}

interface IExternalPanelHostProps {
    // envData: any;
    fullWidthLayout?: any;
}

interface IPanelHostState {
    width: number;
}

interface IPanelHostProps extends IConnectedPanelHostProps, IExternalPanelHostProps, IWithChatConnection {}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    private subscription: Subscription | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.fetchAllBerths();

        if (this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        } else {
            this.props.changeMenuHidden(false);
        }

        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps: Readonly<IPanelHostProps>, prevState: Readonly<IPanelHostState>, snapshot?: any) {
        if (this.state.width !== prevState.width && this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        }

        if (this.state.width !== prevState.width && this.state.width > 768) {
            this.props.changeMenuHidden(false);
        }
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }

        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const isHidden = this.props.menuHidden,
            menuCollapsed = this.props.menuCollapsed,
            navbarWrapperClasses = {
                floating: 'navbar-floating',
                sticky: 'navbar-sticky',
                static: 'navbar-static',
                hidden: 'navbar-hidden',
            },
            navbarType = 'floating',
            windowWidth = this.state.width,
            avatarUrl = (this.props.account as OperatorFullInfo)?.user?.avatar?.fileUrls?.tinyThumb;

        const isBoatsmanUser = this.isUserRoleBoatsman(),
            menuItems = menu();

        if (isBoatsmanUser) {
            const boatsmanMenuItem: ISideMenuHeader = {
                header: 'auth.header.boatsman',
            };
            menuItems.unshift(boatsmanMenuItem);
        }

        return (
            <div className={`${themeConfig.layout.skin}-layout`}>
                <h1 className="sr-only">
                    <Translation text="app.title" />
                </h1>
                <div
                    className={classnames(`wrapper vertical-layout ${navbarWrapperClasses[navbarType] || 'navbar-floating'}`, {
                        'vertical-menu-modern': windowWidth >= 768,
                        'menu-collapsed': (menuCollapsed && !isHidden) || (windowWidth <= 768 && !isHidden),
                        'menu-expanded': !menuCollapsed && windowWidth > 768,
                        'menu-hide': isHidden || windowWidth <= 768,
                        // 'vertical-overlay-menu': windowWidth < 768,
                    })}
                    {...(isHidden || windowWidth <= 768 ? {'data-col': '1-column'} : {})}>
                    <nav className="header-navbar d-md-none d-sm-flex">
                        <div className="navbar-container d-flex justify-content-between align-items-center">
                            <img className="horizontal-logo" src={themeConfig.app.appMobileImage} alt="" />
                            <ul className="bookmark-wrapper justify-content-end">
                                <NavItem className="mobile-menu me-auto">
                                    <NavLink
                                        className="nav-menu-main menu-toggle is-active"
                                        onClick={() => this.props.changeMenuHidden(!isHidden)}>
                                        <Menu className="ficon" color="#000" />
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </nav>

                    {!isHidden ? (
                        <Sidebar
                            skin={themeConfig.layout.skin}
                            logoImage={themeConfig.app.appLogoImage}
                            menuData={[menuItems, subMenu(this.onLogout)]}
                            menuCollapsed={menuCollapsed}
                            setMenuCollapsed={() => this.props.changeMenuCollapsed(!menuCollapsed)}
                            toggleMobileMenu={() => this.props.changeMenuHidden(!isHidden)}
                            currentActiveItem={null}
                        />
                    ) : null}
                    <main className="panel-wrapper">
                        <div
                            className={classnames('sidenav-overlay d-md-none d-sm-flex', {
                                show: !isHidden,
                            })}
                            onClick={() => this.props.changeMenuHidden(!isHidden)}></div>

                        <Routes>
                            <Route
                                path="/edit/berth/:id/*"
                                element={<WizardEdit editedItemType={WizardEditHostType.BERTH} />}
                                key="edit-berth"
                            />
                            <Route
                                path="/edit/marina/:id?/*"
                                element={<WizardEdit editedItemType={WizardEditHostType.MARINA} />}
                                key="edit-berth"
                            />
                            <Route path="/administration/*" element={<Marinas />} key="administration" />
                            <Route path="/reports" element={<MarinaReports />} key="reports" />
                            <Route path="/reservations" element={<MainMap />} key="reservations" />
                            <Route path="/berths" element={<Berths />} key="berths" />
                            <Route path="/berths/berthsTags/:id" element={<BerthTags isRouteComponent={true} />} key="berths_tags" />
                            <Route
                                path="/inbox"
                                element={
                                    <Inbox
                                        messagesNumberPerHistoryPage={10}
                                        canSendMessagesToOffline={this.props.canSendMessagesToOffline}
                                        authToken={this.props.authToken}
                                        getMessagesFromUser={this.props.getMessagesFromUser}
                                        confirmMessageRead={this.props.confirmMessageRead}
                                        accountId={this.props.accountId}
                                        errors={this.props.errors}
                                        hasUnreadMessages={this.props.hasUnreadMessages}
                                        addMessage={this.props.addMessage}
                                        setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                        selectedChatRoomId={this.props.selectedChatRoomId}
                                        peerConnections={this.props.peerConnections}
                                        hasNodeConnection={this.props.hasNodeConnection}
                                        allowedChannels={this.props.allowedChannels}
                                        allContactsList={this.props.allowedChannels} // ToDo not sure if this is the right props
                                        errorHandler={(e: any) => this.alertManager?.handleApiError(e)}
                                        avatarUrl={avatarUrl}
                                        showOnlineIndicator={true}
                                        chatContactsList={this.props.allowedChannels}
                                    />
                                }
                                key="inbox"
                            />
                            <Route path="/profile" element={<Profile />} key="profile" />
                            <Route path="/support" element={<Support />} key="support" />
                            <Route path="/settings" element={<Settings />} key="settings" />
                            <Route path="*" element={<NotFound />} key="not-found" />
                        </Routes>
                    </main>
                    {this.props.modalName !== null || undefined ? <ModalComponent toggleModalOpen={this.toggleModalOpen} /> : null}
                </div>
                <Toast />
            </div>
        );
    }

    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
        return <Navigate to="/" />;
    };

    private toggleModalOpen = () => {
        this.props.setModalName(null);
        this.props.changeIsModalOpen(false);
    };

    private isUserRoleBoatsman = () => {
        return this.props.userRoles.includes(UserRole.BOATSMAN);
    };

    private updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };
}

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        username: usernameSelector(state),
        userRoles: userRolesSelector(state),
        menuCollapsed: menuCollapsedSelector(state),
        menuHidden: menuHiddenSelector(state),
        modalName: nameSelector(state),
        authToken: authTokenSelector(state),
        accountId: accountUserIdSelector(state),
        accountUserId: accountUserIdSelector(state),
        channelsToJoin: chatContactsSelector(state),
    }),
    {
        initLogout,
        changeMenuHidden,
        changeMenuCollapsed,
        fetchAllBerths,
        changeIsModalOpen,
        setModalName,
    }
)(
    withChatConnection(PanelHost, {
        saveMessagesConfig: {
            saveMessagesOnlyWhenPeerIsOffline: false,
            messagesNumberPerHistoryPage: MESSAGES_NUMBER_PER_HISTORY_PAGE,
            sendMessageToOfflineAPI: saveMessageApi,
            getMessagesFromUserAPI: getMessagesApi,
            postMessageReadConfirmationAPI: confirmMessageReadApi,
            getContacts: getChatContactsApi,
        },
        websocketServerAddress: process.env.REACT_APP_WEBSOCKET_SERVER_ADDRESS || '',
    })
);
