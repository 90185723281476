import {IModelApiResponseViewObject, MarinaBroad, Pagination, Reservation, Translation} from 'marine-panel-common-web';
import {Component} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {changeBerths, fetchAllBerths, fetchBerthDetails} from '../../../../store/reducers/berthsSlice';
import {fetchAllMarinas} from '../../../../store/reducers/marinasSlice';
import {changeReservationsPagination, fetchReservations} from '../../../../store/reducers/reservationsSlice';
import {berthsSelector} from '../../../../store/selectors/berthSelectors';
import {marinasLoading, marinasSelector} from '../../../../store/selectors/marinaSelectors';
import {reservationsMetadataSelector, reservationsSelector} from '../../../../store/selectors/reservationSelectors';
import CreateReservationButton from './CreateReservationButton';
import MarinaReservationList from './MarinaReservationList';
import MarinaSelector from './MarinaSelector';
import ReservationCalendar from './ReservationDatepicker';

interface MarinaSideCardProps {
    readonly marinas: MarinaBroad[] | null;
    readonly reservations: Reservation[] | null;
    readonly reservationsMetadata: IModelApiResponseViewObject | null;
    readonly isLoading: boolean;
    readonly fetchAllMarinas: typeof fetchAllMarinas;
    readonly fetchReservations: typeof fetchReservations;
    readonly changeBerths: typeof changeBerths;
    readonly changeReservationsPagination: typeof changeReservationsPagination;
}

class MarinaSideCard extends Component<MarinaSideCardProps> {
    render() {
        const marinas = this.props.marinas,
            reservationsMetadata = this.props.reservationsMetadata;
        if (!marinas) return null;
        return (
            <>
                <div className="details-header">
                    <h2 className="sr-only">
                        <Translation text="mainMap.marinaSelector.title"></Translation>
                    </h2>
                    <div className="marina-controller-wrapper">
                        <MarinaSelector />
                        <CreateReservationButton />
                    </div>
                </div>
                <div className="details-body">
                    <ReservationCalendar />
                    <MarinaReservationList reservations={this.props.reservations} />
                </div>
                <div className="details-footer">
                    {reservationsMetadata && reservationsMetadata.total > 10 && (
                        <Pagination listMetadata={reservationsMetadata} changePage={changeReservationsPagination} />
                    )}
                </div>
            </>
        );
    }
}

export default connect(
    (state: RootState) => ({
        berths: berthsSelector(state),
        marinas: marinasSelector(state),
        reservations: reservationsSelector(state),
        reservationsMetadata: reservationsMetadataSelector(state),
        isLoading: marinasLoading(state),
    }),
    {
        fetchAllBerths,
        fetchBerthDetails,
        fetchAllMarinas,
        fetchReservations,
        changeBerths,
        changeReservationsPagination,
    }
)(MarinaSideCard);
