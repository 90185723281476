import {Form, FormControlChangeType, GeoPoint, Translation, IMultiselectOption} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../store/reducers';
import {tagListSelector} from '../../../../store/selectors/marinaEditWizardSelectors';
import {createMarina, fetchMarinaTags, ICreateMarina} from '../../../../store/reducers/marinaEditWizardSlice';
import {createMarinaFormConfig} from './createMarinaFormConfig';
import LocationInput from '../../../Shared/WizardEdit/WizardContent/Common/SetLocationModal/LocationInput';
import {SelectedItemType} from '../../../../store/reducers/mapHostSlice';
interface ICreateMarinaModalProps {
    readonly tagList: any[] | [];
    readonly createMarina: typeof createMarina;
    toggleModalOpen: () => void;
}

interface ICreateMarinaModalValues {
    name: string;
    restaurants: string | null;
    description: string | null;
    attractions: string | null;
    phone: string | null;
    email: string | null;
    location: GeoPoint | null;
    marinaTagIds: IMultiselectOption[] | null;
}
const CreateMarinaModal: FC<ICreateMarinaModalProps> = ({tagList, createMarina, toggleModalOpen}) => {
    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState: ICreateMarinaModalValues) => {
                            const formValues = {
                                name: data.value && data.value.name ? data.value.name : prevState?.name,
                                restaurants: data.value && data.value.restaurants ? data.value.restaurants : prevState?.restaurants,
                                description: data.value && data.value.description ? data.value.description : prevState?.description,
                                attractions: data.value && data.value.attractions ? data.value.attractions : prevState?.attractions,
                                phone: data.value && data.value.phone ? data.value.phone : prevState?.phone,
                                email: data.value && data.value.email ? data.value.email : prevState?.email,
                                location: prevState?.location ? prevState?.location : null,
                                marinaTagIds: data.value && data.value.marinaTagIds ? data.value.marinaTagIds : prevState?.marinaTagIds,
                            };
                            return formValues;
                        });
                    }
                })
        );
    }, []);

    const [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<ICreateMarinaModalValues>({
            name: '',
            restaurants: null,
            description: null,
            attractions: null,
            phone: null,
            email: null,
            location: null,
            marinaTagIds: null,
        }),
        subscriptions: Subscription[] = [],
        dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMarinaTags());

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [dispatch]);

    const isDisabled = !values.name || !values.location;
    const locationPayload: GeoPoint | null = values.location ? values.location : null;
    let payload: ICreateMarina | null = null;
    if (values.name && locationPayload) {
        const getMarinaTagIdPayload = (selectedTags: IMultiselectOption[]) => selectedTags.map((tag) => tag.value);

        payload = {
            name: values.name,
            location: locationPayload,
            restaurants: values.restaurants,
            description: values.description,
            attractions: values.attractions,
            phone: values.phone,
            email: values.email,
            marinaTagIds: values.marinaTagIds ? getMarinaTagIdPayload(values.marinaTagIds) : [],
        };
    }
    return (
        <div className="modal-wrapper create-marina">
            <div>
                <ModalHeader>
                    <p className="main title">
                        <Translation text="modal.createMarina.title" />
                    </p>
                    <button className="btn-close" onClick={() => toggleModalOpen()}></button>
                </ModalHeader>
            </div>
            <ModalBody>
                <div>
                    <div className="form-control offers-form-control modal-form">
                        <Form
                            controlName={'marinaNameForm'}
                            config={createMarinaFormConfig(tagList)}
                            value={values?.name}
                            onValueStateChange={onValueStateChange}
                        />
                    </div>
                    <div className="form-control offers-form-control modal-form">
                        <p className="main title">
                            <Translation text={`modal.createMarina.inputs.location`} />
                        </p>
                        <div className="location-input-wrapper">
                            <LocationInput
                                updatedCoords={
                                    (coords: any) => console.log(coords)
                                    // setValue((prevState: any) => ({...prevState, location: coords}))
                                }
                                createdCoords={(coords: any) => setValue((prevState: any) => ({...prevState, location: coords}))}
                                locationItem={SelectedItemType.MARINA}
                                predefinedLocation={null}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="sm-btn btn-black-white borderless" onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className="sm-btn btn-black-white" disabled={isDisabled} onClick={() => (payload ? createMarina(payload) : null)}>
                    <Translation text="buttons.create" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        tagList: tagListSelector(state),
    }),
    {
        createMarina,
        fetchMarinaTags,
    }
)(CreateMarinaModal);
