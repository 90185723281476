import {Form, PricingStrategyCalendarRule, Switch, Translation} from 'marine-panel-common-web';
import React, {useState} from 'react';
import {transformValueToPrice} from '../../../../../../../../../service/helperFunctions';
import PriceSummaryDetails from '../../PriceSummary';
import {setStrategyInputPriceFormConfig} from './setStrategyInputPriceFormConfig';

interface Props {
    readonly selectedStartDate: Date;
    readonly selectedEndDate: Date;
    readonly values: any;
    readonly onPayloadSend: (payload: PricingStrategyCalendarRule) => void;
}

const SetPricingStrategyInput: React.FC<Props> = ({onPayloadSend, selectedStartDate, selectedEndDate, values}) => {
    const [pricingStrategyPayload, setPricingStrategyPayload] = useState<PricingStrategyCalendarRule>({
        startsAt: selectedStartDate,
        endsAt: selectedEndDate,
        price: values.basePriceGross,
        active: true,
    });

    const changeActive = () => {
        setPricingStrategyPayload({
            ...pricingStrategyPayload,
            active: !pricingStrategyPayload.active,
        });
    };
    const changePrice = (controlName: string, value: any) => {
        const price = transformValueToPrice(value.basePriceGross);
        setPricingStrategyPayload({
            ...pricingStrategyPayload,
            price: price,
        });
    };

    const sendPayload = () => {
        if (pricingStrategyPayload !== null) {
            const transformedStrategyPayload = pricingStrategyPayload;
            if (pricingStrategyPayload.price?.amount) {
                transformedStrategyPayload.price = transformValueToPrice(pricingStrategyPayload.price?.amount);
            }
            onPayloadSend(transformedStrategyPayload);
        }
    };

    return (
        <div className="price-summary-wrapper price-strategy-input">
            <div className="form-wrapper">
                <div className="price-summary item">
                    <h4 className="main title">
                        <Translation text="modal.createPricingStrategy.inputs.setAvailability" />
                    </h4>
                    <p className="secondary-title description">
                        <Translation text="modal.createPricingStrategy.inputs.setAvailabilityDescription" />
                    </p>
                    <div className="activity-wrapper">
                        <span className="title">
                            <Translation
                                text={`modal.createPricingStrategy.inputs.${pricingStrategyPayload.active ? 'active' : 'inactive'}`}
                            />
                        </span>
                        <Switch name="active" checked={pricingStrategyPayload.active} handleChange={changeActive} />
                    </div>
                </div>
                <div className="price-summary item">
                    <h4 className="main title">
                        <Translation text="modal.createPricingStrategy.inputs.dateRangePrice" />
                    </h4>
                    <p className="secondary-title description">
                        <Translation text="modal.createPricingStrategy.inputs.dateRangePriceDescription" />
                    </p>
                    <Form
                        controlName={'basePrice'}
                        config={setStrategyInputPriceFormConfig()}
                        value={values}
                        onValueStateChange={changePrice}
                    />
                </div>
            </div>
            <div className="summary-wrapper">
                <PriceSummaryDetails values={values} changedGrossPrice={pricingStrategyPayload.price?.amount} />
                <button className="sm-btn btn btn-create set-price-button" onClick={sendPayload}>
                    <Translation text="modal.createPricingStrategy.buttons.setPrice" />
                </button>
            </div>
        </div>
    );
};

export default SetPricingStrategyInput;
