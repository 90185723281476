import {Berth, Marina, Translation, isNullOrUndefined} from 'marine-panel-common-web';
import {FC} from 'react';
import {connect} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {RootState} from '../../../../store/reducers';
import {deletePhoto} from '../../../../store/reducers/berthEditWizardSlice';
import {deleteMarinaPhoto} from '../../../../store/reducers/marinaEditWizardSlice';
import {currentlyEditedBerth} from '../../../../store/selectors/berthEditWizardSelectors';
import {photoIdSelector} from '../../../../store/selectors/modalSelectors';
import styles from '../style.module.scss';
import {currentlyEditedMarina} from '../../../../store/selectors/marinaEditWizardSelectors';

interface IDeletePhotoModalProps {
    readonly deletePhoto: typeof deletePhoto;
    readonly deleteMarinaPhoto: typeof deleteMarinaPhoto;
    readonly berth?: Berth | null;
    readonly marina?: Marina | null;
    readonly photoId?: string | null;

    toggleModalOpen: () => void;
}

const DeletePhotoModal: FC<IDeletePhotoModalProps> = ({deletePhoto, berth, marina, deleteMarinaPhoto, photoId, toggleModalOpen}) => {
    const updateAction = (photoId?: string | null) => {
        if ((isNullOrUndefined(berth) && isNullOrUndefined(marina)) || isNullOrUndefined(photoId)) return;
        if (berth && photoId) {
            deletePhoto(photoId, berth.id);
        }
        if (marina && photoId) {
            deleteMarinaPhoto(photoId, marina.id);
        }
    };

    return (
        <div className="modal-wrapper">
            <ModalHeader>
                <p className={styles.title}>
                    <Translation text="modal.deletePhoto.title" />
                </p>
                <button className="btn-close" onClick={() => toggleModalOpen()}></button>
            </ModalHeader>
            <ModalBody className={styles.container}>
                <div className={styles.content}>
                    <p>
                        <Translation text="modal.deletePhoto.description" />
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-underline btn-underline-primary close-modal-button" onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className="btn btn-create delete-photo-button" onClick={() => updateAction(photoId)}>
                    <Translation text="buttons.delete" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        berth: currentlyEditedBerth(state),
        marina: currentlyEditedMarina(state),
        photoId: photoIdSelector(state),
    }),
    {
        deletePhoto,
        deleteMarinaPhoto,
    }
)(DeletePhotoModal);
