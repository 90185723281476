import {createSelector} from '@reduxjs/toolkit';
import {IBerthsState} from '../reducers/berthsSlice';

export const selectBerthsSlice = (state: any): IBerthsState => {
    return state.berths;
};

export const berthsSelector = createSelector([selectBerthsSlice], (state: IBerthsState) => state.berths);

export const activeBerthSelector = createSelector([selectBerthsSlice], (state: IBerthsState) => state.activeBerth);
export const berthsLoading = createSelector([selectBerthsSlice], (state: IBerthsState) => state.isLoading);
export const berthMetadataSelector = createSelector([selectBerthsSlice], (state: IBerthsState) => state.metadata);
export const berthPaginationSelector = createSelector([selectBerthsSlice], (state: IBerthsState) => state.pagination);
export const berthInitializedSelector = createSelector([selectBerthsSlice], (state: IBerthsState) => state.isInitialized);

export const berthFiltersSelector = createSelector([selectBerthsSlice], (state: IBerthsState) => state.filters);
