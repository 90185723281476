import {
    MultiSelectType,
    FormButtonType,
    collectionInputDataMapper,
    InputType,
    collectionOutputDataMapper,
    ValidationRules,
    FormControlType,
    IMultiselectOption,
    DateFormat,
} from 'marine-panel-common-web';
import moment from 'moment';

export const createReservationFormConfig: any = (berthList: any, vesselTypes?: IMultiselectOption[]) => {
    return {
        controlType: 'form',
        class: '',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'reservation_status',
                controlType: 'group',
                class: 'row',
                controls: {
                    isPaid: {
                        controlType: 'control',
                        defaultValue: false,
                        formControlType: FormControlType.SWITCH,
                        validationRules: [],
                        placeholder: '',
                        isLabelHidden: false,
                        label: 'reservations.form.paid',
                        hostClass: 'col-xl-2 form-control switch-label-container',
                    },
                    isArrived: {
                        controlType: 'control',
                        defaultValue: false,
                        formControlType: FormControlType.SWITCH,
                        validationRules: [],
                        placeholder: '',
                        isLabelHidden: false,
                        label: 'reservations.form.arrived',
                        hostClass: 'col-xl-2 form-control switch-label-container',
                    },
                },
            },
            {
                key: 'reservation_details',
                controlType: 'group',
                class: 'row',
                controls: {
                    date: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.DATE,
                        validationRules: [
                            {
                                name: ValidationRules.IS_DATE_GTE_THAN,
                                params: {
                                    valueAccessor: (data: any) => data,
                                    compareAccessor: () => moment().subtract(1, 'day').toDate(),
                                },
                            },
                            {name: ValidationRules.IS_REQUIRED, params: {}},
                        ],
                        placeholder: 'reservations.form.selectDate',
                        label: 'reservations.form.date',
                        hostClass: 'col-xl-6 form-control reservation-single-datepicker',
                        isLabelHidden: false,
                        minDate: new Date(),
                        openToDate: new Date(),
                        type: InputType.DATE,
                        dateFormat: DateFormat.FormDateDefault,
                    },
                    berth: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: berthList ? berthList : [],
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        placeholder: 'reservations.form.selectBerth',
                        label: 'reservations.form.berth',
                        hostClass: 'col-xl-6 form-control berth-type-control',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                    },
                },
            },
            {
                key: 'skipper_details',
                controlType: 'group',
                class: 'row',
                controls: {
                    skipperName: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        placeholder: 'reservations.form.skipperName',
                        isLabelHidden: false,
                        label: 'reservations.form.skipperName',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-6 form-control',
                    },
                    email: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED, params: {}},
                            {name: ValidationRules.IS_EMAIL, params: {}},
                        ],
                        placeholder: 'reservations.form.skipperEmail',
                        isLabelHidden: false,
                        label: 'reservations.form.skipperEmail',
                        type: InputType.EMAIL,
                        hostClass: 'col-xl-12 form-control',
                    },
                    phone: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED, params: {}},
                            {name: ValidationRules.MIN_LENGTH, params: {length: 9}},
                        ],
                        placeholder: 'reservations.form.skipperPhone',
                        isLabelHidden: false,
                        label: 'reservations.form.skipperPhone',
                        type: InputType.TEL,
                        hostClass: 'col-xl-6 form-control',
                    },
                },
            },
            {
                key: 'yacht_details',
                controlType: 'group',
                class: 'row',
                controls: {
                    vesselName: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        placeholder: 'reservations.form.yachtName',
                        isLabelHidden: false,
                        label: 'reservations.form.yachtName',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-6 form-control',
                    },
                    registrationNumber: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        placeholder: 'reservations.yachtNo',
                        isLabelHidden: false,
                        label: 'reservations.yachtNo',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-6 form-control',
                    },
                    vesselType: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: vesselTypes ? vesselTypes : [],
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: 'reservations.form.yachtTypePlaceholder',
                        label: 'reservations.form.yachtType',
                        hostClass: 'col-xl-6 form-control vessel-type-control',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                    },
                    price: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        hostClass: 'col-xl-2 form-control',
                        label: 'reservations.form.price',
                        placeholder: 'reservations.form.price',
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        minValue: 0,
                        stepValue: 100,
                        type: InputType.NUMBER,
                    },
                },
            },
            {
                key: 'note_submit',
                controlType: 'group',
                class: 'row',
                controls: {
                    note: {
                        controlType: 'control',
                        formControlType: FormControlType.TEXTAREA,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}, {name: ValidationRules.MAX_LENGTH, params: {length: 2000}}],
                        placeholder: 'reservations.form.note',
                        isLabelHidden: false,
                        rows: 5,
                        maxLength: 2000,
                        label: 'reservations.form.note',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                controlType: 'group',
                key: 'submit',
                class: 'row btn-row',
                controls: {
                    submitButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: FormButtonType.SUBMIT,
                        inputStyles: 'btn',
                        buttonName: 'submit',
                        defaultContainerStyles: '',
                        hostClass: 'form-control align-items-end',
                        containerStyles: '',
                        defaultValue: null,
                        isLabelHidden: true,
                        btnText: 'buttons.createReservation',
                        buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                            return !isFormValid;
                        },
                    },
                },
            },
        ],
    };
};
