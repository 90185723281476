import {LayoutWrapper, Loader, Marina, MarinaBroad, Translation} from 'marine-panel-common-web';
import {Component} from 'react';
import {connect, useSelector} from 'react-redux';
import {Card, Table} from 'reactstrap';
import {RootState} from '../../../store/reducers';
import {changeMarina, fetchAllMarinas} from '../../../store/reducers/marinasSlice';
import {ModalNames, changeIsModalOpen, setModalName} from '../../../store/reducers/modalSlice';
import {marinasLoading, marinasSelector} from '../../../store/selectors/marinaSelectors';
import MarinaItem from './MarinaItem';
import {redirectToMarinaList} from '../../../store/selectors/marinaEditWizardSelectors';
import {changeRedirectToMarinaList} from '../../../store/reducers/marinaEditWizardSlice';

interface IConnectedMarinaViewMapProps {
    readonly marinas: MarinaBroad[] | null;
    readonly isLoading: boolean;
    readonly fetchAllMarinas: typeof fetchAllMarinas;
    readonly changeMarina: typeof changeMarina;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly setModalName: typeof setModalName;
    readonly changeRedirectToMarinaList: typeof changeRedirectToMarinaList;
    readonly redirectToMarinaList: boolean;
}

interface IMarinaViewMapState {
    marinas: Marina[];
    selectedMarina: Marina | null;
    currentlyEditedMarina: Marina | null;
}

class Marinas extends Component<IConnectedMarinaViewMapProps, IMarinaViewMapState> {
    constructor(props: IConnectedMarinaViewMapProps) {
        super(props);
        this.state = {
            marinas: [],
            selectedMarina: null,
            currentlyEditedMarina: null,
        };
    }
    componentDidMount() {
        this.props.fetchAllMarinas();
    }

    componentDidUpdate(): void {
        if (this.props.redirectToMarinaList === true) {
            this.props.fetchAllMarinas();
            this.props.changeRedirectToMarinaList(false);
        }
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="row">
                    <div className="col-md-12 col-lg-9">
                        <Loader showLoader={this.props.isLoading} />
                        <Card className="marina-details-card">
                            <div className="marina-details-header">
                                <h3 className="marina-details-title">
                                    <Translation text="administration.marinas.title" />
                                </h3>
                                <button className="btn btn-create create-marina-button" onClick={() => this.openCreateMarinaModal()}>
                                    <Translation text="administration.marinas.buttons.create" />
                                </button>
                            </div>
                            <Table responsive className="marina-details-table">
                                <tbody>{this.renderMatchesTableRows()}</tbody>
                            </Table>
                        </Card>
                    </div>
                </div>
            </LayoutWrapper>
        );
    }

    private openCreateMarinaModal = () => {
        this.props.setModalName(ModalNames.CREATE_MARINA);
        this.props.changeIsModalOpen(true);
    };

    private renderMatchesTableRows() {
        if (!this.props.marinas || !Array.isArray(this.props.marinas)) return;

        return this.props.marinas.map((marina: MarinaBroad) => <MarinaItem marina={marina} key={marina.id} />);
    }
}

export default connect(
    (state: RootState) => ({
        marinas: marinasSelector(state),
        isLoading: marinasLoading(state),
        redirectToMarinaList: redirectToMarinaList(state),
    }),
    {
        fetchAllMarinas,
        changeMarina,
        setModalName,
        changeIsModalOpen,
        changeRedirectToMarinaList,
    }
)(Marinas);
