import {Translation} from 'marine-panel-common-web';

interface ReservationNoteProps {
    readonly reservationNote: string | null;
}
function ReservationNote(props: ReservationNoteProps) {
    if (!props.reservationNote) return null;
    return (
        <article className="reservation-details-wrapper">
            <h3 className="details-item-title">
                <Translation text={'mainMap.reservation.view.note'} />
            </h3>
            <p className="reservation-details-value">{props.reservationNote}</p>
        </article>
    );
}

export default ReservationNote;
