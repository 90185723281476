import {Berth, Toast, Translation, addAlert, basicAccountMiscSelector, changeMisc, initLogout} from 'marine-panel-common-web';
import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AlertType} from '../../../../service/alertManagerService';
import {RootState} from '../../../../store/reducers';
import {InitWizardStep, changeCurrentStep} from '../../../../store/reducers/berthInitWizardSlice';
import {ModalNames, changeIsModalOpen, setModalName} from '../../../../store/reducers/modalSlice';
import {currentlyEditedBerth} from '../../../../store/selectors/berthEditWizardSelectors';
import {currentWizardStepSelector} from '../../../../store/selectors/berthInitWizardSelectors';
import {nameSelector} from '../../../../store/selectors/modalSelectors';
import ModalComponent from '../../ModalComponent';
import WizardFormNavigationButtons from '../Common/WizardFormNavigationButtons';
import {additionalWizardStep, initWizardSteps} from './init-wizard-steps';
interface WizardInitPanelProps {
    readonly berth: Berth | null;
    readonly initLogout: typeof initLogout;
    readonly modalName: ModalNames | null | undefined;
    readonly currentWizardStep: InitWizardStep;
    readonly accountMisc: string | null;
    readonly changeMisc: typeof changeMisc;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly setModalName: typeof setModalName;
}

const WizardInitPanel: React.FC<WizardInitPanelProps> = ({
    berth,
    initLogout,
    currentWizardStep,
    changeIsModalOpen,
    modalName,
    changeMisc,
    setModalName,
    accountMisc,
}) => {
    let currentStepData = initWizardSteps.find((step) => step.step === currentWizardStep);
    const appLogoImage = require('../../../../assets/images/logo.png');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    if (currentWizardStep === InitWizardStep.CREATE_FIRST_MARINA) {
        currentStepData = additionalWizardStep;
    }
    useEffect(() => {
        if (accountMisc && accountMisc.includes('berthInitPassed')) {
            navigate('/');
        }
    }, [accountMisc, navigate]);

    if (!currentStepData) return null;

    const onLogout = () => {
        initLogout();
        dispatch(addAlert({message: 'auth.alert.logout', type: AlertType.INFO}));
        navigate('/');
    };

    const toggleModalOpen = () => {
        setModalName(null);
        changeIsModalOpen(false);
    };

    const setInitWizardDisabled = () => {
        changeMisc({misc: 'berthInitPassed'});
    };

    const isLastStep = currentWizardStep === InitWizardStep.WELL_DONE,
        isButtonWizardVisible =
            currentWizardStep !== InitWizardStep.CREATE_FIRST_MARINA &&
            currentWizardStep !== InitWizardStep.PRICING_STRATEGY &&
            currentWizardStep !== InitWizardStep.BASE_INFORMATION;
    return (
        <main className="wizard-panel">
            <section className="image-host-section">
                <img src={currentStepData.stepImage} alt="stepImage" className="image-host" />
            </section>
            <section className="step-host-section">
                <div className="host-section-header">
                    <div className="logo-wrapper">
                        <img src={appLogoImage} alt="Logo" className="logo" />
                    </div>
                    <button className="host-section-header-button close" onClick={setInitWizardDisabled}>
                        <Translation text={currentStepData.closeButtonMessage} />
                    </button>
                    <button className="host-section-header-button logout" onClick={onLogout}>
                        <Translation text="menuItems.logout" />
                    </button>
                </div>
                <div className="step-body">
                    <div className="step-body-title">
                        <h1 className="title main">
                            <Translation text={currentStepData.mainTitle} config={berth ? {name: berth.name} : undefined} />
                        </h1>
                        {currentStepData.description && (
                            <p className="description">
                                <Translation text={currentStepData.description} />
                            </p>
                        )}
                    </div>
                    <div className="step-body-title">
                        {currentStepData.secondaryTitle && (
                            <h2 className="title">
                                <Translation text={currentStepData.secondaryTitle} />
                            </h2>
                        )}
                        {currentStepData.secondaryDescription && (
                            <p className="description">
                                <Translation text={currentStepData.secondaryDescription} />
                            </p>
                        )}
                    </div>
                    <div className="step-body-title">
                        {currentStepData.additionalTitle && (
                            <h2 className="title">
                                <Translation text={currentStepData.additionalTitle} />
                            </h2>
                        )}
                        {currentStepData.additionalDescription && (
                            <p className="description">
                                <Translation text={currentStepData.additionalDescription} />
                            </p>
                        )}
                    </div>
                    <div className={`step-component ${isLastStep ? 'well-done' : ''}`}>
                        <div className="wizard-init-form-wrapper">
                            <h3 className="form-title">
                                <Translation text={currentStepData.formName} />
                            </h3>
                            {currentStepData.stepComponent}

                            <WizardFormNavigationButtons
                                submitAllowed={true}
                                currentStepId={currentWizardStep}
                                visible={isButtonWizardVisible}
                            />
                        </div>
                    </div>
                </div>
            </section>
            {modalName !== null || undefined ? <ModalComponent toggleModalOpen={toggleModalOpen} /> : null}
            <Toast />
        </main>
    );
};

export default connect(
    (state: RootState) => ({
        modalName: nameSelector(state),
        currentWizardStep: currentWizardStepSelector(state),
        berth: currentlyEditedBerth(state),
        accountMisc: basicAccountMiscSelector(state),
    }),
    {
        initLogout,
        changeIsModalOpen,
        setModalName,
        changeCurrentStep,
        changeMisc,
    }
)(WizardInitPanel);
