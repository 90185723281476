import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IModalState} from '../reducers/modalSlice';

export const selectModalSlice = (state: RootState) => {
    return state.modal;
};

export const nameSelector = createSelector([selectModalSlice], (state: IModalState) => state.name);
export const isModalLoadingSelector = createSelector([selectModalSlice], (state: IModalState) => state.isLoading);
export const isModalOpenSelector = createSelector([selectModalSlice], (state: IModalState) => state.isOpen);
export const berthModalSelector = createSelector([selectModalSlice], (state: IModalState) => state.berth);
export const photoIdSelector = createSelector([selectModalSlice], (state: IModalState) => state.photoId);
export const currentlyEditedItemIdSelector = createSelector([selectModalSlice], (state: IModalState) => state.currentlyEditedItemId);
