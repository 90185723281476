import {
    FormButtonType,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
    ValidationRules,
    DateFormat,
} from 'marine-panel-common-web';

export const reservationFormConfig = (vesselTypes?: IMultiselectOption[], berths?: IMultiselectOption[]): IFormConfig => {
    return {
        controlType: 'form',
        class: '',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'reservation_details',
                controlType: 'group',
                class: 'row',
                controls: {
                    date: {
                        controlType: 'control',
                        additionalStyles: '',
                        formControlType: FormControlType.DATE,
                        validationRules: [
                            {
                                name: ValidationRules.IS_DATE_GTE_THAN,
                                params: {
                                    valueAccessor: (data: any) => data,
                                    compareAccessor: () => new Date(),
                                },
                            },
                        ],
                        isLabelHidden: false,
                        type: InputType.DATE,
                        dateFormat: DateFormat.FormDateDefault,
                        minDate: new Date(),
                        // openToDate: currentReservationDate ? currentReservationDate : new Date(),
                        placeholder: 'reservations.form.selectDate',
                        label: 'reservations.form.date',
                        hostClass: 'col-xl-6 form-control reservation-single-datepicker',
                    },
                    berth: {
                        controlType: 'control',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: berths ? berths : [],
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: 'reservations.form.selectBerth',
                        label: 'reservations.form.berth',
                        hostClass: 'col-xl-6 form-control berth-type-control',
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                    },
                },
            },
            {
                key: 'skipper_details',
                controlType: 'group',
                class: 'row',
                controls: {
                    skipperName: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: '',
                        isLabelHidden: false,
                        label: 'reservations.form.skipperName',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-6 form-control',
                    },
                    email: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED, params: {}},
                            {name: ValidationRules.IS_EMAIL, params: {}},
                        ],
                        placeholder: '',
                        isLabelHidden: false,
                        label: 'reservations.form.skipperEmail',
                        type: InputType.EMAIL,
                        hostClass: 'col-xl-12 form-control',
                    },
                    phone: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [],
                        placeholder: '',
                        isLabelHidden: false,
                        label: 'reservations.form.skipperPhone',
                        type: InputType.TEL,
                        hostClass: 'col-xl-6 form-control',
                    },
                },
            },
            {
                key: 'yacht_details',
                controlType: 'group',
                class: 'row',
                controls: {
                    vesselName: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: '',
                        isLabelHidden: false,
                        label: 'reservations.form.yachtName',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-6 form-control',
                    },
                    registrationNumber: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: '',
                        isLabelHidden: false,
                        label: 'reservations.yachtNo',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-6 form-control',
                    },
                    vesselType: {
                        controlType: 'control',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: vesselTypes ? vesselTypes : [],
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: 'reservations.form.yachtTypePlaceholder',
                        label: 'reservations.form.yachtType',
                        hostClass: 'col-xl-6 form-control vessel-type-control',
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                    },
                    price: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        hostClass: 'col-xl-2 form-control',
                        label: 'reservations.form.price',
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        minValue: 0,
                        stepValue: 100,
                        type: InputType.NUMBER,
                    },
                },
            },
            {
                key: 'note_submit',
                controlType: 'group',
                class: 'row',
                controls: {
                    note: {
                        controlType: 'control',
                        formControlType: FormControlType.TEXTAREA,
                        validationRules: [{name: ValidationRules.MAX_LENGTH, params: {length: 2000}}],
                        placeholder: '',
                        isLabelHidden: false,
                        rows: 5,
                        maxLength: 2000,
                        label: 'reservations.form.note',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                key: 'submit_button',
                controlType: 'group',
                class: 'row reservation-update-button',
                controls: {
                    submitButton: {
                        buttonName: 'submit',
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: FormButtonType.SUBMIT,
                        inputStyles: 'btn',
                        hostClass: 'form-control',
                        defaultContainerStyles: '',
                        containerStyles: '',
                        defaultValue: null,
                        btnText: 'buttons.updateReservation',
                        buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                            return !isFormValid;
                        },
                    },
                },
            },
        ],
    };
};
