import {
    Reservation,
    Money,
    Currency,
    MoneyInput,
    PricingStrategyCalendarRule,
    PricingStrategyCalendarRuleInput,
    deepCloneObject,
} from 'marine-panel-common-web';

export type SkipperDefault = {
    name: string;
    email: string;
    phone: string;
};

export function getSkipperData(reservation: Reservation | null): SkipperDefault {
    const skipperData: SkipperDefault = {
        name: '',
        email: '',
        phone: '',
    };
    if (reservation?.skipper) {
        skipperData.name = reservation.skipper.firstName + ' ' + reservation.skipper.lastName;
        skipperData.phone = reservation.skipper.mobile ? reservation.skipper.mobile : '';
        skipperData.email = reservation.skipper.username;
    } else if (reservation?.customSkipper) {
        skipperData.name = reservation.customSkipper.name;
        skipperData.phone = reservation.customSkipper.phone;
        skipperData.email = reservation.customSkipper.email;
    }
    return skipperData;
}

export function getVesselData(reservation: Reservation | null): string {
    if (reservation?.vessel) {
        return reservation.vessel.name;
    }
    return '';
}

// Edit wizard subsection scroller helper function

export function scrollSmoothTo(elementId: string, navigation: any) {
    var element = document.getElementById(elementId);

    if (!element) return;
    element.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
    });
    navigation(`#${elementId}`);
}

export const convertPriceToInputValue = (price: string): string => {
    return formatHighPrecisionPrice(price);
};

export function transformValueToPrice(value: string): Money {
    return {
        amount: value,
        currency: {
            name: Currency.EURO,
        },
    };
}

export function transformValueToPayloadPrice(value: string): Money {
    const convertedValue = Math.floor(Number(value) * 100);
    return {
        amount: convertedValue.toString(),
        currency: {
            name: Currency.EURO,
        },
    };
}

export function transformValueToMoneyInput(value: string): MoneyInput {
    const convertedValue = Math.floor(Number(value) * 100);
    return {
        amount: convertedValue.toString(),
        currency: Currency.EURO,
    };
}
export function transformMoneyToMoneyInput(price: Money): MoneyInput {
    return {
        amount: price.amount.toString(),
        currency: price.currency.name,
    };
}

export function transformCalendarRulesToPayloadInputs(calendarRules: PricingStrategyCalendarRule[]): PricingStrategyCalendarRuleInput[] {
    const transformedCalendarRules: PricingStrategyCalendarRuleInput[] = [];
    calendarRules.forEach((rule) => {
        if (rule.price) {
            transformedCalendarRules.push({
                ...deepCloneObject(rule),
                price: transformMoneyToMoneyInput(rule.price),
            });
        }
    });
    return transformedCalendarRules;
}
export function formatHighPrecisionPrice(price: number | string, precision: number = 2): string {
    const formatted = Math.round(Number(price)).toString();
    let left: string, right: string;
    if (formatted.length <= precision) {
        left = '0';
        right = formatted.padStart(precision, '0');
    } else {
        const index = formatted.length - precision;
        left = formatted.substring(0, index);
        right = formatted.substring(index);
    }

    return `${left}.${right}`;
}
