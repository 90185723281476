import {Berth, Form, FormControlChangeType, Translation, meterNumberOrNull} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../../store/reducers';
import {
    changeBerthSize,
    changeCurrentlyEditedControlId,
    changeIsBerthEditWizardLoading,
    fetchBerthDetails,
    fetchMarinaList,
    resetToInitialBerthEditWizardState,
} from '../../../../../../../store/reducers/berthEditWizardSlice';
import {changeIsModalOpen} from '../../../../../../../store/reducers/modalSlice';
import {
    currentlyEditedControlId,
    isBerthEditWizardInitializedSelector,
} from '../../../../../../../store/selectors/berthEditWizardSelectors';
import EditFormControl from '../../../Common/EditFormControl';
import {berthSizeFormConfig} from './berthSizeFormConfig';

interface IBerthSizesProps {
    readonly berth: Berth | null;
    readonly isBerthEditWizardInitialized: boolean;
    readonly changeBerthSize: typeof changeBerthSize;
    readonly changeCurrentlyEditedControlId: typeof changeCurrentlyEditedControlId;
    readonly currentlyEditedControlId: string | null;
}

interface IBerthSizesValues {
    value: {
        maxLength?: number | null;
        maxWidth?: number | null;
        maxDraft?: number | null;
        maxHeight?: number | null;
        noMaxHeight?: boolean;
    };
}

const BerthSizes: FC<IBerthSizesProps> = ({berth, isBerthEditWizardInitialized, currentlyEditedControlId}) => {
    const params = useParams(),
        berthId = params.id,
        dispatch = useDispatch(),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share()));
    const [values, setValue] = useState<IBerthSizesValues>();

    useEffect(() => {
        if (berth !== null) {
            setValue({
                value: {
                    maxLength: meterNumberOrNull(berth?.maxLength),
                    maxWidth: meterNumberOrNull(berth?.maxWidth),
                    maxDraft: meterNumberOrNull(berth?.maxDraft),
                    maxHeight: meterNumberOrNull(berth?.maxHeight),
                    noMaxHeight: false,
                },
            });
        }
    }, [berth, isBerthEditWizardInitialized]);

    useEffect(() => {
        const subscriptions: Subscription[] = [];
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState) => {
                            const formValues = {
                                value: {
                                    maxLength: data?.value?.maxLength ? Number(data?.value?.maxLength) : prevState?.value.maxLength,
                                    maxWidth: data?.value?.maxWidth ? Number(data?.value?.maxWidth) : prevState?.value.maxWidth,
                                    maxDraft: data?.value?.maxDraft ? Number(data?.value?.maxDraft) : prevState?.value.maxDraft,
                                    maxHeight: data?.value?.maxHeight ? Number(data?.value?.maxHeight) : prevState?.value.maxHeight,
                                },
                            };
                            return formValues;
                        });
                    }
                })
        );
    }, [onValueStateChange$$]);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    const defaultMarina = {
        value: berth?.sector?.marina ? berth?.sector?.marina?.id : '',
        label: berth?.sector?.marina ? berth?.sector?.marina?.name : '',
    };
    const editedItemId = currentlyEditedControlId;
    const onConfirmAction = (action: any) => {
        dispatch(action);
        dispatch(changeCurrentlyEditedControlId(null));
    };
    return (
        <div id="berth_sizes" className="details-edit-section col-xl-6 " data-section="berth_sizes">
            <h3 className="title main sr-only">
                <Translation text="editMenuItems.sections.berth_sizes.title" />
            </h3>

            <EditFormControl
                editedItemId={editedItemId}
                setEditedItemId={(editedItemId: string | null) => dispatch(changeCurrentlyEditedControlId(editedItemId))}
                currentItemId={'max_yacht_sizes'}
                titleTranslationKey={'editMenuItems.sections.berth_sizes.title'}
                mainTitle={true}
                onConfirm={() =>
                    onConfirmAction(
                        changeBerthSize(
                            berthId,
                            Number(values?.value?.maxWidth),
                            Number(values?.value?.maxLength),
                            Number(values?.value?.maxDraft),
                            Number(values?.value?.maxHeight)
                        )
                    )
                }
                editedChildren={
                    <>
                        <p className="edit-form-control-subtitle">
                            <Translation text="editMenuItems.sections.berth_sizes.description" />
                        </p>
                        <Form
                            controlName={'berthSizeForm'}
                            config={berthSizeFormConfig(values?.value)}
                            value={values?.value}
                            onValueStateChange={onValueStateChange}
                        />
                    </>
                }
                displayChildren={
                    <dl className="sizes-list">
                        <div className="size-list-item">
                            <dt>
                                <Translation text="editMenuItems.sections.berth_sizes.formControls.maxLength" />:{' '}
                            </dt>
                            <dd>{berth?.maxLength ? `${berth.maxLength / 100}m` : '-'}</dd>
                        </div>
                        <div className="size-list-item">
                            <dt>
                                <Translation text="editMenuItems.sections.berth_sizes.formControls.maxWidth" />:{' '}
                            </dt>
                            <dd>{berth?.maxWidth ? `${berth.maxWidth / 100}m` : '-'}</dd>
                        </div>
                        <div className="size-list-item">
                            <dt>
                                <Translation text="editMenuItems.sections.berth_sizes.formControls.maxDraft" />:{' '}
                            </dt>
                            <dd>{berth?.maxDraft ? `${berth.maxDraft / 100}m` : '-'}</dd>
                        </div>
                        <div className="size-list-item">
                            <dt>
                                <Translation text="editMenuItems.sections.berth_sizes.formControls.maxHeight" />:{' '}
                            </dt>
                            <dd>{berth?.maxHeight ? `${berth.maxHeight / 100}m` : '-'}</dd>
                        </div>
                    </dl>
                }
            />
        </div>
    );
};
export default connect(
    (state: RootState) => ({
        isBerthEditWizardInitialized: isBerthEditWizardInitializedSelector(state),
        currentlyEditedControlId: currentlyEditedControlId(state),
    }),
    {
        fetchBerthDetails,
        resetToInitialBerthEditWizardState,
        changeIsModalOpen,
        changeIsBerthEditWizardLoading,
        changeBerthSize,
        fetchMarinaList,
        changeCurrentlyEditedControlId,
    }
)(BerthSizes);
