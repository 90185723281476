import {Observable} from 'rxjs';
import {FullAccount, SectorWithMarina} from 'marine-panel-common-web';

export type ChatChannel = {
    biggestPageNumber: number;
    peerIsOnline: boolean;
    channelId: string;
    messages: ChatMessage[];
    unseenMessages: number;
    totalMessagesNumber: number;
};

export type AllUserChats = {
    [roomId: string]: ChatChannel;
};

export type ChatContact = {
    roomId: string;
    accountId: string;
    firstName: string;
    lastName: string;
    unreadMessagesCount: number;
    berthName: string;
    berthId: string;
    marinaName: string;
    marinaId: string;
    avatarUrl?: string;
};

export class ChatMessage {
    from: string;
    to: string;
    date: Date;
    messageType: MessageContentType;
    messageContent?: string;
    messageMedia?: any;
    messageId?: string;
    messagePreview?: string;
    constructor({from, to, messageType, messageContent, messageId, messageMedia = null}: ChatMessage) {
        this.from = from || '';
        this.to = to || '';
        this.messageType = messageType || '';
        this.messageContent = messageContent || '';
        this.date = new Date();
        this.messageId = messageId;
        if (messageMedia) {
            this.messageMedia = messageMedia;
        }
    }
}

export enum NodeErrorTypes {
    NOT_AUTHORIZED = 'not_authorized',
    NO_TOKEN = 'no_token',
    ALREADY_ONLINE = 'already_online',
    EXPIRED_TOKEN = 'expired_token',
    WEBSOCKET_ERROR = 'websocket error',
}

export type ChatServerMessagePayload = {
    toAccountId: string;
    berthId: string | null;
    content: string;
};

export enum MessageContentType {
    TEXT = 'text',
    IMAGE = 'image',
    FILE = 'file',
    PLACEHOLDER = 'placeholder',
    ERROR = 'error',
}

export type ChatConfig = {
    websocketServerAddress: string;
    saveMessagesConfig?: SaveMessagesConfig;
};

export type SaveMessagesConfig = {
    saveMessagesOnlyWhenPeerIsOffline: boolean;
    messagesNumberPerHistoryPage: number;
    sendMessageToOfflineAPI: (message: ChatServerMessagePayload) => Observable<any>;
    getMessagesFromUserAPI: (
        accountId: string,
        berthId: string,
        page?: number,
        messagesNumberPerHistoryPage?: number
    ) => Observable<MessageMapperResult>;
    postMessageReadConfirmationAPI?: (messages: string[]) => Observable<any>;
    saveFile?: (formData: FormData) => Observable<any>;
    getContacts?: ((authToken: string | null, accountId: string) => Observable<any>) | null;
};

//*** WithChatConnection***
export interface IWithChatConnectionProps {
    authToken: string;
    accountId: string;
    channelsToJoin: ChatContact[];
}

export interface IWithChatConnectionState {
    userPeerConnections: AllUserChats;
    selectedChatRoomId: string;
    alert: string[];
    nodeServerConnected: boolean;
    hadConnectionError: boolean;
    allowedChannels: ChatContact[];
}

export enum SocketMessages {
    MESSAGE = 'message',
    LOGGED_IN = 'logged_in',
    JOIN_CHANNELS = 'join_channels',
    CHANNEL_JOINED = 'channel_joined',
    USER_JOINED_CHANNEL = 'user_joined_channel',
    CONNECTION = 'connection',
    DISCONNECTING = 'disconnecting',
    USER_HAS_LEFT = 'user_has_left',
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    CONNECT_ERROR = 'connect_error',
    ALLOWED_CHANNELS_LIST = 'allowed_channels_list',
    ERROR = 'error',
    NOTIFICATION = 'notification',
}

//*** Chat Contacts Types ***
export type BerthChat = {
    id: string; // id Berth
    sector: SectorWithMarina | null;
    name: string;
};

export type ServerChatContact = {
    '@id': string;
    '@type': 'Account';
    unread: number; // ilość nieprzeczytanych wiadomości z tym kontaktem
    contact: FullAccount;
    berth: BerthChat;
};

export type User = {
    '@id': string;
    '@type': 'AccountBasicInfoOutput';
    about: string | null;
    avatar: UserAvatarOrFile | null;
    birthDate: string | null;
    cityName: string | null;
    country: Country;
    firstName: string | null;
    id: string;
    lastName: string | null;
    locale: string | null;
    phone: string | null;
};

export type Country = {
    '@type': 'CountryOutput';
    '@id': string;
    id: string;
    name: string;
};

export type City = {
    '@id': string;
    '@type': 'CityOutput';
    country: Country;
    id: string;
    name: string;
};

export type UserAvatarOrFile = {
    '@id': string;
    '@type': 'FileOutput';
    contentUrl: string | null;
    fileUrls: {
        largeThumb: string;
        mediumThumb: string;
        original: string;
        smallThumb: string;
        tinyThumb: string;
    };
    id: string;
};

//*** Messages mapper ***
export type MessageMapperResult = {
    messages: [ChatMessage[], ChatServerMessage[]];
    totalResults: number;
};

//*** save message to API ***
export type ChatServerMessage = {
    id: string;
    fromAccountId: string;
    toAccountId: string;
    content: string;
    sentAt: string;
    receivedAt: string;
    // mediaObject: {
    //     '@context': string;
    //     '@id': string;
    //     '@type': string;
    //     id: string;
    //     fileUrls: {
    //         largeThumb: string;
    //         mediumThumb: string;
    //         original: string;
    //         smallThumb: string;
    //         tinyThumb: string;
    //     } | null;
    //     contentUrl: string;
    // };
};

//*** save file to media_objects ***

export type MediaObjectAPIResponse = {
    '@context': '/api/contexts/MediaObject';
    '@id': string;
    '@type': 'https://schema.org/MediaObject';
    contentUrls: {
        largeThumb: string;
        mediumThumb: string;
        original: string;
        smallThumb: string;
        tinyThumb: string;
    };
    id: string;
};

export enum ChatType {
    MESSAGE = 'message',
    RESPONSE = 'response',
}

export type PeerConnection = {
    biggestPageNumber: number;
    peerIsOnline: boolean;
    connection: RTCPeerConnection | null;
    channelId: string;
    messagesBuffer: ([ChatMessage, string] | string)[];
    messages: ChatMessage[];
    unseenMessages: number;
    channel: RTCDataChannel | null;
    peerIsPolite: boolean;
    makingOffer: boolean;
    connectionAttemptNumber: number;
    totalMessagesNumber: number;
};

export type AllUserConnections = {
    [roomId: string]: PeerConnection;
};
