import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IReservationsPageState} from '../reducers/reservationsSlice';

export const selectReservationsPage = (state: RootState): IReservationsPageState => {
    return state.reservations;
};

export const reservationsSelector = createSelector([selectReservationsPage], (state: IReservationsPageState) => state.reservations);
export const activeReservationsSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.activeReservation
);

export const reservationsMetadataSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.reservationsMetadata
);

export const reservationsPaginationSelector = createSelector([selectReservationsPage], (state: IReservationsPageState) => state.pagination);

export const isReservationsPageLoadingSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.isReservationsPageLoading
);

export const isReservationsPageInitializedSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.isReservationsPageInitialized
);

export const reservationCountSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.reservationsCount
);

export const reservationFiltersSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.reservationsFilters
);

export const reservationCountFiltersSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.reservationCountFilters
);

export const reservationsErrorSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.reservationsError
);

export const vesselLatestPositionSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.latestVesselPosition
);

export const routeCalculationTaskSelector = createSelector(
    [selectReservationsPage],
    (state: IReservationsPageState) => state.routeCalculationTask
);
