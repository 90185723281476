import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MarinaBroad} from 'marine-panel-common-web';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './berthsSlice';

export interface IMarinasState extends IBaseDictionaryState {
    marinas: MarinaBroad[] | null;
    activeMarina: MarinaBroad | null;
}

export interface IFetchMarinaDetails {
    marinaId: string;
    marinaSectorId?: string;
}

export interface IFormListFilters {
    page?: string;
    itemsPerPage?: string;
    startDate?: string | null;
    endDate?: string | null;
    searchPhrase?: string | null;
    published?: boolean | null;
    active?: boolean | null;
}

export type IFormListFiltersType = 'page' | 'itemsPerPage' | 'startDate' | 'endDate' | 'searchPhrase' | 'published' | 'active';

export interface IChangeFormListFilters {
    readonly payload: {
        formListFilters: IFormListFilters;
    };
}

export interface ISetMarinas {
    readonly marinas: MarinaBroad[] | null;
}

export interface ISetActiveMarina {
    readonly activeMarina: MarinaBroad | null;
}

const initialState: IMarinasState = {
    marinas: [],
    activeMarina: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const marinasSlice = createSlice({
    name: 'marinas',
    initialState: initialState,
    reducers: {
        changeMarina: {
            reducer: (state: IMarinasState, action: PayloadAction<ISetMarinas>) => {
                return {
                    marinas: action.payload.marinas,
                    activeMarina: state.activeMarina,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(marinas: MarinaBroad[] | null) {
                return {
                    payload: {marinas: marinas},
                };
            },
        },

        changeIsMarinaLoading: {
            reducer: (state: IMarinasState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    marinas: state.marinas,
                    activeMarina: state.activeMarina,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsMarinaInitialized: {
            reducer: (state: IMarinasState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    marinas: state.marinas,
                    activeMarina: state.activeMarina,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setMarinaError: {
            reducer: (state: IMarinasState, action: PayloadAction<ISetError>) => {
                return {
                    marinas: state.marinas,
                    activeMarina: state.activeMarina,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchMarinaDetails: {
            reducer: (state: IMarinasState) => {
                return {
                    ...state,
                    isBerthsViewLoading: true,
                };
            },
            prepare(marinaId: string, marinaSectorId?: string) {
                return {
                    payload: {marinaId: marinaId, marinaSectorId: marinaSectorId},
                };
            },
        },
        fetchMarinas: (state: IMarinasState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        fetchAllMarinas: (state: IMarinasState) => {
            return {
                ...state,
            };
        },
        setActiveMarina: {
            reducer: (state: IMarinasState, action: PayloadAction<ISetActiveMarina>) => {
                return {
                    marinas: state.marinas,
                    activeMarina: action.payload.activeMarina,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(activeMarina: MarinaBroad | null) {
                return {
                    payload: {activeMarina: activeMarina},
                };
            },
        },
    },
});

export const {
    changeMarina,
    changeIsMarinaLoading,
    changeIsMarinaInitialized,
    setMarinaError,
    fetchMarinas,
    fetchMarinaDetails,
    fetchAllMarinas,
    setActiveMarina,
} = marinasSlice.actions;

export default marinasSlice.reducer;
