import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IChangeMaxHeight {
    maxHeight: string;
}

export function changeMaxHeightAPI(
    id: string | null | undefined,
    payload: IChangeMaxHeight | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/change_max_height`, payload, new RestQueryParams(), headers);
}
