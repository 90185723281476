import React from 'react';
import ChatUsersListItem from './ChatUserListItem';
import {Input, InputGroup, InputGroupText} from 'reactstrap';
import {User, Search} from 'react-feather';
import styles from './styles.module.scss';
import {AllUserChats} from '../../../WithSoccetChatConnection/chat.types';
import {ChatContact} from '@solvee/reactjs-websocket-text-chat';
import {Avatar, AvatarColor, AvatarStatus, Translation} from 'marine-panel-common-web';

interface Props {
    peerConnections: AllUserChats;
    chatContactsList: ChatContact[];
    setSelectedChatRoomId: (chat: string) => void;
    selectedChatRoomId: string;
    hasNodeConnection: boolean;
    showOnlineIndicator?: boolean;
    avatarUrl?: string;
}

interface State {
    searchedUser: string;
}

class ChatUsersList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            searchedUser: '',
        };
    }

    render() {
        return (
            <div className={`sidebar-left ${styles.listWrapper}`}>
                <div className="sidebar">
                    <div className={'sidebar-content'}>
                        <div className={`chat-fixed-search ${styles.searchWrapper}`}>
                            <div className={`d-flex align-items-center w-100 ${styles.searchContent}`}>
                                <div className="sidebar-profile-toggle">
                                    <Avatar
                                        img={this.props.avatarUrl}
                                        icon={<User size={42} />}
                                        className="avatar-border"
                                        color={AvatarColor.PRIMARY}
                                        imgHeight="42"
                                        imgWidth="42"
                                        status={this.props.hasNodeConnection ? AvatarStatus.ONLINE : AvatarStatus.OFFLINE}
                                        tag={'div'}
                                    />
                                </div>
                                <InputGroup className="input-group-merge ms-1 w-100 round" style={{border: '1px solid #d8d6de'}}>
                                    <InputGroupText className="round border-0">
                                        <Search className="text-muted" size={14} />
                                    </InputGroupText>
                                    <Input
                                        value={this.state.searchedUser}
                                        className="round"
                                        placeholder="Search or start a new chat"
                                        onChange={this.handleFilter}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="chat-user-list-wrapper list-group">
                            <h4 className="chat-list-title">
                                <Translation text="inbox.chat.contacts" />
                            </h4>
                            {this.renderContactList()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderContactList = () => {
        if (!this.props.chatContactsList || this.props.chatContactsList.length === 0) {
            return (
                <div className="no-contacts-wrapper">
                    <p className="no-contacts">
                        <Translation text={'chat.noUserListProvided'} />
                    </p>
                </div>
            );
        } else {
            return (
                <ul className={'list-group chat-users-list chat-list media-list'}>
                    {this.sortUsers.map((chatContact: ChatContact) => (
                        <React.Fragment key={chatContact.roomId}>
                            <ChatUsersListItem
                                chatContact={chatContact}
                                showOnlineIndicator={
                                    this.props.showOnlineIndicator && this.props.peerConnections?.[chatContact.roomId]?.peerIsOnline
                                }
                                unseenMessages={this.props.peerConnections?.[chatContact.roomId]?.unseenMessages || 0}
                                selectedChatRoomId={this.props.selectedChatRoomId}
                                setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                            />
                        </React.Fragment>
                    ))}
                </ul>
            );
        }
    };

    private handleFilter = (e: any) => this.setState({searchedUser: e.target.value});

    private get sortUsers() {
        const list = this.props.chatContactsList.filter(
            (contact) => `${contact.firstName} ${contact.lastName}`.toLowerCase().indexOf(this.state.searchedUser?.toLowerCase()) !== -1
        );
        // const list = deepCloneObject(this.props.chatContactsList)
        if (!list || list.length < 2) {
            return list;
        }
        const UNNAMED_LAST = 'ZZZZZ';
        const alphabeticalList = list.sort((a, b) => {
            const textA = a?.lastName?.toUpperCase() || UNNAMED_LAST;
            const textB = b?.lastName?.toUpperCase() || UNNAMED_LAST;

            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        if (this.props.showOnlineIndicator) {
            alphabeticalList.sort((a: ChatContact, b: ChatContact) => {
                if (this.props.peerConnections?.[a.roomId]?.unseenMessages && !this.props.peerConnections?.[b.roomId]?.unseenMessages) {
                    return -1;
                } else if (this.props.peerConnections?.[a.roomId]?.peerIsOnline && !this.props.peerConnections?.[b.roomId]?.peerIsOnline) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }

        return alphabeticalList;
    }
}

export default ChatUsersList;
