export const REFRESH_TOKEN_TIME = 600;
export const GET_MEASUREMENTS_API_CALL_INTERVAL = 120000;
export const RESERVATIONS_API_LIMIT = 90;
export const MAX_PHOTO_FILE_SIZE = 5000000;
export const REFRESH_TOKEN_NEED_CHECK_INTERVAL = 1000 * 60 * 5;

export const DEFAULT_SEARCH_FILTER_VALUE_DISTANCE = 1;
export const DEFAULT_SEARCH_FILTER_VALUE_NAME = '';
export const DEFAULT_SEARCH_FILTER_VALUE_LAT = 44.4095;
export const DEFAULT_SEARCH_FILTER_VALUE_LNG = 8.9173;

export const SEARCH_DEBOUNCE_TIME = 500;
export const MIN_SEARCH_PHRASE_LENGTH = 3;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_MOBILE_LENGTH = 32;
export const MIN_CREW_NUMBER = 1;
export const STRIPE_PUBLISHABLE_KEY =
    'pk_test_51IxZfoC5rCOAuh3gUsxOVV13emYtelph8bUYc3cY9D4IR0OvPvNDh087PNmjMfmYpsOLB6SurIB33569PtGcpyrE00x0FhnLgX';
export const MESSAGES_NUMBER_PER_HISTORY_PAGE = 10;

export const VESSEL_DEFAULT_LENGTH = 10;
export const VESSEL_DEFAULT_WIDTH = 10;
export const VESSEL_DEFAULT_HEIGHT = 10;
export const VESSEL_DEFAULT_DRAFT = 10;

export const RESULTS_NUMBER_PER_MARINA_PAGE = 10;
export const RESULTS_NUMBER_PER_BERTH_PAGE = 10;
export const MIN_SEARCH_ENABLED_ZOOM_LEVEL = 7;

export const SIZE_UNIT = 'm';
export const BOOKING_DAY_START_HOUR = 12;

export const MAX_QUEUE_LENGTH = 3;
export const MIN_LATITUDE = -90;
export const MIN_LONGITUDE = -180;
export const MAX_LATITUDE = 90;
export const MAX_LONGITUDE = 180;

export const WATER_API_STATUS_CALL_DELAY = 500;

export const DEFAULT_USER_LOCATION = {
    latitude: 0.0,
    longitude: 0.0,
};
