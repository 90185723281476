import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IBerthEditWizardState} from '../reducers/berthEditWizardSlice';

export const selectBerthEditWizardSlice = (state: RootState) => {
    return state.berthEditWizard;
};

export const currentlyEditedBerth = createSelector([selectBerthEditWizardSlice], (state: IBerthEditWizardState) => state.berth);

export const isBerthEditWizardLoadingSelector = createSelector(
    [selectBerthEditWizardSlice],
    (state: IBerthEditWizardState) => state.isBerthEditWizardLoading
);
export const isBerthEditWizardInitializedSelector = createSelector(
    [selectBerthEditWizardSlice],
    (state: IBerthEditWizardState) => state.isBerthEditWizardInitialized
);

export const isActionSuccessfulSelector = createSelector(
    [selectBerthEditWizardSlice],
    (state: IBerthEditWizardState) => state.isActionSuccessful
);

export const berthEditWizardErrorSelector = createSelector(
    [selectBerthEditWizardSlice],
    (state: IBerthEditWizardState) => state.berthEditWizardError
);

export const currentlyEditedControlId = createSelector(
    [selectBerthEditWizardSlice],
    (state: IBerthEditWizardState) => state.currentlyEditedControlId
);

export const marinaListSelector = createSelector([selectBerthEditWizardSlice], (state: IBerthEditWizardState) => state.marinaList);
export const sectorListSelector = createSelector([selectBerthEditWizardSlice], (state: IBerthEditWizardState) => state.sectorList);
