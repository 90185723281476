const pl = {
    app: {
        title: 'Operator Panel',
        exception: {
            payment_account: {
                payment_vendor_account: {
                    stripe: {
                        onboarding_not_completed: 'Onboarding process is not completed',
                    },
                },
            },
        },
    },

    administration: {
        title: 'Administration',
        marinas: {
            title: 'Marinas',
            table: {
                headers: {
                    project: 'Project',
                    city: 'City',
                    salary: 'Salary',
                    publication: 'Publication',
                },
            },
            buttons: {
                create: 'Create Marina',
                edit: 'Edit Marina',
                delete: 'Delete Marina',
            },
        },
        pricingStrategies: {
            title: 'Pricing Strategies',
            table: {
                headers: {
                    name: 'Name',
                    type: 'Type',
                    maxSizes: 'Max Sizes',
                    currentPrice: 'Current Price',
                    actions: 'Actions',
                },
            },
            buttons: {
                create: 'Create Strategy',
                edit: 'Edit Strategy',
                delete: 'Delete Strategy',
            },
        },
        extras: {
            title: 'Extras',
            table: {
                headers: {
                    name: 'Name',
                    currentPrice: 'Current Price',
                    actions: 'Actions',
                },
            },
            buttons: {
                create: 'Create Extras',
                edit: 'Edit Extras',
                delete: 'Delete Extras',
            },
        },
        internalTags: {
            title: 'Internal Tags',
            table: {
                headers: {
                    name: 'Name',
                    actions: 'Actions',
                },
            },
            buttons: {
                create: 'Create Tag',
                edit: 'Edit Tag',
                delete: 'Delete Tag',
            },
        },
    },
    reports: {
        title: 'Reports',
    },
    reservations: {
        title: 'Reservations',
    },
    mainMap: {
        marinaSelector: {
            title: 'Marina selector',
            placeholder: 'Select marina',
        },
        reservation: {
            title: 'Reservation',
            user: 'Reserved by: {{userName}}',
            startDate: 'from: {{date}}',
            endDate: 'to: {{date}}',
            view: {
                noSkipper: 'Brak nazwiska',
            },
        },
        legend: {
            title: 'Legend',
            disabled: 'Disabled',
            available: 'Available',
            reserved: 'Reserved',
            selected: 'Selected',
        },
        berthInfoCard: {
            title: 'Berth info - {{berthName}}',
            name: 'Name: {{name}}',
            description: 'Description: {{description}}',
            disabled: 'Berth is out of order',
            reserved: 'Berth is reserved',
            owner: 'Owner: {{owner}}',
            editBerthButton: 'Edit berth',
            closeCardButton: 'Close',
            maxSizes: {
                title: 'Max sizes:',
                length: 'Length: {{size}} ft',
                width: 'Width: {{size}} ft',
                height: 'Height: {{size}} ft',
                draft: 'Draft: {{size}} ft',
            },
            status: {
                active: 'Berth active',
                inactive: 'Berth inactive',
                app_booking: 'App booking',
                available: 'Available',
                temp_reserved: 'Temp. Reserved',
                reserved: 'Reserved',
                paid: 'Paid',
                yacht_arrived: 'Yacht arrived',
            },
            tags: 'Tags:',
            note: 'Note:',
        },
    },

    auth: {
        formControls: {
            placeholder: 'Wpisz tutaj',
            email: 'Email',
            password: 'Hasło',
            newPassword: 'Nowe hasło',
            repeatPassword: 'Powtórz nowe hasło',
            registrationTermsAgreement: ``,
            phone: 'Telefon',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            registrationTerms: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr.`,
            placeholders: {
                email: 'Wpisz swój email',
                password: 'Wpisz swoje hasło',
                repeatPassword: 'Powtórz nowe hasło',
            },
        },
        register: {
            title: 'Zarejestruj się',
            subtitle:
                'Platforma EduCat to  miejsce ułatwiające proces przygotowania do studiów. Wypełnij poniższy formularz, aby założyć swój profil.',
            haveAccount: 'Masz już konto w naszym serwisie? ',
            logIn: 'Zaloguj się.',
            registerWith: {
                title: 'lub zarejestruj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zarejestruj się przez Facebook',
                google: 'Zarejestruj się przez Google',
            },
        },
        confirmRegistration: {
            title: 'Potwierdź rejestrację',
            subtitle: 'Aby zakończyć rejestrację nacisnij przycisk poniżej.',
        },
        login: {
            title: 'Zaloguj się',
            subtitle: '',
            haveAccount: 'Nie masz jeszcze konta w naszym serwisie? ',
            register: 'Zarejestruj się.',
            resetPassword: 'Nie pamiętasz hasła?',
            loginWith: {
                title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zaloguj się przez Facebook',
                google: 'Zaloguj się przez Google',
            },
        },
        resetPassword: {
            title: 'Zresetuj hasło',
            subtitle:
                'Nie martw się, że hasło Ci umknęło :) Wpisz adres email, który został użyty przy rejestracji. Wyślemy na niego dalsze instrukcje.',
        },
        newPassword: {
            title: 'Zmień hasło',
            subtitle: 'Wpisz nowe hasło i kontynuuj przygodę na Platformie EduCat',
        },
        alert: {
            loginSuccess: 'Logowanie zakończone pomyślnie.',
            loginFailed: 'Niepoprawny login lub hasło. Spróbuj jeszcze raz.',
            logout: 'Zostałeś wylogowany.',
            registrationSuccess: 'Dane rejestracyjne wysłane prawidłowo, potwierdź rejestrację klikając w link podany w emailu.',
            confirmRegistrationSuccess: 'Rejestracja została potwierdzona, zaloguj się za pomocą maila i swojego hasła.',
            sendResetPasswordMailSuccess: 'Na twój adres email wysłaliśmy wiadomość z linkiem resetującym hasło.',
            confirmResetPasswordSuccess: 'Twoje hasło zostało zmienione, możesz zalogować się za jego pomocą.',
            authenticationError: 'Musisz być zalogowany aby mieć dostęp do danych.',
        },
    },
    settings: {
        changePassword: {
            title: 'Profile Details',
            form: {
                labels: {
                    currentPassword: 'Current password',
                    newPassword: 'New password',
                    confirmPassword: 'Confirm new password',
                },
                placeholders: {
                    currentPassword: 'Enter current password',
                    newPassword: 'Enter new password',
                    confirmPassword: 'Confirm your new password',
                },
            },
            passwordRequirements: {
                title: 'Password requirements:',
                length: 'Minimum 8 characters long - the more, the better',
                uppercase: 'At least one lowercase character',
                characters: 'At least one number, symbol, or whitespace character',
            },
        },
    },
    support: {
        title: 'Contact us',
        form: {
            labels: {
                subject: 'Subject',
                message: 'Message',
            },
            placeholders: {
                subject: 'Type your subject',
                message: 'Type your message',
            },
        },
        privacyPolicyTitle: 'Privacy Policy',
        privacyPolicyMessage: 'Privacy Policy',
        readLink: 'READ',
        formSent: 'Your message has been successfully sent.',
    },
    paymentCard: {
        form: {
            labels: {
                cardholderName: 'Cardholder name',
                cardNumber: 'Card number',
            },
            placeholders: {
                cardholderName: 'Cardholder name',
            },
        },
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        upload: 'Upload',
        more: 'Zobacz więcej',
        login: 'Zaloguj się',
        saveChanges: 'Save changes',
        discard: 'Discard',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Read less',
        readMore: 'Read more',
        close: 'Close',
        join: 'Join',
        next: 'Next',
        previous: 'Previous',
        submit: 'Submit',
        change: 'Change',
        remove: 'Remove',
        register: 'Zarejestruj się',
        save: 'Save',
        resetPassword: 'Resetuj hasło',
        backToLogin: 'Back to login',
    },
    formValidation: {
        errors: {
            minLength: 'Wartość jest zbyt krótka',
            isEmailValid: 'Adres email jest nieprawidłowy',
            isRequired: 'Pole wymagane',
            isCheckedTrue: 'Musi być zaznaczone',
            isTrue: 'Musi być zaznaczone',
            isPasswordSame: 'Hasła nie mogą być różne',
            isStartDateValid: 'Data musi być w przyszłości',
            isStartDateGreaterOrEvenValid: 'Data musi być w przyszłości lub dziś',
            isEndDateValid: 'Data powrotu musi być po dacie wylotu',
            isNumber: 'Wartość musi być liczbą',
        },
    },
    footer: {
        copyright: `MarineHub {{date}} © Wszelkie prawa zastrzeżone`,
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony',
    },
    menuItems: {
        berths: 'Berths',
        reservations: 'Reservations',
        inbox: 'Inbox',
        reports: 'Reports',
        administration: 'Administration',
        settings: 'Settings',
        logout: 'Logout',
    },
    alerts: {
        warning: 'Warning!',
        error: 'Error!',
        success: 'Success!',
        info: 'Info',
    },
    account: {
        changeAccountDataSuccess: 'Dane konta zostały zmienione.',
        changeLocaleSuccess: 'Język został zmieniony.',
        changePasswordSuccess: 'Hasło zostało zmienione.',
        imageAddSuccess: 'Obraz został dodany.',
    },
};

export default pl;
