import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IChangeMaxWidth {
    maxWidth: string;
}

export function changeMaxWidthAPI(
    id: string | null | undefined,
    payload: IChangeMaxWidth | null,
    authToken: string | null
): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/berths/${id}/change_max_width`, payload, new RestQueryParams(), headers);
}
