import {authTokenSelector, Marina, IconSize, Loader, Translation} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import {Trash2} from 'react-feather';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RootState} from '../../../../../../store/reducers';
import {
    addPhoto,
    changeGalleryOrder,
    fetchMarinaDetails,
    ISetGalleryOrderPayload,
    resetToInitialMarinaEditWizardState,
} from '../../../../../../store/reducers/marinaEditWizardSlice';
import {changeIsModalOpen, getPhotoId, ModalNames, setMarina, setModalName} from '../../../../../../store/reducers/modalSlice';
import {
    currentlyEditedMarina,
    isActionSuccessfulSelector,
    isMarinaEditWizardLoadingSelector,
    isMarinaEditWizardInitializedSelector,
} from '../../../../../../store/selectors/marinaEditWizardSelectors';
import styles from './style.module.scss';

interface IConnectedGalleryMarinaComponentProps {
    readonly marina: Marina | null;
    readonly isLoading: boolean;
    readonly fetchMarinaDetails: typeof fetchMarinaDetails;
    readonly isGalleryMarinaComponentInitialized: boolean;
    readonly resetToInitialMarinaEditWizardState: typeof resetToInitialMarinaEditWizardState;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly setModalName: typeof setModalName;
    readonly setMarina: typeof setMarina;
    readonly getPhotoId: typeof getPhotoId;
    readonly addPhoto: typeof addPhoto;
    readonly authToken: string;
    readonly isActionSuccessful: boolean;
    readonly changeGalleryOrder: typeof changeGalleryOrder;
}

export interface IFileUploadListElement {
    name: string;
    fileKey: string;
    url: string;
}

const GalleryMarinaComponent: FC<IConnectedGalleryMarinaComponentProps> = ({
    marina,
    isLoading,
    fetchMarinaDetails,
    isGalleryMarinaComponentInitialized,
    resetToInitialMarinaEditWizardState,
    changeIsModalOpen,
    setModalName,
    setMarina,
    getPhotoId,
    isActionSuccessful,
    changeGalleryOrder,
}) => {
    const params = useParams(),
        marinaId = params.id,
        dispatch = useDispatch();

    const [photos, setPhotos] = useState(marina?.photos ? marina?.photos : []);

    useEffect(() => {
        dispatch(fetchMarinaDetails(marinaId ? marinaId : null));
        return () => {
            dispatch(resetToInitialMarinaEditWizardState());
        };
    }, [marinaId, dispatch, fetchMarinaDetails, isActionSuccessful, resetToInitialMarinaEditWizardState]);

    useEffect(() => {
        const photosList = marina?.photos ? marina?.photos : [];
        setPhotos(photosList);
    }, [marina?.photos, isGalleryMarinaComponentInitialized]);

    // Drag&Drop section

    const onDragEnd = (result: DropResult) => {
        const {source, destination} = result;
        if (!destination) return;
        const payload: ISetGalleryOrderPayload = {
            marinaId: marinaId,
            order: {
                photos: [
                    {
                        id: result.draggableId,
                        position: destination.index,
                    },
                ],
            },
        };

        const items = Array.from(photos);
        const [newOrder] = items.splice(source.index, 1);
        items.splice(destination.index, 0, newOrder);

        setPhotos(items);
        changeGalleryOrder(payload);
    };
    // Modals
    function openDeletePhotoModal(id: string): void {
        setModalName(ModalNames.DELETE_PHOTO);
        changeIsModalOpen(true);
        getPhotoId(id);
        setMarina(marina);
    }
    function openChangeCoverPhoto(marina: Marina | null): void {
        setModalName(ModalNames.CHANGE_PHOTO);
        changeIsModalOpen(true);
        setMarina(marina);
    }
    function openAddPhotoModal(marina: Marina | null): void {
        setModalName(ModalNames.ADD_PHOTO);
        changeIsModalOpen(true);
        setMarina(marina);
    }
    return (
        <div className={styles.container} data-scroller>
            <h2 className="title main">
                <Translation text="editMenuItems.sections.marina_gallery.title" />
            </h2>
            <div className={styles.section} data-section="cover_photo">
                <div className={styles.galleryHeader}>
                    <div>
                        <h3>
                            <Translation text="editMenuItems.sections.marina_gallery.sectionTitleCoverPhoto" />
                        </h3>
                        <p>
                            <Translation text="editMenuItems.sections.marina_gallery.coverSubtitle" />
                        </p>
                    </div>

                    <div>
                        <button onClick={() => openChangeCoverPhoto(marina)} className="btn btn-underline">
                            <Translation text="buttons.changePhoto" />
                        </button>
                    </div>
                </div>
                <div>
                    {marina?.cover?.fileUrls?.original ? (
                        <img
                            className={`${styles.coverPhoto} ${marina?.cover?.fileUrls?.original ? '' : styles.placeholder}`}
                            src={marina?.cover.fileUrls?.original}
                            alt=""
                        />
                    ) : (
                        <div className={`${styles.coverPhoto} ${styles.placeholder}`} />
                    )}
                </div>
            </div>
            <div className={styles.section} data-section="all_photos">
                <div className={styles.galleryHeader}>
                    <div>
                        <h3 className="title main">
                            <Translation text="editMenuItems.sections.marina_gallery.mediaTitle" />
                        </h3>
                        <p>
                            <Translation text="editMenuItems.sections.marina_gallery.mediaSubtitle" />
                        </p>
                    </div>
                    <div>
                        <button
                            disabled={marina?.photos.length === 6}
                            className="btn btn-underline"
                            onClick={() => openAddPhotoModal(marina)}>
                            <Translation text="buttons.addPhoto" />
                        </button>
                    </div>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="photos" direction="horizontal">
                        {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                                {photos !== null && photos.length > 0 && photos !== undefined
                                    ? photos.map((item: any, index: any) => {
                                          return (
                                              <Draggable key={item.file.id} draggableId={item.file.id} index={index}>
                                                  {(provided) => (
                                                      <li
                                                          key={item?.file.id}
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}>
                                                          <img
                                                              className={styles.galleryPhoto}
                                                              src={item?.file.fileUrls ? item?.file.fileUrls?.original : ''}
                                                              alt=""></img>
                                                          <button
                                                              onClick={() => openDeletePhotoModal(item?.file.id)}
                                                              disabled={item?.file.fileUrls?.original === marina?.cover?.fileUrls?.original}
                                                              className={`btn btn-icon ${styles.deleteBtn}`}>
                                                              <Trash2 id={item?.id} size={IconSize.BerthDeleteButton} />
                                                          </button>
                                                      </li>
                                                  )}
                                              </Draggable>
                                          );
                                      })
                                    : 'no data'}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <Loader showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        marina: currentlyEditedMarina(state),
        isLoading: isMarinaEditWizardLoadingSelector(state),
        isActionSuccessful: isActionSuccessfulSelector(state),
        isGalleryMarinaComponentInitialized: isMarinaEditWizardInitializedSelector(state),
    }),
    {
        fetchMarinaDetails,
        resetToInitialMarinaEditWizardState,
        changeIsModalOpen,
        setModalName,
        setMarina,
        getPhotoId,
        addPhoto,
        changeGalleryOrder,
    }
)(GalleryMarinaComponent);
