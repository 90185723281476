import {FormControlType, ValidationRules} from 'marine-panel-common-web';

export const calendarFormConfig: any = (eventDates: any[]) => ({
    controlType: 'group',
    class: 'reservation-datepicker',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'date',
            class: 'form-row',
            controls: {
                date: {
                    hostClass: 'col-100 react-datepicker-host month-calendar event-calendar reservation-calendar',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: '',
                    formControlType: FormControlType.EVENT_CALENDAR,
                    eventDates: eventDates,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                },
            },
        },
    ],
});
