import {InputType, ValidationRules, FormControlType, IFormConfig} from 'marine-panel-common-web';

export const noteFormConfig = (value?: any, isDisabled?: boolean): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form note-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'note',
                controlType: 'group',
                class: 'row',
                controls: {
                    note: {
                        controlType: 'control',
                        defaultValue: value ? value : '',
                        formControlType: FormControlType.TEXTAREA,
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: '',
                        isLabelHidden: false,
                        label: 'editMenuItems.sections.berth_note.description',
                        type: InputType.TEXT,
                        disabled: isDisabled,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
        ],
    };
};
